import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUserActivateMutation } from '../../../graphql/generated';

export const useActivationController = () => {
  const params = useParams<{ activationLinkId: string }>();
  const [activate, { loading, error }] = useUserActivateMutation({
    variables: { activationLinkId: params.activationLinkId }
  });

  useEffect(() => {
    if (params.activationLinkId) {
      activate();
    }
  }, [params.activationLinkId, activate]);

  return {
    error,
    loading
  };
};
