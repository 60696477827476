import { FormControl, Stack, styled, TextField } from '@mui/material';

export const FormControlStyled = styled(FormControl)`
  width: 100%;
  align-items: flex-start;
`;

export const TextFieldStyled = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const StackStyled = styled(Stack)`
  flex-direction: row;
`;
