import React from 'react';
import { FieldsStackStyled, FooterStackStyled, RowStyled } from '../MentorBlockEdit.styles';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';
import {
  bold,
  code,
  codeBlock,
  divider,
  hr,
  image,
  italic,
  link,
  orderedListCommand,
  quote,
  strikethrough,
  title,
  unorderedListCommand
} from '@uiw/react-md-editor';
import { ContentErrorStyled } from '../../../publications/publicationForm/PublicationForm.styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Save } from '@material-ui/icons';
import { useMainInfoController } from './MainInfo.controller';
import { Maybe, User } from '../../../../graphql/generated';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DateInputsRowStyled, StackInput } from './MainInfo.styles';
import MarkdownEditor from '../../../sharedComponents/markdown/MarkdownEditor';

type Mentors = Array<Pick<User, 'firstName' | 'image' | 'jobTitle' | 'lastName' | '_id'> | null>;

export interface MainProps {
  mentorBlockData: {
    title: Maybe<string>;
    description: Maybe<string>;
    stack: Array<Maybe<string>>;
    isGroup: boolean;
    mentors: Mentors;
    startDate: string;
    endDate: string;
    ownerId: string;
  };
}

export const MainInfo: React.FC<MainProps> = ({ mentorBlockData }) => {
  const {
    onSubmitFormHandler,
    register,
    control,
    formErrors,
    loading,
    userLoading,
    users,
    usersSearchWithDebounce,
    getFullName,
    watchStartDate,
    stackOptions,
    emptyFieldValue,
    clearDate,
    cancelButtonHandle
  } = useMainInfoController({ mentorBlockData });
  return (
    <div>
      <form
        action="src/app/mentorium/mentorBlockForm/MentorBlockEdit.tsx"
        onSubmit={e => {
          e.preventDefault();
          onSubmitFormHandler();
        }}
      >
        <FieldsStackStyled>
          <TextField
            label="Title"
            size="small"
            id="title"
            fullWidth={true}
            autoFocus={true}
            error={!!formErrors.title}
            helperText={formErrors.title?.message}
            {...register('title')}
          />

          <InputLabel children={'Description'} error={!!formErrors.description} />
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MarkdownEditor
                commands={[
                  title,
                  bold,
                  italic,
                  strikethrough,
                  divider,
                  link,
                  image,
                  divider,
                  code,
                  codeBlock,
                  quote,
                  hr,
                  divider,
                  orderedListCommand,
                  unorderedListCommand
                ]}
                preview="edit"
                height={500}
                value={value || ''}
                onChange={(values: string) => onChange(values)}
              />
            )}
          />
          {formErrors.description && <ContentErrorStyled>Description is required</ContentErrorStyled>}

          <Controller
            control={control}
            name="stack"
            render={({ field: { ref, onChange, value } }) => (
              <Autocomplete
                id="stack"
                size="small"
                multiple
                freeSolo
                value={value || []}
                blurOnSelect={false}
                options={stackOptions}
                onChange={(_, data) => onChange(data)}
                renderInput={params => {
                  const inputValue = params.inputProps.value ? params.inputProps.value.toString().trim() : '';
                  return (
                    <StackInput>
                      <TextField {...params} inputRef={ref} variant="outlined" label="Stack" size="small" />
                      <Button
                        sx={{ whiteSpace: 'nowrap' }}
                        onClick={() => {
                          onChange([...value, inputValue]);
                          params.inputProps.onChange && params.inputProps.onChange(emptyFieldValue);
                        }}
                        disabled={!inputValue.length}
                        variant="contained"
                      >
                        Add my
                      </Button>
                    </StackInput>
                  );
                }}
              />
            )}
          />

          <Controller
            control={control}
            name={'mentors'}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Autocomplete
                id="mentors"
                size="small"
                loading={userLoading}
                multiple
                filterSelectedOptions={true}
                value={value || []}
                options={users || []}
                getOptionLabel={option => getFullName(option)}
                onChange={(_, data) => onChange(data)}
                renderInput={params => (
                  <TextField
                    {...params}
                    {...field}
                    inputRef={ref}
                    variant="outlined"
                    label="Mentors"
                    size="small"
                    onChange={e => usersSearchWithDebounce(e.target.value)}
                  />
                )}
              />
            )}
          />

          <RowStyled>
            <Typography>One on One</Typography>

            <Controller
              name="isGroup"
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <Switch checked={value} onChange={e => onChange(e.target.checked)} />
              )}
            />

            <Typography>Group</Typography>
          </RowStyled>

          <DateInputsRowStyled>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Stack>
                      <DateTimePicker
                        {...field}
                        label="Start Course"
                        disablePast
                        views={['year', 'month', 'day', 'hours', 'minutes']}
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: !!error
                          }
                        }}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock
                        }}
                      />
                      <FormHelperText error>{error?.message}</FormHelperText>
                    </Stack>
                  );
                }}
              />

              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Stack>
                    <DateTimePicker
                      disablePast
                      {...field}
                      label="End Course"
                      minDateTime={watchStartDate}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: !!error
                        }
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock
                      }}
                    />
                    <FormHelperText error>{error?.message}</FormHelperText>
                  </Stack>
                )}
              />
            </LocalizationProvider>
            <Button variant="outlined" children="Clear Dates" onClick={clearDate} />
          </DateInputsRowStyled>
        </FieldsStackStyled>

        <FooterStackStyled>
          <Button variant="outlined" children="cancel" onClick={cancelButtonHandle} />
          <Button
            variant="outlined"
            children="Save"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={18} /> : <Save />}
            type="submit"
          />
        </FooterStackStyled>
      </form>
    </div>
  );
};
