import React from 'react';
import { Button, CircularProgress, Dialog, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { Send } from '@material-ui/icons';
import { DialogActionsStyled, DialogContentStyled } from './EmailChangeDialog.styles';
import { UseFormReturn } from 'react-hook-form';

interface EmailChangeDialogProps {
  showDialog: boolean;
  onDialogCloseHandle: () => void;
  onDialogSubmitHandle: () => void;
  emailUpdateLoading: boolean;
  register: UseFormReturn['register'];
  currentEmail: string | null | undefined;
  updatedEmail: string | null | undefined;
}

const EmailChangeDialog: React.FC<EmailChangeDialogProps> = props => {
  const {
    register,
    showDialog,
    onDialogCloseHandle,
    onDialogSubmitHandle,
    emailUpdateLoading,
    currentEmail,
    updatedEmail
  } = props;

  return (
    <Dialog open={showDialog} onClose={onDialogCloseHandle}>
      <form
        onSubmit={e => {
          e.preventDefault();
          onDialogSubmitHandle();
        }}
      >
        <DialogTitle>Confirm your password</DialogTitle>
        <DialogContentStyled>
          <DialogContentText>
            You are trying to change the current email address for this account from <b>{currentEmail}</b> to{' '}
            <b>{updatedEmail}</b>! To apply this action, please enter your current password! We will send confirmation
            to new email address.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Current password"
            type="password"
            fullWidth
            {...register('password')}
          />
        </DialogContentStyled>
        <DialogActionsStyled>
          <Button onClick={onDialogCloseHandle} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={emailUpdateLoading}
            startIcon={emailUpdateLoading ? <CircularProgress size={18} /> : <Send />}
            type="submit"
            color="primary"
            variant="outlined"
          >
            Submit
          </Button>
        </DialogActionsStyled>
      </form>
    </Dialog>
  );
};

export default EmailChangeDialog;
