import React from 'react';
import { CircularProgress, Pagination } from '@mui/material';
import { useUsersListController } from './UsersList.controller';
import { PaginationStyled, RootStyled } from './UsersList.styles';
import SearchHeader from '../../globalSearch/searchHeader/SearchHeader';
import { NoResults } from '../../globalSearch/noResults/NoResults';
import { UserCard } from './userCard/UserCard';

const UsersList: React.FC = () => {
  const { loading, usersList, page, totalPages, onPageChange, search, profileId, isMobile } = useUsersListController();

  return (
    <>
      {search && <SearchHeader />}
      {loading ? (
        <CircularProgress />
      ) : !usersList?.length ? (
        <NoResults />
      ) : (
        <RootStyled>
          {usersList?.map(user => {
            const fullName = `${user?.firstName} ${user?.lastName}`.trim();
            const link = `/${profileId === user?._id ? 'profile' : 'user'}/${user?._id}`;
            return (
              <UserCard
                key={user?._id}
                imageUrl={user?.image || ''}
                name={fullName}
                jobTitle={user?.jobTitle || 'Unemployed'}
                userId={user?._id || ''}
                link={link}
              />
            );
          })}
        </RootStyled>
      )}
      {!!totalPages && (
        <PaginationStyled>
          <Pagination
            siblingCount={Number(!isMobile)}
            count={totalPages || 0}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={onPageChange}
          />
        </PaginationStyled>
      )}
    </>
  );
};

export default UsersList;
