import { useHistory } from 'react-router';
import {
  PublicationCreateDocument,
  PublicationUpdateDocument,
  usePublicationCreateMutation,
  usePublicationGetLazyQuery,
  usePublicationUpdateMutation
} from '../../../graphql/generated';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { REGEX, Validate } from '../../../helpers/validation';

interface IPublicationForm {
  title: string;
  description: string;
  content: string;
  image: string;
}

export const usePublicationFormController = () => {
  const history = useHistory();
  const { pubId }: { pubId: string } = useParams();
  const [ownerId, setOwnerId] = useState<string | undefined>();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, isSubmitting },
    reset,
    control
  } = useForm<IPublicationForm>({
    resolver: yupResolver(
      yup.object({
        title: yup.string().matches(REGEX.singleInput, Validate.singleInput.error).required('Title is required').trim(),
        image: yup.string().url().trim().matches(REGEX.singleInput, Validate.singleInput.error),
        description: yup
          .string()
          .matches(REGEX.singleInput, Validate.singleInput.error)
          .required('Description is required')
          .trim(),
        content: yup.string().matches(REGEX.content, Validate.content.error).required('Content is required').trim()
      })
    ),
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      image: '',
      description: '',
      content: ''
    }
  });

  const [publicationUpdate, { loading: updateLoading, error: updateError }] = usePublicationUpdateMutation();
  const [publicationCreate, { loading: createLoading, error: createError }] = usePublicationCreateMutation({
    update: (cache, { data }) => {
      if (!data?.publicationCreate) return;
      cache.modify({
        fields: {
          publications(existingPublications = []) {
            return {
              ...existingPublications,
              list: [data?.publicationCreate, ...existingPublications.list]
            };
          }
        }
      });
    }
  });
  const [publicationGet, { loading: getLoading, error: getError }] = usePublicationGetLazyQuery({
    variables: { pubId }
  });

  useEffect(() => {
    if (pubId) {
      publicationGet().then(({ data }) => {
        setOwnerId(data?.publication?.owner?._id);
        reset({
          title: data?.publication?.title || '',
          image: data?.publication?.image || '',
          description: data?.publication?.description || '',
          content: data?.publication?.content || ''
        });
      });
    }
  }, [pubId]);

  const onSubmitFormHandle = handleSubmit(async formData => {
    if (pubId) {
      const { errors } = await publicationUpdate({
        variables: {
          pubId,
          values: { ...formData }
        }
      });
      if (!errors) {
        history.push(`/publication/${pubId}`);
      }
    } else {
      const { data, errors } = await publicationCreate({ variables: { values: { ...formData } } });
      if (!errors) history.push(`/publication/${data?.publicationCreate?._id}`);
    }
  });

  if (isSubmitting) {
    reset(getValues());
  }

  const backButton = () => history.goBack();

  return {
    onSubmitFormHandle,
    loading: getLoading || updateLoading || createLoading,
    error: getError?.message || updateError?.message || createError?.message,
    control,
    register,
    formErrors: errors,
    isDirty,
    backButton,
    permission: pubId ? PublicationUpdateDocument : PublicationCreateDocument,
    ownerId
  };
};
