import { Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const ContainerStyled = styled(Stack)`
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
`;

export const LinkStyled = styled(Link)<{ isActive: boolean }>`
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  ${({ theme, isActive }) => {
    return isActive
      ? {
          backgroundColor: theme.palette.primary.main,
          color: 'white'
        }
      : {
          outline: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main
        };
  }};

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
    text-decoration: unset;
    color: white;
  }

  :active {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export const SearchHeaderStyled = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  ${({ theme }) => ({
    ...theme.typography.subtitle1,
    color: theme.palette.primary.main
  })};
`;
