import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

export const useContentEditPageController = () => {
  const {
    location: { pathname },
    replace
  } = useHistory();
  const { taskId, lessonId }: { taskId: string; lessonId: string } = useParams();

  const isLessonEdit = /\b(lesson)\b/.test(pathname);
  const isTaskEdit = /\b(task)\b/.test(pathname);
  const closeFormHandle = () => {
    const match = pathname.match(/\/manageContent\/(task|lesson)\/create/);

    if (match) {
      const basePath = match[1]; // 'task' or 'lesson'
      const newBasePath = basePath + 's'; // 'tasks' or 'lessons'
      const newPath = `/manageContent/${newBasePath}`;

      replace(newPath);
    }

    if (taskId) replace(`/manageContent/task/${taskId}`);
    if (lessonId) replace(`/manageContent/lesson/${lessonId}`);
  };

  return {
    isLessonEdit,
    isTaskEdit,
    closeFormHandle
  };
};
