import React, { FC, useEffect, useState } from 'react';
import { Maybe, ScheduleSlot } from '../../../../../graphql/generated';
import { Paper, Stack, ToggleButton, Typography, Tooltip } from '@mui/material';
import { CalendarTodayOutlined, ViewWeekOutlined } from '@material-ui/icons';
import { EventsStack } from '../../../../sharedComponents/calendars/eventsStack/EventsStack';
import { WeekSimpleCalendar } from '../../../../sharedComponents/calendars/weekSimpleCalendar/WeekSimpleCalendar';

interface EventsWidgetProps {
  weekEvents: Array<Maybe<ScheduleSlot>>;
  stackEvents: Array<Maybe<ScheduleSlot>>;
}
export const EventsWidget: FC<EventsWidgetProps> = ({ weekEvents, stackEvents }) => {
  const [isStackView, setIsStackView] = useState(false);

  useEffect(() => {
    const viewMode = localStorage.getItem('mentorEventWidget') === 'true';
    setIsStackView(viewMode);
  }, []);
  const viewToggle = () => {
    setIsStackView(prev => {
      localStorage.setItem('mentorEventWidget', String(!prev));
      return !prev;
    });
  };

  const viewTitle = isStackView ? 'Next 5 Stream Events' : 'Next Week Stream Events';

  return !stackEvents.length && !weekEvents.length ? null : (
    <Paper style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography variant="subtitle1">{viewTitle}</Typography>
        <ToggleButton size="small" value={isStackView} onClick={viewToggle}>
          {isStackView ? (
            <Tooltip title="Closest 7 days view" placement="top">
              <ViewWeekOutlined fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip title="Closest 5 events view" placement="top">
              <CalendarTodayOutlined fontSize="small" />
            </Tooltip>
          )}
        </ToggleButton>
      </Stack>
      {isStackView ? <EventsStack events={stackEvents} /> : <WeekSimpleCalendar events={weekEvents} />}
    </Paper>
  );
};
