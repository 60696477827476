import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/react-hooks';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Comment = {
  __typename?: 'Comment';
  _id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<User>>>;
  owner?: Maybe<User>;
  parent?: Maybe<CommentParentType>;
  parentType?: Maybe<ParentType>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CommentParentType = Comment | Publication | ScheduleSlot | TaskAnswer;

export type CommentsPaginated = {
  __typename?: 'CommentsPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<Comment>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type Company = {
  __typename?: 'Company';
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<User>>>;
  link?: Maybe<Scalars['String']>;
  problems?: Maybe<Array<Maybe<Problem>>>;
  title?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CompanyPaginated = {
  __typename?: 'CompanyPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<Company>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type CompanySearch = {
  __typename?: 'CompanySearch';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type EmailUpdateInput = {
  activationLinkId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type Entity = Lesson | MentorBlock | Task;

export enum EntityType {
  Lesson = 'Lesson',
  MentorBlock = 'MentorBlock',
  Task = 'Task'
}

export type Filter = {
  column?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type GlobalSearchResult = {
  __typename?: 'GlobalSearchResult';
  companies?: Maybe<CompanyPaginated>;
  mentorBlocks?: Maybe<MentorBlockPaginated>;
  problems?: Maybe<ProblemsPaginated>;
  publications?: Maybe<PublicationPaginated>;
  totalCount?: Maybe<Scalars['Int']>;
  users?: Maybe<UsersPaginated>;
};

export type Homework = {
  __typename?: 'Homework';
  hidden?: Maybe<Scalars['Boolean']>;
  tasks?: Maybe<Array<Maybe<Task>>>;
};

export type Lesson = {
  __typename?: 'Lesson';
  _id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  homework?: Maybe<Homework>;
  owner?: Maybe<User>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type LessonInput = {
  content?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type LessonsPaginated = {
  __typename?: 'LessonsPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<Lesson>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type MentorBlock = {
  __typename?: 'MentorBlock';
  _id: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  isGroup: Scalars['Boolean'];
  isStarred?: Maybe<Scalars['Boolean']>;
  mentees?: Maybe<Array<Maybe<User>>>;
  mentors?: Maybe<Array<Maybe<User>>>;
  owner?: Maybe<User>;
  requests?: Maybe<Array<Maybe<User>>>;
  stack?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MentorBlockInput = {
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  isGroup: Scalars['Boolean'];
  mentors?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  stack?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MentorBlockPaginated = {
  __typename?: 'MentorBlockPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<MentorBlock>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  commentCreate?: Maybe<Comment>;
  commentDelete?: Maybe<Scalars['Int']>;
  commentLike?: Maybe<Comment>;
  commentUpdate?: Maybe<Comment>;
  companyCreate?: Maybe<Company>;
  companyDelete?: Maybe<Scalars['String']>;
  companyLike?: Maybe<Company>;
  companyUpdate?: Maybe<Company>;
  lessonCreate?: Maybe<Lesson>;
  lessonDelete?: Maybe<Scalars['Int']>;
  lessonUpdate?: Maybe<Lesson>;
  mentorBlockAcceptRequest?: Maybe<MentorBlock>;
  mentorBlockCreate?: Maybe<MentorBlock>;
  mentorBlockDelete?: Maybe<Scalars['Int']>;
  mentorBlockJoin?: Maybe<MentorBlock>;
  mentorBlockRemoveMentee?: Maybe<MentorBlock>;
  mentorBlockRemoveMenteeSelf?: Maybe<MentorBlock>;
  mentorBlockRemoveMentorSelf?: Maybe<MentorBlock>;
  mentorBlockStar?: Maybe<MentorBlock>;
  mentorBlockUpdate?: Maybe<MentorBlock>;
  notificationDeleteById?: Maybe<Notification>;
  notificationMarkAsReadById?: Maybe<Notification>;
  problemCreate?: Maybe<Problem>;
  problemDelete?: Maybe<Scalars['String']>;
  problemUpdate?: Maybe<Problem>;
  publicationCreate?: Maybe<Publication>;
  publicationDelete?: Maybe<Scalars['String']>;
  publicationLike?: Maybe<Publication>;
  publicationUpdate?: Maybe<Publication>;
  scheduleSlotCreate?: Maybe<ScheduleSlot>;
  scheduleSlotDelete?: Maybe<Scalars['Int']>;
  scheduleSlotUpdate?: Maybe<ScheduleSlot>;
  solutionCreate?: Maybe<Solution>;
  solutionDelete?: Maybe<Scalars['String']>;
  solutionLike?: Maybe<Solution>;
  solutionUpdate?: Maybe<Solution>;
  taskAnswerCreate?: Maybe<TaskAnswer>;
  taskAnswerDelete?: Maybe<Scalars['Int']>;
  taskAnswerStatusUpdate?: Maybe<TaskAnswer>;
  taskAnswerUpdate?: Maybe<TaskAnswer>;
  taskCreate?: Maybe<Task>;
  taskDelete?: Maybe<Scalars['Int']>;
  taskUpdate?: Maybe<Task>;
  userActivate?: Maybe<Scalars['String']>;
  userCheckAuth: UserAuthData;
  userCreate: Scalars['String'];
  userDelete?: Maybe<Scalars['String']>;
  userEmailUpdate?: Maybe<Scalars['String']>;
  userFriendInvite?: Maybe<User>;
  userFriendReject?: Maybe<User>;
  userPasswordReset?: Maybe<Scalars['String']>;
  userPasswordResetRequest?: Maybe<Scalars['String']>;
  userRefreshToken: UserAuthData;
  userResendActivationLink?: Maybe<Scalars['String']>;
  userStarMentorBlock?: Maybe<User>;
  userStarPublication?: Maybe<User>;
  userUpdate?: Maybe<User>;
};


export type MutationcommentCreateArgs = {
  content: Scalars['String'];
  parentId: Scalars['ID'];
};


export type MutationcommentDeleteArgs = {
  commentId: Scalars['ID'];
};


export type MutationcommentLikeArgs = {
  commentId: Scalars['ID'];
};


export type MutationcommentUpdateArgs = {
  commentId: Scalars['ID'];
  content: Scalars['String'];
};


export type MutationcompanyCreateArgs = {
  data?: InputMaybe<CompanyInput>;
};


export type MutationcompanyDeleteArgs = {
  companyId: Scalars['ID'];
};


export type MutationcompanyLikeArgs = {
  companyId: Scalars['ID'];
};


export type MutationcompanyUpdateArgs = {
  companyId: Scalars['ID'];
  data?: InputMaybe<CompanyInput>;
};


export type MutationlessonCreateArgs = {
  data?: InputMaybe<LessonInput>;
};


export type MutationlessonDeleteArgs = {
  lessonId?: InputMaybe<Scalars['ID']>;
};


export type MutationlessonUpdateArgs = {
  lessonId?: InputMaybe<Scalars['ID']>;
  values?: InputMaybe<LessonInput>;
};


export type MutationmentorBlockAcceptRequestArgs = {
  blockId?: InputMaybe<Scalars['ID']>;
  usersIdList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationmentorBlockCreateArgs = {
  data?: InputMaybe<MentorBlockInput>;
};


export type MutationmentorBlockDeleteArgs = {
  blockId?: InputMaybe<Scalars['ID']>;
};


export type MutationmentorBlockJoinArgs = {
  blockId?: InputMaybe<Scalars['ID']>;
};


export type MutationmentorBlockRemoveMenteeArgs = {
  blockId?: InputMaybe<Scalars['ID']>;
  usersIdList?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationmentorBlockRemoveMenteeSelfArgs = {
  blockId?: InputMaybe<Scalars['ID']>;
};


export type MutationmentorBlockRemoveMentorSelfArgs = {
  blockId?: InputMaybe<Scalars['ID']>;
};


export type MutationmentorBlockStarArgs = {
  blockId?: InputMaybe<Scalars['ID']>;
};


export type MutationmentorBlockUpdateArgs = {
  blockId?: InputMaybe<Scalars['ID']>;
  values?: InputMaybe<MentorBlockInput>;
};


export type MutationnotificationDeleteByIdArgs = {
  noteId?: InputMaybe<Scalars['ID']>;
};


export type MutationnotificationMarkAsReadByIdArgs = {
  noteId?: InputMaybe<Scalars['ID']>;
};


export type MutationproblemCreateArgs = {
  data?: InputMaybe<ProblemInput>;
};


export type MutationproblemDeleteArgs = {
  problemId: Scalars['ID'];
};


export type MutationproblemUpdateArgs = {
  data?: InputMaybe<ProblemInput>;
  problemId: Scalars['ID'];
};


export type MutationpublicationCreateArgs = {
  values?: InputMaybe<PublicationInput>;
};


export type MutationpublicationDeleteArgs = {
  pubId: Scalars['ID'];
};


export type MutationpublicationLikeArgs = {
  pubId: Scalars['ID'];
};


export type MutationpublicationUpdateArgs = {
  pubId: Scalars['ID'];
  values?: InputMaybe<PublicationInput>;
};


export type MutationscheduleSlotCreateArgs = {
  data?: InputMaybe<ScheduleSlotInput>;
};


export type MutationscheduleSlotDeleteArgs = {
  slotId?: InputMaybe<Scalars['ID']>;
};


export type MutationscheduleSlotUpdateArgs = {
  slotId?: InputMaybe<Scalars['ID']>;
  values?: InputMaybe<ScheduleSlotUpdateInput>;
};


export type MutationsolutionCreateArgs = {
  content: Scalars['String'];
  problemId: Scalars['ID'];
};


export type MutationsolutionDeleteArgs = {
  solutionId: Scalars['ID'];
};


export type MutationsolutionLikeArgs = {
  solutionId: Scalars['ID'];
};


export type MutationsolutionUpdateArgs = {
  content: Scalars['String'];
  solutionId: Scalars['ID'];
};


export type MutationtaskAnswerCreateArgs = {
  data?: InputMaybe<TaskAnswerInput>;
};


export type MutationtaskAnswerDeleteArgs = {
  answerId: Scalars['ID'];
};


export type MutationtaskAnswerStatusUpdateArgs = {
  answerId: Scalars['ID'];
  status: Status;
};


export type MutationtaskAnswerUpdateArgs = {
  answerId: Scalars['ID'];
  content: Scalars['String'];
};


export type MutationtaskCreateArgs = {
  data?: InputMaybe<TaskInput>;
};


export type MutationtaskDeleteArgs = {
  taskId?: InputMaybe<Scalars['ID']>;
};


export type MutationtaskUpdateArgs = {
  taskId?: InputMaybe<Scalars['ID']>;
  values?: InputMaybe<TaskInput>;
};


export type MutationuserActivateArgs = {
  activationLinkId: Scalars['String'];
};


export type MutationuserCreateArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationuserDeleteArgs = {
  userId: Scalars['ID'];
};


export type MutationuserEmailUpdateArgs = {
  userId: Scalars['ID'];
  values?: InputMaybe<EmailUpdateInput>;
};


export type MutationuserFriendInviteArgs = {
  friendId: Scalars['ID'];
};


export type MutationuserFriendRejectArgs = {
  friendId: Scalars['ID'];
};


export type MutationuserPasswordResetArgs = {
  password: Scalars['String'];
  resetId: Scalars['String'];
};


export type MutationuserPasswordResetRequestArgs = {
  email: Scalars['String'];
};


export type MutationuserResendActivationLinkArgs = {
  email: Scalars['String'];
};


export type MutationuserStarMentorBlockArgs = {
  blockId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationuserStarPublicationArgs = {
  pubId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationuserUpdateArgs = {
  userId: Scalars['ID'];
  values?: InputMaybe<UserInput>;
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ID'];
  actionType?: Maybe<Scalars['String']>;
  entity?: Maybe<NotificationEntity>;
  entityType?: Maybe<NotificationEntityType>;
  initiator?: Maybe<User>;
  isRead?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<User>;
};

export type NotificationEntity = Comment | MentorBlock | Publication | ScheduleSlot | User;

export enum NotificationEntityType {
  Comment = 'Comment',
  MentorBlock = 'MentorBlock',
  Publication = 'Publication',
  ScheduleSlot = 'ScheduleSlot',
  User = 'User'
}

export type NotificationsPaginated = {
  __typename?: 'NotificationsPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<Notification>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export enum ParentType {
  Comment = 'Comment',
  Publication = 'Publication',
  ScheduleSlot = 'ScheduleSlot',
  TaskAnswer = 'TaskAnswer'
}

export type Problem = {
  __typename?: 'Problem';
  _id: Scalars['ID'];
  company?: Maybe<Company>;
  content?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  solutions?: Maybe<Array<Maybe<Solution>>>;
  title?: Maybe<Scalars['String']>;
};

export type ProblemInput = {
  company?: InputMaybe<Scalars['ID']>;
  content: Scalars['String'];
  hidden?: InputMaybe<Scalars['Boolean']>;
  jobTitle: Scalars['String'];
  title: Scalars['String'];
};

export type ProblemsPaginated = {
  __typename?: 'ProblemsPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<Problem>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type Publication = {
  __typename?: 'Publication';
  _id: Scalars['ID'];
  commentsCount?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<User>>>;
  owner?: Maybe<User>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type PublicationInput = {
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PublicationPaginated = {
  __typename?: 'PublicationPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<Publication>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  comment?: Maybe<Comment>;
  comments: CommentsPaginated;
  companies?: Maybe<CompanyPaginated>;
  company?: Maybe<Company>;
  companySearch: CompanyPaginated;
  companySearchNew?: Maybe<Array<Maybe<CompanySearch>>>;
  globalSearch: GlobalSearchResult;
  lesson?: Maybe<Lesson>;
  lessonsGetByIdList?: Maybe<LessonsPaginated>;
  lessonsGetByOwnerId?: Maybe<LessonsPaginated>;
  login: UserAuthData;
  logout: Scalars['Boolean'];
  mentorBlock?: Maybe<MentorBlock>;
  mentorBlocks?: Maybe<MentorBlockPaginated>;
  mentorBlocksSearch?: Maybe<MentorBlockPaginated>;
  mentorGetAllByMenteeId?: Maybe<MentorBlockPaginated>;
  mentorGetAllByOwnerId?: Maybe<MentorBlockPaginated>;
  notificationsGetByOwnerId?: Maybe<NotificationsPaginated>;
  notificationsHasUnreadByOwnerId?: Maybe<Scalars['Int']>;
  problem?: Maybe<Problem>;
  problems?: Maybe<ProblemsPaginated>;
  problemsGetByUserId?: Maybe<ProblemsPaginated>;
  publication?: Maybe<Publication>;
  publicationSearch?: Maybe<PublicationPaginated>;
  publications?: Maybe<PublicationPaginated>;
  publicationsByUserId?: Maybe<Array<Maybe<Publication>>>;
  scheduleSlot?: Maybe<ScheduleSlot>;
  scheduleSlotsGetByParentId?: Maybe<ScheduleSlotsPaginated>;
  solution?: Maybe<Solution>;
  solutions?: Maybe<Array<Maybe<Solution>>>;
  taskAnswerGet?: Maybe<TaskAnswer>;
  taskAnswersListGet?: Maybe<TaskAnswersPaginated>;
  taskGet?: Maybe<Task>;
  tasksGetByIdList?: Maybe<TasksPaginated>;
  tasksGetByOwnerId?: Maybe<TasksPaginated>;
  user?: Maybe<User>;
  userSearch: UsersPaginated;
};


export type QuerycommentArgs = {
  commentId: Scalars['ID'];
};


export type QuerycommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  parentId: Scalars['ID'];
};


export type QuerycompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QuerycompanyArgs = {
  companyId: Scalars['ID'];
};


export type QuerycompanySearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  value: Scalars['String'];
};


export type QuerycompanySearchNewArgs = {
  title: Scalars['String'];
};


export type QueryglobalSearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  value: Scalars['String'];
};


export type QuerylessonArgs = {
  lessonId?: InputMaybe<Scalars['ID']>;
};


export type QuerylessonsGetByIdListArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QuerylessonsGetByOwnerIdArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['ID']>;
};


export type QueryloginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type QuerymentorBlockArgs = {
  blockId?: InputMaybe<Scalars['ID']>;
};


export type QuerymentorBlocksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QuerymentorBlocksSearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  value: Scalars['String'];
};


export type QuerymentorGetAllByMenteeIdArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  menteeId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QuerymentorGetAllByOwnerIdArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['ID']>;
};


export type QuerynotificationsGetByOwnerIdArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['ID']>;
};


export type QuerynotificationsHasUnreadByOwnerIdArgs = {
  dateSeen?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['ID']>;
};


export type QueryproblemArgs = {
  problemId: Scalars['ID'];
};


export type QueryproblemsArgs = {
  filter?: InputMaybe<Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryproblemsGetByUserIdArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  userId: Scalars['ID'];
};


export type QuerypublicationArgs = {
  pubId: Scalars['ID'];
};


export type QuerypublicationSearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};


export type QuerypublicationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QuerypublicationsByUserIdArgs = {
  userId: Scalars['ID'];
};


export type QueryscheduleSlotArgs = {
  slotId?: InputMaybe<Scalars['ID']>;
};


export type QueryscheduleSlotsGetByParentIdArgs = {
  entityType?: InputMaybe<EntityType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
};


export type QuerysolutionArgs = {
  solutionId: Scalars['ID'];
};


export type QuerytaskAnswerGetArgs = {
  answerId: Scalars['ID'];
};


export type QuerytaskAnswersListGetArgs = {
  blockId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
};


export type QuerytaskGetArgs = {
  taskId?: InputMaybe<Scalars['ID']>;
};


export type QuerytasksGetByIdListArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QuerytasksGetByOwnerIdArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['ID']>;
};


export type QueryuserArgs = {
  userId: Scalars['ID'];
};


export type QueryuserSearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  value: Scalars['String'];
};

export type ScheduleSlot = {
  __typename?: 'ScheduleSlot';
  _id: Scalars['ID'];
  endDate?: Maybe<Scalars['String']>;
  entity: Entity;
  entityType?: Maybe<EntityType>;
  ownerId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  streamLink?: Maybe<Scalars['String']>;
  streamProvider?: Maybe<Scalars['String']>;
};

export type ScheduleSlotInput = {
  endDate: Scalars['String'];
  entityId: Scalars['ID'];
  entityType: EntityType;
  parentId: Scalars['ID'];
  startDate: Scalars['String'];
  streamLink?: InputMaybe<Scalars['String']>;
};

export type ScheduleSlotPaginated = {
  __typename?: 'ScheduleSlotPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<ScheduleSlot>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type ScheduleSlotUpdateInput = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  streamLink?: InputMaybe<Scalars['String']>;
};

export type ScheduleSlotsPaginated = {
  __typename?: 'ScheduleSlotsPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list?: Maybe<Array<Maybe<ScheduleSlot>>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type Solution = {
  __typename?: 'Solution';
  _id: Scalars['ID'];
  complexity?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<User>>>;
  owner?: Maybe<User>;
  problemId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type SolutionCreateInput = {
  content: Scalars['String'];
  problemId: Scalars['ID'];
};

export enum Status {
  done = 'done',
  pending = 'pending',
  returned = 'returned'
}

export type Task = {
  __typename?: 'Task';
  _id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type TaskAnswer = {
  __typename?: 'TaskAnswer';
  _id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  parent?: Maybe<ScheduleSlot>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type TaskAnswerInput = {
  content: Scalars['String'];
  parentId: Scalars['ID'];
};

export type TaskAnswersPaginated = {
  __typename?: 'TaskAnswersPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<TaskAnswer>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type TaskInput = {
  content?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TasksPaginated = {
  __typename?: 'TasksPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<Task>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  about?: Maybe<Scalars['String']>;
  activationLinkId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  friendInviteReceived?: Maybe<Array<Maybe<User>>>;
  friendInviteSent?: Maybe<Array<Maybe<User>>>;
  friendList?: Maybe<Array<Maybe<User>>>;
  image?: Maybe<Scalars['String']>;
  isActivated?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastAccess?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  links?: Maybe<Array<Maybe<Scalars['String']>>>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  starredMentorBlocks?: Maybe<Array<Maybe<MentorBlock>>>;
  starredProblems?: Maybe<Array<Maybe<Problem>>>;
  starredPublications?: Maybe<Array<Maybe<Publication>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UserAuthData = {
  __typename?: 'UserAuthData';
  accessToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type UserInput = {
  about?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  starredMentorBlocks?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  starredProblems?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  starredPublications?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPaginated = {
  __typename?: 'UsersPaginated';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  list: Array<Maybe<User>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type CommentsGetQueryVariables = Exact<{
  parentId: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type CommentsGetQuery = { __typename?: 'Query', comments: { __typename?: 'CommentsPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename: 'Comment', _id: string, content?: string | null, createdAt?: string | null, updatedAt?: string | null, parent?: { __typename: 'Comment', _id: string } | { __typename: 'Publication', _id: string, title?: string | null } | { __typename: 'ScheduleSlot', _id: string } | { __typename: 'TaskAnswer', _id: string } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null> } };

export type CommentsGetTotalCountQueryVariables = Exact<{
  parentId: Scalars['ID'];
}>;


export type CommentsGetTotalCountQuery = { __typename?: 'Query', comments: { __typename?: 'CommentsPaginated', totalCount?: number | null } };

export type CommentGetQueryVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type CommentGetQuery = { __typename?: 'Query', comment?: { __typename: 'Comment', _id: string, content?: string | null, createdAt?: string | null, updatedAt?: string | null, parent?: { __typename: 'Comment', _id: string } | { __typename: 'Publication', _id: string, title?: string | null } | { __typename: 'ScheduleSlot', _id: string } | { __typename: 'TaskAnswer', _id: string } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type CommentLikeMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type CommentLikeMutation = { __typename?: 'Mutation', commentLike?: { __typename?: 'Comment', _id: string, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type CommentCreateMutationVariables = Exact<{
  parentId: Scalars['ID'];
  content: Scalars['String'];
}>;


export type CommentCreateMutation = { __typename?: 'Mutation', commentCreate?: { __typename: 'Comment', _id: string, content?: string | null, createdAt?: string | null, updatedAt?: string | null, parent?: { __typename: 'Comment', _id: string } | { __typename: 'Publication', _id: string, title?: string | null } | { __typename: 'ScheduleSlot', _id: string } | { __typename: 'TaskAnswer', _id: string } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type CommentUpdateMutationVariables = Exact<{
  commentId: Scalars['ID'];
  content: Scalars['String'];
}>;


export type CommentUpdateMutation = { __typename?: 'Mutation', commentUpdate?: { __typename: 'Comment', _id: string, content?: string | null, createdAt?: string | null, updatedAt?: string | null, parent?: { __typename: 'Comment', _id: string } | { __typename: 'Publication', _id: string, title?: string | null } | { __typename: 'ScheduleSlot', _id: string } | { __typename: 'TaskAnswer', _id: string } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type CommentDeleteMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type CommentDeleteMutation = { __typename?: 'Mutation', commentDelete?: number | null };

export type CompaniesGetQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type CompaniesGetQuery = { __typename?: 'Query', companies?: { __typename: 'CompanyPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'Company', _id: string, title?: string | null, description?: string | null, image?: string | null, link?: string | null, problems?: Array<{ __typename?: 'Problem', _id: string } | null> | null } | null> } | null };

export type CompanySearchQueryVariables = Exact<{
  value: Scalars['String'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type CompanySearchQuery = { __typename?: 'Query', companySearch: { __typename: 'CompanyPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'Company', _id: string, title?: string | null, description?: string | null, image?: string | null, link?: string | null, problems?: Array<{ __typename?: 'Problem', _id: string } | null> | null } | null> } };

export type CompaniesAutocompleteGetQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type CompaniesAutocompleteGetQuery = { __typename?: 'Query', companies?: { __typename: 'CompanyPaginated', list: Array<{ __typename?: 'Company', _id: string, title?: string | null, description?: string | null, image?: string | null } | null> } | null };

export type CompanyGetQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CompanyGetQuery = { __typename?: 'Query', company?: { __typename?: 'Company', _id: string, title?: string | null, description?: string | null, image?: string | null, link?: string | null, problems?: Array<{ __typename?: 'Problem', _id: string, title?: string | null, jobTitle?: string | null } | null> | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type CompanyWikiSearchQueryVariables = Exact<{
  title: Scalars['String'];
}>;


export type CompanyWikiSearchQuery = { __typename?: 'Query', companySearchNew?: Array<{ __typename?: 'CompanySearch', title?: string | null, description?: string | null, image?: string | null } | null> | null };

export type CompanyCreateMutationVariables = Exact<{
  data?: InputMaybe<CompanyInput>;
}>;


export type CompanyCreateMutation = { __typename?: 'Mutation', companyCreate?: { __typename?: 'Company', _id: string, title?: string | null, description?: string | null, image?: string | null, link?: string | null, problems?: Array<{ __typename?: 'Problem', _id: string, title?: string | null, jobTitle?: string | null } | null> | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type CompanyLikeMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CompanyLikeMutation = { __typename?: 'Mutation', companyLike?: { __typename?: 'Company', _id: string, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type GlobalSearchQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  value: Scalars['String'];
}>;


export type GlobalSearchQuery = { __typename?: 'Query', globalSearch: { __typename?: 'GlobalSearchResult', totalCount?: number | null, companies?: { __typename?: 'CompanyPaginated', totalCount?: number | null, list: Array<{ __typename?: 'Company', _id: string, title?: string | null, image?: string | null } | null> } | null, publications?: { __typename?: 'PublicationPaginated', totalCount?: number | null, list: Array<{ __typename?: 'Publication', _id: string, title?: string | null, image?: string | null } | null> } | null, problems?: { __typename?: 'ProblemsPaginated', totalCount?: number | null, list: Array<{ __typename?: 'Problem', _id: string, title?: string | null } | null> } | null, mentorBlocks?: { __typename?: 'MentorBlockPaginated', totalCount?: number | null, list: Array<{ __typename?: 'MentorBlock', _id: string, title?: string | null } | null> } | null, users?: { __typename?: 'UsersPaginated', totalCount?: number | null, list: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> } | null } };

export type LessonsGetByOwnerIdQueryVariables = Exact<{
  ownerId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type LessonsGetByOwnerIdQuery = { __typename?: 'Query', lessonsGetByOwnerId?: { __typename?: 'LessonsPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'Lesson', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | null> } | null };

export type LessonGetByIdQueryVariables = Exact<{
  lessonId?: InputMaybe<Scalars['ID']>;
}>;


export type LessonGetByIdQuery = { __typename?: 'Query', lesson?: { __typename?: 'Lesson', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | null };

export type LessonCreateMutationVariables = Exact<{
  data?: InputMaybe<LessonInput>;
}>;


export type LessonCreateMutation = { __typename?: 'Mutation', lessonCreate?: { __typename?: 'Lesson', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | null };

export type LessonDeleteMutationVariables = Exact<{
  lessonId?: InputMaybe<Scalars['ID']>;
}>;


export type LessonDeleteMutation = { __typename?: 'Mutation', lessonDelete?: number | null };

export type LessonUpdateMutationVariables = Exact<{
  lessonId?: InputMaybe<Scalars['ID']>;
  values?: InputMaybe<LessonInput>;
}>;


export type LessonUpdateMutation = { __typename?: 'Mutation', lessonUpdate?: { __typename?: 'Lesson', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | null };

export type MentorBlockCreateMutationVariables = Exact<{
  data?: InputMaybe<MentorBlockInput>;
}>;


export type MentorBlockCreateMutation = { __typename?: 'Mutation', mentorBlockCreate?: { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type MentorBlockUpdateMutationVariables = Exact<{
  blockId?: InputMaybe<Scalars['ID']>;
  values?: InputMaybe<MentorBlockInput>;
}>;


export type MentorBlockUpdateMutation = { __typename?: 'Mutation', mentorBlockUpdate?: { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type MentorBlockDeleteMutationVariables = Exact<{
  blockId: Scalars['ID'];
}>;


export type MentorBlockDeleteMutation = { __typename?: 'Mutation', mentorBlockDelete?: number | null };

export type MentorBlockJoinMutationVariables = Exact<{
  blockId?: InputMaybe<Scalars['ID']>;
}>;


export type MentorBlockJoinMutation = { __typename?: 'Mutation', mentorBlockJoin?: { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type MentorBlockRemoveMenteeMutationVariables = Exact<{
  blockId?: InputMaybe<Scalars['ID']>;
  usersIdList: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
}>;


export type MentorBlockRemoveMenteeMutation = { __typename?: 'Mutation', mentorBlockRemoveMentee?: { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type MentorBlockRemoveMenteeSelfMutationVariables = Exact<{
  blockId?: InputMaybe<Scalars['ID']>;
}>;


export type MentorBlockRemoveMenteeSelfMutation = { __typename?: 'Mutation', mentorBlockRemoveMenteeSelf?: { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type MentorBlockRemoveMentorSelfMutationVariables = Exact<{
  blockId?: InputMaybe<Scalars['ID']>;
}>;


export type MentorBlockRemoveMentorSelfMutation = { __typename?: 'Mutation', mentorBlockRemoveMentorSelf?: { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type MentorBlockAcceptRequestMutationVariables = Exact<{
  blockId?: InputMaybe<Scalars['ID']>;
  usersIdList: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
}>;


export type MentorBlockAcceptRequestMutation = { __typename?: 'Mutation', mentorBlockAcceptRequest?: { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type MentorBlockStarMutationVariables = Exact<{
  blockId?: InputMaybe<Scalars['ID']>;
}>;


export type MentorBlockStarMutation = { __typename?: 'Mutation', mentorBlockStar?: { __typename?: 'MentorBlock', _id: string, isStarred?: boolean | null } | null };

export type MentorBlockQueryVariables = Exact<{
  blockId?: InputMaybe<Scalars['ID']>;
}>;


export type MentorBlockQuery = { __typename?: 'Query', mentorBlock?: { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type MentorBlockOwnerQueryVariables = Exact<{
  blockId?: InputMaybe<Scalars['ID']>;
}>;


export type MentorBlockOwnerQuery = { __typename?: 'Query', mentorBlock?: { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, mentees?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, requests?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type MentorBlocksQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type MentorBlocksQuery = { __typename?: 'Query', mentorBlocks?: { __typename?: 'MentorBlockPaginated', totalCount?: number | null, totalPages?: number | null, hasNext?: boolean | null, hasPrev?: boolean | null, list: Array<{ __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | null> } | null };

export type MentorBlocksSearchQueryVariables = Exact<{
  value: Scalars['String'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type MentorBlocksSearchQuery = { __typename?: 'Query', mentorBlocksSearch?: { __typename?: 'MentorBlockPaginated', totalCount?: number | null, totalPages?: number | null, hasNext?: boolean | null, hasPrev?: boolean | null, list: Array<{ __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | null> } | null };

export type MentorBlockGetAllByMenteeIdQueryVariables = Exact<{
  menteeId: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type MentorBlockGetAllByMenteeIdQuery = { __typename?: 'Query', mentorGetAllByMenteeId?: { __typename?: 'MentorBlockPaginated', list: Array<{ __typename?: 'MentorBlock', title?: string | null, isGroup: boolean, description: string, _id: string, owner?: { __typename?: 'User', firstName?: string | null, image?: string | null, lastName?: string | null, _id: string } | null } | null> } | null };

export type MentorBlockGetAllByOwnerIdQueryVariables = Exact<{
  ownerId: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type MentorBlockGetAllByOwnerIdQuery = { __typename?: 'Query', mentorGetAllByOwnerId?: { __typename?: 'MentorBlockPaginated', list: Array<{ __typename?: 'MentorBlock', title?: string | null, description: string, _id: string, owner?: { __typename?: 'User', image?: string | null, _id: string } | null } | null> } | null };

export type NotificationsGetByOwnerIdQueryVariables = Exact<{
  ownerId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type NotificationsGetByOwnerIdQuery = { __typename?: 'Query', notificationsGetByOwnerId?: { __typename?: 'NotificationsPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'Notification', _id: string, actionType?: string | null, entityType?: NotificationEntityType | null, isRead?: boolean | null, initiator?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, entity?: { __typename: 'Comment', _id: string, content?: string | null, parent?: { __typename: 'Comment', _id: string } | { __typename: 'Publication', _id: string, title?: string | null } | { __typename: 'ScheduleSlot', _id: string, entity: { __typename?: 'Lesson' } | { __typename?: 'MentorBlock', _id: string } | { __typename?: 'Task' } } | { __typename: 'TaskAnswer', _id: string, parent?: { __typename: 'ScheduleSlot', _id: string, parentId?: string | null } | null } | null } | { __typename: 'MentorBlock', _id: string, title?: string | null } | { __typename: 'Publication', _id: string, title?: string | null } | { __typename: 'ScheduleSlot', _id: string, parentId?: string | null, entity: { __typename?: 'Lesson', _id: string, title?: string | null } | { __typename?: 'MentorBlock' } | { __typename?: 'Task' } } | { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null } | null> } | null };

export type NotificationsHasUnreadByOwnerIdQueryVariables = Exact<{
  ownerId?: InputMaybe<Scalars['ID']>;
  dateSeen?: InputMaybe<Scalars['String']>;
}>;


export type NotificationsHasUnreadByOwnerIdQuery = { __typename?: 'Query', notificationsHasUnreadByOwnerId?: number | null };

export type NotificationMarkAsReadByIdMutationVariables = Exact<{
  noteId?: InputMaybe<Scalars['ID']>;
}>;


export type NotificationMarkAsReadByIdMutation = { __typename?: 'Mutation', notificationMarkAsReadById?: { __typename?: 'Notification', _id: string, isRead?: boolean | null } | null };

export type NotificationDeleteByIdMutationVariables = Exact<{
  noteId?: InputMaybe<Scalars['ID']>;
}>;


export type NotificationDeleteByIdMutation = { __typename?: 'Mutation', notificationDeleteById?: { __typename: 'Notification' } | null };

export type ProblemsGetQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Filter>;
}>;


export type ProblemsGetQuery = { __typename?: 'Query', problems?: { __typename?: 'ProblemsPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'Problem', _id: string, title?: string | null, jobTitle?: string | null, company?: { __typename?: 'Company', title?: string | null } | null, solutions?: Array<{ __typename?: 'Solution', _id: string } | null> | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null } | null> } | null };

export type ProblemsGetByUserIdQueryVariables = Exact<{
  userId: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ProblemsGetByUserIdQuery = { __typename?: 'Query', problemsGetByUserId?: { __typename?: 'ProblemsPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'Problem', _id: string, title?: string | null, jobTitle?: string | null, company?: { __typename?: 'Company', _id: string, title?: string | null } | null } | null> } | null };

export type ProblemGetQueryVariables = Exact<{
  problemId: Scalars['ID'];
}>;


export type ProblemGetQuery = { __typename?: 'Query', problem?: { __typename?: 'Problem', _id: string, title?: string | null, content?: string | null, jobTitle?: string | null, company?: { __typename?: 'Company', _id: string, title?: string | null } | null, solutions?: Array<{ __typename?: 'Solution', _id: string, content?: string | null, complexity?: number | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null> | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, jobTitle?: string | null, image?: string | null } | null } | null };

export type ProblemUpdateMutationVariables = Exact<{
  problemId: Scalars['ID'];
  data?: InputMaybe<ProblemInput>;
}>;


export type ProblemUpdateMutation = { __typename?: 'Mutation', problemUpdate?: { __typename?: 'Problem', _id: string, title?: string | null, content?: string | null, jobTitle?: string | null, company?: { __typename?: 'Company', _id: string, title?: string | null } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, jobTitle?: string | null, image?: string | null } | null } | null };

export type ProblemCreateMutationVariables = Exact<{
  data?: InputMaybe<ProblemInput>;
}>;


export type ProblemCreateMutation = { __typename?: 'Mutation', problemCreate?: { __typename?: 'Problem', _id: string, title?: string | null, content?: string | null, jobTitle?: string | null, company?: { __typename?: 'Company', _id: string, title?: string | null } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, jobTitle?: string | null, image?: string | null } | null } | null };

export type PublicationsGetQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type PublicationsGetQuery = { __typename?: 'Query', publications?: { __typename: 'PublicationPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, content?: string | null, image?: string | null, commentsCount?: number | null, createdAt?: string | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null> } | null };

export type PublicationSearchQueryVariables = Exact<{
  value?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type PublicationSearchQuery = { __typename?: 'Query', publicationSearch?: { __typename: 'PublicationPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, content?: string | null, image?: string | null, commentsCount?: number | null, createdAt?: string | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null> } | null };

export type PublicationsByUserIdQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type PublicationsByUserIdQuery = { __typename?: 'Query', publicationsByUserId?: Array<{ __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, image?: string | null } | null> | null };

export type PublicationGetQueryVariables = Exact<{
  pubId: Scalars['ID'];
}>;


export type PublicationGetQuery = { __typename?: 'Query', publication?: { __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, content?: string | null, image?: string | null, commentsCount?: number | null, createdAt?: string | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type PublicationCommentsCountGetQueryVariables = Exact<{
  pubId: Scalars['ID'];
}>;


export type PublicationCommentsCountGetQuery = { __typename?: 'Query', publication?: { __typename?: 'Publication', _id: string, commentsCount?: number | null } | null };

export type PublicationLikeMutationVariables = Exact<{
  pubId: Scalars['ID'];
}>;


export type PublicationLikeMutation = { __typename?: 'Mutation', publicationLike?: { __typename?: 'Publication', _id: string, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type PublicationCreateMutationVariables = Exact<{
  values?: InputMaybe<PublicationInput>;
}>;


export type PublicationCreateMutation = { __typename?: 'Mutation', publicationCreate?: { __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, content?: string | null, image?: string | null, commentsCount?: number | null, createdAt?: string | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type PublicationUpdateMutationVariables = Exact<{
  pubId: Scalars['ID'];
  values?: InputMaybe<PublicationInput>;
}>;


export type PublicationUpdateMutation = { __typename?: 'Mutation', publicationUpdate?: { __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, content?: string | null, image?: string | null, commentsCount?: number | null, createdAt?: string | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type PublicationDeleteMutationVariables = Exact<{
  pubId: Scalars['ID'];
}>;


export type PublicationDeleteMutation = { __typename?: 'Mutation', publicationDelete?: string | null };

export type ScheduleSlotCreateMutationVariables = Exact<{
  data?: InputMaybe<ScheduleSlotInput>;
}>;


export type ScheduleSlotCreateMutation = { __typename?: 'Mutation', scheduleSlotCreate?: { __typename?: 'ScheduleSlot', _id: string, parentId?: string | null, ownerId?: string | null, entityType?: EntityType | null, startDate?: string | null, endDate?: string | null, streamLink?: string | null, streamProvider?: string | null, entity: { __typename?: 'Lesson', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | { __typename?: 'Task', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } } | null };

export type ScheduleSlotUpdateMutationVariables = Exact<{
  slotId?: InputMaybe<Scalars['ID']>;
  values?: InputMaybe<ScheduleSlotUpdateInput>;
}>;


export type ScheduleSlotUpdateMutation = { __typename?: 'Mutation', scheduleSlotUpdate?: { __typename?: 'ScheduleSlot', _id: string, parentId?: string | null, ownerId?: string | null, entityType?: EntityType | null, startDate?: string | null, endDate?: string | null, streamLink?: string | null, streamProvider?: string | null, entity: { __typename?: 'Lesson', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | { __typename?: 'Task', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } } | null };

export type ScheduleSlotDeleteMutationVariables = Exact<{
  slotId?: InputMaybe<Scalars['ID']>;
}>;


export type ScheduleSlotDeleteMutation = { __typename?: 'Mutation', scheduleSlotDelete?: number | null };

export type ScheduleSlotQueryVariables = Exact<{
  slotId?: InputMaybe<Scalars['ID']>;
}>;


export type ScheduleSlotQuery = { __typename?: 'Query', scheduleSlot?: { __typename?: 'ScheduleSlot', _id: string, parentId?: string | null, ownerId?: string | null, entityType?: EntityType | null, startDate?: string | null, endDate?: string | null, streamLink?: string | null, streamProvider?: string | null, entity: { __typename?: 'Lesson', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | { __typename?: 'Task', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } } | null };

export type ScheduleSlotsGetByParentIdQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars['ID']>;
  entityType?: InputMaybe<EntityType>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ScheduleSlotsGetByParentIdQuery = { __typename?: 'Query', scheduleSlotsGetByParentId?: { __typename?: 'ScheduleSlotsPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list?: Array<{ __typename?: 'ScheduleSlot', _id: string, parentId?: string | null, ownerId?: string | null, entityType?: EntityType | null, startDate?: string | null, endDate?: string | null, streamLink?: string | null, streamProvider?: string | null, entity: { __typename?: 'Lesson', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | { __typename?: 'Task', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } } | null> | null } | null };

export type SolutionGetQueryVariables = Exact<{
  solutionId: Scalars['ID'];
}>;


export type SolutionGetQuery = { __typename?: 'Query', solution?: { __typename?: 'Solution', _id: string, problemId?: string | null, content?: string | null, complexity?: number | null, createdAt?: string | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type SolutionCreateMutationVariables = Exact<{
  problemId: Scalars['ID'];
  content: Scalars['String'];
}>;


export type SolutionCreateMutation = { __typename?: 'Mutation', solutionCreate?: { __typename?: 'Solution', _id: string, problemId?: string | null, content?: string | null, complexity?: number | null, createdAt?: string | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string } | null } | null };

export type SolutionUpdateMutationVariables = Exact<{
  solutionId: Scalars['ID'];
  content: Scalars['String'];
}>;


export type SolutionUpdateMutation = { __typename?: 'Mutation', solutionUpdate?: { __typename?: 'Solution', _id: string, problemId?: string | null, content?: string | null, complexity?: number | null, createdAt?: string | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type SolutionLikeMutationVariables = Exact<{
  solutionId: Scalars['ID'];
}>;


export type SolutionLikeMutation = { __typename?: 'Mutation', solutionLike?: { __typename?: 'Solution', _id: string, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null } | null };

export type SolutionDeleteMutationVariables = Exact<{
  solutionId: Scalars['ID'];
}>;


export type SolutionDeleteMutation = { __typename?: 'Mutation', solutionDelete?: string | null };

export type TaskGetByIdQueryVariables = Exact<{
  taskId?: InputMaybe<Scalars['ID']>;
}>;


export type TaskGetByIdQuery = { __typename?: 'Query', taskGet?: { __typename?: 'Task', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | null };

export type TasksGetByOwnerIdQueryVariables = Exact<{
  ownerId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type TasksGetByOwnerIdQuery = { __typename?: 'Query', tasksGetByOwnerId?: { __typename?: 'TasksPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'Task', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | null> } | null };

export type TaskCreateMutationVariables = Exact<{
  data?: InputMaybe<TaskInput>;
}>;


export type TaskCreateMutation = { __typename?: 'Mutation', taskCreate?: { __typename?: 'Task', _id: string, title?: string | null, content?: string | null } | null };

export type TaskDeleteMutationVariables = Exact<{
  taskId?: InputMaybe<Scalars['ID']>;
}>;


export type TaskDeleteMutation = { __typename?: 'Mutation', taskDelete?: number | null };

export type TaskUpdateMutationVariables = Exact<{
  taskId?: InputMaybe<Scalars['ID']>;
  values?: InputMaybe<TaskInput>;
}>;


export type TaskUpdateMutation = { __typename?: 'Mutation', taskUpdate?: { __typename?: 'Task', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | null };

export type TaskAnswersListGetQueryVariables = Exact<{
  blockId?: InputMaybe<Scalars['ID']>;
  parentId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type TaskAnswersListGetQuery = { __typename?: 'Query', taskAnswersListGet?: { __typename?: 'TaskAnswersPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'TaskAnswer', _id: string, status?: Status | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null } | null> } | null };

export type TaskAnswerGetContentQueryVariables = Exact<{
  answerId: Scalars['ID'];
}>;


export type TaskAnswerGetContentQuery = { __typename?: 'Query', taskAnswerGet?: { __typename?: 'TaskAnswer', _id: string, content?: string | null } | null };

export type TaskAnswerCreateMutationVariables = Exact<{
  data?: InputMaybe<TaskAnswerInput>;
}>;


export type TaskAnswerCreateMutation = { __typename?: 'Mutation', taskAnswerCreate?: { __typename?: 'TaskAnswer', _id: string, content?: string | null, status?: Status | null, createdAt?: string | null, updatedAt?: string | null, parent?: { __typename: 'ScheduleSlot', _id: string, parentId?: string | null } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null } | null };

export type TaskAnswerDeleteMutationVariables = Exact<{
  answerId: Scalars['ID'];
}>;


export type TaskAnswerDeleteMutation = { __typename?: 'Mutation', taskAnswerDelete?: number | null };

export type TaskAnswerUpdateMutationVariables = Exact<{
  answerId: Scalars['ID'];
  content: Scalars['String'];
}>;


export type TaskAnswerUpdateMutation = { __typename?: 'Mutation', taskAnswerUpdate?: { __typename?: 'TaskAnswer', status?: Status | null, updatedAt?: string | null, _id: string, content?: string | null } | null };

export type TaskAnswerStatusUpdateMutationVariables = Exact<{
  answerId: Scalars['ID'];
  status: Status;
}>;


export type TaskAnswerStatusUpdateMutation = { __typename?: 'Mutation', taskAnswerStatusUpdate?: { __typename?: 'TaskAnswer', _id: string, status?: Status | null } | null };

export type UserLogoutQueryVariables = Exact<{ [key: string]: never; }>;


export type UserLogoutQuery = { __typename?: 'Query', logout: boolean };

export type UserLoginQueryVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserLoginQuery = { __typename?: 'Query', login: { __typename?: 'UserAuthData', accessToken?: string | null, user?: { __typename?: 'User', email?: string | null, isActivated?: boolean | null, roles?: Array<string | null> | null, _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null } };

export type UserCheckAuthMutationVariables = Exact<{ [key: string]: never; }>;


export type UserCheckAuthMutation = { __typename?: 'Mutation', userCheckAuth: { __typename?: 'UserAuthData', user?: { __typename?: 'User', roles?: Array<string | null> | null, level?: number | null, _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null } };

export type UserRefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type UserRefreshTokenMutation = { __typename?: 'Mutation', userRefreshToken: { __typename?: 'UserAuthData', accessToken?: string | null, user?: { __typename?: 'User', _id: string } | null } };

export type UserRegistrationMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserRegistrationMutation = { __typename?: 'Mutation', userCreate: string };

export type UserActivateMutationVariables = Exact<{
  activationLinkId: Scalars['String'];
}>;


export type UserActivateMutation = { __typename?: 'Mutation', userActivate?: string | null };

export type UserResendActivationLinkMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserResendActivationLinkMutation = { __typename?: 'Mutation', userResendActivationLink?: string | null };

export type UserGetQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserGetQuery = { __typename?: 'Query', user?: { __typename?: 'User', email?: string | null, about?: string | null, roles?: Array<string | null> | null, jobTitle?: string | null, languages?: Array<string | null> | null, level?: number | null, _id: string, firstName?: string | null, lastName?: string | null, image?: string | null, starredProblems?: Array<{ __typename?: 'Problem', _id: string, title?: string | null } | null> | null, starredPublications?: Array<{ __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, image?: string | null } | null> | null, friendList?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null, jobTitle?: string | null } | null> | null, friendInviteSent?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null, jobTitle?: string | null } | null> | null, friendInviteReceived?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null, jobTitle?: string | null } | null> | null } | null };

export type UserStarredPublicationsGetQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserStarredPublicationsGetQuery = { __typename?: 'Query', user?: { __typename?: 'User', _id: string, starredPublications?: Array<{ __typename?: 'Publication', _id: string } | null> | null } | null };

export type UserStarredMentorBlocksGetQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserStarredMentorBlocksGetQuery = { __typename?: 'Query', user?: { __typename?: 'User', _id: string, starredMentorBlocks?: Array<{ __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, owner?: { __typename?: 'User', image?: string | null } | null } | null> | null } | null };

export type UserFriendListGetQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserFriendListGetQuery = { __typename?: 'Query', user?: { __typename?: 'User', _id: string, friendList?: Array<{ __typename?: 'User', _id: string } | null> | null, friendInviteSent?: Array<{ __typename?: 'User', _id: string } | null> | null, friendInviteReceived?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type UserFriendInviteMutationVariables = Exact<{
  friendId: Scalars['ID'];
}>;


export type UserFriendInviteMutation = { __typename?: 'Mutation', userFriendInvite?: { __typename?: 'User', _id: string, friendList?: Array<{ __typename?: 'User', _id: string } | null> | null, friendInviteSent?: Array<{ __typename?: 'User', _id: string } | null> | null, friendInviteReceived?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type UserFriendRejectMutationVariables = Exact<{
  friendId: Scalars['ID'];
}>;


export type UserFriendRejectMutation = { __typename?: 'Mutation', userFriendReject?: { __typename?: 'User', _id: string, friendList?: Array<{ __typename?: 'User', _id: string } | null> | null, friendInviteSent?: Array<{ __typename?: 'User', _id: string } | null> | null, friendInviteReceived?: Array<{ __typename?: 'User', _id: string } | null> | null } | null };

export type UserUpdateMutationVariables = Exact<{
  userId: Scalars['ID'];
  values?: InputMaybe<UserInput>;
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', userUpdate?: { __typename?: 'User', email?: string | null, about?: string | null, roles?: Array<string | null> | null, jobTitle?: string | null, languages?: Array<string | null> | null, level?: number | null, _id: string, firstName?: string | null, lastName?: string | null, image?: string | null, starredProblems?: Array<{ __typename?: 'Problem', _id: string, title?: string | null } | null> | null, starredPublications?: Array<{ __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, image?: string | null } | null> | null } | null };

export type UserEmailUpdateMutationVariables = Exact<{
  userId: Scalars['ID'];
  values?: InputMaybe<EmailUpdateInput>;
}>;


export type UserEmailUpdateMutation = { __typename?: 'Mutation', userEmailUpdate?: string | null };

export type UserPasswordResetRequestMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserPasswordResetRequestMutation = { __typename?: 'Mutation', userPasswordResetRequest?: string | null };

export type UserPasswordUpdateMutationVariables = Exact<{
  resetId: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserPasswordUpdateMutation = { __typename?: 'Mutation', userPasswordReset?: string | null };

export type UserStarPublicationMutationVariables = Exact<{
  userId: Scalars['ID'];
  pubId: Scalars['ID'];
}>;


export type UserStarPublicationMutation = { __typename?: 'Mutation', userStarPublication?: { __typename?: 'User', _id: string, starredPublications?: Array<{ __typename?: 'Publication', _id: string } | null> | null } | null };

export type UserStarMentorBlockMutationVariables = Exact<{
  userId: Scalars['ID'];
  blockId: Scalars['ID'];
}>;


export type UserStarMentorBlockMutation = { __typename?: 'Mutation', userStarMentorBlock?: { __typename?: 'User', _id: string, starredMentorBlocks?: Array<{ __typename?: 'MentorBlock', _id: string } | null> | null } | null };

export type UserSearchQueryVariables = Exact<{
  value: Scalars['String'];
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type UserSearchQuery = { __typename?: 'Query', userSearch: { __typename?: 'UsersPaginated', hasPrev?: boolean | null, hasNext?: boolean | null, totalPages?: number | null, totalCount?: number | null, list: Array<{ __typename?: 'User', jobTitle?: string | null, _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> } };

export type CommentFragment = { __typename: 'Comment', _id: string, content?: string | null, createdAt?: string | null, updatedAt?: string | null, parent?: { __typename: 'Comment', _id: string } | { __typename: 'Publication', _id: string, title?: string | null } | { __typename: 'ScheduleSlot', _id: string } | { __typename: 'TaskAnswer', _id: string } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null };

export type CommentForCommentFragment = { __typename: 'Comment', _id: string, content?: string | null, parent?: { __typename: 'Comment', _id: string } | { __typename: 'Publication', _id: string, title?: string | null } | { __typename: 'ScheduleSlot', _id: string, entity: { __typename?: 'Lesson' } | { __typename?: 'MentorBlock', _id: string } | { __typename?: 'Task' } } | { __typename: 'TaskAnswer', _id: string, parent?: { __typename: 'ScheduleSlot', _id: string, parentId?: string | null } | null } | null };

export type UserFriendFragmentFragment = { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null, jobTitle?: string | null };

export type LessonFragment = { __typename?: 'Lesson', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null };

export type MentorBlockFragment = { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null };

export type NotificationFragment = { __typename?: 'Notification', _id: string, actionType?: string | null, entityType?: NotificationEntityType | null, isRead?: boolean | null, initiator?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, entity?: { __typename: 'Comment', _id: string, content?: string | null, parent?: { __typename: 'Comment', _id: string } | { __typename: 'Publication', _id: string, title?: string | null } | { __typename: 'ScheduleSlot', _id: string, entity: { __typename?: 'Lesson' } | { __typename?: 'MentorBlock', _id: string } | { __typename?: 'Task' } } | { __typename: 'TaskAnswer', _id: string, parent?: { __typename: 'ScheduleSlot', _id: string, parentId?: string | null } | null } | null } | { __typename: 'MentorBlock', _id: string, title?: string | null } | { __typename: 'Publication', _id: string, title?: string | null } | { __typename: 'ScheduleSlot', _id: string, parentId?: string | null, entity: { __typename?: 'Lesson', _id: string, title?: string | null } | { __typename?: 'MentorBlock' } | { __typename?: 'Task' } } | { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null };

export type PublicationFragment = { __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, content?: string | null, image?: string | null, commentsCount?: number | null, createdAt?: string | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, likes?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null };

export type ScheduleSlotFragment = { __typename?: 'ScheduleSlot', _id: string, parentId?: string | null, ownerId?: string | null, entityType?: EntityType | null, startDate?: string | null, endDate?: string | null, streamLink?: string | null, streamProvider?: string | null, entity: { __typename?: 'Lesson', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } | { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string, isGroup: boolean, stack?: Array<string | null> | null, startDate?: string | null, endDate?: string | null, isStarred?: boolean | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null, mentors?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null> | null, mentees?: Array<{ __typename?: 'User', _id: string } | null> | null, requests?: Array<{ __typename?: 'User', _id: string } | null> | null } | { __typename?: 'Task', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null } };

export type StarredProblemsFragmentFragment = { __typename?: 'Problem', _id: string, title?: string | null };

export type StarredPublicationsFragmentFragment = { __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, image?: string | null };

export type StarredMentorBlocksFragmentFragment = { __typename?: 'MentorBlock', _id: string, title?: string | null, description: string };

export type TaskFragment = { __typename?: 'Task', _id: string, title?: string | null, content?: string | null, createdAt?: string | null, updatedAt?: string | null };

export type TaskAnswerFragment = { __typename?: 'TaskAnswer', _id: string, content?: string | null, status?: Status | null, createdAt?: string | null, updatedAt?: string | null, parent?: { __typename: 'ScheduleSlot', _id: string, parentId?: string | null } | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null };

export type TaskAnswerContentFragment = { __typename?: 'TaskAnswer', _id: string, content?: string | null };

export type TaskAnswerStatusFragment = { __typename?: 'TaskAnswer', _id: string, status?: Status | null };

export type TaskAnswerShortFragment = { __typename?: 'TaskAnswer', _id: string, status?: Status | null, updatedAt?: string | null, owner?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null } | null };

export type UserFragment = { __typename?: 'User', _id: string, email?: string | null, firstName?: string | null, lastName?: string | null, about?: string | null, image?: string | null, jobTitle?: string | null, level?: number | null, languages?: Array<string | null> | null, roles?: Array<string | null> | null, links?: Array<string | null> | null, lastAccess?: string | null, createdAt?: string | null, updatedAt?: string | null, isActivated?: boolean | null, activationLinkId?: string | null, starredProblems?: Array<{ __typename?: 'Problem', _id: string, title?: string | null } | null> | null, starredPublications?: Array<{ __typename?: 'Publication', _id: string, title?: string | null, description?: string | null, image?: string | null } | null> | null, friendList?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null, jobTitle?: string | null } | null> | null, friendInviteSent?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null, jobTitle?: string | null } | null> | null, friendInviteReceived?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null, jobTitle?: string | null } | null> | null };

export type UserShortFragment = { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, image?: string | null };

export const CommentFragmentDoc = gql`
    fragment Comment on Comment {
  _id
  parent {
    ... on Publication {
      _id
      title
      __typename
    }
    ... on Comment {
      _id
      __typename
    }
    ... on TaskAnswer {
      _id
      __typename
    }
    ... on ScheduleSlot {
      _id
      __typename
    }
  }
  content
  owner {
    _id
    firstName
    lastName
    image
  }
  likes {
    _id
    firstName
    lastName
    image
  }
  createdAt
  updatedAt
  __typename
}
    `;
export const CommentForCommentFragmentDoc = gql`
    fragment CommentForComment on Comment {
  _id
  parent {
    ... on Publication {
      _id
      title
      __typename
    }
    ... on Comment {
      _id
      __typename
    }
    ... on TaskAnswer {
      _id
      parent {
        _id
        parentId
        __typename
      }
      __typename
    }
    ... on ScheduleSlot {
      _id
      entity {
        ... on MentorBlock {
          _id
        }
      }
      __typename
    }
  }
  content
  __typename
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on Notification {
  _id
  initiator {
    ... on User {
      _id
      firstName
      lastName
      image
    }
  }
  owner {
    ... on User {
      _id
      firstName
      lastName
      image
    }
  }
  actionType
  entity {
    ... on Comment {
      ...CommentForComment
    }
    ... on Publication {
      _id
      title
      __typename
    }
    ... on MentorBlock {
      _id
      title
      __typename
    }
    ... on ScheduleSlot {
      _id
      parentId
      entity {
        ... on Lesson {
          _id
          title
        }
      }
      __typename
    }
    ... on User {
      _id
      firstName
      lastName
      image
    }
  }
  entityType
  isRead
}
    ${CommentForCommentFragmentDoc}`;
export const PublicationFragmentDoc = gql`
    fragment Publication on Publication {
  _id
  title
  description
  content
  image
  commentsCount
  owner {
    _id
    firstName
    lastName
    image
  }
  likes {
    _id
    firstName
    lastName
    image
  }
  createdAt
  updatedAt
}
    `;
export const LessonFragmentDoc = gql`
    fragment Lesson on Lesson {
  _id
  title
  content
  createdAt
  updatedAt
}
    `;
export const MentorBlockFragmentDoc = gql`
    fragment MentorBlock on MentorBlock {
  _id
  owner {
    _id
    firstName
    lastName
    image
  }
  title
  description
  isGroup
  stack
  startDate
  endDate
  mentors {
    _id
    firstName
    lastName
    image
  }
  mentees {
    _id
  }
  requests {
    _id
  }
  isStarred
}
    `;
export const TaskFragmentDoc = gql`
    fragment Task on Task {
  _id
  title
  content
  createdAt
  updatedAt
}
    `;
export const ScheduleSlotFragmentDoc = gql`
    fragment ScheduleSlot on ScheduleSlot {
  _id
  parentId
  ownerId
  entity {
    ...Lesson
    ...MentorBlock
    ...Task
  }
  entityType
  startDate
  endDate
  streamLink
  streamProvider
}
    ${LessonFragmentDoc}
${MentorBlockFragmentDoc}
${TaskFragmentDoc}`;
export const StarredMentorBlocksFragmentFragmentDoc = gql`
    fragment StarredMentorBlocksFragment on MentorBlock {
  _id
  title
  description
}
    `;
export const UserShortFragmentDoc = gql`
    fragment UserShort on User {
  _id
  firstName
  lastName
  image
}
    `;
export const TaskAnswerFragmentDoc = gql`
    fragment TaskAnswer on TaskAnswer {
  _id
  parent {
    _id
    parentId
    __typename
  }
  content
  owner {
    ...UserShort
  }
  status
  createdAt
  updatedAt
}
    ${UserShortFragmentDoc}`;
export const TaskAnswerContentFragmentDoc = gql`
    fragment TaskAnswerContent on TaskAnswer {
  _id
  content
}
    `;
export const TaskAnswerStatusFragmentDoc = gql`
    fragment TaskAnswerStatus on TaskAnswer {
  _id
  status
}
    `;
export const TaskAnswerShortFragmentDoc = gql`
    fragment TaskAnswerShort on TaskAnswer {
  _id
  owner {
    ...UserShort
  }
  status
  updatedAt
}
    ${UserShortFragmentDoc}`;
export const StarredProblemsFragmentFragmentDoc = gql`
    fragment StarredProblemsFragment on Problem {
  _id
  title
}
    `;
export const StarredPublicationsFragmentFragmentDoc = gql`
    fragment StarredPublicationsFragment on Publication {
  _id
  title
  description
  image
}
    `;
export const UserFriendFragmentFragmentDoc = gql`
    fragment UserFriendFragment on User {
  _id
  firstName
  lastName
  image
  jobTitle
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  _id
  email
  firstName
  lastName
  about
  image
  jobTitle
  level
  languages
  roles
  links
  starredProblems {
    ...StarredProblemsFragment
  }
  starredPublications {
    ...StarredPublicationsFragment
  }
  friendList {
    ...UserFriendFragment
  }
  friendInviteSent {
    ...UserFriendFragment
  }
  friendInviteReceived {
    ...UserFriendFragment
  }
  lastAccess
  createdAt
  updatedAt
  isActivated
  activationLinkId
}
    ${StarredProblemsFragmentFragmentDoc}
${StarredPublicationsFragmentFragmentDoc}
${UserFriendFragmentFragmentDoc}`;
export const CommentsGetDocument = gql`
    query CommentsGet($parentId: ID!, $offset: Int, $limit: Int) {
  comments(parentId: $parentId, offset: $offset, limit: $limit) {
    list {
      ...Comment
    }
    hasPrev
    hasNext
    totalPages
    totalCount
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useCommentsGetQuery__
 *
 * To run a query within a React component, call `useCommentsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsGetQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCommentsGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CommentsGetQuery, CommentsGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CommentsGetQuery, CommentsGetQueryVariables>(CommentsGetDocument, options);
      }
export function useCommentsGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommentsGetQuery, CommentsGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CommentsGetQuery, CommentsGetQueryVariables>(CommentsGetDocument, options);
        }
export type CommentsGetQueryHookResult = ReturnType<typeof useCommentsGetQuery>;
export type CommentsGetLazyQueryHookResult = ReturnType<typeof useCommentsGetLazyQuery>;
export type CommentsGetQueryResult = Apollo.QueryResult<CommentsGetQuery, CommentsGetQueryVariables>;
export const CommentsGetTotalCountDocument = gql`
    query CommentsGetTotalCount($parentId: ID!) {
  comments(parentId: $parentId) {
    totalCount
  }
}
    `;

/**
 * __useCommentsGetTotalCountQuery__
 *
 * To run a query within a React component, call `useCommentsGetTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsGetTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsGetTotalCountQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useCommentsGetTotalCountQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CommentsGetTotalCountQuery, CommentsGetTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CommentsGetTotalCountQuery, CommentsGetTotalCountQueryVariables>(CommentsGetTotalCountDocument, options);
      }
export function useCommentsGetTotalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommentsGetTotalCountQuery, CommentsGetTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CommentsGetTotalCountQuery, CommentsGetTotalCountQueryVariables>(CommentsGetTotalCountDocument, options);
        }
export type CommentsGetTotalCountQueryHookResult = ReturnType<typeof useCommentsGetTotalCountQuery>;
export type CommentsGetTotalCountLazyQueryHookResult = ReturnType<typeof useCommentsGetTotalCountLazyQuery>;
export type CommentsGetTotalCountQueryResult = Apollo.QueryResult<CommentsGetTotalCountQuery, CommentsGetTotalCountQueryVariables>;
export const CommentGetDocument = gql`
    query CommentGet($commentId: ID!) {
  comment(commentId: $commentId) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useCommentGetQuery__
 *
 * To run a query within a React component, call `useCommentGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentGetQuery({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useCommentGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CommentGetQuery, CommentGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CommentGetQuery, CommentGetQueryVariables>(CommentGetDocument, options);
      }
export function useCommentGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommentGetQuery, CommentGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CommentGetQuery, CommentGetQueryVariables>(CommentGetDocument, options);
        }
export type CommentGetQueryHookResult = ReturnType<typeof useCommentGetQuery>;
export type CommentGetLazyQueryHookResult = ReturnType<typeof useCommentGetLazyQuery>;
export type CommentGetQueryResult = Apollo.QueryResult<CommentGetQuery, CommentGetQueryVariables>;
export const CommentLikeDocument = gql`
    mutation CommentLike($commentId: ID!) {
  commentLike(commentId: $commentId) {
    _id
    likes {
      _id
      firstName
      lastName
      image
    }
  }
}
    `;
export type CommentLikeMutationFn = Apollo.MutationFunction<CommentLikeMutation, CommentLikeMutationVariables>;

/**
 * __useCommentLikeMutation__
 *
 * To run a mutation, you first call `useCommentLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentLikeMutation, { data, loading, error }] = useCommentLikeMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useCommentLikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CommentLikeMutation, CommentLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CommentLikeMutation, CommentLikeMutationVariables>(CommentLikeDocument, options);
      }
export type CommentLikeMutationHookResult = ReturnType<typeof useCommentLikeMutation>;
export type CommentLikeMutationResult = Apollo.MutationResult<CommentLikeMutation>;
export type CommentLikeMutationOptions = Apollo.BaseMutationOptions<CommentLikeMutation, CommentLikeMutationVariables>;
export const CommentCreateDocument = gql`
    mutation CommentCreate($parentId: ID!, $content: String!) {
  commentCreate(parentId: $parentId, content: $content) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;
export type CommentCreateMutationFn = Apollo.MutationFunction<CommentCreateMutation, CommentCreateMutationVariables>;

/**
 * __useCommentCreateMutation__
 *
 * To run a mutation, you first call `useCommentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentCreateMutation, { data, loading, error }] = useCommentCreateMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCommentCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CommentCreateMutation, CommentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CommentCreateMutation, CommentCreateMutationVariables>(CommentCreateDocument, options);
      }
export type CommentCreateMutationHookResult = ReturnType<typeof useCommentCreateMutation>;
export type CommentCreateMutationResult = Apollo.MutationResult<CommentCreateMutation>;
export type CommentCreateMutationOptions = Apollo.BaseMutationOptions<CommentCreateMutation, CommentCreateMutationVariables>;
export const CommentUpdateDocument = gql`
    mutation CommentUpdate($commentId: ID!, $content: String!) {
  commentUpdate(commentId: $commentId, content: $content) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;
export type CommentUpdateMutationFn = Apollo.MutationFunction<CommentUpdateMutation, CommentUpdateMutationVariables>;

/**
 * __useCommentUpdateMutation__
 *
 * To run a mutation, you first call `useCommentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentUpdateMutation, { data, loading, error }] = useCommentUpdateMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCommentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CommentUpdateMutation, CommentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CommentUpdateMutation, CommentUpdateMutationVariables>(CommentUpdateDocument, options);
      }
export type CommentUpdateMutationHookResult = ReturnType<typeof useCommentUpdateMutation>;
export type CommentUpdateMutationResult = Apollo.MutationResult<CommentUpdateMutation>;
export type CommentUpdateMutationOptions = Apollo.BaseMutationOptions<CommentUpdateMutation, CommentUpdateMutationVariables>;
export const CommentDeleteDocument = gql`
    mutation CommentDelete($commentId: ID!) {
  commentDelete(commentId: $commentId)
}
    `;
export type CommentDeleteMutationFn = Apollo.MutationFunction<CommentDeleteMutation, CommentDeleteMutationVariables>;

/**
 * __useCommentDeleteMutation__
 *
 * To run a mutation, you first call `useCommentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentDeleteMutation, { data, loading, error }] = useCommentDeleteMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useCommentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CommentDeleteMutation, CommentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CommentDeleteMutation, CommentDeleteMutationVariables>(CommentDeleteDocument, options);
      }
export type CommentDeleteMutationHookResult = ReturnType<typeof useCommentDeleteMutation>;
export type CommentDeleteMutationResult = Apollo.MutationResult<CommentDeleteMutation>;
export type CommentDeleteMutationOptions = Apollo.BaseMutationOptions<CommentDeleteMutation, CommentDeleteMutationVariables>;
export const CompaniesGetDocument = gql`
    query CompaniesGet($offset: Int, $limit: Int) {
  companies(offset: $offset, limit: $limit) {
    list {
      _id
      title
      description
      image
      link
      problems {
        _id
      }
    }
    hasPrev
    hasNext
    totalPages
    totalCount
    __typename
  }
}
    `;

/**
 * __useCompaniesGetQuery__
 *
 * To run a query within a React component, call `useCompaniesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesGetQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCompaniesGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesGetQuery, CompaniesGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CompaniesGetQuery, CompaniesGetQueryVariables>(CompaniesGetDocument, options);
      }
export function useCompaniesGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesGetQuery, CompaniesGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CompaniesGetQuery, CompaniesGetQueryVariables>(CompaniesGetDocument, options);
        }
export type CompaniesGetQueryHookResult = ReturnType<typeof useCompaniesGetQuery>;
export type CompaniesGetLazyQueryHookResult = ReturnType<typeof useCompaniesGetLazyQuery>;
export type CompaniesGetQueryResult = Apollo.QueryResult<CompaniesGetQuery, CompaniesGetQueryVariables>;
export const CompanySearchDocument = gql`
    query CompanySearch($value: String!, $offset: Int, $limit: Int) {
  companySearch(value: $value, offset: $offset, limit: $limit) {
    list {
      _id
      title
      description
      image
      link
      problems {
        _id
      }
    }
    hasPrev
    hasNext
    totalPages
    totalCount
    __typename
  }
}
    `;

/**
 * __useCompanySearchQuery__
 *
 * To run a query within a React component, call `useCompanySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySearchQuery({
 *   variables: {
 *      value: // value for 'value'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCompanySearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchDocument, options);
      }
export function useCompanySearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchDocument, options);
        }
export type CompanySearchQueryHookResult = ReturnType<typeof useCompanySearchQuery>;
export type CompanySearchLazyQueryHookResult = ReturnType<typeof useCompanySearchLazyQuery>;
export type CompanySearchQueryResult = Apollo.QueryResult<CompanySearchQuery, CompanySearchQueryVariables>;
export const CompaniesAutocompleteGetDocument = gql`
    query CompaniesAutocompleteGet($offset: Int, $limit: Int) {
  companies(offset: $offset, limit: $limit) {
    list {
      _id
      title
      description
      image
    }
    __typename
  }
}
    `;

/**
 * __useCompaniesAutocompleteGetQuery__
 *
 * To run a query within a React component, call `useCompaniesAutocompleteGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesAutocompleteGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesAutocompleteGetQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCompaniesAutocompleteGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesAutocompleteGetQuery, CompaniesAutocompleteGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CompaniesAutocompleteGetQuery, CompaniesAutocompleteGetQueryVariables>(CompaniesAutocompleteGetDocument, options);
      }
export function useCompaniesAutocompleteGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesAutocompleteGetQuery, CompaniesAutocompleteGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CompaniesAutocompleteGetQuery, CompaniesAutocompleteGetQueryVariables>(CompaniesAutocompleteGetDocument, options);
        }
export type CompaniesAutocompleteGetQueryHookResult = ReturnType<typeof useCompaniesAutocompleteGetQuery>;
export type CompaniesAutocompleteGetLazyQueryHookResult = ReturnType<typeof useCompaniesAutocompleteGetLazyQuery>;
export type CompaniesAutocompleteGetQueryResult = Apollo.QueryResult<CompaniesAutocompleteGetQuery, CompaniesAutocompleteGetQueryVariables>;
export const CompanyGetDocument = gql`
    query CompanyGet($companyId: ID!) {
  company(companyId: $companyId) {
    _id
    title
    description
    image
    link
    problems {
      _id
      title
      jobTitle
    }
    likes {
      _id
      firstName
      lastName
      image
    }
  }
}
    `;

/**
 * __useCompanyGetQuery__
 *
 * To run a query within a React component, call `useCompanyGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyGetQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CompanyGetQuery, CompanyGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CompanyGetQuery, CompanyGetQueryVariables>(CompanyGetDocument, options);
      }
export function useCompanyGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyGetQuery, CompanyGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CompanyGetQuery, CompanyGetQueryVariables>(CompanyGetDocument, options);
        }
export type CompanyGetQueryHookResult = ReturnType<typeof useCompanyGetQuery>;
export type CompanyGetLazyQueryHookResult = ReturnType<typeof useCompanyGetLazyQuery>;
export type CompanyGetQueryResult = Apollo.QueryResult<CompanyGetQuery, CompanyGetQueryVariables>;
export const CompanyWikiSearchDocument = gql`
    query CompanyWikiSearch($title: String!) {
  companySearchNew(title: $title) {
    title
    description
    image
  }
}
    `;

/**
 * __useCompanyWikiSearchQuery__
 *
 * To run a query within a React component, call `useCompanyWikiSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyWikiSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyWikiSearchQuery({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCompanyWikiSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CompanyWikiSearchQuery, CompanyWikiSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CompanyWikiSearchQuery, CompanyWikiSearchQueryVariables>(CompanyWikiSearchDocument, options);
      }
export function useCompanyWikiSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyWikiSearchQuery, CompanyWikiSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CompanyWikiSearchQuery, CompanyWikiSearchQueryVariables>(CompanyWikiSearchDocument, options);
        }
export type CompanyWikiSearchQueryHookResult = ReturnType<typeof useCompanyWikiSearchQuery>;
export type CompanyWikiSearchLazyQueryHookResult = ReturnType<typeof useCompanyWikiSearchLazyQuery>;
export type CompanyWikiSearchQueryResult = Apollo.QueryResult<CompanyWikiSearchQuery, CompanyWikiSearchQueryVariables>;
export const CompanyCreateDocument = gql`
    mutation CompanyCreate($data: CompanyInput) {
  companyCreate(data: $data) {
    _id
    title
    description
    image
    link
    problems {
      _id
      title
      jobTitle
    }
    likes {
      _id
      firstName
      lastName
      image
    }
  }
}
    `;
export type CompanyCreateMutationFn = Apollo.MutationFunction<CompanyCreateMutation, CompanyCreateMutationVariables>;

/**
 * __useCompanyCreateMutation__
 *
 * To run a mutation, you first call `useCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCreateMutation, { data, loading, error }] = useCompanyCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyCreateMutation, CompanyCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CompanyCreateMutation, CompanyCreateMutationVariables>(CompanyCreateDocument, options);
      }
export type CompanyCreateMutationHookResult = ReturnType<typeof useCompanyCreateMutation>;
export type CompanyCreateMutationResult = Apollo.MutationResult<CompanyCreateMutation>;
export type CompanyCreateMutationOptions = Apollo.BaseMutationOptions<CompanyCreateMutation, CompanyCreateMutationVariables>;
export const CompanyLikeDocument = gql`
    mutation CompanyLike($companyId: ID!) {
  companyLike(companyId: $companyId) {
    _id
    likes {
      _id
      firstName
      lastName
      image
    }
  }
}
    `;
export type CompanyLikeMutationFn = Apollo.MutationFunction<CompanyLikeMutation, CompanyLikeMutationVariables>;

/**
 * __useCompanyLikeMutation__
 *
 * To run a mutation, you first call `useCompanyLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyLikeMutation, { data, loading, error }] = useCompanyLikeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyLikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyLikeMutation, CompanyLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CompanyLikeMutation, CompanyLikeMutationVariables>(CompanyLikeDocument, options);
      }
export type CompanyLikeMutationHookResult = ReturnType<typeof useCompanyLikeMutation>;
export type CompanyLikeMutationResult = Apollo.MutationResult<CompanyLikeMutation>;
export type CompanyLikeMutationOptions = Apollo.BaseMutationOptions<CompanyLikeMutation, CompanyLikeMutationVariables>;
export const GlobalSearchDocument = gql`
    query GlobalSearch($limit: Int, $offset: Int, $value: String!) {
  globalSearch(limit: $limit, offset: $offset, value: $value) {
    companies {
      list {
        _id
        title
        image
      }
      totalCount
    }
    publications {
      list {
        _id
        title
        image
      }
      totalCount
    }
    problems {
      list {
        _id
        title
      }
      totalCount
    }
    mentorBlocks {
      list {
        _id
        title
      }
      totalCount
    }
    users {
      list {
        _id
        firstName
        lastName
        image
      }
      totalCount
    }
    totalCount
  }
}
    `;

/**
 * __useGlobalSearchQuery__
 *
 * To run a query within a React component, call `useGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGlobalSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GlobalSearchQuery, GlobalSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(GlobalSearchDocument, options);
      }
export function useGlobalSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlobalSearchQuery, GlobalSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(GlobalSearchDocument, options);
        }
export type GlobalSearchQueryHookResult = ReturnType<typeof useGlobalSearchQuery>;
export type GlobalSearchLazyQueryHookResult = ReturnType<typeof useGlobalSearchLazyQuery>;
export type GlobalSearchQueryResult = Apollo.QueryResult<GlobalSearchQuery, GlobalSearchQueryVariables>;
export const LessonsGetByOwnerIdDocument = gql`
    query LessonsGetByOwnerId($ownerId: ID, $offset: Int, $limit: Int) {
  lessonsGetByOwnerId(ownerId: $ownerId, offset: $offset, limit: $limit) {
    list {
      ...Lesson
    }
    hasPrev
    hasNext
    totalPages
    totalCount
  }
}
    ${LessonFragmentDoc}`;

/**
 * __useLessonsGetByOwnerIdQuery__
 *
 * To run a query within a React component, call `useLessonsGetByOwnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonsGetByOwnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonsGetByOwnerIdQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLessonsGetByOwnerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LessonsGetByOwnerIdQuery, LessonsGetByOwnerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LessonsGetByOwnerIdQuery, LessonsGetByOwnerIdQueryVariables>(LessonsGetByOwnerIdDocument, options);
      }
export function useLessonsGetByOwnerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LessonsGetByOwnerIdQuery, LessonsGetByOwnerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LessonsGetByOwnerIdQuery, LessonsGetByOwnerIdQueryVariables>(LessonsGetByOwnerIdDocument, options);
        }
export type LessonsGetByOwnerIdQueryHookResult = ReturnType<typeof useLessonsGetByOwnerIdQuery>;
export type LessonsGetByOwnerIdLazyQueryHookResult = ReturnType<typeof useLessonsGetByOwnerIdLazyQuery>;
export type LessonsGetByOwnerIdQueryResult = Apollo.QueryResult<LessonsGetByOwnerIdQuery, LessonsGetByOwnerIdQueryVariables>;
export const LessonGetByIdDocument = gql`
    query LessonGetById($lessonId: ID) {
  lesson(lessonId: $lessonId) {
    ...Lesson
  }
}
    ${LessonFragmentDoc}`;

/**
 * __useLessonGetByIdQuery__
 *
 * To run a query within a React component, call `useLessonGetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonGetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonGetByIdQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useLessonGetByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LessonGetByIdQuery, LessonGetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LessonGetByIdQuery, LessonGetByIdQueryVariables>(LessonGetByIdDocument, options);
      }
export function useLessonGetByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LessonGetByIdQuery, LessonGetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LessonGetByIdQuery, LessonGetByIdQueryVariables>(LessonGetByIdDocument, options);
        }
export type LessonGetByIdQueryHookResult = ReturnType<typeof useLessonGetByIdQuery>;
export type LessonGetByIdLazyQueryHookResult = ReturnType<typeof useLessonGetByIdLazyQuery>;
export type LessonGetByIdQueryResult = Apollo.QueryResult<LessonGetByIdQuery, LessonGetByIdQueryVariables>;
export const LessonCreateDocument = gql`
    mutation LessonCreate($data: LessonInput) {
  lessonCreate(data: $data) {
    ...Lesson
  }
}
    ${LessonFragmentDoc}`;
export type LessonCreateMutationFn = Apollo.MutationFunction<LessonCreateMutation, LessonCreateMutationVariables>;

/**
 * __useLessonCreateMutation__
 *
 * To run a mutation, you first call `useLessonCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonCreateMutation, { data, loading, error }] = useLessonCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLessonCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LessonCreateMutation, LessonCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LessonCreateMutation, LessonCreateMutationVariables>(LessonCreateDocument, options);
      }
export type LessonCreateMutationHookResult = ReturnType<typeof useLessonCreateMutation>;
export type LessonCreateMutationResult = Apollo.MutationResult<LessonCreateMutation>;
export type LessonCreateMutationOptions = Apollo.BaseMutationOptions<LessonCreateMutation, LessonCreateMutationVariables>;
export const LessonDeleteDocument = gql`
    mutation LessonDelete($lessonId: ID) {
  lessonDelete(lessonId: $lessonId)
}
    `;
export type LessonDeleteMutationFn = Apollo.MutationFunction<LessonDeleteMutation, LessonDeleteMutationVariables>;

/**
 * __useLessonDeleteMutation__
 *
 * To run a mutation, you first call `useLessonDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonDeleteMutation, { data, loading, error }] = useLessonDeleteMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useLessonDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LessonDeleteMutation, LessonDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LessonDeleteMutation, LessonDeleteMutationVariables>(LessonDeleteDocument, options);
      }
export type LessonDeleteMutationHookResult = ReturnType<typeof useLessonDeleteMutation>;
export type LessonDeleteMutationResult = Apollo.MutationResult<LessonDeleteMutation>;
export type LessonDeleteMutationOptions = Apollo.BaseMutationOptions<LessonDeleteMutation, LessonDeleteMutationVariables>;
export const LessonUpdateDocument = gql`
    mutation LessonUpdate($lessonId: ID, $values: LessonInput) {
  lessonUpdate(lessonId: $lessonId, values: $values) {
    ...Lesson
  }
}
    ${LessonFragmentDoc}`;
export type LessonUpdateMutationFn = Apollo.MutationFunction<LessonUpdateMutation, LessonUpdateMutationVariables>;

/**
 * __useLessonUpdateMutation__
 *
 * To run a mutation, you first call `useLessonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonUpdateMutation, { data, loading, error }] = useLessonUpdateMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useLessonUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LessonUpdateMutation, LessonUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LessonUpdateMutation, LessonUpdateMutationVariables>(LessonUpdateDocument, options);
      }
export type LessonUpdateMutationHookResult = ReturnType<typeof useLessonUpdateMutation>;
export type LessonUpdateMutationResult = Apollo.MutationResult<LessonUpdateMutation>;
export type LessonUpdateMutationOptions = Apollo.BaseMutationOptions<LessonUpdateMutation, LessonUpdateMutationVariables>;
export const MentorBlockCreateDocument = gql`
    mutation MentorBlockCreate($data: MentorBlockInput) {
  mentorBlockCreate(data: $data) {
    ...MentorBlock
  }
}
    ${MentorBlockFragmentDoc}`;
export type MentorBlockCreateMutationFn = Apollo.MutationFunction<MentorBlockCreateMutation, MentorBlockCreateMutationVariables>;

/**
 * __useMentorBlockCreateMutation__
 *
 * To run a mutation, you first call `useMentorBlockCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentorBlockCreateMutation, { data, loading, error }] = useMentorBlockCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMentorBlockCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MentorBlockCreateMutation, MentorBlockCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MentorBlockCreateMutation, MentorBlockCreateMutationVariables>(MentorBlockCreateDocument, options);
      }
export type MentorBlockCreateMutationHookResult = ReturnType<typeof useMentorBlockCreateMutation>;
export type MentorBlockCreateMutationResult = Apollo.MutationResult<MentorBlockCreateMutation>;
export type MentorBlockCreateMutationOptions = Apollo.BaseMutationOptions<MentorBlockCreateMutation, MentorBlockCreateMutationVariables>;
export const MentorBlockUpdateDocument = gql`
    mutation MentorBlockUpdate($blockId: ID, $values: MentorBlockInput) {
  mentorBlockUpdate(blockId: $blockId, values: $values) {
    ...MentorBlock
  }
}
    ${MentorBlockFragmentDoc}`;
export type MentorBlockUpdateMutationFn = Apollo.MutationFunction<MentorBlockUpdateMutation, MentorBlockUpdateMutationVariables>;

/**
 * __useMentorBlockUpdateMutation__
 *
 * To run a mutation, you first call `useMentorBlockUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentorBlockUpdateMutation, { data, loading, error }] = useMentorBlockUpdateMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useMentorBlockUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MentorBlockUpdateMutation, MentorBlockUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MentorBlockUpdateMutation, MentorBlockUpdateMutationVariables>(MentorBlockUpdateDocument, options);
      }
export type MentorBlockUpdateMutationHookResult = ReturnType<typeof useMentorBlockUpdateMutation>;
export type MentorBlockUpdateMutationResult = Apollo.MutationResult<MentorBlockUpdateMutation>;
export type MentorBlockUpdateMutationOptions = Apollo.BaseMutationOptions<MentorBlockUpdateMutation, MentorBlockUpdateMutationVariables>;
export const MentorBlockDeleteDocument = gql`
    mutation MentorBlockDelete($blockId: ID!) {
  mentorBlockDelete(blockId: $blockId)
}
    `;
export type MentorBlockDeleteMutationFn = Apollo.MutationFunction<MentorBlockDeleteMutation, MentorBlockDeleteMutationVariables>;

/**
 * __useMentorBlockDeleteMutation__
 *
 * To run a mutation, you first call `useMentorBlockDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentorBlockDeleteMutation, { data, loading, error }] = useMentorBlockDeleteMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useMentorBlockDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MentorBlockDeleteMutation, MentorBlockDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MentorBlockDeleteMutation, MentorBlockDeleteMutationVariables>(MentorBlockDeleteDocument, options);
      }
export type MentorBlockDeleteMutationHookResult = ReturnType<typeof useMentorBlockDeleteMutation>;
export type MentorBlockDeleteMutationResult = Apollo.MutationResult<MentorBlockDeleteMutation>;
export type MentorBlockDeleteMutationOptions = Apollo.BaseMutationOptions<MentorBlockDeleteMutation, MentorBlockDeleteMutationVariables>;
export const MentorBlockJoinDocument = gql`
    mutation MentorBlockJoin($blockId: ID) {
  mentorBlockJoin(blockId: $blockId) {
    ...MentorBlock
  }
}
    ${MentorBlockFragmentDoc}`;
export type MentorBlockJoinMutationFn = Apollo.MutationFunction<MentorBlockJoinMutation, MentorBlockJoinMutationVariables>;

/**
 * __useMentorBlockJoinMutation__
 *
 * To run a mutation, you first call `useMentorBlockJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentorBlockJoinMutation, { data, loading, error }] = useMentorBlockJoinMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useMentorBlockJoinMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MentorBlockJoinMutation, MentorBlockJoinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MentorBlockJoinMutation, MentorBlockJoinMutationVariables>(MentorBlockJoinDocument, options);
      }
export type MentorBlockJoinMutationHookResult = ReturnType<typeof useMentorBlockJoinMutation>;
export type MentorBlockJoinMutationResult = Apollo.MutationResult<MentorBlockJoinMutation>;
export type MentorBlockJoinMutationOptions = Apollo.BaseMutationOptions<MentorBlockJoinMutation, MentorBlockJoinMutationVariables>;
export const MentorBlockRemoveMenteeDocument = gql`
    mutation MentorBlockRemoveMentee($blockId: ID, $usersIdList: [ID]!) {
  mentorBlockRemoveMentee(blockId: $blockId, usersIdList: $usersIdList) {
    ...MentorBlock
  }
}
    ${MentorBlockFragmentDoc}`;
export type MentorBlockRemoveMenteeMutationFn = Apollo.MutationFunction<MentorBlockRemoveMenteeMutation, MentorBlockRemoveMenteeMutationVariables>;

/**
 * __useMentorBlockRemoveMenteeMutation__
 *
 * To run a mutation, you first call `useMentorBlockRemoveMenteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockRemoveMenteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentorBlockRemoveMenteeMutation, { data, loading, error }] = useMentorBlockRemoveMenteeMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      usersIdList: // value for 'usersIdList'
 *   },
 * });
 */
export function useMentorBlockRemoveMenteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MentorBlockRemoveMenteeMutation, MentorBlockRemoveMenteeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MentorBlockRemoveMenteeMutation, MentorBlockRemoveMenteeMutationVariables>(MentorBlockRemoveMenteeDocument, options);
      }
export type MentorBlockRemoveMenteeMutationHookResult = ReturnType<typeof useMentorBlockRemoveMenteeMutation>;
export type MentorBlockRemoveMenteeMutationResult = Apollo.MutationResult<MentorBlockRemoveMenteeMutation>;
export type MentorBlockRemoveMenteeMutationOptions = Apollo.BaseMutationOptions<MentorBlockRemoveMenteeMutation, MentorBlockRemoveMenteeMutationVariables>;
export const MentorBlockRemoveMenteeSelfDocument = gql`
    mutation MentorBlockRemoveMenteeSelf($blockId: ID) {
  mentorBlockRemoveMenteeSelf(blockId: $blockId) {
    ...MentorBlock
  }
}
    ${MentorBlockFragmentDoc}`;
export type MentorBlockRemoveMenteeSelfMutationFn = Apollo.MutationFunction<MentorBlockRemoveMenteeSelfMutation, MentorBlockRemoveMenteeSelfMutationVariables>;

/**
 * __useMentorBlockRemoveMenteeSelfMutation__
 *
 * To run a mutation, you first call `useMentorBlockRemoveMenteeSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockRemoveMenteeSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentorBlockRemoveMenteeSelfMutation, { data, loading, error }] = useMentorBlockRemoveMenteeSelfMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useMentorBlockRemoveMenteeSelfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MentorBlockRemoveMenteeSelfMutation, MentorBlockRemoveMenteeSelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MentorBlockRemoveMenteeSelfMutation, MentorBlockRemoveMenteeSelfMutationVariables>(MentorBlockRemoveMenteeSelfDocument, options);
      }
export type MentorBlockRemoveMenteeSelfMutationHookResult = ReturnType<typeof useMentorBlockRemoveMenteeSelfMutation>;
export type MentorBlockRemoveMenteeSelfMutationResult = Apollo.MutationResult<MentorBlockRemoveMenteeSelfMutation>;
export type MentorBlockRemoveMenteeSelfMutationOptions = Apollo.BaseMutationOptions<MentorBlockRemoveMenteeSelfMutation, MentorBlockRemoveMenteeSelfMutationVariables>;
export const MentorBlockRemoveMentorSelfDocument = gql`
    mutation MentorBlockRemoveMentorSelf($blockId: ID) {
  mentorBlockRemoveMentorSelf(blockId: $blockId) {
    ...MentorBlock
  }
}
    ${MentorBlockFragmentDoc}`;
export type MentorBlockRemoveMentorSelfMutationFn = Apollo.MutationFunction<MentorBlockRemoveMentorSelfMutation, MentorBlockRemoveMentorSelfMutationVariables>;

/**
 * __useMentorBlockRemoveMentorSelfMutation__
 *
 * To run a mutation, you first call `useMentorBlockRemoveMentorSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockRemoveMentorSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentorBlockRemoveMentorSelfMutation, { data, loading, error }] = useMentorBlockRemoveMentorSelfMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useMentorBlockRemoveMentorSelfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MentorBlockRemoveMentorSelfMutation, MentorBlockRemoveMentorSelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MentorBlockRemoveMentorSelfMutation, MentorBlockRemoveMentorSelfMutationVariables>(MentorBlockRemoveMentorSelfDocument, options);
      }
export type MentorBlockRemoveMentorSelfMutationHookResult = ReturnType<typeof useMentorBlockRemoveMentorSelfMutation>;
export type MentorBlockRemoveMentorSelfMutationResult = Apollo.MutationResult<MentorBlockRemoveMentorSelfMutation>;
export type MentorBlockRemoveMentorSelfMutationOptions = Apollo.BaseMutationOptions<MentorBlockRemoveMentorSelfMutation, MentorBlockRemoveMentorSelfMutationVariables>;
export const MentorBlockAcceptRequestDocument = gql`
    mutation MentorBlockAcceptRequest($blockId: ID, $usersIdList: [ID]!) {
  mentorBlockAcceptRequest(blockId: $blockId, usersIdList: $usersIdList) {
    ...MentorBlock
  }
}
    ${MentorBlockFragmentDoc}`;
export type MentorBlockAcceptRequestMutationFn = Apollo.MutationFunction<MentorBlockAcceptRequestMutation, MentorBlockAcceptRequestMutationVariables>;

/**
 * __useMentorBlockAcceptRequestMutation__
 *
 * To run a mutation, you first call `useMentorBlockAcceptRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockAcceptRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentorBlockAcceptRequestMutation, { data, loading, error }] = useMentorBlockAcceptRequestMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      usersIdList: // value for 'usersIdList'
 *   },
 * });
 */
export function useMentorBlockAcceptRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MentorBlockAcceptRequestMutation, MentorBlockAcceptRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MentorBlockAcceptRequestMutation, MentorBlockAcceptRequestMutationVariables>(MentorBlockAcceptRequestDocument, options);
      }
export type MentorBlockAcceptRequestMutationHookResult = ReturnType<typeof useMentorBlockAcceptRequestMutation>;
export type MentorBlockAcceptRequestMutationResult = Apollo.MutationResult<MentorBlockAcceptRequestMutation>;
export type MentorBlockAcceptRequestMutationOptions = Apollo.BaseMutationOptions<MentorBlockAcceptRequestMutation, MentorBlockAcceptRequestMutationVariables>;
export const MentorBlockStarDocument = gql`
    mutation MentorBlockStar($blockId: ID) {
  mentorBlockStar(blockId: $blockId) {
    _id
    isStarred
  }
}
    `;
export type MentorBlockStarMutationFn = Apollo.MutationFunction<MentorBlockStarMutation, MentorBlockStarMutationVariables>;

/**
 * __useMentorBlockStarMutation__
 *
 * To run a mutation, you first call `useMentorBlockStarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockStarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mentorBlockStarMutation, { data, loading, error }] = useMentorBlockStarMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useMentorBlockStarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MentorBlockStarMutation, MentorBlockStarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MentorBlockStarMutation, MentorBlockStarMutationVariables>(MentorBlockStarDocument, options);
      }
export type MentorBlockStarMutationHookResult = ReturnType<typeof useMentorBlockStarMutation>;
export type MentorBlockStarMutationResult = Apollo.MutationResult<MentorBlockStarMutation>;
export type MentorBlockStarMutationOptions = Apollo.BaseMutationOptions<MentorBlockStarMutation, MentorBlockStarMutationVariables>;
export const MentorBlockDocument = gql`
    query MentorBlock($blockId: ID) {
  mentorBlock(blockId: $blockId) {
    ...MentorBlock
  }
}
    ${MentorBlockFragmentDoc}`;

/**
 * __useMentorBlockQuery__
 *
 * To run a query within a React component, call `useMentorBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentorBlockQuery({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useMentorBlockQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MentorBlockQuery, MentorBlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MentorBlockQuery, MentorBlockQueryVariables>(MentorBlockDocument, options);
      }
export function useMentorBlockLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MentorBlockQuery, MentorBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MentorBlockQuery, MentorBlockQueryVariables>(MentorBlockDocument, options);
        }
export type MentorBlockQueryHookResult = ReturnType<typeof useMentorBlockQuery>;
export type MentorBlockLazyQueryHookResult = ReturnType<typeof useMentorBlockLazyQuery>;
export type MentorBlockQueryResult = Apollo.QueryResult<MentorBlockQuery, MentorBlockQueryVariables>;
export const MentorBlockOwnerDocument = gql`
    query MentorBlockOwner($blockId: ID) {
  mentorBlock(blockId: $blockId) {
    ...MentorBlock
    mentees {
      _id
      firstName
      lastName
      image
    }
    requests {
      _id
      firstName
      lastName
      image
    }
  }
}
    ${MentorBlockFragmentDoc}`;

/**
 * __useMentorBlockOwnerQuery__
 *
 * To run a query within a React component, call `useMentorBlockOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentorBlockOwnerQuery({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useMentorBlockOwnerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MentorBlockOwnerQuery, MentorBlockOwnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MentorBlockOwnerQuery, MentorBlockOwnerQueryVariables>(MentorBlockOwnerDocument, options);
      }
export function useMentorBlockOwnerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MentorBlockOwnerQuery, MentorBlockOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MentorBlockOwnerQuery, MentorBlockOwnerQueryVariables>(MentorBlockOwnerDocument, options);
        }
export type MentorBlockOwnerQueryHookResult = ReturnType<typeof useMentorBlockOwnerQuery>;
export type MentorBlockOwnerLazyQueryHookResult = ReturnType<typeof useMentorBlockOwnerLazyQuery>;
export type MentorBlockOwnerQueryResult = Apollo.QueryResult<MentorBlockOwnerQuery, MentorBlockOwnerQueryVariables>;
export const MentorBlocksDocument = gql`
    query MentorBlocks($offset: Int, $limit: Int) {
  mentorBlocks(offset: $offset, limit: $limit) {
    list {
      ...MentorBlock
    }
    totalCount
    totalPages
    hasNext
    hasPrev
  }
}
    ${MentorBlockFragmentDoc}`;

/**
 * __useMentorBlocksQuery__
 *
 * To run a query within a React component, call `useMentorBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentorBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentorBlocksQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMentorBlocksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MentorBlocksQuery, MentorBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MentorBlocksQuery, MentorBlocksQueryVariables>(MentorBlocksDocument, options);
      }
export function useMentorBlocksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MentorBlocksQuery, MentorBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MentorBlocksQuery, MentorBlocksQueryVariables>(MentorBlocksDocument, options);
        }
export type MentorBlocksQueryHookResult = ReturnType<typeof useMentorBlocksQuery>;
export type MentorBlocksLazyQueryHookResult = ReturnType<typeof useMentorBlocksLazyQuery>;
export type MentorBlocksQueryResult = Apollo.QueryResult<MentorBlocksQuery, MentorBlocksQueryVariables>;
export const MentorBlocksSearchDocument = gql`
    query MentorBlocksSearch($value: String!, $offset: Int, $limit: Int) {
  mentorBlocksSearch(value: $value, offset: $offset, limit: $limit) {
    list {
      ...MentorBlock
    }
    totalCount
    totalPages
    hasNext
    hasPrev
  }
}
    ${MentorBlockFragmentDoc}`;

/**
 * __useMentorBlocksSearchQuery__
 *
 * To run a query within a React component, call `useMentorBlocksSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentorBlocksSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentorBlocksSearchQuery({
 *   variables: {
 *      value: // value for 'value'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMentorBlocksSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MentorBlocksSearchQuery, MentorBlocksSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MentorBlocksSearchQuery, MentorBlocksSearchQueryVariables>(MentorBlocksSearchDocument, options);
      }
export function useMentorBlocksSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MentorBlocksSearchQuery, MentorBlocksSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MentorBlocksSearchQuery, MentorBlocksSearchQueryVariables>(MentorBlocksSearchDocument, options);
        }
export type MentorBlocksSearchQueryHookResult = ReturnType<typeof useMentorBlocksSearchQuery>;
export type MentorBlocksSearchLazyQueryHookResult = ReturnType<typeof useMentorBlocksSearchLazyQuery>;
export type MentorBlocksSearchQueryResult = Apollo.QueryResult<MentorBlocksSearchQuery, MentorBlocksSearchQueryVariables>;
export const MentorBlockGetAllByMenteeIdDocument = gql`
    query MentorBlockGetAllByMenteeId($menteeId: ID!, $offset: Int, $limit: Int) {
  mentorGetAllByMenteeId(menteeId: $menteeId, offset: $offset, limit: $limit) {
    list {
      title
      isGroup
      description
      _id
      owner {
        firstName
        image
        lastName
        _id
      }
    }
  }
}
    `;

/**
 * __useMentorBlockGetAllByMenteeIdQuery__
 *
 * To run a query within a React component, call `useMentorBlockGetAllByMenteeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockGetAllByMenteeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentorBlockGetAllByMenteeIdQuery({
 *   variables: {
 *      menteeId: // value for 'menteeId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMentorBlockGetAllByMenteeIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MentorBlockGetAllByMenteeIdQuery, MentorBlockGetAllByMenteeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MentorBlockGetAllByMenteeIdQuery, MentorBlockGetAllByMenteeIdQueryVariables>(MentorBlockGetAllByMenteeIdDocument, options);
      }
export function useMentorBlockGetAllByMenteeIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MentorBlockGetAllByMenteeIdQuery, MentorBlockGetAllByMenteeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MentorBlockGetAllByMenteeIdQuery, MentorBlockGetAllByMenteeIdQueryVariables>(MentorBlockGetAllByMenteeIdDocument, options);
        }
export type MentorBlockGetAllByMenteeIdQueryHookResult = ReturnType<typeof useMentorBlockGetAllByMenteeIdQuery>;
export type MentorBlockGetAllByMenteeIdLazyQueryHookResult = ReturnType<typeof useMentorBlockGetAllByMenteeIdLazyQuery>;
export type MentorBlockGetAllByMenteeIdQueryResult = Apollo.QueryResult<MentorBlockGetAllByMenteeIdQuery, MentorBlockGetAllByMenteeIdQueryVariables>;
export const MentorBlockGetAllByOwnerIdDocument = gql`
    query MentorBlockGetAllByOwnerId($ownerId: ID!, $offset: Int, $limit: Int) {
  mentorGetAllByOwnerId(ownerId: $ownerId, offset: $offset, limit: $limit) {
    list {
      title
      description
      _id
      owner {
        image
        _id
      }
    }
  }
}
    `;

/**
 * __useMentorBlockGetAllByOwnerIdQuery__
 *
 * To run a query within a React component, call `useMentorBlockGetAllByOwnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentorBlockGetAllByOwnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentorBlockGetAllByOwnerIdQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMentorBlockGetAllByOwnerIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MentorBlockGetAllByOwnerIdQuery, MentorBlockGetAllByOwnerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MentorBlockGetAllByOwnerIdQuery, MentorBlockGetAllByOwnerIdQueryVariables>(MentorBlockGetAllByOwnerIdDocument, options);
      }
export function useMentorBlockGetAllByOwnerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MentorBlockGetAllByOwnerIdQuery, MentorBlockGetAllByOwnerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MentorBlockGetAllByOwnerIdQuery, MentorBlockGetAllByOwnerIdQueryVariables>(MentorBlockGetAllByOwnerIdDocument, options);
        }
export type MentorBlockGetAllByOwnerIdQueryHookResult = ReturnType<typeof useMentorBlockGetAllByOwnerIdQuery>;
export type MentorBlockGetAllByOwnerIdLazyQueryHookResult = ReturnType<typeof useMentorBlockGetAllByOwnerIdLazyQuery>;
export type MentorBlockGetAllByOwnerIdQueryResult = Apollo.QueryResult<MentorBlockGetAllByOwnerIdQuery, MentorBlockGetAllByOwnerIdQueryVariables>;
export const NotificationsGetByOwnerIdDocument = gql`
    query NotificationsGetByOwnerId($ownerId: ID, $offset: Int, $limit: Int) {
  notificationsGetByOwnerId(ownerId: $ownerId, offset: $offset, limit: $limit) {
    list {
      ...Notification
    }
    hasPrev
    hasNext
    totalPages
    totalCount
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationsGetByOwnerIdQuery__
 *
 * To run a query within a React component, call `useNotificationsGetByOwnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsGetByOwnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsGetByOwnerIdQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNotificationsGetByOwnerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationsGetByOwnerIdQuery, NotificationsGetByOwnerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<NotificationsGetByOwnerIdQuery, NotificationsGetByOwnerIdQueryVariables>(NotificationsGetByOwnerIdDocument, options);
      }
export function useNotificationsGetByOwnerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationsGetByOwnerIdQuery, NotificationsGetByOwnerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<NotificationsGetByOwnerIdQuery, NotificationsGetByOwnerIdQueryVariables>(NotificationsGetByOwnerIdDocument, options);
        }
export type NotificationsGetByOwnerIdQueryHookResult = ReturnType<typeof useNotificationsGetByOwnerIdQuery>;
export type NotificationsGetByOwnerIdLazyQueryHookResult = ReturnType<typeof useNotificationsGetByOwnerIdLazyQuery>;
export type NotificationsGetByOwnerIdQueryResult = Apollo.QueryResult<NotificationsGetByOwnerIdQuery, NotificationsGetByOwnerIdQueryVariables>;
export const NotificationsHasUnreadByOwnerIdDocument = gql`
    query NotificationsHasUnreadByOwnerId($ownerId: ID, $dateSeen: String) {
  notificationsHasUnreadByOwnerId(ownerId: $ownerId, dateSeen: $dateSeen)
}
    `;

/**
 * __useNotificationsHasUnreadByOwnerIdQuery__
 *
 * To run a query within a React component, call `useNotificationsHasUnreadByOwnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsHasUnreadByOwnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsHasUnreadByOwnerIdQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      dateSeen: // value for 'dateSeen'
 *   },
 * });
 */
export function useNotificationsHasUnreadByOwnerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationsHasUnreadByOwnerIdQuery, NotificationsHasUnreadByOwnerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<NotificationsHasUnreadByOwnerIdQuery, NotificationsHasUnreadByOwnerIdQueryVariables>(NotificationsHasUnreadByOwnerIdDocument, options);
      }
export function useNotificationsHasUnreadByOwnerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationsHasUnreadByOwnerIdQuery, NotificationsHasUnreadByOwnerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<NotificationsHasUnreadByOwnerIdQuery, NotificationsHasUnreadByOwnerIdQueryVariables>(NotificationsHasUnreadByOwnerIdDocument, options);
        }
export type NotificationsHasUnreadByOwnerIdQueryHookResult = ReturnType<typeof useNotificationsHasUnreadByOwnerIdQuery>;
export type NotificationsHasUnreadByOwnerIdLazyQueryHookResult = ReturnType<typeof useNotificationsHasUnreadByOwnerIdLazyQuery>;
export type NotificationsHasUnreadByOwnerIdQueryResult = Apollo.QueryResult<NotificationsHasUnreadByOwnerIdQuery, NotificationsHasUnreadByOwnerIdQueryVariables>;
export const NotificationMarkAsReadByIdDocument = gql`
    mutation NotificationMarkAsReadById($noteId: ID) {
  notificationMarkAsReadById(noteId: $noteId) {
    _id
    isRead
  }
}
    `;
export type NotificationMarkAsReadByIdMutationFn = Apollo.MutationFunction<NotificationMarkAsReadByIdMutation, NotificationMarkAsReadByIdMutationVariables>;

/**
 * __useNotificationMarkAsReadByIdMutation__
 *
 * To run a mutation, you first call `useNotificationMarkAsReadByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationMarkAsReadByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationMarkAsReadByIdMutation, { data, loading, error }] = useNotificationMarkAsReadByIdMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useNotificationMarkAsReadByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotificationMarkAsReadByIdMutation, NotificationMarkAsReadByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<NotificationMarkAsReadByIdMutation, NotificationMarkAsReadByIdMutationVariables>(NotificationMarkAsReadByIdDocument, options);
      }
export type NotificationMarkAsReadByIdMutationHookResult = ReturnType<typeof useNotificationMarkAsReadByIdMutation>;
export type NotificationMarkAsReadByIdMutationResult = Apollo.MutationResult<NotificationMarkAsReadByIdMutation>;
export type NotificationMarkAsReadByIdMutationOptions = Apollo.BaseMutationOptions<NotificationMarkAsReadByIdMutation, NotificationMarkAsReadByIdMutationVariables>;
export const NotificationDeleteByIdDocument = gql`
    mutation NotificationDeleteById($noteId: ID) {
  notificationDeleteById(noteId: $noteId) {
    __typename
  }
}
    `;
export type NotificationDeleteByIdMutationFn = Apollo.MutationFunction<NotificationDeleteByIdMutation, NotificationDeleteByIdMutationVariables>;

/**
 * __useNotificationDeleteByIdMutation__
 *
 * To run a mutation, you first call `useNotificationDeleteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationDeleteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationDeleteByIdMutation, { data, loading, error }] = useNotificationDeleteByIdMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useNotificationDeleteByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotificationDeleteByIdMutation, NotificationDeleteByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<NotificationDeleteByIdMutation, NotificationDeleteByIdMutationVariables>(NotificationDeleteByIdDocument, options);
      }
export type NotificationDeleteByIdMutationHookResult = ReturnType<typeof useNotificationDeleteByIdMutation>;
export type NotificationDeleteByIdMutationResult = Apollo.MutationResult<NotificationDeleteByIdMutation>;
export type NotificationDeleteByIdMutationOptions = Apollo.BaseMutationOptions<NotificationDeleteByIdMutation, NotificationDeleteByIdMutationVariables>;
export const ProblemsGetDocument = gql`
    query ProblemsGet($offset: Int, $limit: Int, $filter: Filter) {
  problems(offset: $offset, limit: $limit, filter: $filter) {
    list {
      _id
      title
      company {
        title
      }
      jobTitle
      solutions {
        _id
      }
      owner {
        _id
        firstName
        lastName
        image
      }
    }
    hasPrev
    hasNext
    totalPages
    totalCount
  }
}
    `;

/**
 * __useProblemsGetQuery__
 *
 * To run a query within a React component, call `useProblemsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProblemsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProblemsGetQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProblemsGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProblemsGetQuery, ProblemsGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProblemsGetQuery, ProblemsGetQueryVariables>(ProblemsGetDocument, options);
      }
export function useProblemsGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProblemsGetQuery, ProblemsGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProblemsGetQuery, ProblemsGetQueryVariables>(ProblemsGetDocument, options);
        }
export type ProblemsGetQueryHookResult = ReturnType<typeof useProblemsGetQuery>;
export type ProblemsGetLazyQueryHookResult = ReturnType<typeof useProblemsGetLazyQuery>;
export type ProblemsGetQueryResult = Apollo.QueryResult<ProblemsGetQuery, ProblemsGetQueryVariables>;
export const ProblemsGetByUserIdDocument = gql`
    query ProblemsGetByUserId($userId: ID!, $offset: Int, $limit: Int) {
  problemsGetByUserId(userId: $userId, offset: $offset, limit: $limit) {
    list {
      _id
      title
      jobTitle
      company {
        _id
        title
      }
    }
    hasPrev
    hasNext
    totalPages
    totalCount
  }
}
    `;

/**
 * __useProblemsGetByUserIdQuery__
 *
 * To run a query within a React component, call `useProblemsGetByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProblemsGetByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProblemsGetByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProblemsGetByUserIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProblemsGetByUserIdQuery, ProblemsGetByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProblemsGetByUserIdQuery, ProblemsGetByUserIdQueryVariables>(ProblemsGetByUserIdDocument, options);
      }
export function useProblemsGetByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProblemsGetByUserIdQuery, ProblemsGetByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProblemsGetByUserIdQuery, ProblemsGetByUserIdQueryVariables>(ProblemsGetByUserIdDocument, options);
        }
export type ProblemsGetByUserIdQueryHookResult = ReturnType<typeof useProblemsGetByUserIdQuery>;
export type ProblemsGetByUserIdLazyQueryHookResult = ReturnType<typeof useProblemsGetByUserIdLazyQuery>;
export type ProblemsGetByUserIdQueryResult = Apollo.QueryResult<ProblemsGetByUserIdQuery, ProblemsGetByUserIdQueryVariables>;
export const ProblemGetDocument = gql`
    query ProblemGet($problemId: ID!) {
  problem(problemId: $problemId) {
    _id
    title
    content
    company {
      _id
      title
    }
    jobTitle
    solutions {
      _id
      content
      complexity
      updatedAt
      owner {
        _id
        firstName
        lastName
      }
      likes {
        _id
        firstName
        lastName
        image
      }
    }
    owner {
      _id
      firstName
      lastName
      jobTitle
      image
    }
  }
}
    `;

/**
 * __useProblemGetQuery__
 *
 * To run a query within a React component, call `useProblemGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProblemGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProblemGetQuery({
 *   variables: {
 *      problemId: // value for 'problemId'
 *   },
 * });
 */
export function useProblemGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProblemGetQuery, ProblemGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProblemGetQuery, ProblemGetQueryVariables>(ProblemGetDocument, options);
      }
export function useProblemGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProblemGetQuery, ProblemGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProblemGetQuery, ProblemGetQueryVariables>(ProblemGetDocument, options);
        }
export type ProblemGetQueryHookResult = ReturnType<typeof useProblemGetQuery>;
export type ProblemGetLazyQueryHookResult = ReturnType<typeof useProblemGetLazyQuery>;
export type ProblemGetQueryResult = Apollo.QueryResult<ProblemGetQuery, ProblemGetQueryVariables>;
export const ProblemUpdateDocument = gql`
    mutation ProblemUpdate($problemId: ID!, $data: ProblemInput) {
  problemUpdate(problemId: $problemId, data: $data) {
    _id
    title
    content
    company {
      _id
      title
    }
    jobTitle
    owner {
      _id
      firstName
      lastName
      jobTitle
      image
    }
  }
}
    `;
export type ProblemUpdateMutationFn = Apollo.MutationFunction<ProblemUpdateMutation, ProblemUpdateMutationVariables>;

/**
 * __useProblemUpdateMutation__
 *
 * To run a mutation, you first call `useProblemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProblemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [problemUpdateMutation, { data, loading, error }] = useProblemUpdateMutation({
 *   variables: {
 *      problemId: // value for 'problemId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProblemUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProblemUpdateMutation, ProblemUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ProblemUpdateMutation, ProblemUpdateMutationVariables>(ProblemUpdateDocument, options);
      }
export type ProblemUpdateMutationHookResult = ReturnType<typeof useProblemUpdateMutation>;
export type ProblemUpdateMutationResult = Apollo.MutationResult<ProblemUpdateMutation>;
export type ProblemUpdateMutationOptions = Apollo.BaseMutationOptions<ProblemUpdateMutation, ProblemUpdateMutationVariables>;
export const ProblemCreateDocument = gql`
    mutation ProblemCreate($data: ProblemInput) {
  problemCreate(data: $data) {
    _id
    title
    content
    company {
      _id
      title
    }
    jobTitle
    owner {
      _id
      firstName
      lastName
      jobTitle
      image
    }
  }
}
    `;
export type ProblemCreateMutationFn = Apollo.MutationFunction<ProblemCreateMutation, ProblemCreateMutationVariables>;

/**
 * __useProblemCreateMutation__
 *
 * To run a mutation, you first call `useProblemCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProblemCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [problemCreateMutation, { data, loading, error }] = useProblemCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProblemCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProblemCreateMutation, ProblemCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ProblemCreateMutation, ProblemCreateMutationVariables>(ProblemCreateDocument, options);
      }
export type ProblemCreateMutationHookResult = ReturnType<typeof useProblemCreateMutation>;
export type ProblemCreateMutationResult = Apollo.MutationResult<ProblemCreateMutation>;
export type ProblemCreateMutationOptions = Apollo.BaseMutationOptions<ProblemCreateMutation, ProblemCreateMutationVariables>;
export const PublicationsGetDocument = gql`
    query PublicationsGet($offset: Int, $limit: Int) {
  publications(offset: $offset, limit: $limit) {
    list {
      ...Publication
    }
    hasPrev
    hasNext
    totalPages
    totalCount
    __typename
  }
}
    ${PublicationFragmentDoc}`;

/**
 * __usePublicationsGetQuery__
 *
 * To run a query within a React component, call `usePublicationsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationsGetQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePublicationsGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublicationsGetQuery, PublicationsGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PublicationsGetQuery, PublicationsGetQueryVariables>(PublicationsGetDocument, options);
      }
export function usePublicationsGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublicationsGetQuery, PublicationsGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PublicationsGetQuery, PublicationsGetQueryVariables>(PublicationsGetDocument, options);
        }
export type PublicationsGetQueryHookResult = ReturnType<typeof usePublicationsGetQuery>;
export type PublicationsGetLazyQueryHookResult = ReturnType<typeof usePublicationsGetLazyQuery>;
export type PublicationsGetQueryResult = Apollo.QueryResult<PublicationsGetQuery, PublicationsGetQueryVariables>;
export const PublicationSearchDocument = gql`
    query PublicationSearch($value: String, $offset: Int, $limit: Int) {
  publicationSearch(value: $value, offset: $offset, limit: $limit) {
    list {
      ...Publication
    }
    hasPrev
    hasNext
    totalPages
    totalCount
    __typename
  }
}
    ${PublicationFragmentDoc}`;

/**
 * __usePublicationSearchQuery__
 *
 * To run a query within a React component, call `usePublicationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationSearchQuery({
 *   variables: {
 *      value: // value for 'value'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePublicationSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublicationSearchQuery, PublicationSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PublicationSearchQuery, PublicationSearchQueryVariables>(PublicationSearchDocument, options);
      }
export function usePublicationSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublicationSearchQuery, PublicationSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PublicationSearchQuery, PublicationSearchQueryVariables>(PublicationSearchDocument, options);
        }
export type PublicationSearchQueryHookResult = ReturnType<typeof usePublicationSearchQuery>;
export type PublicationSearchLazyQueryHookResult = ReturnType<typeof usePublicationSearchLazyQuery>;
export type PublicationSearchQueryResult = Apollo.QueryResult<PublicationSearchQuery, PublicationSearchQueryVariables>;
export const PublicationsByUserIdDocument = gql`
    query PublicationsByUserId($userId: ID!) {
  publicationsByUserId(userId: $userId) {
    _id
    title
    description
    image
  }
}
    `;

/**
 * __usePublicationsByUserIdQuery__
 *
 * To run a query within a React component, call `usePublicationsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePublicationsByUserIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PublicationsByUserIdQuery, PublicationsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PublicationsByUserIdQuery, PublicationsByUserIdQueryVariables>(PublicationsByUserIdDocument, options);
      }
export function usePublicationsByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublicationsByUserIdQuery, PublicationsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PublicationsByUserIdQuery, PublicationsByUserIdQueryVariables>(PublicationsByUserIdDocument, options);
        }
export type PublicationsByUserIdQueryHookResult = ReturnType<typeof usePublicationsByUserIdQuery>;
export type PublicationsByUserIdLazyQueryHookResult = ReturnType<typeof usePublicationsByUserIdLazyQuery>;
export type PublicationsByUserIdQueryResult = Apollo.QueryResult<PublicationsByUserIdQuery, PublicationsByUserIdQueryVariables>;
export const PublicationGetDocument = gql`
    query PublicationGet($pubId: ID!) {
  publication(pubId: $pubId) {
    ...Publication
  }
}
    ${PublicationFragmentDoc}`;

/**
 * __usePublicationGetQuery__
 *
 * To run a query within a React component, call `usePublicationGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationGetQuery({
 *   variables: {
 *      pubId: // value for 'pubId'
 *   },
 * });
 */
export function usePublicationGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PublicationGetQuery, PublicationGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PublicationGetQuery, PublicationGetQueryVariables>(PublicationGetDocument, options);
      }
export function usePublicationGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublicationGetQuery, PublicationGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PublicationGetQuery, PublicationGetQueryVariables>(PublicationGetDocument, options);
        }
export type PublicationGetQueryHookResult = ReturnType<typeof usePublicationGetQuery>;
export type PublicationGetLazyQueryHookResult = ReturnType<typeof usePublicationGetLazyQuery>;
export type PublicationGetQueryResult = Apollo.QueryResult<PublicationGetQuery, PublicationGetQueryVariables>;
export const PublicationCommentsCountGetDocument = gql`
    query PublicationCommentsCountGet($pubId: ID!) {
  publication(pubId: $pubId) {
    _id
    commentsCount
  }
}
    `;

/**
 * __usePublicationCommentsCountGetQuery__
 *
 * To run a query within a React component, call `usePublicationCommentsCountGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationCommentsCountGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationCommentsCountGetQuery({
 *   variables: {
 *      pubId: // value for 'pubId'
 *   },
 * });
 */
export function usePublicationCommentsCountGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PublicationCommentsCountGetQuery, PublicationCommentsCountGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PublicationCommentsCountGetQuery, PublicationCommentsCountGetQueryVariables>(PublicationCommentsCountGetDocument, options);
      }
export function usePublicationCommentsCountGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublicationCommentsCountGetQuery, PublicationCommentsCountGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PublicationCommentsCountGetQuery, PublicationCommentsCountGetQueryVariables>(PublicationCommentsCountGetDocument, options);
        }
export type PublicationCommentsCountGetQueryHookResult = ReturnType<typeof usePublicationCommentsCountGetQuery>;
export type PublicationCommentsCountGetLazyQueryHookResult = ReturnType<typeof usePublicationCommentsCountGetLazyQuery>;
export type PublicationCommentsCountGetQueryResult = Apollo.QueryResult<PublicationCommentsCountGetQuery, PublicationCommentsCountGetQueryVariables>;
export const PublicationLikeDocument = gql`
    mutation PublicationLike($pubId: ID!) {
  publicationLike(pubId: $pubId) {
    _id
    likes {
      _id
      firstName
      lastName
      image
    }
  }
}
    `;
export type PublicationLikeMutationFn = Apollo.MutationFunction<PublicationLikeMutation, PublicationLikeMutationVariables>;

/**
 * __usePublicationLikeMutation__
 *
 * To run a mutation, you first call `usePublicationLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublicationLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publicationLikeMutation, { data, loading, error }] = usePublicationLikeMutation({
 *   variables: {
 *      pubId: // value for 'pubId'
 *   },
 * });
 */
export function usePublicationLikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublicationLikeMutation, PublicationLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PublicationLikeMutation, PublicationLikeMutationVariables>(PublicationLikeDocument, options);
      }
export type PublicationLikeMutationHookResult = ReturnType<typeof usePublicationLikeMutation>;
export type PublicationLikeMutationResult = Apollo.MutationResult<PublicationLikeMutation>;
export type PublicationLikeMutationOptions = Apollo.BaseMutationOptions<PublicationLikeMutation, PublicationLikeMutationVariables>;
export const PublicationCreateDocument = gql`
    mutation PublicationCreate($values: PublicationInput) {
  publicationCreate(values: $values) {
    ...Publication
  }
}
    ${PublicationFragmentDoc}`;
export type PublicationCreateMutationFn = Apollo.MutationFunction<PublicationCreateMutation, PublicationCreateMutationVariables>;

/**
 * __usePublicationCreateMutation__
 *
 * To run a mutation, you first call `usePublicationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublicationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publicationCreateMutation, { data, loading, error }] = usePublicationCreateMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function usePublicationCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublicationCreateMutation, PublicationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PublicationCreateMutation, PublicationCreateMutationVariables>(PublicationCreateDocument, options);
      }
export type PublicationCreateMutationHookResult = ReturnType<typeof usePublicationCreateMutation>;
export type PublicationCreateMutationResult = Apollo.MutationResult<PublicationCreateMutation>;
export type PublicationCreateMutationOptions = Apollo.BaseMutationOptions<PublicationCreateMutation, PublicationCreateMutationVariables>;
export const PublicationUpdateDocument = gql`
    mutation PublicationUpdate($pubId: ID!, $values: PublicationInput) {
  publicationUpdate(pubId: $pubId, values: $values) {
    ...Publication
  }
}
    ${PublicationFragmentDoc}`;
export type PublicationUpdateMutationFn = Apollo.MutationFunction<PublicationUpdateMutation, PublicationUpdateMutationVariables>;

/**
 * __usePublicationUpdateMutation__
 *
 * To run a mutation, you first call `usePublicationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublicationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publicationUpdateMutation, { data, loading, error }] = usePublicationUpdateMutation({
 *   variables: {
 *      pubId: // value for 'pubId'
 *      values: // value for 'values'
 *   },
 * });
 */
export function usePublicationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublicationUpdateMutation, PublicationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PublicationUpdateMutation, PublicationUpdateMutationVariables>(PublicationUpdateDocument, options);
      }
export type PublicationUpdateMutationHookResult = ReturnType<typeof usePublicationUpdateMutation>;
export type PublicationUpdateMutationResult = Apollo.MutationResult<PublicationUpdateMutation>;
export type PublicationUpdateMutationOptions = Apollo.BaseMutationOptions<PublicationUpdateMutation, PublicationUpdateMutationVariables>;
export const PublicationDeleteDocument = gql`
    mutation PublicationDelete($pubId: ID!) {
  publicationDelete(pubId: $pubId)
}
    `;
export type PublicationDeleteMutationFn = Apollo.MutationFunction<PublicationDeleteMutation, PublicationDeleteMutationVariables>;

/**
 * __usePublicationDeleteMutation__
 *
 * To run a mutation, you first call `usePublicationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublicationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publicationDeleteMutation, { data, loading, error }] = usePublicationDeleteMutation({
 *   variables: {
 *      pubId: // value for 'pubId'
 *   },
 * });
 */
export function usePublicationDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublicationDeleteMutation, PublicationDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PublicationDeleteMutation, PublicationDeleteMutationVariables>(PublicationDeleteDocument, options);
      }
export type PublicationDeleteMutationHookResult = ReturnType<typeof usePublicationDeleteMutation>;
export type PublicationDeleteMutationResult = Apollo.MutationResult<PublicationDeleteMutation>;
export type PublicationDeleteMutationOptions = Apollo.BaseMutationOptions<PublicationDeleteMutation, PublicationDeleteMutationVariables>;
export const ScheduleSlotCreateDocument = gql`
    mutation ScheduleSlotCreate($data: ScheduleSlotInput) {
  scheduleSlotCreate(data: $data) {
    ...ScheduleSlot
  }
}
    ${ScheduleSlotFragmentDoc}`;
export type ScheduleSlotCreateMutationFn = Apollo.MutationFunction<ScheduleSlotCreateMutation, ScheduleSlotCreateMutationVariables>;

/**
 * __useScheduleSlotCreateMutation__
 *
 * To run a mutation, you first call `useScheduleSlotCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleSlotCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleSlotCreateMutation, { data, loading, error }] = useScheduleSlotCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useScheduleSlotCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleSlotCreateMutation, ScheduleSlotCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ScheduleSlotCreateMutation, ScheduleSlotCreateMutationVariables>(ScheduleSlotCreateDocument, options);
      }
export type ScheduleSlotCreateMutationHookResult = ReturnType<typeof useScheduleSlotCreateMutation>;
export type ScheduleSlotCreateMutationResult = Apollo.MutationResult<ScheduleSlotCreateMutation>;
export type ScheduleSlotCreateMutationOptions = Apollo.BaseMutationOptions<ScheduleSlotCreateMutation, ScheduleSlotCreateMutationVariables>;
export const ScheduleSlotUpdateDocument = gql`
    mutation ScheduleSlotUpdate($slotId: ID, $values: ScheduleSlotUpdateInput) {
  scheduleSlotUpdate(slotId: $slotId, values: $values) {
    ...ScheduleSlot
  }
}
    ${ScheduleSlotFragmentDoc}`;
export type ScheduleSlotUpdateMutationFn = Apollo.MutationFunction<ScheduleSlotUpdateMutation, ScheduleSlotUpdateMutationVariables>;

/**
 * __useScheduleSlotUpdateMutation__
 *
 * To run a mutation, you first call `useScheduleSlotUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleSlotUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleSlotUpdateMutation, { data, loading, error }] = useScheduleSlotUpdateMutation({
 *   variables: {
 *      slotId: // value for 'slotId'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useScheduleSlotUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleSlotUpdateMutation, ScheduleSlotUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ScheduleSlotUpdateMutation, ScheduleSlotUpdateMutationVariables>(ScheduleSlotUpdateDocument, options);
      }
export type ScheduleSlotUpdateMutationHookResult = ReturnType<typeof useScheduleSlotUpdateMutation>;
export type ScheduleSlotUpdateMutationResult = Apollo.MutationResult<ScheduleSlotUpdateMutation>;
export type ScheduleSlotUpdateMutationOptions = Apollo.BaseMutationOptions<ScheduleSlotUpdateMutation, ScheduleSlotUpdateMutationVariables>;
export const ScheduleSlotDeleteDocument = gql`
    mutation ScheduleSlotDelete($slotId: ID) {
  scheduleSlotDelete(slotId: $slotId)
}
    `;
export type ScheduleSlotDeleteMutationFn = Apollo.MutationFunction<ScheduleSlotDeleteMutation, ScheduleSlotDeleteMutationVariables>;

/**
 * __useScheduleSlotDeleteMutation__
 *
 * To run a mutation, you first call `useScheduleSlotDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleSlotDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleSlotDeleteMutation, { data, loading, error }] = useScheduleSlotDeleteMutation({
 *   variables: {
 *      slotId: // value for 'slotId'
 *   },
 * });
 */
export function useScheduleSlotDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleSlotDeleteMutation, ScheduleSlotDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ScheduleSlotDeleteMutation, ScheduleSlotDeleteMutationVariables>(ScheduleSlotDeleteDocument, options);
      }
export type ScheduleSlotDeleteMutationHookResult = ReturnType<typeof useScheduleSlotDeleteMutation>;
export type ScheduleSlotDeleteMutationResult = Apollo.MutationResult<ScheduleSlotDeleteMutation>;
export type ScheduleSlotDeleteMutationOptions = Apollo.BaseMutationOptions<ScheduleSlotDeleteMutation, ScheduleSlotDeleteMutationVariables>;
export const ScheduleSlotDocument = gql`
    query ScheduleSlot($slotId: ID) {
  scheduleSlot(slotId: $slotId) {
    ...ScheduleSlot
  }
}
    ${ScheduleSlotFragmentDoc}`;

/**
 * __useScheduleSlotQuery__
 *
 * To run a query within a React component, call `useScheduleSlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleSlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleSlotQuery({
 *   variables: {
 *      slotId: // value for 'slotId'
 *   },
 * });
 */
export function useScheduleSlotQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScheduleSlotQuery, ScheduleSlotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ScheduleSlotQuery, ScheduleSlotQueryVariables>(ScheduleSlotDocument, options);
      }
export function useScheduleSlotLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScheduleSlotQuery, ScheduleSlotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ScheduleSlotQuery, ScheduleSlotQueryVariables>(ScheduleSlotDocument, options);
        }
export type ScheduleSlotQueryHookResult = ReturnType<typeof useScheduleSlotQuery>;
export type ScheduleSlotLazyQueryHookResult = ReturnType<typeof useScheduleSlotLazyQuery>;
export type ScheduleSlotQueryResult = Apollo.QueryResult<ScheduleSlotQuery, ScheduleSlotQueryVariables>;
export const ScheduleSlotsGetByParentIdDocument = gql`
    query ScheduleSlotsGetByParentId($parentId: ID, $entityType: EntityType, $offset: Int, $limit: Int) {
  scheduleSlotsGetByParentId(
    parentId: $parentId
    entityType: $entityType
    offset: $offset
    limit: $limit
  ) {
    list {
      ...ScheduleSlot
    }
    hasPrev
    hasNext
    totalPages
    totalCount
  }
}
    ${ScheduleSlotFragmentDoc}`;

/**
 * __useScheduleSlotsGetByParentIdQuery__
 *
 * To run a query within a React component, call `useScheduleSlotsGetByParentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleSlotsGetByParentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleSlotsGetByParentIdQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      entityType: // value for 'entityType'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useScheduleSlotsGetByParentIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScheduleSlotsGetByParentIdQuery, ScheduleSlotsGetByParentIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ScheduleSlotsGetByParentIdQuery, ScheduleSlotsGetByParentIdQueryVariables>(ScheduleSlotsGetByParentIdDocument, options);
      }
export function useScheduleSlotsGetByParentIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScheduleSlotsGetByParentIdQuery, ScheduleSlotsGetByParentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ScheduleSlotsGetByParentIdQuery, ScheduleSlotsGetByParentIdQueryVariables>(ScheduleSlotsGetByParentIdDocument, options);
        }
export type ScheduleSlotsGetByParentIdQueryHookResult = ReturnType<typeof useScheduleSlotsGetByParentIdQuery>;
export type ScheduleSlotsGetByParentIdLazyQueryHookResult = ReturnType<typeof useScheduleSlotsGetByParentIdLazyQuery>;
export type ScheduleSlotsGetByParentIdQueryResult = Apollo.QueryResult<ScheduleSlotsGetByParentIdQuery, ScheduleSlotsGetByParentIdQueryVariables>;
export const SolutionGetDocument = gql`
    query SolutionGet($solutionId: ID!) {
  solution(solutionId: $solutionId) {
    _id
    problemId
    content
    owner {
      _id
      firstName
      lastName
      image
    }
    likes {
      _id
      firstName
      lastName
      image
    }
    complexity
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSolutionGetQuery__
 *
 * To run a query within a React component, call `useSolutionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolutionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolutionGetQuery({
 *   variables: {
 *      solutionId: // value for 'solutionId'
 *   },
 * });
 */
export function useSolutionGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SolutionGetQuery, SolutionGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SolutionGetQuery, SolutionGetQueryVariables>(SolutionGetDocument, options);
      }
export function useSolutionGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SolutionGetQuery, SolutionGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SolutionGetQuery, SolutionGetQueryVariables>(SolutionGetDocument, options);
        }
export type SolutionGetQueryHookResult = ReturnType<typeof useSolutionGetQuery>;
export type SolutionGetLazyQueryHookResult = ReturnType<typeof useSolutionGetLazyQuery>;
export type SolutionGetQueryResult = Apollo.QueryResult<SolutionGetQuery, SolutionGetQueryVariables>;
export const SolutionCreateDocument = gql`
    mutation SolutionCreate($problemId: ID!, $content: String!) {
  solutionCreate(problemId: $problemId, content: $content) {
    _id
    problemId
    content
    owner {
      _id
    }
    complexity
    createdAt
    updatedAt
  }
}
    `;
export type SolutionCreateMutationFn = Apollo.MutationFunction<SolutionCreateMutation, SolutionCreateMutationVariables>;

/**
 * __useSolutionCreateMutation__
 *
 * To run a mutation, you first call `useSolutionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSolutionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [solutionCreateMutation, { data, loading, error }] = useSolutionCreateMutation({
 *   variables: {
 *      problemId: // value for 'problemId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSolutionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SolutionCreateMutation, SolutionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SolutionCreateMutation, SolutionCreateMutationVariables>(SolutionCreateDocument, options);
      }
export type SolutionCreateMutationHookResult = ReturnType<typeof useSolutionCreateMutation>;
export type SolutionCreateMutationResult = Apollo.MutationResult<SolutionCreateMutation>;
export type SolutionCreateMutationOptions = Apollo.BaseMutationOptions<SolutionCreateMutation, SolutionCreateMutationVariables>;
export const SolutionUpdateDocument = gql`
    mutation SolutionUpdate($solutionId: ID!, $content: String!) {
  solutionUpdate(solutionId: $solutionId, content: $content) {
    _id
    problemId
    content
    owner {
      _id
      firstName
      lastName
      image
    }
    likes {
      _id
      firstName
      lastName
      image
    }
    complexity
    createdAt
    updatedAt
  }
}
    `;
export type SolutionUpdateMutationFn = Apollo.MutationFunction<SolutionUpdateMutation, SolutionUpdateMutationVariables>;

/**
 * __useSolutionUpdateMutation__
 *
 * To run a mutation, you first call `useSolutionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSolutionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [solutionUpdateMutation, { data, loading, error }] = useSolutionUpdateMutation({
 *   variables: {
 *      solutionId: // value for 'solutionId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSolutionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SolutionUpdateMutation, SolutionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SolutionUpdateMutation, SolutionUpdateMutationVariables>(SolutionUpdateDocument, options);
      }
export type SolutionUpdateMutationHookResult = ReturnType<typeof useSolutionUpdateMutation>;
export type SolutionUpdateMutationResult = Apollo.MutationResult<SolutionUpdateMutation>;
export type SolutionUpdateMutationOptions = Apollo.BaseMutationOptions<SolutionUpdateMutation, SolutionUpdateMutationVariables>;
export const SolutionLikeDocument = gql`
    mutation SolutionLike($solutionId: ID!) {
  solutionLike(solutionId: $solutionId) {
    _id
    likes {
      _id
      firstName
      lastName
      image
    }
  }
}
    `;
export type SolutionLikeMutationFn = Apollo.MutationFunction<SolutionLikeMutation, SolutionLikeMutationVariables>;

/**
 * __useSolutionLikeMutation__
 *
 * To run a mutation, you first call `useSolutionLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSolutionLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [solutionLikeMutation, { data, loading, error }] = useSolutionLikeMutation({
 *   variables: {
 *      solutionId: // value for 'solutionId'
 *   },
 * });
 */
export function useSolutionLikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SolutionLikeMutation, SolutionLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SolutionLikeMutation, SolutionLikeMutationVariables>(SolutionLikeDocument, options);
      }
export type SolutionLikeMutationHookResult = ReturnType<typeof useSolutionLikeMutation>;
export type SolutionLikeMutationResult = Apollo.MutationResult<SolutionLikeMutation>;
export type SolutionLikeMutationOptions = Apollo.BaseMutationOptions<SolutionLikeMutation, SolutionLikeMutationVariables>;
export const SolutionDeleteDocument = gql`
    mutation SolutionDelete($solutionId: ID!) {
  solutionDelete(solutionId: $solutionId)
}
    `;
export type SolutionDeleteMutationFn = Apollo.MutationFunction<SolutionDeleteMutation, SolutionDeleteMutationVariables>;

/**
 * __useSolutionDeleteMutation__
 *
 * To run a mutation, you first call `useSolutionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSolutionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [solutionDeleteMutation, { data, loading, error }] = useSolutionDeleteMutation({
 *   variables: {
 *      solutionId: // value for 'solutionId'
 *   },
 * });
 */
export function useSolutionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SolutionDeleteMutation, SolutionDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SolutionDeleteMutation, SolutionDeleteMutationVariables>(SolutionDeleteDocument, options);
      }
export type SolutionDeleteMutationHookResult = ReturnType<typeof useSolutionDeleteMutation>;
export type SolutionDeleteMutationResult = Apollo.MutationResult<SolutionDeleteMutation>;
export type SolutionDeleteMutationOptions = Apollo.BaseMutationOptions<SolutionDeleteMutation, SolutionDeleteMutationVariables>;
export const TaskGetByIdDocument = gql`
    query TaskGetById($taskId: ID) {
  taskGet(taskId: $taskId) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

/**
 * __useTaskGetByIdQuery__
 *
 * To run a query within a React component, call `useTaskGetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskGetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskGetByIdQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskGetByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TaskGetByIdQuery, TaskGetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TaskGetByIdQuery, TaskGetByIdQueryVariables>(TaskGetByIdDocument, options);
      }
export function useTaskGetByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskGetByIdQuery, TaskGetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TaskGetByIdQuery, TaskGetByIdQueryVariables>(TaskGetByIdDocument, options);
        }
export type TaskGetByIdQueryHookResult = ReturnType<typeof useTaskGetByIdQuery>;
export type TaskGetByIdLazyQueryHookResult = ReturnType<typeof useTaskGetByIdLazyQuery>;
export type TaskGetByIdQueryResult = Apollo.QueryResult<TaskGetByIdQuery, TaskGetByIdQueryVariables>;
export const TasksGetByOwnerIdDocument = gql`
    query TasksGetByOwnerId($ownerId: ID, $offset: Int, $limit: Int) {
  tasksGetByOwnerId(ownerId: $ownerId, offset: $offset, limit: $limit) {
    list {
      ...Task
    }
    hasPrev
    hasNext
    totalPages
    totalCount
  }
}
    ${TaskFragmentDoc}`;

/**
 * __useTasksGetByOwnerIdQuery__
 *
 * To run a query within a React component, call `useTasksGetByOwnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksGetByOwnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksGetByOwnerIdQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTasksGetByOwnerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TasksGetByOwnerIdQuery, TasksGetByOwnerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TasksGetByOwnerIdQuery, TasksGetByOwnerIdQueryVariables>(TasksGetByOwnerIdDocument, options);
      }
export function useTasksGetByOwnerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TasksGetByOwnerIdQuery, TasksGetByOwnerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TasksGetByOwnerIdQuery, TasksGetByOwnerIdQueryVariables>(TasksGetByOwnerIdDocument, options);
        }
export type TasksGetByOwnerIdQueryHookResult = ReturnType<typeof useTasksGetByOwnerIdQuery>;
export type TasksGetByOwnerIdLazyQueryHookResult = ReturnType<typeof useTasksGetByOwnerIdLazyQuery>;
export type TasksGetByOwnerIdQueryResult = Apollo.QueryResult<TasksGetByOwnerIdQuery, TasksGetByOwnerIdQueryVariables>;
export const TaskCreateDocument = gql`
    mutation TaskCreate($data: TaskInput) {
  taskCreate(data: $data) {
    _id
    title
    content
  }
}
    `;
export type TaskCreateMutationFn = Apollo.MutationFunction<TaskCreateMutation, TaskCreateMutationVariables>;

/**
 * __useTaskCreateMutation__
 *
 * To run a mutation, you first call `useTaskCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCreateMutation, { data, loading, error }] = useTaskCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTaskCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskCreateMutation, TaskCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TaskCreateMutation, TaskCreateMutationVariables>(TaskCreateDocument, options);
      }
export type TaskCreateMutationHookResult = ReturnType<typeof useTaskCreateMutation>;
export type TaskCreateMutationResult = Apollo.MutationResult<TaskCreateMutation>;
export type TaskCreateMutationOptions = Apollo.BaseMutationOptions<TaskCreateMutation, TaskCreateMutationVariables>;
export const TaskDeleteDocument = gql`
    mutation TaskDelete($taskId: ID) {
  taskDelete(taskId: $taskId)
}
    `;
export type TaskDeleteMutationFn = Apollo.MutationFunction<TaskDeleteMutation, TaskDeleteMutationVariables>;

/**
 * __useTaskDeleteMutation__
 *
 * To run a mutation, you first call `useTaskDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskDeleteMutation, { data, loading, error }] = useTaskDeleteMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskDeleteMutation, TaskDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TaskDeleteMutation, TaskDeleteMutationVariables>(TaskDeleteDocument, options);
      }
export type TaskDeleteMutationHookResult = ReturnType<typeof useTaskDeleteMutation>;
export type TaskDeleteMutationResult = Apollo.MutationResult<TaskDeleteMutation>;
export type TaskDeleteMutationOptions = Apollo.BaseMutationOptions<TaskDeleteMutation, TaskDeleteMutationVariables>;
export const TaskUpdateDocument = gql`
    mutation TaskUpdate($taskId: ID, $values: TaskInput) {
  taskUpdate(taskId: $taskId, values: $values) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;
export type TaskUpdateMutationFn = Apollo.MutationFunction<TaskUpdateMutation, TaskUpdateMutationVariables>;

/**
 * __useTaskUpdateMutation__
 *
 * To run a mutation, you first call `useTaskUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskUpdateMutation, { data, loading, error }] = useTaskUpdateMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useTaskUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskUpdateMutation, TaskUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TaskUpdateMutation, TaskUpdateMutationVariables>(TaskUpdateDocument, options);
      }
export type TaskUpdateMutationHookResult = ReturnType<typeof useTaskUpdateMutation>;
export type TaskUpdateMutationResult = Apollo.MutationResult<TaskUpdateMutation>;
export type TaskUpdateMutationOptions = Apollo.BaseMutationOptions<TaskUpdateMutation, TaskUpdateMutationVariables>;
export const TaskAnswersListGetDocument = gql`
    query TaskAnswersListGet($blockId: ID, $parentId: ID, $offset: Int, $limit: Int) {
  taskAnswersListGet(
    blockId: $blockId
    parentId: $parentId
    offset: $offset
    limit: $limit
  ) {
    list {
      ...TaskAnswerShort
    }
    hasPrev
    hasNext
    totalPages
    totalCount
  }
}
    ${TaskAnswerShortFragmentDoc}`;

/**
 * __useTaskAnswersListGetQuery__
 *
 * To run a query within a React component, call `useTaskAnswersListGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskAnswersListGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskAnswersListGetQuery({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      parentId: // value for 'parentId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTaskAnswersListGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TaskAnswersListGetQuery, TaskAnswersListGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TaskAnswersListGetQuery, TaskAnswersListGetQueryVariables>(TaskAnswersListGetDocument, options);
      }
export function useTaskAnswersListGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskAnswersListGetQuery, TaskAnswersListGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TaskAnswersListGetQuery, TaskAnswersListGetQueryVariables>(TaskAnswersListGetDocument, options);
        }
export type TaskAnswersListGetQueryHookResult = ReturnType<typeof useTaskAnswersListGetQuery>;
export type TaskAnswersListGetLazyQueryHookResult = ReturnType<typeof useTaskAnswersListGetLazyQuery>;
export type TaskAnswersListGetQueryResult = Apollo.QueryResult<TaskAnswersListGetQuery, TaskAnswersListGetQueryVariables>;
export const TaskAnswerGetContentDocument = gql`
    query TaskAnswerGetContent($answerId: ID!) {
  taskAnswerGet(answerId: $answerId) {
    ...TaskAnswerContent
  }
}
    ${TaskAnswerContentFragmentDoc}`;

/**
 * __useTaskAnswerGetContentQuery__
 *
 * To run a query within a React component, call `useTaskAnswerGetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskAnswerGetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskAnswerGetContentQuery({
 *   variables: {
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useTaskAnswerGetContentQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TaskAnswerGetContentQuery, TaskAnswerGetContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TaskAnswerGetContentQuery, TaskAnswerGetContentQueryVariables>(TaskAnswerGetContentDocument, options);
      }
export function useTaskAnswerGetContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskAnswerGetContentQuery, TaskAnswerGetContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TaskAnswerGetContentQuery, TaskAnswerGetContentQueryVariables>(TaskAnswerGetContentDocument, options);
        }
export type TaskAnswerGetContentQueryHookResult = ReturnType<typeof useTaskAnswerGetContentQuery>;
export type TaskAnswerGetContentLazyQueryHookResult = ReturnType<typeof useTaskAnswerGetContentLazyQuery>;
export type TaskAnswerGetContentQueryResult = Apollo.QueryResult<TaskAnswerGetContentQuery, TaskAnswerGetContentQueryVariables>;
export const TaskAnswerCreateDocument = gql`
    mutation TaskAnswerCreate($data: TaskAnswerInput) {
  taskAnswerCreate(data: $data) {
    ...TaskAnswer
  }
}
    ${TaskAnswerFragmentDoc}`;
export type TaskAnswerCreateMutationFn = Apollo.MutationFunction<TaskAnswerCreateMutation, TaskAnswerCreateMutationVariables>;

/**
 * __useTaskAnswerCreateMutation__
 *
 * To run a mutation, you first call `useTaskAnswerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskAnswerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskAnswerCreateMutation, { data, loading, error }] = useTaskAnswerCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTaskAnswerCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskAnswerCreateMutation, TaskAnswerCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TaskAnswerCreateMutation, TaskAnswerCreateMutationVariables>(TaskAnswerCreateDocument, options);
      }
export type TaskAnswerCreateMutationHookResult = ReturnType<typeof useTaskAnswerCreateMutation>;
export type TaskAnswerCreateMutationResult = Apollo.MutationResult<TaskAnswerCreateMutation>;
export type TaskAnswerCreateMutationOptions = Apollo.BaseMutationOptions<TaskAnswerCreateMutation, TaskAnswerCreateMutationVariables>;
export const TaskAnswerDeleteDocument = gql`
    mutation TaskAnswerDelete($answerId: ID!) {
  taskAnswerDelete(answerId: $answerId)
}
    `;
export type TaskAnswerDeleteMutationFn = Apollo.MutationFunction<TaskAnswerDeleteMutation, TaskAnswerDeleteMutationVariables>;

/**
 * __useTaskAnswerDeleteMutation__
 *
 * To run a mutation, you first call `useTaskAnswerDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskAnswerDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskAnswerDeleteMutation, { data, loading, error }] = useTaskAnswerDeleteMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useTaskAnswerDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskAnswerDeleteMutation, TaskAnswerDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TaskAnswerDeleteMutation, TaskAnswerDeleteMutationVariables>(TaskAnswerDeleteDocument, options);
      }
export type TaskAnswerDeleteMutationHookResult = ReturnType<typeof useTaskAnswerDeleteMutation>;
export type TaskAnswerDeleteMutationResult = Apollo.MutationResult<TaskAnswerDeleteMutation>;
export type TaskAnswerDeleteMutationOptions = Apollo.BaseMutationOptions<TaskAnswerDeleteMutation, TaskAnswerDeleteMutationVariables>;
export const TaskAnswerUpdateDocument = gql`
    mutation TaskAnswerUpdate($answerId: ID!, $content: String!) {
  taskAnswerUpdate(answerId: $answerId, content: $content) {
    ...TaskAnswerContent
    status
    updatedAt
  }
}
    ${TaskAnswerContentFragmentDoc}`;
export type TaskAnswerUpdateMutationFn = Apollo.MutationFunction<TaskAnswerUpdateMutation, TaskAnswerUpdateMutationVariables>;

/**
 * __useTaskAnswerUpdateMutation__
 *
 * To run a mutation, you first call `useTaskAnswerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskAnswerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskAnswerUpdateMutation, { data, loading, error }] = useTaskAnswerUpdateMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useTaskAnswerUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskAnswerUpdateMutation, TaskAnswerUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TaskAnswerUpdateMutation, TaskAnswerUpdateMutationVariables>(TaskAnswerUpdateDocument, options);
      }
export type TaskAnswerUpdateMutationHookResult = ReturnType<typeof useTaskAnswerUpdateMutation>;
export type TaskAnswerUpdateMutationResult = Apollo.MutationResult<TaskAnswerUpdateMutation>;
export type TaskAnswerUpdateMutationOptions = Apollo.BaseMutationOptions<TaskAnswerUpdateMutation, TaskAnswerUpdateMutationVariables>;
export const TaskAnswerStatusUpdateDocument = gql`
    mutation TaskAnswerStatusUpdate($answerId: ID!, $status: Status!) {
  taskAnswerStatusUpdate(answerId: $answerId, status: $status) {
    ...TaskAnswerStatus
  }
}
    ${TaskAnswerStatusFragmentDoc}`;
export type TaskAnswerStatusUpdateMutationFn = Apollo.MutationFunction<TaskAnswerStatusUpdateMutation, TaskAnswerStatusUpdateMutationVariables>;

/**
 * __useTaskAnswerStatusUpdateMutation__
 *
 * To run a mutation, you first call `useTaskAnswerStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskAnswerStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskAnswerStatusUpdateMutation, { data, loading, error }] = useTaskAnswerStatusUpdateMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTaskAnswerStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskAnswerStatusUpdateMutation, TaskAnswerStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TaskAnswerStatusUpdateMutation, TaskAnswerStatusUpdateMutationVariables>(TaskAnswerStatusUpdateDocument, options);
      }
export type TaskAnswerStatusUpdateMutationHookResult = ReturnType<typeof useTaskAnswerStatusUpdateMutation>;
export type TaskAnswerStatusUpdateMutationResult = Apollo.MutationResult<TaskAnswerStatusUpdateMutation>;
export type TaskAnswerStatusUpdateMutationOptions = Apollo.BaseMutationOptions<TaskAnswerStatusUpdateMutation, TaskAnswerStatusUpdateMutationVariables>;
export const UserLogoutDocument = gql`
    query UserLogout {
  logout
}
    `;

/**
 * __useUserLogoutQuery__
 *
 * To run a query within a React component, call `useUserLogoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLogoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLogoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLogoutQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserLogoutQuery, UserLogoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserLogoutQuery, UserLogoutQueryVariables>(UserLogoutDocument, options);
      }
export function useUserLogoutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserLogoutQuery, UserLogoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserLogoutQuery, UserLogoutQueryVariables>(UserLogoutDocument, options);
        }
export type UserLogoutQueryHookResult = ReturnType<typeof useUserLogoutQuery>;
export type UserLogoutLazyQueryHookResult = ReturnType<typeof useUserLogoutLazyQuery>;
export type UserLogoutQueryResult = Apollo.QueryResult<UserLogoutQuery, UserLogoutQueryVariables>;
export const UserLoginDocument = gql`
    query UserLogin($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    user {
      ...UserShort
      email
      isActivated
      roles
    }
  }
}
    ${UserShortFragmentDoc}`;

/**
 * __useUserLoginQuery__
 *
 * To run a query within a React component, call `useUserLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserLoginQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserLoginQuery, UserLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserLoginQuery, UserLoginQueryVariables>(UserLoginDocument, options);
      }
export function useUserLoginLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserLoginQuery, UserLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserLoginQuery, UserLoginQueryVariables>(UserLoginDocument, options);
        }
export type UserLoginQueryHookResult = ReturnType<typeof useUserLoginQuery>;
export type UserLoginLazyQueryHookResult = ReturnType<typeof useUserLoginLazyQuery>;
export type UserLoginQueryResult = Apollo.QueryResult<UserLoginQuery, UserLoginQueryVariables>;
export const UserCheckAuthDocument = gql`
    mutation UserCheckAuth {
  userCheckAuth {
    user {
      ...UserShort
      roles
      level
    }
  }
}
    ${UserShortFragmentDoc}`;
export type UserCheckAuthMutationFn = Apollo.MutationFunction<UserCheckAuthMutation, UserCheckAuthMutationVariables>;

/**
 * __useUserCheckAuthMutation__
 *
 * To run a mutation, you first call `useUserCheckAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCheckAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCheckAuthMutation, { data, loading, error }] = useUserCheckAuthMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserCheckAuthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserCheckAuthMutation, UserCheckAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserCheckAuthMutation, UserCheckAuthMutationVariables>(UserCheckAuthDocument, options);
      }
export type UserCheckAuthMutationHookResult = ReturnType<typeof useUserCheckAuthMutation>;
export type UserCheckAuthMutationResult = Apollo.MutationResult<UserCheckAuthMutation>;
export type UserCheckAuthMutationOptions = Apollo.BaseMutationOptions<UserCheckAuthMutation, UserCheckAuthMutationVariables>;
export const UserRefreshTokenDocument = gql`
    mutation UserRefreshToken {
  userRefreshToken {
    accessToken
    user {
      _id
    }
  }
}
    `;
export type UserRefreshTokenMutationFn = Apollo.MutationFunction<UserRefreshTokenMutation, UserRefreshTokenMutationVariables>;

/**
 * __useUserRefreshTokenMutation__
 *
 * To run a mutation, you first call `useUserRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRefreshTokenMutation, { data, loading, error }] = useUserRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserRefreshTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserRefreshTokenMutation, UserRefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserRefreshTokenMutation, UserRefreshTokenMutationVariables>(UserRefreshTokenDocument, options);
      }
export type UserRefreshTokenMutationHookResult = ReturnType<typeof useUserRefreshTokenMutation>;
export type UserRefreshTokenMutationResult = Apollo.MutationResult<UserRefreshTokenMutation>;
export type UserRefreshTokenMutationOptions = Apollo.BaseMutationOptions<UserRefreshTokenMutation, UserRefreshTokenMutationVariables>;
export const UserRegistrationDocument = gql`
    mutation UserRegistration($email: String!, $password: String!) {
  userCreate(email: $email, password: $password)
}
    `;
export type UserRegistrationMutationFn = Apollo.MutationFunction<UserRegistrationMutation, UserRegistrationMutationVariables>;

/**
 * __useUserRegistrationMutation__
 *
 * To run a mutation, you first call `useUserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRegistrationMutation, { data, loading, error }] = useUserRegistrationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserRegistrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserRegistrationMutation, UserRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserRegistrationMutation, UserRegistrationMutationVariables>(UserRegistrationDocument, options);
      }
export type UserRegistrationMutationHookResult = ReturnType<typeof useUserRegistrationMutation>;
export type UserRegistrationMutationResult = Apollo.MutationResult<UserRegistrationMutation>;
export type UserRegistrationMutationOptions = Apollo.BaseMutationOptions<UserRegistrationMutation, UserRegistrationMutationVariables>;
export const UserActivateDocument = gql`
    mutation UserActivate($activationLinkId: String!) {
  userActivate(activationLinkId: $activationLinkId)
}
    `;
export type UserActivateMutationFn = Apollo.MutationFunction<UserActivateMutation, UserActivateMutationVariables>;

/**
 * __useUserActivateMutation__
 *
 * To run a mutation, you first call `useUserActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userActivateMutation, { data, loading, error }] = useUserActivateMutation({
 *   variables: {
 *      activationLinkId: // value for 'activationLinkId'
 *   },
 * });
 */
export function useUserActivateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserActivateMutation, UserActivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserActivateMutation, UserActivateMutationVariables>(UserActivateDocument, options);
      }
export type UserActivateMutationHookResult = ReturnType<typeof useUserActivateMutation>;
export type UserActivateMutationResult = Apollo.MutationResult<UserActivateMutation>;
export type UserActivateMutationOptions = Apollo.BaseMutationOptions<UserActivateMutation, UserActivateMutationVariables>;
export const UserResendActivationLinkDocument = gql`
    mutation UserResendActivationLink($email: String!) {
  userResendActivationLink(email: $email)
}
    `;
export type UserResendActivationLinkMutationFn = Apollo.MutationFunction<UserResendActivationLinkMutation, UserResendActivationLinkMutationVariables>;

/**
 * __useUserResendActivationLinkMutation__
 *
 * To run a mutation, you first call `useUserResendActivationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserResendActivationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userResendActivationLinkMutation, { data, loading, error }] = useUserResendActivationLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserResendActivationLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserResendActivationLinkMutation, UserResendActivationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserResendActivationLinkMutation, UserResendActivationLinkMutationVariables>(UserResendActivationLinkDocument, options);
      }
export type UserResendActivationLinkMutationHookResult = ReturnType<typeof useUserResendActivationLinkMutation>;
export type UserResendActivationLinkMutationResult = Apollo.MutationResult<UserResendActivationLinkMutation>;
export type UserResendActivationLinkMutationOptions = Apollo.BaseMutationOptions<UserResendActivationLinkMutation, UserResendActivationLinkMutationVariables>;
export const UserGetDocument = gql`
    query UserGet($userId: ID!) {
  user(userId: $userId) {
    ...UserShort
    email
    about
    roles
    jobTitle
    languages
    level
    starredProblems {
      ...StarredProblemsFragment
    }
    starredPublications {
      ...StarredPublicationsFragment
    }
    friendList {
      ...UserFriendFragment
    }
    friendInviteSent {
      ...UserFriendFragment
    }
    friendInviteReceived {
      ...UserFriendFragment
    }
  }
}
    ${UserShortFragmentDoc}
${StarredProblemsFragmentFragmentDoc}
${StarredPublicationsFragmentFragmentDoc}
${UserFriendFragmentFragmentDoc}`;

/**
 * __useUserGetQuery__
 *
 * To run a query within a React component, call `useUserGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, options);
      }
export function useUserGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, options);
        }
export type UserGetQueryHookResult = ReturnType<typeof useUserGetQuery>;
export type UserGetLazyQueryHookResult = ReturnType<typeof useUserGetLazyQuery>;
export type UserGetQueryResult = Apollo.QueryResult<UserGetQuery, UserGetQueryVariables>;
export const UserStarredPublicationsGetDocument = gql`
    query UserStarredPublicationsGet($userId: ID!) {
  user(userId: $userId) {
    _id
    starredPublications {
      _id
    }
  }
}
    `;

/**
 * __useUserStarredPublicationsGetQuery__
 *
 * To run a query within a React component, call `useUserStarredPublicationsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStarredPublicationsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStarredPublicationsGetQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserStarredPublicationsGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserStarredPublicationsGetQuery, UserStarredPublicationsGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserStarredPublicationsGetQuery, UserStarredPublicationsGetQueryVariables>(UserStarredPublicationsGetDocument, options);
      }
export function useUserStarredPublicationsGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStarredPublicationsGetQuery, UserStarredPublicationsGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserStarredPublicationsGetQuery, UserStarredPublicationsGetQueryVariables>(UserStarredPublicationsGetDocument, options);
        }
export type UserStarredPublicationsGetQueryHookResult = ReturnType<typeof useUserStarredPublicationsGetQuery>;
export type UserStarredPublicationsGetLazyQueryHookResult = ReturnType<typeof useUserStarredPublicationsGetLazyQuery>;
export type UserStarredPublicationsGetQueryResult = Apollo.QueryResult<UserStarredPublicationsGetQuery, UserStarredPublicationsGetQueryVariables>;
export const UserStarredMentorBlocksGetDocument = gql`
    query UserStarredMentorBlocksGet($userId: ID!) {
  user(userId: $userId) {
    _id
    starredMentorBlocks {
      _id
      title
      description
      owner {
        image
      }
    }
  }
}
    `;

/**
 * __useUserStarredMentorBlocksGetQuery__
 *
 * To run a query within a React component, call `useUserStarredMentorBlocksGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStarredMentorBlocksGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStarredMentorBlocksGetQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserStarredMentorBlocksGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserStarredMentorBlocksGetQuery, UserStarredMentorBlocksGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserStarredMentorBlocksGetQuery, UserStarredMentorBlocksGetQueryVariables>(UserStarredMentorBlocksGetDocument, options);
      }
export function useUserStarredMentorBlocksGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStarredMentorBlocksGetQuery, UserStarredMentorBlocksGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserStarredMentorBlocksGetQuery, UserStarredMentorBlocksGetQueryVariables>(UserStarredMentorBlocksGetDocument, options);
        }
export type UserStarredMentorBlocksGetQueryHookResult = ReturnType<typeof useUserStarredMentorBlocksGetQuery>;
export type UserStarredMentorBlocksGetLazyQueryHookResult = ReturnType<typeof useUserStarredMentorBlocksGetLazyQuery>;
export type UserStarredMentorBlocksGetQueryResult = Apollo.QueryResult<UserStarredMentorBlocksGetQuery, UserStarredMentorBlocksGetQueryVariables>;
export const UserFriendListGetDocument = gql`
    query UserFriendListGet($userId: ID!) {
  user(userId: $userId) {
    _id
    friendList {
      _id
    }
    friendInviteSent {
      _id
    }
    friendInviteReceived {
      _id
    }
  }
}
    `;

/**
 * __useUserFriendListGetQuery__
 *
 * To run a query within a React component, call `useUserFriendListGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFriendListGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFriendListGetQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserFriendListGetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserFriendListGetQuery, UserFriendListGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserFriendListGetQuery, UserFriendListGetQueryVariables>(UserFriendListGetDocument, options);
      }
export function useUserFriendListGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserFriendListGetQuery, UserFriendListGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserFriendListGetQuery, UserFriendListGetQueryVariables>(UserFriendListGetDocument, options);
        }
export type UserFriendListGetQueryHookResult = ReturnType<typeof useUserFriendListGetQuery>;
export type UserFriendListGetLazyQueryHookResult = ReturnType<typeof useUserFriendListGetLazyQuery>;
export type UserFriendListGetQueryResult = Apollo.QueryResult<UserFriendListGetQuery, UserFriendListGetQueryVariables>;
export const UserFriendInviteDocument = gql`
    mutation UserFriendInvite($friendId: ID!) {
  userFriendInvite(friendId: $friendId) {
    _id
    friendList {
      _id
    }
    friendInviteSent {
      _id
    }
    friendInviteReceived {
      _id
    }
  }
}
    `;
export type UserFriendInviteMutationFn = Apollo.MutationFunction<UserFriendInviteMutation, UserFriendInviteMutationVariables>;

/**
 * __useUserFriendInviteMutation__
 *
 * To run a mutation, you first call `useUserFriendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFriendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFriendInviteMutation, { data, loading, error }] = useUserFriendInviteMutation({
 *   variables: {
 *      friendId: // value for 'friendId'
 *   },
 * });
 */
export function useUserFriendInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserFriendInviteMutation, UserFriendInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserFriendInviteMutation, UserFriendInviteMutationVariables>(UserFriendInviteDocument, options);
      }
export type UserFriendInviteMutationHookResult = ReturnType<typeof useUserFriendInviteMutation>;
export type UserFriendInviteMutationResult = Apollo.MutationResult<UserFriendInviteMutation>;
export type UserFriendInviteMutationOptions = Apollo.BaseMutationOptions<UserFriendInviteMutation, UserFriendInviteMutationVariables>;
export const UserFriendRejectDocument = gql`
    mutation UserFriendReject($friendId: ID!) {
  userFriendReject(friendId: $friendId) {
    _id
    friendList {
      _id
    }
    friendInviteSent {
      _id
    }
    friendInviteReceived {
      _id
    }
  }
}
    `;
export type UserFriendRejectMutationFn = Apollo.MutationFunction<UserFriendRejectMutation, UserFriendRejectMutationVariables>;

/**
 * __useUserFriendRejectMutation__
 *
 * To run a mutation, you first call `useUserFriendRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFriendRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFriendRejectMutation, { data, loading, error }] = useUserFriendRejectMutation({
 *   variables: {
 *      friendId: // value for 'friendId'
 *   },
 * });
 */
export function useUserFriendRejectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserFriendRejectMutation, UserFriendRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserFriendRejectMutation, UserFriendRejectMutationVariables>(UserFriendRejectDocument, options);
      }
export type UserFriendRejectMutationHookResult = ReturnType<typeof useUserFriendRejectMutation>;
export type UserFriendRejectMutationResult = Apollo.MutationResult<UserFriendRejectMutation>;
export type UserFriendRejectMutationOptions = Apollo.BaseMutationOptions<UserFriendRejectMutation, UserFriendRejectMutationVariables>;
export const UserUpdateDocument = gql`
    mutation UserUpdate($userId: ID!, $values: UserInput) {
  userUpdate(userId: $userId, values: $values) {
    ...UserShort
    email
    about
    roles
    jobTitle
    languages
    level
    starredProblems {
      ...StarredProblemsFragment
    }
    starredPublications {
      ...StarredPublicationsFragment
    }
  }
}
    ${UserShortFragmentDoc}
${StarredProblemsFragmentFragmentDoc}
${StarredPublicationsFragmentFragmentDoc}`;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserEmailUpdateDocument = gql`
    mutation UserEmailUpdate($userId: ID!, $values: EmailUpdateInput) {
  userEmailUpdate(userId: $userId, values: $values)
}
    `;
export type UserEmailUpdateMutationFn = Apollo.MutationFunction<UserEmailUpdateMutation, UserEmailUpdateMutationVariables>;

/**
 * __useUserEmailUpdateMutation__
 *
 * To run a mutation, you first call `useUserEmailUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserEmailUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userEmailUpdateMutation, { data, loading, error }] = useUserEmailUpdateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUserEmailUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserEmailUpdateMutation, UserEmailUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserEmailUpdateMutation, UserEmailUpdateMutationVariables>(UserEmailUpdateDocument, options);
      }
export type UserEmailUpdateMutationHookResult = ReturnType<typeof useUserEmailUpdateMutation>;
export type UserEmailUpdateMutationResult = Apollo.MutationResult<UserEmailUpdateMutation>;
export type UserEmailUpdateMutationOptions = Apollo.BaseMutationOptions<UserEmailUpdateMutation, UserEmailUpdateMutationVariables>;
export const UserPasswordResetRequestDocument = gql`
    mutation UserPasswordResetRequest($email: String!) {
  userPasswordResetRequest(email: $email)
}
    `;
export type UserPasswordResetRequestMutationFn = Apollo.MutationFunction<UserPasswordResetRequestMutation, UserPasswordResetRequestMutationVariables>;

/**
 * __useUserPasswordResetRequestMutation__
 *
 * To run a mutation, you first call `useUserPasswordResetRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPasswordResetRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPasswordResetRequestMutation, { data, loading, error }] = useUserPasswordResetRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserPasswordResetRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserPasswordResetRequestMutation, UserPasswordResetRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserPasswordResetRequestMutation, UserPasswordResetRequestMutationVariables>(UserPasswordResetRequestDocument, options);
      }
export type UserPasswordResetRequestMutationHookResult = ReturnType<typeof useUserPasswordResetRequestMutation>;
export type UserPasswordResetRequestMutationResult = Apollo.MutationResult<UserPasswordResetRequestMutation>;
export type UserPasswordResetRequestMutationOptions = Apollo.BaseMutationOptions<UserPasswordResetRequestMutation, UserPasswordResetRequestMutationVariables>;
export const UserPasswordUpdateDocument = gql`
    mutation UserPasswordUpdate($resetId: String!, $password: String!) {
  userPasswordReset(resetId: $resetId, password: $password)
}
    `;
export type UserPasswordUpdateMutationFn = Apollo.MutationFunction<UserPasswordUpdateMutation, UserPasswordUpdateMutationVariables>;

/**
 * __useUserPasswordUpdateMutation__
 *
 * To run a mutation, you first call `useUserPasswordUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPasswordUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPasswordUpdateMutation, { data, loading, error }] = useUserPasswordUpdateMutation({
 *   variables: {
 *      resetId: // value for 'resetId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserPasswordUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserPasswordUpdateMutation, UserPasswordUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserPasswordUpdateMutation, UserPasswordUpdateMutationVariables>(UserPasswordUpdateDocument, options);
      }
export type UserPasswordUpdateMutationHookResult = ReturnType<typeof useUserPasswordUpdateMutation>;
export type UserPasswordUpdateMutationResult = Apollo.MutationResult<UserPasswordUpdateMutation>;
export type UserPasswordUpdateMutationOptions = Apollo.BaseMutationOptions<UserPasswordUpdateMutation, UserPasswordUpdateMutationVariables>;
export const UserStarPublicationDocument = gql`
    mutation UserStarPublication($userId: ID!, $pubId: ID!) {
  userStarPublication(userId: $userId, pubId: $pubId) {
    _id
    starredPublications {
      _id
    }
  }
}
    `;
export type UserStarPublicationMutationFn = Apollo.MutationFunction<UserStarPublicationMutation, UserStarPublicationMutationVariables>;

/**
 * __useUserStarPublicationMutation__
 *
 * To run a mutation, you first call `useUserStarPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStarPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStarPublicationMutation, { data, loading, error }] = useUserStarPublicationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      pubId: // value for 'pubId'
 *   },
 * });
 */
export function useUserStarPublicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserStarPublicationMutation, UserStarPublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserStarPublicationMutation, UserStarPublicationMutationVariables>(UserStarPublicationDocument, options);
      }
export type UserStarPublicationMutationHookResult = ReturnType<typeof useUserStarPublicationMutation>;
export type UserStarPublicationMutationResult = Apollo.MutationResult<UserStarPublicationMutation>;
export type UserStarPublicationMutationOptions = Apollo.BaseMutationOptions<UserStarPublicationMutation, UserStarPublicationMutationVariables>;
export const UserStarMentorBlockDocument = gql`
    mutation UserStarMentorBlock($userId: ID!, $blockId: ID!) {
  userStarMentorBlock(userId: $userId, blockId: $blockId) {
    _id
    starredMentorBlocks {
      _id
    }
  }
}
    `;
export type UserStarMentorBlockMutationFn = Apollo.MutationFunction<UserStarMentorBlockMutation, UserStarMentorBlockMutationVariables>;

/**
 * __useUserStarMentorBlockMutation__
 *
 * To run a mutation, you first call `useUserStarMentorBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStarMentorBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStarMentorBlockMutation, { data, loading, error }] = useUserStarMentorBlockMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useUserStarMentorBlockMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserStarMentorBlockMutation, UserStarMentorBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UserStarMentorBlockMutation, UserStarMentorBlockMutationVariables>(UserStarMentorBlockDocument, options);
      }
export type UserStarMentorBlockMutationHookResult = ReturnType<typeof useUserStarMentorBlockMutation>;
export type UserStarMentorBlockMutationResult = Apollo.MutationResult<UserStarMentorBlockMutation>;
export type UserStarMentorBlockMutationOptions = Apollo.BaseMutationOptions<UserStarMentorBlockMutation, UserStarMentorBlockMutationVariables>;
export const UserSearchDocument = gql`
    query UserSearch($value: String!, $offset: Int, $limit: Int) {
  userSearch(value: $value, offset: $offset, limit: $limit) {
    list {
      ...UserShort
      jobTitle
    }
    hasPrev
    hasNext
    totalPages
    totalCount
  }
}
    ${UserShortFragmentDoc}`;

/**
 * __useUserSearchQuery__
 *
 * To run a query within a React component, call `useUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSearchQuery({
 *   variables: {
 *      value: // value for 'value'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUserSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserSearchQuery, UserSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserSearchQuery, UserSearchQueryVariables>(UserSearchDocument, options);
      }
export function useUserSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSearchQuery, UserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserSearchQuery, UserSearchQueryVariables>(UserSearchDocument, options);
        }
export type UserSearchQueryHookResult = ReturnType<typeof useUserSearchQuery>;
export type UserSearchLazyQueryHookResult = ReturnType<typeof useUserSearchLazyQuery>;
export type UserSearchQueryResult = Apollo.QueryResult<UserSearchQuery, UserSearchQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CommentParentType": [
      "Comment",
      "Publication",
      "ScheduleSlot",
      "TaskAnswer"
    ],
    "Entity": [
      "Lesson",
      "MentorBlock",
      "Task"
    ],
    "NotificationEntity": [
      "Comment",
      "MentorBlock",
      "Publication",
      "ScheduleSlot",
      "User"
    ]
  }
};
      export default result;
    
export type CommentKeySpecifier = ('_id' | 'content' | 'createdAt' | 'likes' | 'owner' | 'parent' | 'parentType' | 'updatedAt' | CommentKeySpecifier)[];
export type CommentFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	likes?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	parent?: FieldPolicy<any> | FieldReadFunction<any>,
	parentType?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CommentsPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | CommentsPaginatedKeySpecifier)[];
export type CommentsPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CompanyKeySpecifier = ('_id' | 'description' | 'image' | 'likes' | 'link' | 'problems' | 'title' | CompanyKeySpecifier)[];
export type CompanyFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	likes?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	problems?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CompanyPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | CompanyPaginatedKeySpecifier)[];
export type CompanyPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CompanySearchKeySpecifier = ('description' | 'image' | 'title' | CompanySearchKeySpecifier)[];
export type CompanySearchFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GlobalSearchResultKeySpecifier = ('companies' | 'mentorBlocks' | 'problems' | 'publications' | 'totalCount' | 'users' | GlobalSearchResultKeySpecifier)[];
export type GlobalSearchResultFieldPolicy = {
	companies?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlocks?: FieldPolicy<any> | FieldReadFunction<any>,
	problems?: FieldPolicy<any> | FieldReadFunction<any>,
	publications?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	users?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HomeworkKeySpecifier = ('hidden' | 'tasks' | HomeworkKeySpecifier)[];
export type HomeworkFieldPolicy = {
	hidden?: FieldPolicy<any> | FieldReadFunction<any>,
	tasks?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LessonKeySpecifier = ('_id' | 'content' | 'createdAt' | 'homework' | 'owner' | 'title' | 'updatedAt' | LessonKeySpecifier)[];
export type LessonFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	homework?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LessonsPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | LessonsPaginatedKeySpecifier)[];
export type LessonsPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MentorBlockKeySpecifier = ('_id' | 'createdAt' | 'description' | 'endDate' | 'isGroup' | 'isStarred' | 'mentees' | 'mentors' | 'owner' | 'requests' | 'stack' | 'startDate' | 'title' | MentorBlockKeySpecifier)[];
export type MentorBlockFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	endDate?: FieldPolicy<any> | FieldReadFunction<any>,
	isGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	isStarred?: FieldPolicy<any> | FieldReadFunction<any>,
	mentees?: FieldPolicy<any> | FieldReadFunction<any>,
	mentors?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	requests?: FieldPolicy<any> | FieldReadFunction<any>,
	stack?: FieldPolicy<any> | FieldReadFunction<any>,
	startDate?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MentorBlockPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | MentorBlockPaginatedKeySpecifier)[];
export type MentorBlockPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('commentCreate' | 'commentDelete' | 'commentLike' | 'commentUpdate' | 'companyCreate' | 'companyDelete' | 'companyLike' | 'companyUpdate' | 'lessonCreate' | 'lessonDelete' | 'lessonUpdate' | 'mentorBlockAcceptRequest' | 'mentorBlockCreate' | 'mentorBlockDelete' | 'mentorBlockJoin' | 'mentorBlockRemoveMentee' | 'mentorBlockRemoveMenteeSelf' | 'mentorBlockRemoveMentorSelf' | 'mentorBlockStar' | 'mentorBlockUpdate' | 'notificationDeleteById' | 'notificationMarkAsReadById' | 'problemCreate' | 'problemDelete' | 'problemUpdate' | 'publicationCreate' | 'publicationDelete' | 'publicationLike' | 'publicationUpdate' | 'scheduleSlotCreate' | 'scheduleSlotDelete' | 'scheduleSlotUpdate' | 'solutionCreate' | 'solutionDelete' | 'solutionLike' | 'solutionUpdate' | 'taskAnswerCreate' | 'taskAnswerDelete' | 'taskAnswerStatusUpdate' | 'taskAnswerUpdate' | 'taskCreate' | 'taskDelete' | 'taskUpdate' | 'userActivate' | 'userCheckAuth' | 'userCreate' | 'userDelete' | 'userEmailUpdate' | 'userFriendInvite' | 'userFriendReject' | 'userPasswordReset' | 'userPasswordResetRequest' | 'userRefreshToken' | 'userResendActivationLink' | 'userStarMentorBlock' | 'userStarPublication' | 'userUpdate' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	commentCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	commentDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	commentLike?: FieldPolicy<any> | FieldReadFunction<any>,
	commentUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	companyCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	companyDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	companyLike?: FieldPolicy<any> | FieldReadFunction<any>,
	companyUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	lessonCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	lessonDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	lessonUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlockAcceptRequest?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlockCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlockDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlockJoin?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlockRemoveMentee?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlockRemoveMenteeSelf?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlockRemoveMentorSelf?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlockStar?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlockUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	notificationDeleteById?: FieldPolicy<any> | FieldReadFunction<any>,
	notificationMarkAsReadById?: FieldPolicy<any> | FieldReadFunction<any>,
	problemCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	problemDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	problemUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	publicationCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	publicationDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	publicationLike?: FieldPolicy<any> | FieldReadFunction<any>,
	publicationUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleSlotCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleSlotDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleSlotUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	solutionCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	solutionDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	solutionLike?: FieldPolicy<any> | FieldReadFunction<any>,
	solutionUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	taskAnswerCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	taskAnswerDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	taskAnswerStatusUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	taskAnswerUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	taskCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	taskDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	taskUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	userActivate?: FieldPolicy<any> | FieldReadFunction<any>,
	userCheckAuth?: FieldPolicy<any> | FieldReadFunction<any>,
	userCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	userDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	userEmailUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	userFriendInvite?: FieldPolicy<any> | FieldReadFunction<any>,
	userFriendReject?: FieldPolicy<any> | FieldReadFunction<any>,
	userPasswordReset?: FieldPolicy<any> | FieldReadFunction<any>,
	userPasswordResetRequest?: FieldPolicy<any> | FieldReadFunction<any>,
	userRefreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	userResendActivationLink?: FieldPolicy<any> | FieldReadFunction<any>,
	userStarMentorBlock?: FieldPolicy<any> | FieldReadFunction<any>,
	userStarPublication?: FieldPolicy<any> | FieldReadFunction<any>,
	userUpdate?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationKeySpecifier = ('_id' | 'actionType' | 'entity' | 'entityType' | 'initiator' | 'isRead' | 'owner' | NotificationKeySpecifier)[];
export type NotificationFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	actionType?: FieldPolicy<any> | FieldReadFunction<any>,
	entity?: FieldPolicy<any> | FieldReadFunction<any>,
	entityType?: FieldPolicy<any> | FieldReadFunction<any>,
	initiator?: FieldPolicy<any> | FieldReadFunction<any>,
	isRead?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationsPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | NotificationsPaginatedKeySpecifier)[];
export type NotificationsPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProblemKeySpecifier = ('_id' | 'company' | 'content' | 'jobTitle' | 'owner' | 'solutions' | 'title' | ProblemKeySpecifier)[];
export type ProblemFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	jobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	solutions?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProblemsPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | ProblemsPaginatedKeySpecifier)[];
export type ProblemsPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PublicationKeySpecifier = ('_id' | 'commentsCount' | 'content' | 'createdAt' | 'description' | 'image' | 'likes' | 'owner' | 'title' | 'updatedAt' | PublicationKeySpecifier)[];
export type PublicationFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	commentsCount?: FieldPolicy<any> | FieldReadFunction<any>,
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	likes?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PublicationPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | PublicationPaginatedKeySpecifier)[];
export type PublicationPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('comment' | 'comments' | 'companies' | 'company' | 'companySearch' | 'companySearchNew' | 'globalSearch' | 'lesson' | 'lessonsGetByIdList' | 'lessonsGetByOwnerId' | 'login' | 'logout' | 'mentorBlock' | 'mentorBlocks' | 'mentorBlocksSearch' | 'mentorGetAllByMenteeId' | 'mentorGetAllByOwnerId' | 'notificationsGetByOwnerId' | 'notificationsHasUnreadByOwnerId' | 'problem' | 'problems' | 'problemsGetByUserId' | 'publication' | 'publicationSearch' | 'publications' | 'publicationsByUserId' | 'scheduleSlot' | 'scheduleSlotsGetByParentId' | 'solution' | 'solutions' | 'taskAnswerGet' | 'taskAnswersListGet' | 'taskGet' | 'tasksGetByIdList' | 'tasksGetByOwnerId' | 'user' | 'userSearch' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	comment?: FieldPolicy<any> | FieldReadFunction<any>,
	comments?: FieldPolicy<any> | FieldReadFunction<any>,
	companies?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	companySearch?: FieldPolicy<any> | FieldReadFunction<any>,
	companySearchNew?: FieldPolicy<any> | FieldReadFunction<any>,
	globalSearch?: FieldPolicy<any> | FieldReadFunction<any>,
	lesson?: FieldPolicy<any> | FieldReadFunction<any>,
	lessonsGetByIdList?: FieldPolicy<any> | FieldReadFunction<any>,
	lessonsGetByOwnerId?: FieldPolicy<any> | FieldReadFunction<any>,
	login?: FieldPolicy<any> | FieldReadFunction<any>,
	logout?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlock?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlocks?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorBlocksSearch?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorGetAllByMenteeId?: FieldPolicy<any> | FieldReadFunction<any>,
	mentorGetAllByOwnerId?: FieldPolicy<any> | FieldReadFunction<any>,
	notificationsGetByOwnerId?: FieldPolicy<any> | FieldReadFunction<any>,
	notificationsHasUnreadByOwnerId?: FieldPolicy<any> | FieldReadFunction<any>,
	problem?: FieldPolicy<any> | FieldReadFunction<any>,
	problems?: FieldPolicy<any> | FieldReadFunction<any>,
	problemsGetByUserId?: FieldPolicy<any> | FieldReadFunction<any>,
	publication?: FieldPolicy<any> | FieldReadFunction<any>,
	publicationSearch?: FieldPolicy<any> | FieldReadFunction<any>,
	publications?: FieldPolicy<any> | FieldReadFunction<any>,
	publicationsByUserId?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleSlot?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleSlotsGetByParentId?: FieldPolicy<any> | FieldReadFunction<any>,
	solution?: FieldPolicy<any> | FieldReadFunction<any>,
	solutions?: FieldPolicy<any> | FieldReadFunction<any>,
	taskAnswerGet?: FieldPolicy<any> | FieldReadFunction<any>,
	taskAnswersListGet?: FieldPolicy<any> | FieldReadFunction<any>,
	taskGet?: FieldPolicy<any> | FieldReadFunction<any>,
	tasksGetByIdList?: FieldPolicy<any> | FieldReadFunction<any>,
	tasksGetByOwnerId?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	userSearch?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduleSlotKeySpecifier = ('_id' | 'endDate' | 'entity' | 'entityType' | 'ownerId' | 'parentId' | 'startDate' | 'streamLink' | 'streamProvider' | ScheduleSlotKeySpecifier)[];
export type ScheduleSlotFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	endDate?: FieldPolicy<any> | FieldReadFunction<any>,
	entity?: FieldPolicy<any> | FieldReadFunction<any>,
	entityType?: FieldPolicy<any> | FieldReadFunction<any>,
	ownerId?: FieldPolicy<any> | FieldReadFunction<any>,
	parentId?: FieldPolicy<any> | FieldReadFunction<any>,
	startDate?: FieldPolicy<any> | FieldReadFunction<any>,
	streamLink?: FieldPolicy<any> | FieldReadFunction<any>,
	streamProvider?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduleSlotPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | ScheduleSlotPaginatedKeySpecifier)[];
export type ScheduleSlotPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduleSlotsPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | ScheduleSlotsPaginatedKeySpecifier)[];
export type ScheduleSlotsPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SolutionKeySpecifier = ('_id' | 'complexity' | 'content' | 'createdAt' | 'likes' | 'owner' | 'problemId' | 'updatedAt' | SolutionKeySpecifier)[];
export type SolutionFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	complexity?: FieldPolicy<any> | FieldReadFunction<any>,
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	likes?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	problemId?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TaskKeySpecifier = ('_id' | 'content' | 'createdAt' | 'owner' | 'title' | 'updatedAt' | TaskKeySpecifier)[];
export type TaskFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TaskAnswerKeySpecifier = ('_id' | 'content' | 'createdAt' | 'owner' | 'parent' | 'status' | 'updatedAt' | TaskAnswerKeySpecifier)[];
export type TaskAnswerFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	parent?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TaskAnswersPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | TaskAnswersPaginatedKeySpecifier)[];
export type TaskAnswersPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TasksPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | TasksPaginatedKeySpecifier)[];
export type TasksPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserKeySpecifier = ('_id' | 'about' | 'activationLinkId' | 'createdAt' | 'email' | 'firstName' | 'friendInviteReceived' | 'friendInviteSent' | 'friendList' | 'image' | 'isActivated' | 'jobTitle' | 'languages' | 'lastAccess' | 'lastName' | 'level' | 'links' | 'roles' | 'starredMentorBlocks' | 'starredProblems' | 'starredPublications' | 'updatedAt' | UserKeySpecifier)[];
export type UserFieldPolicy = {
	_id?: FieldPolicy<any> | FieldReadFunction<any>,
	about?: FieldPolicy<any> | FieldReadFunction<any>,
	activationLinkId?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	friendInviteReceived?: FieldPolicy<any> | FieldReadFunction<any>,
	friendInviteSent?: FieldPolicy<any> | FieldReadFunction<any>,
	friendList?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	isActivated?: FieldPolicy<any> | FieldReadFunction<any>,
	jobTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	languages?: FieldPolicy<any> | FieldReadFunction<any>,
	lastAccess?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	level?: FieldPolicy<any> | FieldReadFunction<any>,
	links?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	starredMentorBlocks?: FieldPolicy<any> | FieldReadFunction<any>,
	starredProblems?: FieldPolicy<any> | FieldReadFunction<any>,
	starredPublications?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserAuthDataKeySpecifier = ('accessToken' | 'user' | UserAuthDataKeySpecifier)[];
export type UserAuthDataFieldPolicy = {
	accessToken?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UsersPaginatedKeySpecifier = ('hasNext' | 'hasPrev' | 'list' | 'totalCount' | 'totalPages' | UsersPaginatedKeySpecifier)[];
export type UsersPaginatedFieldPolicy = {
	hasNext?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPrev?: FieldPolicy<any> | FieldReadFunction<any>,
	list?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPages?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	Comment?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommentKeySpecifier | (() => undefined | CommentKeySpecifier),
		fields?: CommentFieldPolicy,
	},
	CommentsPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommentsPaginatedKeySpecifier | (() => undefined | CommentsPaginatedKeySpecifier),
		fields?: CommentsPaginatedFieldPolicy,
	},
	Company?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyKeySpecifier | (() => undefined | CompanyKeySpecifier),
		fields?: CompanyFieldPolicy,
	},
	CompanyPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyPaginatedKeySpecifier | (() => undefined | CompanyPaginatedKeySpecifier),
		fields?: CompanyPaginatedFieldPolicy,
	},
	CompanySearch?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanySearchKeySpecifier | (() => undefined | CompanySearchKeySpecifier),
		fields?: CompanySearchFieldPolicy,
	},
	GlobalSearchResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GlobalSearchResultKeySpecifier | (() => undefined | GlobalSearchResultKeySpecifier),
		fields?: GlobalSearchResultFieldPolicy,
	},
	Homework?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HomeworkKeySpecifier | (() => undefined | HomeworkKeySpecifier),
		fields?: HomeworkFieldPolicy,
	},
	Lesson?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LessonKeySpecifier | (() => undefined | LessonKeySpecifier),
		fields?: LessonFieldPolicy,
	},
	LessonsPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LessonsPaginatedKeySpecifier | (() => undefined | LessonsPaginatedKeySpecifier),
		fields?: LessonsPaginatedFieldPolicy,
	},
	MentorBlock?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MentorBlockKeySpecifier | (() => undefined | MentorBlockKeySpecifier),
		fields?: MentorBlockFieldPolicy,
	},
	MentorBlockPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MentorBlockPaginatedKeySpecifier | (() => undefined | MentorBlockPaginatedKeySpecifier),
		fields?: MentorBlockPaginatedFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	Notification?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationKeySpecifier | (() => undefined | NotificationKeySpecifier),
		fields?: NotificationFieldPolicy,
	},
	NotificationsPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationsPaginatedKeySpecifier | (() => undefined | NotificationsPaginatedKeySpecifier),
		fields?: NotificationsPaginatedFieldPolicy,
	},
	Problem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProblemKeySpecifier | (() => undefined | ProblemKeySpecifier),
		fields?: ProblemFieldPolicy,
	},
	ProblemsPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProblemsPaginatedKeySpecifier | (() => undefined | ProblemsPaginatedKeySpecifier),
		fields?: ProblemsPaginatedFieldPolicy,
	},
	Publication?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PublicationKeySpecifier | (() => undefined | PublicationKeySpecifier),
		fields?: PublicationFieldPolicy,
	},
	PublicationPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PublicationPaginatedKeySpecifier | (() => undefined | PublicationPaginatedKeySpecifier),
		fields?: PublicationPaginatedFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	ScheduleSlot?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduleSlotKeySpecifier | (() => undefined | ScheduleSlotKeySpecifier),
		fields?: ScheduleSlotFieldPolicy,
	},
	ScheduleSlotPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduleSlotPaginatedKeySpecifier | (() => undefined | ScheduleSlotPaginatedKeySpecifier),
		fields?: ScheduleSlotPaginatedFieldPolicy,
	},
	ScheduleSlotsPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduleSlotsPaginatedKeySpecifier | (() => undefined | ScheduleSlotsPaginatedKeySpecifier),
		fields?: ScheduleSlotsPaginatedFieldPolicy,
	},
	Solution?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SolutionKeySpecifier | (() => undefined | SolutionKeySpecifier),
		fields?: SolutionFieldPolicy,
	},
	Task?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TaskKeySpecifier | (() => undefined | TaskKeySpecifier),
		fields?: TaskFieldPolicy,
	},
	TaskAnswer?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TaskAnswerKeySpecifier | (() => undefined | TaskAnswerKeySpecifier),
		fields?: TaskAnswerFieldPolicy,
	},
	TaskAnswersPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TaskAnswersPaginatedKeySpecifier | (() => undefined | TaskAnswersPaginatedKeySpecifier),
		fields?: TaskAnswersPaginatedFieldPolicy,
	},
	TasksPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TasksPaginatedKeySpecifier | (() => undefined | TasksPaginatedKeySpecifier),
		fields?: TasksPaginatedFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	},
	UserAuthData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserAuthDataKeySpecifier | (() => undefined | UserAuthDataKeySpecifier),
		fields?: UserAuthDataFieldPolicy,
	},
	UsersPaginated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UsersPaginatedKeySpecifier | (() => undefined | UsersPaginatedKeySpecifier),
		fields?: UsersPaginatedFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;