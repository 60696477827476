import React, { FC } from 'react';
import { Button, Table, TableBody, TableRow, Typography } from '@mui/material';
import {
  EventPreviewContentStyled,
  EventStyled,
  EventTitleStyled,
  TableBodyCellStyled,
  TableContainerStyled,
  TableHeaderCellStyled,
  TableHeadStyled
} from './WeekSimpleCalendar.styles';
import moment from 'moment/moment';
import { generateWeekDaysFromToday } from '../../../../helpers/generateWeekDaysFromToday';
import { Maybe, ScheduleSlot } from '../../../../graphql/generated';
import { Cast } from '@material-ui/icons';
import { useDialogContext } from '../../../../providers/dialogProvider/DialogContext';

interface WeekSimpleCalendarProps {
  events: Array<Maybe<ScheduleSlot>>;
}
export const WeekSimpleCalendar: FC<WeekSimpleCalendarProps> = ({ events }) => {
  const { onDialogOpen } = useDialogContext();
  const weekDaysFromToday = generateWeekDaysFromToday();
  const todayIndex = weekDaysFromToday.findIndex(day => day === moment().format('dddd'));

  const eventPreviewHandle = ({ title, time, link, streamProvider, entityType }: any) => {
    onDialogOpen({
      title: entityType.charAt(0).toUpperCase() + entityType.slice(1).toLowerCase(),
      children: () => (
        <EventPreviewContentStyled>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="subtitle1">Starts at: {time}</Typography>
          <Button size="large" variant="contained" href={link} target="_blank" startIcon={<Cast />}>
            {streamProvider}
          </Button>
        </EventPreviewContentStyled>
      )
    });
  };

  return (
    <TableContainerStyled>
      <Table>
        <TableHeadStyled>
          <TableRow>
            {weekDaysFromToday.map((day, index) => {
              const eventDayFull = moment().add(index, 'days').format('ddd, MMM DD');

              return (
                <TableHeaderCellStyled size="small" align="center" style={{ padding: '0.25rem' }} key={day}>
                  <Typography variant="subtitle2" color="white">
                    {eventDayFull}
                  </Typography>
                  <Typography variant="caption" color="white">
                    {index === todayIndex && ' (Today)'}
                  </Typography>
                </TableHeaderCellStyled>
              );
            })}
          </TableRow>
        </TableHeadStyled>
        <TableBody>
          <TableRow>
            {weekDaysFromToday.map((day, index) => (
              <TableBodyCellStyled key={day}>
                {events?.map(event => {
                  const eventDay = moment(Number(event?.startDate)).format('dddd');
                  const eventTime = moment(Number(event?.startDate)).format('LT');
                  return (
                    eventDay === day &&
                    event?.streamLink && (
                      <EventStyled
                        onClick={() =>
                          eventPreviewHandle({
                            title: event.entity.title,
                            time: eventTime,
                            link: event.streamLink,
                            streamProvider: event.streamProvider,
                            entityType: event.entityType
                          })
                        }
                        key={event._id}
                        eventType={event?.entityType || ''}
                      >
                        <Typography variant="subtitle2">{eventTime}</Typography>
                        <EventTitleStyled title={event.entity.title || ''}>{event.entity.title}</EventTitleStyled>
                      </EventStyled>
                    )
                  );
                })}
              </TableBodyCellStyled>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
};
