import { Button, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useUserPasswordResetRequestMutation } from '../../../graphql/generated';
import { Validate } from '../../../helpers/validation';
import { PaperStyled, TextFieldStyled } from '../Account.styles';
import RedirectToLogin from '../RedirectToLogin';

const RestorePassword = () => {
  const [formState, setFormState] = useState('');
  const [emailValidationError, setEmailValidationError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [restore, { loading }] = useUserPasswordResetRequestMutation({
    fetchPolicy: 'network-only'
  });

  const onFinish = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (Validate.email.check(formState)) {
      const result = await restore({
        variables: {
          email: formState
        }
      });
      const resData = result.data;
      if (resData) {
        enqueueSnackbar(resData?.userPasswordResetRequest, { variant: 'success' });
      }
    } else {
      setEmailValidationError(Validate.email.error);
    }
  };

  return (
    <PaperStyled>
      <h3>Restore password</h3>
      <form onSubmit={onFinish}>
        <TextFieldStyled
          id="email"
          size="small"
          label="Email"
          fullWidth={true}
          autoFocus={true}
          value={formState}
          onChange={e => {
            setEmailValidationError('');
            setFormState(e.target.value);
          }}
          helperText={emailValidationError}
          error={!!emailValidationError}
        />

        <Button
          type="submit"
          variant="contained"
          startIcon={loading && <CircularProgress size={18} />}
          disabled={!!emailValidationError || loading}
        >
          Send
        </Button>
      </form>

      <RedirectToLogin />
    </PaperStyled>
  );
};

export default RestorePassword;
