import { NotificationListItemProps } from './NotificationListItem';
import {
  NotificationsGetByOwnerIdDocument,
  useNotificationDeleteByIdMutation,
  useNotificationMarkAsReadByIdMutation
} from '../../../graphql/generated';
import { DeleteOutlined, PlaylistAddCheckOutlined } from '@material-ui/icons';
import { ActionsMenuOption } from '../../sharedComponents/actionsMenu/ActionsMenu';

export const useNotificationListItemController = ({ notification }: NotificationListItemProps) => {
  const [notificationMarkAsRead] = useNotificationMarkAsReadByIdMutation({ variables: { noteId: notification._id } });
  const [notificationDelete] = useNotificationDeleteByIdMutation({
    variables: { noteId: notification._id },
    refetchQueries: [NotificationsGetByOwnerIdDocument]
  });
  const isNotificationRead = notification.isRead;
  const initiatorFullName = `${notification.initiator?.firstName} ${notification.initiator?.lastName}`;
  const initiatorAvatarLink = notification.initiator?.image;
  // const initiatorLink = `/user/${notification.initiator?._id}`; //keep it here for next iterations
  const actionType = notification?.actionType || 'Like';
  const entityType = notification.entity?.__typename;
  const entityId = notification.entity?._id;
  const entityTitle = notification.entity?.title;
  let actionPhrase = '';
  let entityNamePhrase = '';
  let entityLink = '';

  switch (actionType) {
    case 'Like':
      actionPhrase = ' liked your ';
      break;
    case 'Comment':
      actionPhrase = ' commented your ';
      break;
    case 'FriendInvite':
      actionPhrase = ' want to be a friend ';
      break;
    case 'FriendAccept':
      actionPhrase = ' and you are friends ';
      break;
    case 'MentorBlockMenteeRequest':
      actionPhrase = ' wants to join to ';
      break;
    case 'MentorBlockMenteeAccept':
      actionPhrase = ' added you to ';
      break;
    case 'MentorBlockMenteeRemove':
      actionPhrase = ' removed you from ';
      break;
    case 'MentorBlockMenteeLeft':
      actionPhrase = ' left ';
      break;
    //keep it here for next iterations
    // case 'TaskAnswer':
    //   actionPhrase = ' created an answer to ';
    //   break;
    // case 'TaskAnswerApprove':
    //   actionPhrase = ' approved an answer ';
    //   break;
    case 'TaskAnswerReject':
      actionPhrase = ' rejected an answer ';
      break;
    default:
      actionPhrase = ' performed an action ';
      break;
  }

  switch (entityType) {
    case 'Publication': {
      entityNamePhrase = `publication: ${entityTitle}`;
      entityLink = `publication/${entityId}`;
      break;
    }
    case 'MentorBlock': {
      entityNamePhrase = `course: ${entityTitle}`;
      entityLink = `mentorium/${entityId}`;
      break;
    }
    case 'Comment': {
      entityNamePhrase = `comment: ${notification.entity?.content}`;
      const entityParentType = notification.entity.parent.__typename;
      const entityParentId = notification.entity?.parent._id;
      const courseId = notification.entity.parent.parent?.parentId;
      const courseEventId = notification.entity.parent.parent?._id;

      if (entityParentType === 'Publication') {
        entityLink = `${entityParentType.toLowerCase()}/${entityParentId}`;
      }
      if (entityParentType === 'Comment') {
        entityLink = `${entityParentType.toLowerCase()}/${entityParentId}`;
      }
      if (entityParentType === 'TaskAnswer') {
        //meaning the comment under comment, under course Task page
        entityLink = `mentorium/${courseId}/scheduledTask/${courseEventId}`;
      }
      if (entityParentType === 'ScheduleSlot') {
        //meaning the comment under comment, under course Lesson page
        entityLink = `mentorium/${courseId}/lesson/${courseEventId}`;
      }
      break;
    }
    case 'ScheduleSlot': {
      entityNamePhrase = `lesson: ${notification.entity?.entity.title}`;
      entityLink = `mentorium/${notification.entity?.parentId}/lesson/${notification.entity.entity._id}`;
      break;
    }
    case 'User': {
      entityLink = `user/${notification.initiator._id}`;
      break;
    }
    default:
      break;
  }

  const notificationMarkAsReadHandle = () => {
    if (!notification.isRead) {
      notificationMarkAsRead();
    }
  };

  const notificationDeleteHandle = () => {
    notificationDelete();
  };

  const actionOptions: ActionsMenuOption[] = [
    {
      onClick: notificationDeleteHandle,
      name: 'Delete',
      icon: DeleteOutlined
    },
    {
      onClick: notificationMarkAsReadHandle,
      name: 'Mark As Read',
      icon: PlaylistAddCheckOutlined
    }
  ];

  return {
    entityLink,
    initiatorAvatarLink,
    initiatorFullName,
    actionPhrase,
    entityNamePhrase,
    isNotificationRead,
    notificationMarkAsReadHandle,
    actionOptions
  };
};
