import React, { FC } from 'react';
import { AvatarStyled, Footer, Header, LinkStyled, RootStyled, Name } from './UserCard.styles';
import { FriendCtaButton } from '../../friendCtaButton/FriendCtaButton';

interface UserCardProps {
  imageUrl?: string;
  name: string;
  jobTitle: string;
  userId: string;
  roles?: string[];
  link: string;
}
export const UserCard: FC<UserCardProps> = ({ imageUrl, name, jobTitle, userId, link }) => {
  return (
    <RootStyled>
      <Header>
        <Name noWrap variant="subtitle2">
          <LinkStyled to={link}>{name}</LinkStyled>
        </Name>
      </Header>
      <AvatarStyled variant="square" src={imageUrl} />
      <Footer>
        <Name noWrap variant="body1" title={jobTitle}>
          {jobTitle}
        </Name>
        <FriendCtaButton variant="outlined" size="small" inviteeId={userId} />
      </Footer>
    </RootStyled>
  );
};
