import {
  EntityType,
  ScheduleSlot,
  ScheduleSlotsGetByParentIdDocument,
  useScheduleSlotCreateMutation,
  useScheduleSlotUpdateMutation
} from '../../../../graphql/generated';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { REGEX, Validate } from '../../../../helpers/validation';

interface EditScheduledItemFormControllerProps {
  scheduledItem?: ScheduleSlot;
  itemId: string;
  onCancel: () => void;
  itemEntityTypeName: EntityType;
}
export const useEditScheduledItemFormController = ({
  scheduledItem,
  onCancel,
  itemId,
  itemEntityTypeName
}: EditScheduledItemFormControllerProps) => {
  const { blockId }: { blockId: string } = useParams();
  const { reset, control, watch, handleSubmit } = useForm({
    resolver: yupResolver(
      yup.object({
        startDate: yup.date().required('Start Date is required').typeError('Please enter a valid Start Date'),
        endDate: yup.date().required('End Date is required').typeError('Please enter a valid End Date'),
        streamLink: yup.string().matches(REGEX.url, Validate.url.error).trim()
      })
    ),
    mode: 'onSubmit'
  });

  const [scheduleSlotCreate] = useScheduleSlotCreateMutation();
  const [scheduleSlotUpdate] = useScheduleSlotUpdateMutation();

  useEffect(() => {
    const startDate = scheduledItem?.startDate;
    const endDate = scheduledItem?.endDate;
    reset({
      startDate: moment(startDate ? Number(startDate) : null),
      endDate: moment(endDate ? Number(endDate) : null),
      streamLink: scheduledItem?.streamLink ?? ''
    });
  }, [scheduledItem]);

  const addHandle = handleSubmit(({ startDate, endDate, streamLink }) => {
    const modifiedFormData = {
      parentId: blockId,
      entityId: itemId,
      entityType: itemEntityTypeName,
      startDate: moment(startDate).valueOf().toString(),
      endDate: moment(endDate).valueOf().toString(),
      streamLink
    };

    if (scheduledItem) {
      scheduleSlotUpdate({
        variables: {
          slotId: scheduledItem?._id,
          values: {
            startDate: modifiedFormData.startDate,
            endDate: modifiedFormData.endDate,
            streamLink
          }
        },
        refetchQueries: [ScheduleSlotsGetByParentIdDocument]
      });
    } else {
      scheduleSlotCreate({
        variables: {
          data: modifiedFormData
        },
        refetchQueries: [ScheduleSlotsGetByParentIdDocument]
      });
    }
    onCancel();
  });

  const watchStartDate = watch('startDate');

  return {
    control,
    addHandle,
    watchStartDate
  };
};
