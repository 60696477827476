import { Stack, styled } from '@mui/material';

export const StackStyles = styled(Stack)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;

  ${({ theme }) => ({
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      flexWrap: 'unset'
    }
  })}}
`;
export const PaginationStyled = styled('div')`
  display: flex;
  padding: 1em;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
