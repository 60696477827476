import React from 'react';
import {
  EntityType,
  LessonsPaginated,
  MentorBlockPaginated,
  TasksPaginated,
  useLessonsGetByOwnerIdQuery
} from '../../../../graphql/generated';
import { ScheduledList } from '../../../sharedComponents/scheduledList/ScheduledList';
import { useParams } from 'react-router-dom';

export type getSelectableListHook = ({ page }: { page: number }) => {
  selectableListPaginated: LessonsPaginated | TasksPaginated | MentorBlockPaginated | null;
  loading: boolean;
  refetch: () => void;
};
interface ManageLessonsProps {
  ownerId: string;
}

export const ManageLessons: React.FC<ManageLessonsProps> = ({ ownerId }) => {
  const { blockId }: { blockId: string } = useParams();
  const useGetMyLessons = ({ page }: { page: number }) => {
    const { data, loading, refetch } = useLessonsGetByOwnerIdQuery({
      variables: { ownerId, offset: (page - 1) * 10 }
    });
    const myLessons = data?.lessonsGetByOwnerId ?? null;

    return {
      selectableListPaginated: myLessons,
      loading,
      refetch
    };
  };

  return (
    <>
      <ScheduledList
        itemEntityTypeName={EntityType.Lesson}
        parentId={blockId}
        getSelectableListHook={useGetMyLessons}
      />
    </>
  );
};
