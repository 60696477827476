import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDialogContext } from '../dialogProvider/DialogContext';
import { TransitionPromptHook } from 'history';
import { AppRoutes } from '../../helpers/constants';

interface UnsavedChangesProviderProps {
  hasUnsaved: boolean;
}

let unblock: () => void;

export const UnsavedChangesProvider: React.FC<UnsavedChangesProviderProps> = ({ children, hasUnsaved }) => {
  const [attemptedPathname, setAttemptedPathname] = useState('');
  const { block, push } = useHistory();
  const { onDialogOpen } = useDialogContext();

  const onRouteChange: TransitionPromptHook = ({ pathname }) => {
    if (!hasUnsaved || pathname === AppRoutes.Login) {
      if (unblock) {
        unblock();
      }
      return setAttemptedPathname('');
    }

    setAttemptedPathname(pathname);
    return false;
  };

  useEffect(() => {
    if (unblock) {
      unblock();
    }
    unblock = block(onRouteChange);
  }, [hasUnsaved]);

  useEffect(() => {
    !!attemptedPathname &&
      onDialogOpen({
        onSubmit: () => {
          setAttemptedPathname('');
          unblock();
          push(attemptedPathname);
        },
        title: 'You have unsaved changes.',
        showCancel: true,
        message: `Do you want to Leave? All changes will be lost!`,
        confirmButton: 'Leave',
        confirmButtonColor: 'primary'
      });
  }, [attemptedPathname]);

  return <>{children}</>;
};
