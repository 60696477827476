import React from 'react';
import { ScheduledList } from '../../../sharedComponents/scheduledList/ScheduledList';
import { EntityType, useTasksGetByOwnerIdQuery } from '../../../../graphql/generated';
import { useParams } from 'react-router-dom';

interface ManageTasksProps {
  ownerId: string;
}
export const ManageTasks: React.FC<ManageTasksProps> = ({ ownerId }) => {
  const { blockId }: { blockId: string } = useParams();

  const useGetMyTasks = ({ page }: { page: number }) => {
    const { data, loading, refetch } = useTasksGetByOwnerIdQuery({
      variables: { ownerId, offset: (page - 1) * 10 }
    });
    const myLessons = data?.tasksGetByOwnerId ?? null;

    return {
      selectableListPaginated: myLessons,
      loading,
      refetch
    };
  };

  return (
    <>
      <ScheduledList itemEntityTypeName={EntityType.Task} parentId={blockId} getSelectableListHook={useGetMyTasks} />
    </>
  );
};
