import { styled } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';

export const MarkdownPreviewStyled = styled(MDEditor.Markdown)`
  background-color: ${({ theme }) => theme.palette.background.paper} !important;
  color: ${({ theme }) => theme.palette.text.primary} !important;

  .code-line {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const MDEditorStyled = styled(MDEditor)`
  .code-line {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &.w-md-editor-fullscreen {
    top: 72px;
  }
`;
