import { Redirect, Route, Switch } from 'react-router-dom';
import { AppRoutes } from '../helpers/constants';
import AuthProvider from '../providers/AuthProvider';
import ThemeConfig from '../theme';
import Activation from './account/activation/Activation';
import EmailUpdateConfirmation from './account/emailUpdate/EmailUpdateConfirmation';
import { LoginPage } from './account/login/LoginPage';
import ResendActivationLink from './account/ResendActivationLink';
import RestorePassword from './account/restorePassword/RestorePasswordForm';
import RestorePasswordConfirm from './account/RestorePasswordConfirm';
import { SignUpPage } from './account/signup/SignUpPage';
// import CompaniesList from './company/companiesList/CompaniesList';
// import CompanyPage from './company/companyPage/CompanyPage';
import { MainLayout } from './layout/MainLayout';
import MentorBlockEdit from './mentorium/mentorBlockForm/MentorBlockEdit';
import MentorBlockList from './mentorium/mentorBlockList/MentorBlockList';
import { MentorBlockPage } from './mentorium/mentorBlockPage/MentorBlockPage';
import NotFound404 from './NotFound404';
// import ProblemForm from './problem/problemForm/ProblemForm';
// import ProblemList from './problem/problemList/ProblemList';
// import ProblemPage from './problem/problemPage/ProblemPage';
import PublicationForm from './publications/publicationForm/PublicationForm';
import PublicationPage from './publications/publicationPage/PublicationPage';
import Publications from './publications/Publications';
import UserEditForm from './user/userEditForm/UserEditForm';
import UserPage from './user/userPage/UserPage';
import UsersList from './user/usersList/UsersList';
import { TaskPage } from './task/taskPage/TaskPage';
import { LessonPage } from './lesson/lessonPage/LessonPage';
import ManageContent from './manageContent/ManageContent';
import { ContentEditPage } from './manageContent/contentEditPage/ContentEditPage';
import { DialogProvider } from '../providers/dialogProvider/DialogProvider';
import { NotificationsList } from './notification/notificationsList/NotificationsList';

function App() {
  return (
    <ThemeConfig>
      <Switch>
        <Route exact path="/">
          <Redirect to={AppRoutes.Publications} />
        </Route>
        <Route exact path={AppRoutes.Login} component={LoginPage} />
        <Route exact path={AppRoutes.Signup} component={SignUpPage} />
        <Route exact path={AppRoutes.AccountActivation} component={Activation} />
        <Route exact path={AppRoutes.ResendActivationLink} component={ResendActivationLink} />
        <Route exact path={AppRoutes.PasswordReset} component={RestorePassword} />
        <Route exact path={AppRoutes.PasswordResetConfirm} component={RestorePasswordConfirm} />
        <Route exact path={AppRoutes.EmailUpdateConfirmation} component={EmailUpdateConfirmation} />
        <AuthProvider>
          <DialogProvider>
            <MainLayout>
              <Switch>
                <Route exact path={AppRoutes.Dashboard} component={undefined} />
                <Route exact path={AppRoutes.Users} component={UsersList} />
                <Route exact path={AppRoutes.UserPage} component={UserPage} />
                <Route exact path={AppRoutes.Profile} component={UserPage} />
                <Route exact path={AppRoutes.UserEdit} component={UserEditForm} />
                <Route exact path={AppRoutes.Publications} component={Publications} />
                <Route exact path={AppRoutes.PublicationCreate} component={PublicationForm} />
                <Route exact path={AppRoutes.PublicationPage} component={PublicationPage} />
                <Route exact path={AppRoutes.PublicationEdit} component={PublicationForm} />
                {/*<Route exact path={AppRoutes.Companies} component={CompaniesList} />*/}
                {/*<Route exact path={AppRoutes.CompanyPage} component={CompanyPage} />*/}
                {/*<Route exact path={AppRoutes.Problems} component={ProblemList} />*/}
                {/*<Route exact path={AppRoutes.ProblemFormCreate} component={ProblemForm} />*/}
                {/*<Route exact path={AppRoutes.ProblemPage} component={ProblemPage} />*/}
                {/*<Route exact path={AppRoutes.ProblemFormEdit} component={ProblemForm} />*/}
                <Route exact path={AppRoutes.MentorBlockList} component={MentorBlockList} />
                <Route exact path={AppRoutes.MentorBlockCreate} component={MentorBlockEdit} />
                <Route exact path={AppRoutes.MentorBlockPage} component={MentorBlockPage} />
                <Route exact path={AppRoutes.MentorBlockEdit} component={MentorBlockEdit} />
                <Route exact path={AppRoutes.MentorBlockLesson} component={MentorBlockPage} />
                <Route exact path={AppRoutes.MentorBlockScheduledTask} component={MentorBlockPage} />
                <Route exact path={AppRoutes.TaskCreate} component={ContentEditPage} />
                <Route exact path={AppRoutes.Task} component={TaskPage} />
                <Route exact path={AppRoutes.TaskEdit} component={ContentEditPage} />
                <Route exact path={AppRoutes.LessonCreate} component={ContentEditPage} />
                <Route exact path={AppRoutes.Lesson} component={LessonPage} />
                <Route exact path={AppRoutes.LessonEdit} component={ContentEditPage} />
                <Route path={AppRoutes.ManageContent} component={ManageContent} />
                <Route path={AppRoutes.Notifications} component={NotificationsList} />
                <Route path={AppRoutes.PageNotFound} component={NotFound404} />
                <Route path="*">
                  <Redirect to={AppRoutes.PageNotFound} />
                </Route>
              </Switch>
            </MainLayout>
          </DialogProvider>
        </AuthProvider>
      </Switch>
    </ThemeConfig>
  );
}

export default App;
