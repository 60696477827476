import { Button, Pagination, Stack, styled } from '@mui/material';

export const RootStyled = styled(Stack)`
  margin: 40px auto;
  padding: 1rem;
  gap: 1rem;
`;

export const PaginationStyled = styled(Pagination)`
  align-self: center;
`;

export const CreateButtonStyled = styled(Button)`
  align-self: center;
  width: fit-content;
`;
