import LinearProgress, { linearProgressClasses, LinearProgressProps } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface CustomLinearProgressProps extends LinearProgressProps {
  percentage_passed: number;
}

interface CustomLiProps extends TypographyProps {
  is_check_passed: string;
}

export const StyledLinearProgress = styled(LinearProgress)<CustomLinearProgressProps>(
  ({ theme, percentage_passed }) => ({
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        percentage_passed <= 50
          ? theme.palette.error.light
          : percentage_passed < 100
          ? theme.palette.warning.light
          : theme.palette.success.light
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor:
        percentage_passed <= 50
          ? theme.palette.error.main
          : percentage_passed < 100
          ? theme.palette.warning.main
          : theme.palette.success.main
    },
    marginBottom: '10px'
  })
);

export const LiStyled = styled(Typography)<CustomLiProps>(({ theme, is_check_passed }) => ({
  color: is_check_passed === 'true' ? theme.palette.success.main : theme.palette.error.main
}));
