import {
  usePublicationsByUserIdQuery,
  useUserGetQuery,
  useUserStarPublicationMutation
} from '../../../../../graphql/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export const usePublicationListController = () => {
  const { userId }: { userId: string } = useParams();

  const { data: userData } = useUserGetQuery({
    variables: { userId },
    fetchPolicy: 'network-only'
  });
  const { loading, data: publicationsData } = usePublicationsByUserIdQuery({ variables: { userId } });
  const [starPublicationHandle] = useUserStarPublicationMutation();

  const pageData = useMemo(() => {
    return {
      starredPublications: userData?.user?.starredPublications ?? [],
      publications: publicationsData?.publicationsByUserId ?? []
    };
  }, [userData, publicationsData]);

  const onUnstarPublication = (pubId: string) => {
    starPublicationHandle({ variables: { userId, pubId } });
  };

  const OPTION_CONTENT = [
    {
      title: 'My Publications',
      list: pageData.publications,
      linkDirectory: 'publication',
      buttonName: '',
      onClick: () => null,
      emptyState: 'You have no publications'
    },
    {
      title: 'Starred Publications',
      list: pageData.starredPublications,
      linkDirectory: 'publication',
      buttonName: 'Unstar',
      onClick: onUnstarPublication,
      emptyState: 'You have no starred publications'
    }
  ];

  const [option, setOption] = useState<any>(OPTION_CONTENT[0]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setOption(OPTION_CONTENT.find(opt => opt.title === option.title));
  }, [publicationsData, userData]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setOption(OPTION_CONTENT[newValue]);
  };

  return {
    handleChangeTab,
    selectedTab,
    loading,
    option,
    OPTION_CONTENT,
    setOption
  };
};
