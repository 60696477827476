import { ArrowBack, Delete, Edit } from '@material-ui/icons';
import { Button, Chip, CircularProgress, Typography } from '@mui/material';
import React, { FC } from 'react';
import { UserTile } from '../../sharedComponents/userCard/UserTile';
import { HeaderRightBtnGroupStyled, HeaderStyled } from '../../user/userPage/UserPage.styles';
import { MentorLessonPage } from './components/lessonPege/MentorLessonPage';
import { LessonsRoadmap } from './components/lessonsRoadmap/LessonsRoadmap';
import { TaskList } from './components/taskList/TaskList';
import { UserListSelectable } from './components/userListSelectable/UserListSelectable';
import { useMentorBlockPageController } from './MentorBlockPage.controller';
import {
  ContentStyled,
  DescriptionStyled,
  MentorsStyled,
  PaperStyled,
  RootStyled,
  SideBarStyled,
  StackStyled,
  StarButton,
  TitleBlockStyled,
  TypographyStyled
} from './MentorBlockPage.styles';
import { StyledStar, StyledStarBorder } from '../mentorBlockCard/MentorBlockCard.styles';
import MarkdownPreview from '../../sharedComponents/markdown/MarkdownPreview';
import MentorTaskPage from './components/taskPage/MentorTaskPage';
import PermissionProvider from '../../../providers/PermissionProvider';
import { MentorBlockDeleteDocument } from '../../../graphql/generated';
import { EventsWidget } from './components/eventsWidget/EventsWidget';

export const MentorBlockPage: FC = () => {
  const {
    isModerator,
    owner,
    title,
    description,
    mentors,
    mentees,
    menteesRequests,
    scheduleLessons,
    currentWeekEvents,
    nearestFiveEvents,
    scheduleTasks,
    openedScheduledLesson,
    techStack,
    isGroup,
    isHistoryBackExist,
    isJoinAllowed,
    isJoinPending,
    isMentee,
    isMentor,
    timeRangeStatus,
    isInvitable,
    isEducationalContentVisible,
    onBackHandle,
    onEditHandle,
    onJoinRejectMeHandle,
    onLeaveMeHandle,
    onRejectRemoveMultipleHandle,
    onAcceptRequestMultipleHandle,
    onLeaveAsMentorHandle,
    openedScheduleTask,
    ownerDataLoading,
    handleStarMentorBlock,
    isMentorBlockStared,
    starMentorBlockLoading,
    deleteButtonHandle
  } = useMentorBlockPageController();

  return (
    <>
      <HeaderStyled>
        {isHistoryBackExist && (
          <Button onClick={onBackHandle}>
            <ArrowBack>Back</ArrowBack>
          </Button>
        )}
        <HeaderRightBtnGroupStyled>
          {isModerator && (
            <Button onClick={onEditHandle} startIcon={<Edit />} variant="outlined">
              Edit
            </Button>
          )}
          <PermissionProvider permission={MentorBlockDeleteDocument} ownerId={owner._id}>
            <Button
              variant="contained"
              color="error"
              children="delete"
              onClick={deleteButtonHandle}
              startIcon={<Delete />}
            />
          </PermissionProvider>

          {isJoinAllowed && !isMentee && isJoinPending && (
            <Button onClick={onJoinRejectMeHandle} variant="contained">
              Join is pending... Reject?
            </Button>
          )}
          {isJoinAllowed && !isMentee && !isJoinPending && (
            <Button onClick={onJoinRejectMeHandle} disabled={ownerDataLoading || !isInvitable} variant="contained">
              Join as a mentee
            </Button>
          )}

          {isMentee && (
            <Button onClick={onLeaveMeHandle} variant="contained">
              Leave as a mentee
            </Button>
          )}
          {isMentor && (
            <Button onClick={onLeaveAsMentorHandle} variant="contained">
              Leave as a mentor
            </Button>
          )}
        </HeaderRightBtnGroupStyled>
      </HeaderStyled>
      <RootStyled>
        <SideBarStyled>
          <MentorsStyled>
            <Typography variant="h6">Mentors:</Typography>
            <UserTile
              userId={owner._id}
              imageUrl={owner?.image || ''}
              name={owner?.firstName || ''}
              sizePxs={248}
              fontSizePxs={24}
            />
            {mentors.map(mentor => (
              <UserTile
                key={mentor?._id}
                userId={mentor?._id || ''}
                imageUrl={mentor?.image || ''}
                name={mentor?.firstName || ''}
              />
            ))}
          </MentorsStyled>
          <div>
            <StackStyled>
              <Typography variant="h6">Status: </Typography>
              <Chip size="medium" label={timeRangeStatus} />
            </StackStyled>
            <Typography variant="h6">Tech stack:</Typography>
            <StackStyled>
              {techStack.map(item => (
                <Chip size="small" label={item} key={item} />
              ))}
            </StackStyled>
          </div>
          <StackStyled>
            <Typography variant="h6">Type:</Typography>
            <span>{isGroup ? 'Group' : 'Private'}</span>
          </StackStyled>

          {isModerator && (
            <>
              <MentorsStyled>
                <Typography variant="h6">Mentees:</Typography>
                <UserListSelectable
                  rejectButtonName="Remove"
                  onRejectClick={onRejectRemoveMultipleHandle}
                  list={mentees}
                />
              </MentorsStyled>

              <MentorsStyled>
                <Typography variant="h6">Requests:</Typography>
                <UserListSelectable
                  acceptButtonName="Accept"
                  onAcceptClick={onAcceptRequestMultipleHandle}
                  rejectButtonName="Reject"
                  onRejectClick={onRejectRemoveMultipleHandle}
                  list={menteesRequests}
                />
              </MentorsStyled>
            </>
          )}
        </SideBarStyled>

        <ContentStyled>
          {isEducationalContentVisible && (
            <EventsWidget weekEvents={currentWeekEvents} stackEvents={nearestFiveEvents} />
          )}

          <PaperStyled>
            {openedScheduleTask && (
              <MentorTaskPage
                isMentor={isMentor || isModerator}
                task={openedScheduleTask.entity.__typename === 'Task' ? openedScheduleTask.entity : null}
              />
            )}

            {openedScheduledLesson && (
              <MentorLessonPage
                scheduledSlotId={openedScheduledLesson._id}
                scheduledSlotStreamLink={openedScheduledLesson.streamLink ?? ''}
                scheduledSlotStreamProvider={openedScheduledLesson.streamProvider ?? ''}
                lesson={openedScheduledLesson.entity.__typename === 'Lesson' ? openedScheduledLesson.entity : null}
              />
            )}

            {!openedScheduleTask && !openedScheduledLesson && (
              <>
                <TitleBlockStyled>
                  <TypographyStyled variant="h3">{title}</TypographyStyled>
                  <StarButton onClick={handleStarMentorBlock}>
                    {starMentorBlockLoading ? (
                      <CircularProgress size={24} />
                    ) : isMentorBlockStared ? (
                      <StyledStar />
                    ) : (
                      <StyledStarBorder />
                    )}
                  </StarButton>
                </TitleBlockStyled>
                <DescriptionStyled>
                  <MarkdownPreview source={description} />
                </DescriptionStyled>
              </>
            )}
          </PaperStyled>

          {isEducationalContentVisible && !!scheduleLessons.length && (
            <PaperStyled>
              <LessonsRoadmap scheduleSlots={scheduleLessons} />
            </PaperStyled>
          )}

          {isEducationalContentVisible && !!scheduleTasks.length && (
            <PaperStyled>
              <TaskList scheduleTasks={scheduleTasks} isMentor={isMentor || isModerator} />
            </PaperStyled>
          )}
        </ContentStyled>
      </RootStyled>
    </>
  );
};
