import React from 'react';
import {
  AvatarBlockStyled,
  AvatarGroupStyled,
  AvatarOwnerStyled,
  DescriptionMarkdownStyled,
  LinkStyled,
  PaperStyled,
  StackStyled,
  StyledStar,
  StyledStarBorder,
  TitleBlockStyled,
  TypeGroupStyled
} from './MentorBlockCard.styles';
import { useMentorBlockCardController } from './MentorBlockCard.controller';
import { Avatar, Button, Chip, CircularProgress, Typography } from '@mui/material';
import { userInitials } from '../../../helpers/simplifiedData';
import { Maybe, MentorBlockFragment } from '../../../graphql/generated';

interface MentorsCardProps {
  mentorBlock: Maybe<MentorBlockFragment>;
  starred?: Maybe<boolean>;
}

const MentorBlockCard: React.FC<MentorsCardProps> = ({ starred, mentorBlock }) => {
  const { mentorsList, owner, timeRangeStatus, updateUserMentorBlockStarred, starMentorBlockLoading } =
    useMentorBlockCardController(mentorBlock);
  return (
    <PaperStyled>
      <AvatarBlockStyled>
        <AvatarOwnerStyled src={owner?.image || ''} variant="square">
          {userInitials({ firstName: owner?.firstName, lastName: owner?.lastName })}
        </AvatarOwnerStyled>
        <AvatarGroupStyled max={4} variant="square">
          {mentorsList.map(mentor => (
            <Avatar src={mentor?.image || ''} key={mentor?._id} variant="square">
              {userInitials({ firstName: mentor?.firstName, lastName: mentor?.lastName })}
            </Avatar>
          ))}
        </AvatarGroupStyled>
      </AvatarBlockStyled>

      <TitleBlockStyled>
        <LinkStyled to={`/mentorium/${mentorBlock?._id}`}>
          <Typography noWrap={true} variant="h4">
            {mentorBlock?.title}
          </Typography>
        </LinkStyled>

        <DescriptionMarkdownStyled source={mentorBlock?.description} />

        <StackStyled>
          <div>Stack:</div>
          {mentorBlock?.stack?.map(el => (
            <Chip size="small" label={el} key={el} variant="outlined" />
          ))}
        </StackStyled>

        {/*<RatingStyled>*/}
        {/*  <Typography component="legend">Rating:</Typography>*/}
        {/*  <Rating name="rating" value={3} readOnly />*/}
        {/*</RatingStyled>*/}
      </TitleBlockStyled>
      <TypeGroupStyled>
        <Button onClick={updateUserMentorBlockStarred}>
          {starMentorBlockLoading ? <CircularProgress size={24} /> : starred ? <StyledStar /> : <StyledStarBorder />}
        </Button>

        <Chip label={timeRangeStatus} size="small" />

        <Chip label={mentorBlock?.isGroup ? 'Group' : '1x1'} size="small" />
      </TypeGroupStyled>
    </PaperStyled>
  );
};

export default MentorBlockCard;
