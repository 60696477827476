//Function generate an array of weekdays beginning from today.
//Example: today is Wednesday
//function will return ['Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday']
export function generateWeekDaysFromToday() {
  const daysOfWeek = [];
  const today = new Date();

  for (let i = 0; i < 7; i++) {
    // Clone today's date
    const day = new Date(today);
    // Set the date to today + i days
    day.setDate(today.getDate() + i);
    // Get the weekday name and add it to the array
    daysOfWeek.push(day.toLocaleDateString('en-US', { weekday: 'long' }));
  }

  return daysOfWeek;
}
