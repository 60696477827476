import { useApolloClient, useReactiveVar } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { clearGlobalVar, globalVar } from '../../cache';
import { useUserLogoutLazyQuery } from '../../graphql/generated';
import { AppRoutes, LocalStorageItems } from '../../helpers/constants';
import { getPageName } from '../../helpers/simplifiedData';
import { useDisclosure } from '../../helpers/useDisclosure';
import { useDialogContext } from '../../providers/dialogProvider/DialogContext';

export const useMainLayoutController = () => {
  const [logout] = useUserLogoutLazyQuery();
  const { profile, isLoggedIn } = useReactiveVar(globalVar);
  const { onToggle: toggleMenu, isOpen: isMenuOpen } = useDisclosure();
  const client = useApolloClient();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(getPageName(history.location.pathname));
  const { onDialogOpen } = useDialogContext();

  history.listen(location => setCurrentPage(getPageName(location.pathname)));
  const handleLogout = () => {
    onDialogOpen({
      onSubmit: () => {
        globalVar({ profile, isLoggedIn: false });
        const clearGlobalState = clearGlobalVar();
        clearGlobalState();
        client.resetStore();
        localStorage.removeItem(LocalStorageItems.Token);
        localStorage.removeItem(LocalStorageItems.UserId);
        logout();
        history.push(AppRoutes.Login);
      },
      title: 'Logout',
      showCancel: true,
      message: 'Do you want to logout? All unsaved information will be lost!',
      confirmButton: 'Logout',
      confirmButtonColor: 'primary'
    });
  };

  return {
    toggleMenu,
    isMenuOpen,
    handleLogout,
    currentPage,
    isLoggedIn,
    profile
  };
};
