import React from 'react';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import { useMentorBlocksListController } from './MentorBlocksList.controller';
import { ListItems } from '../listItems/ListItems';
import { EmptyListMessageStyled } from '../../UserPage.styles';

export const MentorBlocksList = () => {
  const { loading, option, OPTION_CONTENT, selectedTab, handleChangeTab } = useMentorBlocksListController();
  const component = () => {
    if (!option.list.length)
      return <EmptyListMessageStyled variant="body1">{option.emptyState}</EmptyListMessageStyled>;
    return <ListItems option={option} />;
  };

  return (
    <>
      <Tabs value={selectedTab} onChange={handleChangeTab}>
        {OPTION_CONTENT.map((el, index) => (
          <Tab key={index} label={el.title} disabled={!el.onClick} />
        ))}
      </Tabs>
      {loading ? <CircularProgress /> : component()}
    </>
  );
};
