import { client } from '../appoloClient';
import { UserRefreshTokenDocument } from '../graphql/generated';
import { LocalStorageItems } from '../helpers/constants';

function refreshToken() {
  return client.mutate({ mutation: UserRefreshTokenDocument }).then(response => {
    const accessToken = response.data.userRefreshToken.accessToken;
    localStorage.setItem(LocalStorageItems.Token, accessToken);
    localStorage.setItem(LocalStorageItems.UserId, response.data.userRefreshToken.user._id);

    return accessToken;
  });
}
export default refreshToken;
