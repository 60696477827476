import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { REGEX, Validate } from '../../../helpers/validation';
import { useTaskCreateMutation, useTaskGetByIdLazyQuery, useTaskUpdateMutation } from '../../../graphql/generated';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface TaskEditFormControllerProps {
  onCloseForm?: () => void;
}
export const useTaskEditFormController = ({ onCloseForm }: TaskEditFormControllerProps) => {
  const { taskId }: { taskId: string } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    resolver: yupResolver(
      yup.object({
        title: yup.string().matches(REGEX.singleInput, Validate.singleInput.error).required('Title is required').trim(),
        content: yup.string().matches(REGEX.content, Validate.content.error).required('Content is required').trim()
      })
    ),
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      content: ''
    }
  });

  const [taskCreate] = useTaskCreateMutation();
  const [taskUpdate] = useTaskUpdateMutation();
  const [taskGet] = useTaskGetByIdLazyQuery({ variables: { taskId } });

  useEffect(() => {
    if (taskId) {
      taskGet().then(({ data }) => {
        reset({
          title: data?.taskGet?.title ?? '',
          content: data?.taskGet?.content ?? ''
        });
      });
    }
  }, [taskId]);

  const submitTaskHandle = handleSubmit(async ({ title, content }) => {
    if (taskId) {
      await taskUpdate({
        variables: {
          taskId,
          values: { title, content }
        }
      });
    } else {
      await taskCreate({
        variables: { data: { title, content } }
      });
    }
    onCloseForm && onCloseForm();
  });

  const cancelHandle = () => {
    onCloseForm && onCloseForm();
  };

  return {
    control,
    formErrors: errors,
    submitTaskHandle,
    cancelHandle
  };
};
