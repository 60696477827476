import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Maybe, ScheduleSlot } from '../../../../../graphql/generated';
import moment from 'moment/moment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { CardActionAreaStyled, IconDoneStyled, PaperStyled, TypographyStyled } from './TaskList.styles';
interface TaskListProps {
  scheduleTasks: Array<Maybe<ScheduleSlot>>;
  isMentor: boolean;
}
export const TaskList: React.FC<TaskListProps> = ({ scheduleTasks, isMentor }) => {
  const dateNow = Date.now();
  const history = useHistory();
  const { blockId } = useParams<{ blockId: string }>();
  const isStarted = (startDate: Maybe<string> | undefined) => {
    return moment(Number(startDate)).diff(dateNow, 'milliseconds') <= 0;
  };

  const isFinished = (endDate: Maybe<string> | undefined) => {
    return moment(Number(endDate)).diff(dateNow, 'milliseconds') <= 0;
  };

  const onTaskOpenHandle = (taskSlot?: string) => {
    history.push(`/mentorium/${blockId}/scheduledTask/${taskSlot}`);
  };

  return (
    <Stack gap={1}>
      <Typography variant="h5">Home Work</Typography>
      <Stack gap={1} flexDirection="row" flexWrap="wrap">
        {scheduleTasks.map(taskSlot => {
          return (
            (isStarted(taskSlot?.startDate) || isMentor) && (
              <PaperStyled key={taskSlot?._id} onClick={() => onTaskOpenHandle(taskSlot?._id)}>
                <CardActionAreaStyled title={taskSlot?.entity.title || ''}>
                  {isFinished(taskSlot?.endDate) && <IconDoneStyled />}
                  <TypographyStyled variant="subtitle2">{taskSlot?.entity.title}</TypographyStyled>
                </CardActionAreaStyled>
              </PaperStyled>
            )
          );
        })}
      </Stack>
    </Stack>
  );
};
