import onErrorMiddleware from './midlewares/onErrorMiddleware';
import authMiddleware from './midlewares/authMiddleware';
import { cache } from './cache';
import { ApolloClient, createHttpLink, from, NormalizedCacheObject } from '@apollo/client';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include'
});

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([authMiddleware, onErrorMiddleware, httpLink]),
  connectToDevTools: true,
  cache,
  queryDeduplication: false,
  defaultOptions: {
    mutate: { errorPolicy: 'all' }
  }
});
