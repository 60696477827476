import React from 'react';
import { Button, Tab, Tabs } from '@mui/material';
import LessonsList from './lessonsList/LessonsList';
import TasksList from './tasksList/TasksList';
import { Add } from '@material-ui/icons';
import { ActionButtons, RootStyled } from './ManageContent.styles';
import { useManageContentController } from './ManageContent.controller';

const ManageContent = () => {
  const { TABS, activeTab, tabChangeHandle, isTabLessons, isTabTasks, createLessonHandle } =
    useManageContentController();

  return (
    <RootStyled>
      <Tabs value={activeTab || 'lessons'} onChange={tabChangeHandle}>
        {TABS.map(tab => (
          <Tab key={tab} label={tab} value={tab} />
        ))}
      </Tabs>
      <ActionButtons>
        <Button startIcon={<Add />} variant="outlined" onClick={createLessonHandle}>
          {isTabLessons ? 'lesson' : 'task'}
        </Button>
      </ActionButtons>
      {isTabLessons && <LessonsList />}
      {isTabTasks && <TasksList />}
    </RootStyled>
  );
};

export default ManageContent;
