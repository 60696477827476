import { InputBase, Paper, Stack, styled } from '@mui/material';

export const SearchRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid',
  backgroundColor: theme.palette.background.paper,
  marginLeft: 'auto',
  color: theme.palette.primary.main
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const InputBaseStyled = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100px',
      '&:focus': {
        width: '300px'
      }
    }
  }
}));

export const Autocomplete = styled(Paper)(({ theme }) => ({
  zIndex: 1,
  position: 'absolute',
  padding: '0.5rem',
  marginTop: '0.25rem',
  right: 0,
  width: '436px',

  [theme.breakpoints.down('sm')]: {
    width: '96vw',
    overflow: 'auto',
    height: '90vh',
    margin: '0.25rem auto',
    right: 0,
    left: 0,
    position: 'fixed'
  }
}));

export const BlockStackStyled = styled(Stack)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;
export const BlockStyled = styled(Stack)(({ theme }) => ({
  gap: '0.5rem',
  borderRadius: theme.shape.borderRadius,
  width: '200px',
  maxHeight: '200px',
  border: `1px ${theme.palette.primary.main} solid`,
  padding: '10px',

  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const RowStackStyled = styled(Stack)`
  flex-direction: column;
  gap: 2px;
`;

export const RowStyled = styled(Stack)`
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  gap: 4px;
  overflow: hidden;
`;

export const LinkedButtonStyled = styled('div')`
  color: ${({ theme }) => theme.palette.primary.main};

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const SeeAllLink = styled(LinkedButtonStyled)`
  font-weight: bold;
`;

export const EmptyState = styled('div')`
  margin: auto;
`;
