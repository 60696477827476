import { IconButton, styled } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
export const CloseButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const DialogTitleStyled = styled(DialogTitle)<{ showClose?: boolean }>`
  min-height: 4rem;
  padding-right: ${({ showClose }) => (showClose ? '4rem' : '1.5rem')};
`;
