// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 48
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary
        },
        containedError: {
          backgroundColor: theme.palette.error.dark,
          '&:hover': {
            backgroundColor: theme.palette.error.darker
          }
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        outlinedPrimary: {
          '&:focus': {
            outline: theme.palette.action.focus
          }
        },
        outlinedError: {
          border: `1px solid ${theme.palette.error.dark}`,
          color: theme.palette.error.dark,
          '&:hover': {
            border: `1px solid ${theme.palette.error.darker}`,
            color: theme.palette.error.darker
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        textError: {
          color: theme.palette.error.dark,
          '&:hover': {
            color: theme.palette.error.darker
          }
        }
      }
    }
  };
}
