import { Accordion, Stack, styled } from '@mui/material';
import MarkdownPreview from '../../../../sharedComponents/markdown/MarkdownPreview';

export const RootStyled = styled(Stack)(() => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '0.5rem 1rem',
  alignItems: 'center',
  width: '100%',
  gap: '1rem'
}));

export const AccordionStyled = styled(Accordion)`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
`;

export const DateAndStatusBoxStyled = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: '2rem',
  marginLeft: 'auto'
}));

export const ButtonsStackStyled = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '1rem'
}));

export const ContentRootStyled = styled(Stack)(() => ({
  gap: '1rem'
}));

export const MarkdownStyled = styled(MarkdownPreview)(() => ({
  display: 'grid'
}));
