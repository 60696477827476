import { mapKeys, round } from 'lodash';
import { IPasswordValidaitonRenderProps } from '../app/account/passwordValidation/PasswordValidationRender';

export const REGEX = {
  email:
    /^(?!.{255})(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,255}$/,
  mongoHexId: /^(?=[a-f\d]{24}$)/i,
  content: /^.{1,120000}$/s, //Comments, private messages, chats etc.
  singleInput: /^.{0,255}$/, //Titles, short descriptions, email, password, links, etc:
  userSingleName: /^.{0,35}$/, //First or Last name
  message: /^.{0,5000}$/s, //Comments, private messages, chats etc.
  // eslint-disable-next-line no-useless-escape
  url: /^$|^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?(\?[\w&=.-]*)?(#[\w-]*)?$/
};

export const Validate = {
  email: {
    check: (value: string) => REGEX.email.test(value),
    error: 'Email validation error'
  },

  url: {
    check: (value: string) => REGEX.url.test(value),
    error: 'Invalid URL format'
  },

  password: {
    check: (value: string) => REGEX.password.test(value),
    error: 'Password validation error'
  },

  mongoId: {
    check: (id: string) => REGEX.mongoHexId.test(id),
    error: 'Id is not a valid mongo ObjectId'
  },

  content: {
    check: (value: string) => REGEX.content.test(value),
    error: 'Max length is 120000 characters'
  },

  singleInput: {
    check: (value: string) => REGEX.singleInput.test(value),
    error: 'Max length is 255 characters'
  },

  userSingleName: {
    check: (value: string) => REGEX.userSingleName.test(value),
    error: 'Max length is 35 characters'
  },

  message: {
    check: (value: string) => REGEX.message.test(value),
    error: 'Max length is 5000 characters'
  },

  defaultError: 'Validation error'
};

export const PasswordChecks = {
  length: {
    regex: /^.{6,}$/,
    message: 'At least 6 characters'
  },
  uppercase: {
    regex: /^(?=.*[A-Z]).*$/,
    message: 'At least one uppercase letter (A-Z)'
  },
  lowercase: {
    regex: /^(?=.*[a-z]).*$/,
    message: 'At least one lowercase letter (a-z)'
  },
  number: {
    regex: /^(?=.*[0-9]).*$/,
    message: 'At least one number (0-9)'
  },
  specialChar: {
    regex: /^(?=.*[^a-zA-Z0-9]).*$/,
    message: 'At least one special character (e.g., !@#$%^&*)'
  },
  maxLength: {
    regex: /^.{0,255}$/, // Checks for a maximum of 255 characters
    message: 'Max length of 255 characters'
  }
};

export const getPasswordValidatedCrList = (password: string): IPasswordValidaitonRenderProps => {
  let numberOfChecks = 0;
  const listOfChecks: IValidationCheck[] = [];
  let totalChecksPassed = 0;

  mapKeys(PasswordChecks, (value, key) => {
    numberOfChecks++;
    const isCheckPassed = value.regex.test(password);
    if (isCheckPassed) {
      totalChecksPassed++;
    }
    listOfChecks.push({
      text: value.message,
      isCheckPassed
    });
  });
  const percentageOfChecksPassed = round((totalChecksPassed / numberOfChecks) * 100, 2);

  return { listOfChecks, percentageOfChecksPassed };
};

export interface IValidationCheck {
  text: string;
  isCheckPassed: boolean;
}
