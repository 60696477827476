import { useState } from 'react';
import { useUserRegistrationMutation } from '../../../graphql/generated';
import { getPasswordValidatedCrList, Validate } from '../../../helpers/validation';

export const useSignUpFormController = () => {
  const [registration, { loading, error, data }] = useUserRegistrationMutation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  type FormValidationErrorType = {
    email: string;
    password: string | JSX.Element;
  };
  const [formValidationError, setFormValidationError] = useState<FormValidationErrorType>({
    email: '',
    password: ''
  });

  const [formState, setFormState] = useState({
    email: '',
    password: ''
  });

  const onFinish = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (Validate.email.check(formState.email) && Validate.password.check(formState.password)) {
      registration({
        variables: {
          ...formState
        }
      });
    } else if (!Validate.email.check(formState.email)) {
      setFormValidationError({ ...formValidationError, email: Validate.email.error });
    } else {
      setFormValidationError({ ...formValidationError, password: Validate.password.error });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { listOfChecks, percentageOfChecksPassed: strength } = getPasswordValidatedCrList(formState.password);

  return {
    data,
    onFinish,
    formValidationError,
    setFormValidationError,
    setFormState,
    formState,
    showPassword,
    handleClickShowPassword,
    loading,
    error,
    listOfChecks,
    strength
  };
};
