import { styled } from '@mui/system';
import { CardActionArea, Paper, Typography } from '@mui/material';
import { EventAvailable } from '@material-ui/icons';

export const PaperStyled = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.background.default};
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;
export const CardActionAreaStyled = styled(CardActionArea)`
  padding: 1rem;
  height: 100%;
`;
export const TypographyStyled = styled(Typography)`
  width: 200px;
  max-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const IconDoneStyled = styled(EventAvailable)`
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 50%;
  font-size: 24px;
  padding: 4px;
  z-index: 1;
`;
