import { InMemoryCache, makeVar } from '@apollo/client';
import { User } from './graphql/generated';
import { LocalStorageItems } from './helpers/constants';

interface GlobalVar {
  isLoggedIn: boolean;
  profile: User;
}

export const globalVar = makeVar<GlobalVar>({
  isLoggedIn: !!localStorage.getItem(LocalStorageItems.Token) && !!localStorage.getItem(LocalStorageItems.UserId),
  profile: {
    _id: '',
    roles: ['user'],
    level: 1
  }
});

export const clearGlobalVar = makeVar(() => ({}));

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        globalVar: {
          read() {
            return globalVar();
          }
        },
        publications: {
          keyArgs: ['view', 'first'],
          merge: (existing = { __typename: 'PublicationPaginated', list: [] }, incoming) => {
            return {
              ...incoming,
              __typename: 'PublicationPaginated',
              list: [...existing.list, ...incoming.list]
            };
          }
        }
      }
    }
  }
});
