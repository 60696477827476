import React from 'react';
import Modal, { ModalProps } from '../../../sharedComponents/modal/Modal';
import { useAddScheduledItemDialogController } from './AddScheduledItemDialog.controller';
import { Tab, Tabs } from '@mui/material';
import { EntityType, ScheduleSlotsPaginated } from '../../../../graphql/generated';
import { LessonEditForm } from '../../../lesson/lessonEditForm/LessonEditForm';
import { RootStyled } from './AddScheduledItemDialog.styles';
import { EditScheduledItemForm } from '../editScheduledItemForm/EditScheduledItemForm';
import { EntityList } from '../entityList/EntityList';
import MentorBlockEdit from '../../../mentorium/mentorBlockForm/MentorBlockEdit';
import { TaskEditForm } from '../../../task/taskEditForm/TaskEditForm';
import { getSelectableListHook } from '../../../mentorium/mentorBlockForm/manageLessons/ManageLessons';

interface AddScheduledItemDialogProps extends ModalProps {
  scheduledSlotsPaginated: ScheduleSlotsPaginated;
  itemEntityTypeName: EntityType;
  getSelectableListHook: getSelectableListHook;
}

export const AddScheduledItemDialog: React.FC<AddScheduledItemDialogProps> = ({
  isOpen,
  onClose,
  scheduledSlotsPaginated,
  getSelectableListHook,
  itemEntityTypeName
}) => {
  const {
    loading,
    selectableListPaginated,
    backToSelectableListHandle,
    tabIndex,
    setTabIndex,
    tabChangeHandle,
    onCloseForm,
    selectedItemId,
    onSelectItemHandle,
    page,
    onPageChange
  } = useAddScheduledItemDialogController({ getSelectableListHook });

  const ENTITY_EDIT_FORM = {
    Lesson: <LessonEditForm onCloseForm={onCloseForm} />,
    MentorBlock: <MentorBlockEdit />,
    Task: <TaskEditForm onCloseForm={onCloseForm} />
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        backToSelectableListHandle();
      }}
      title={`Add ${itemEntityTypeName}`}
      showClose
    >
      <RootStyled>
        {!selectedItemId && (
          <>
            <Tabs value={tabIndex} onChange={tabChangeHandle}>
              <Tab label="Select from existing" />
              <Tab label={`Create a new ${itemEntityTypeName}`} />
            </Tabs>
            {tabIndex === 0 && (
              <EntityList
                listPaginated={selectableListPaginated}
                scheduledListPaginated={scheduledSlotsPaginated}
                entityType={itemEntityTypeName}
                onAddToScheduleHandle={onSelectItemHandle}
                onCreateNewItemHandle={() => setTabIndex(1)}
                page={page}
                onPageChange={onPageChange}
                loading={loading}
              />
            )}
            {tabIndex === 1 && ENTITY_EDIT_FORM[itemEntityTypeName]}
          </>
        )}
        {selectedItemId && (
          <EditScheduledItemForm
            itemEntityTypeName={itemEntityTypeName}
            itemId={selectedItemId}
            onCancel={backToSelectableListHandle}
          />
        )}
      </RootStyled>
    </Modal>
  );
};
