import { useProfile, useQueryParams } from '../../../helpers/customHooks';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import {
  TaskAnswersListGetDocument,
  useTaskAnswerCreateMutation,
  useTaskAnswersListGetQuery
} from '../../../graphql/generated';
import { Paginate } from '../../../helpers/pagination';
import { useParams } from 'react-router';

export const useTaskAnswersListViewController = () => {
  const profile = useProfile();
  const history = useHistory();
  const { blockId, scheduledTaskId: parentId } = useParams<{ blockId: string; scheduledTaskId: string }>();
  const { p, ofs, search } = useQueryParams(history.location.search);
  const limit = 5;
  const offset = ofs ? Number(ofs) : 0;
  const page = p ? Number(p) : 1;

  const [isFormShow, setIsFormShow] = useState(false);
  const [taskAnswerCreate, { loading: loadingCreate }] = useTaskAnswerCreateMutation();
  const { data, loading } = useTaskAnswersListGetQuery({ variables: { blockId, parentId, offset, limit } });

  const taskAnswersList = data?.taskAnswersListGet?.list ?? [];
  const totalPages = data?.taskAnswersListGet?.totalPages ?? 0;
  const totalCount = data?.taskAnswersListGet?.totalCount ?? 0;
  const isAnswered = taskAnswersList.some(answer => answer?.owner?._id === profile._id);
  const isPaginationDisplayed = taskAnswersList.length < totalCount;

  const onPageChangeHandle = (e: React.ChangeEvent<unknown>, page: number) => {
    Paginate.onChangePage({ page, limit, history, searchValue: search });
  };
  const startAnswerHandle = () => {
    setIsFormShow(prevState => !prevState);
  };

  const submitAnswerHandle = (content: string) => {
    setIsFormShow(false);
    taskAnswerCreate({
      variables: { data: { parentId, content } },
      refetchQueries: [TaskAnswersListGetDocument],
      awaitRefetchQueries: true
    });
  };

  const TEXT = {
    answersListLabel: 'Answers:',
    answersListEmptyStateMessage: 'There is no Answers yet',
    buttonCreateAnAnswer: 'Write an answer'
  };

  return {
    submitAnswerHandle,
    onPageChangeHandle,
    startAnswerHandle,
    isPaginationDisplayed,
    isFormShow,
    isAnswered,
    taskAnswersList,
    totalCount,
    totalPages,
    loading: loading || loadingCreate,
    page,
    TEXT
  };
};
