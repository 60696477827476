import { useProfile, useQueryParams } from '../../../helpers/customHooks';
import { useHistory } from 'react-router-dom';
import { useLessonsGetByOwnerIdQuery } from '../../../graphql/generated';
import React from 'react';
import { Paginate } from '../../../helpers/pagination';

export const useLessonsListController = () => {
  const { _id: profileId } = useProfile();
  const limit = 10;
  const history = useHistory();
  const { p, ofs } = useQueryParams(history.location.search);
  const offset = ofs ? Number(ofs) : 0;
  const page = p ? Number(p) : 1;

  const { data, loading } = useLessonsGetByOwnerIdQuery({
    variables: {
      ownerId: profileId,
      offset,
      limit
    },
    fetchPolicy: 'cache-and-network'
  });
  const lessons = data?.lessonsGetByOwnerId?.list ?? [];
  const totalPages = data?.lessonsGetByOwnerId?.totalPages ?? 0;
  const onPageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    Paginate.onChangePage({ page, limit, history });
  };

  return {
    page,
    lessons,
    loading,
    totalPages,
    onPageChange
  };
};
