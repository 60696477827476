import { NotificationListItem } from '../notificationListItem/NotificationListItem';
import { ListStyled, PaginationStyled, RootStyled } from './NotificationsList.styles';
import React from 'react';
import { useNotificationsListController } from './NotificationsList.controller';
import { ListEmptyState } from '../../sharedComponents/emptyStates/listEmptySate/ListEmptyState';
import { NotificationsOutlined } from '@material-ui/icons';
import LoaderCentered from '../../sharedComponents/loaderCentered/LoaderCentered';

export const NotificationsList = () => {
  const { data, list, isListEmpty, isMobile, totalPages, page, onPageChange, emptyListMessage } =
    useNotificationsListController();

  if (!data) return <LoaderCentered />;

  return (
    <RootStyled>
      <ListStyled>
        {list.map(notification => (
          <NotificationListItem key={notification?._id} notification={notification} />
        ))}
        {isListEmpty && (
          <ListEmptyState
            icon={<NotificationsOutlined />}
            message={emptyListMessage.top}
            subMessage={emptyListMessage.sub}
          />
        )}
      </ListStyled>
      {!isListEmpty && (
        <PaginationStyled
          siblingCount={Number(!isMobile)}
          count={totalPages}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={onPageChange}
        />
      )}
    </RootStyled>
  );
};
