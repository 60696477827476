import React from 'react';
import {
  bold,
  code,
  codeBlock,
  divider,
  hr,
  image,
  italic,
  link,
  orderedListCommand,
  quote,
  strikethrough,
  title,
  unorderedListCommand
} from '@uiw/react-md-editor';
import { Button, CircularProgress, InputLabel, TextField } from '@mui/material';
import { Save } from '@material-ui/icons';
import { usePublicationFormController } from './PublicationForm.controller';
import {
  AlertStyled,
  ContentErrorStyled,
  FieldsStackStyled,
  FooterStackStyled,
  LoaderStyled,
  PaperStyled,
  RootStyled
} from './PublicationForm.styles';
import PermissionProvider from '../../../providers/PermissionProvider';
import { Controller } from 'react-hook-form';
import { UnsavedChangesProvider } from 'src/providers/unsavedChangesProvider/UnsavedChangesProvider';
import MarkdownEditor from '../../sharedComponents/markdown/MarkdownEditor';

const PublicationForm: React.FC = () => {
  const {
    onSubmitFormHandle,
    loading,
    error,
    control,
    formErrors,
    register,
    isDirty,
    backButton,
    permission,
    ownerId
  } = usePublicationFormController();

  if (loading) {
    return <LoaderStyled children={<CircularProgress />} />;
  }

  return (
    <PermissionProvider permission={permission} ownerId={ownerId}>
      <RootStyled>
        <PaperStyled>
          <UnsavedChangesProvider hasUnsaved={isDirty} />

          <AlertStyled severity="error" hidden={!error} children={error} />

          <form
            action="src/app/publications/publicationForm/PublicationForm.tsx"
            onSubmit={e => {
              e.preventDefault();
              onSubmitFormHandle();
            }}
          >
            <FieldsStackStyled>
              <TextField
                id="title"
                size="small"
                label="Title"
                fullWidth={true}
                autoFocus={true}
                error={!!formErrors.title}
                helperText={formErrors.title?.message}
                {...register('title')}
              />
              <TextField
                id="image"
                size="small"
                label="Image link"
                fullWidth={true}
                error={!!formErrors.image}
                helperText={formErrors.image?.message}
                {...register('image')}
              />

              <TextField
                id="description"
                size="small"
                label="Description"
                fullWidth={true}
                error={!!formErrors.description}
                helperText={formErrors.description?.message}
                {...register('description')}
              />
            </FieldsStackStyled>

            <InputLabel children={'Content'} error={!!formErrors.content} />

            <Controller
              name="content"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MarkdownEditor
                  commands={[
                    title,
                    bold,
                    italic,
                    strikethrough,
                    divider,
                    link,
                    image,
                    divider,
                    code,
                    codeBlock,
                    quote,
                    hr,
                    divider,
                    orderedListCommand,
                    unorderedListCommand
                  ]}
                  preview="edit"
                  height={500}
                  value={value || ''}
                  onChange={(values: string) => onChange(values)}
                />
              )}
            />

            {formErrors.content && <ContentErrorStyled>Content is required</ContentErrorStyled>}

            <FooterStackStyled>
              <Button variant="outlined" onClick={backButton} disabled={loading} children="Cancel" />

              <Button
                disabled={loading}
                startIcon={loading ? <CircularProgress size={18} /> : <Save />}
                variant="outlined"
                type="submit"
                children="Save"
              />
            </FooterStackStyled>
          </form>
        </PaperStyled>
      </RootStyled>
    </PermissionProvider>
  );
};

export default PublicationForm;
