const breakpoints = {
  values: {
    xs: 480,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1536
  }
};

export default breakpoints;
