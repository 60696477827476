import React from 'react';
import { LessonView } from '../../../../lesson/lessonView/LessonView';
import { LessonFragment, Maybe } from '../../../../../graphql/generated';
import { Button, Typography } from '@mui/material';
import { Cast } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import CommentList from '../../../../comment/commentList/CommentList';
import { CommentsContainer, RootStyled, TopButtonsContainer } from './MentorLessonPage.styles';

interface LessonViewProps {
  lesson: Maybe<LessonFragment>;
  scheduledSlotId: string;
  scheduledSlotStreamLink?: string;
  scheduledSlotStreamProvider?: string;
}
export const MentorLessonPage: React.FC<LessonViewProps> = ({
  lesson,
  scheduledSlotId,
  scheduledSlotStreamLink,
  scheduledSlotStreamProvider
}) => {
  const { go } = useHistory();

  return (
    <RootStyled>
      <TopButtonsContainer>
        <Button onClick={() => go(-1)}>Back to main page</Button>
        {scheduledSlotStreamLink && (
          <Button size="large" variant="contained" href={scheduledSlotStreamLink} target="_blank" startIcon={<Cast />}>
            {scheduledSlotStreamProvider}
          </Button>
        )}
      </TopButtonsContainer>

      <LessonView lesson={lesson} />
      <CommentsContainer>
        <Typography variant="h6">Discussion</Typography>
        <CommentList parentId={scheduledSlotId} show />
      </CommentsContainer>
    </RootStyled>
  );
};
