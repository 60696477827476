import { ListItem, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const NotificationListItemStyled = styled(ListItem)<{ isRead: boolean }>`
  gap: 0.5rem;
  padding: 1rem;
  background-color: ${({ theme, isRead }) => !isRead && theme.palette.secondary.lighter};

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.lighter};
  }
`;

export const LinkStyled = styled(Link)`
  width: inherit;
  text-decoration: unset;
  color: inherit;

  :hover {
    text-decoration: unset;
    color: inherit;
  }
`;

export const ActionsMenuContainer = styled('div')`
  margin-left: auto;
`;
