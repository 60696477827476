import React from 'react';
import Avatar from '@mui/material/Avatar';
import ActionsFooter from '../../sharedComponents/actionsFooter/ActionsFooter';
import { Button, CircularProgress, Paper, Typography } from '@mui/material';
import Image from '../../sharedComponents/image/Image';
import { Maybe, PublicationFragment } from '../../../graphql/generated';
import { usePublicationCardController } from './PublicationCard.controller';
import {
  StyledCardHeader,
  StyledCardHeaderLeft,
  StyledFooterWrapper,
  StyledHeaderWrapper,
  StyledLink,
  StyledStar,
  StyledStarBorder,
  StyledUserProfileLinkWrapper,
  StyledTypography
} from './PublicationCard.styles';

interface IProps {
  publication: Maybe<PublicationFragment>;
  starred: boolean;
}

export const PublicationCard: React.FC<IProps> = ({ starred, publication }) => {
  if (!publication) return null;
  const { description, image, title, likes, owner, commentsCount } = publication;
  const {
    onLikeHandler,
    updateUserPublicationStarred,
    starPublicationLoading,
    publicationLikeLoading,
    ownerFullName,
    pubId,
    formattedDate
  } = usePublicationCardController(publication);

  return (
    <Paper className="mb-4">
      <StyledHeaderWrapper>
        <StyledCardHeader>
          <StyledCardHeaderLeft>
            <Avatar src={owner?.image || ''} />
            <StyledUserProfileLinkWrapper>
              {owner?._id ? (
                <StyledLink to={`/user/${owner?._id}`}>{ownerFullName}</StyledLink>
              ) : (
                <Typography variant="subtitle1">Deleted User</Typography>
              )}
              <small>{formattedDate}</small>
            </StyledUserProfileLinkWrapper>
          </StyledCardHeaderLeft>
          <Button onClick={updateUserPublicationStarred}>
            {starPublicationLoading ? <CircularProgress size={24} /> : starred ? <StyledStar /> : <StyledStarBorder />}
          </Button>
        </StyledCardHeader>
        <StyledLink to={`/publication/${pubId}`}>
          <StyledTypography fontSize={'1.5rem'}>{title}</StyledTypography>
        </StyledLink>
      </StyledHeaderWrapper>

      <StyledLink to={`/publication/${pubId}`}>
        <Image src={image || ''} />
      </StyledLink>

      <StyledFooterWrapper>
        <StyledTypography variant="body1">{description}</StyledTypography>
        <ActionsFooter
          parentId={pubId}
          likes={likes}
          onLike={onLikeHandler}
          loading={publicationLikeLoading}
          commentsCount={commentsCount}
        />
      </StyledFooterWrapper>
    </Paper>
  );
};
