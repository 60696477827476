import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { REGEX, Validate } from '../../../helpers/validation';
import {
  useLessonCreateMutation,
  useLessonGetByIdLazyQuery,
  useLessonUpdateMutation
} from '../../../graphql/generated';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

interface LessonEditFormControllerProps {
  onCloseForm?: () => void;
}
export const useLessonEditFormController = ({ onCloseForm }: LessonEditFormControllerProps) => {
  const { lessonId }: { lessonId: string } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    resolver: yupResolver(
      yup.object({
        title: yup.string().matches(REGEX.singleInput, Validate.singleInput.error).required('Title is required').trim(),
        content: yup.string().matches(REGEX.content, Validate.content.error).required('Content is required').trim()
      })
    ),
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      content: ''
    }
  });

  const [lessonCreate] = useLessonCreateMutation();
  const [lessonUpdate] = useLessonUpdateMutation();
  const [lessonGet] = useLessonGetByIdLazyQuery();

  useEffect(() => {
    if (lessonId) {
      lessonGet({ variables: { lessonId } }).then(({ data }) => {
        reset({
          title: data?.lesson?.title ?? '',
          content: data?.lesson?.content ?? ''
        });
      });
    }
  }, [lessonId]);

  const createLessonHandle = handleSubmit(async ({ title, content }) => {
    if (lessonId) {
      await lessonUpdate({
        variables: {
          lessonId,
          values: { title, content }
        }
      });
    } else {
      await lessonCreate({
        variables: { data: { title, content } }
      });
    }
    onCloseForm && onCloseForm();
  });

  const cancelHandle = () => {
    onCloseForm && onCloseForm();
  };

  return {
    control,
    formErrors: errors,
    createLessonHandle,
    cancelHandle
  };
};
