import { Stack, styled } from '@mui/material';

export const StackInput = styled(Stack)`
  flex-direction: row;
  gap: 1rem;
`;

export const DateInputsRowStyled = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
`;
