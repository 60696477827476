import { useLessonDeleteMutation, useLessonGetByIdQuery } from '../../../graphql/generated';
import { useHistory, useParams } from 'react-router';
import { AppRoutes } from '../../../helpers/constants';
import { useDialogContext } from '../../../providers/dialogProvider/DialogContext';

export const useLessonPageController = () => {
  const { go, push, replace } = useHistory();
  const { lessonId } = useParams<{ lessonId: string }>();
  const { data } = useLessonGetByIdQuery({ variables: { lessonId } });
  const [lessonDelete] = useLessonDeleteMutation({ variables: { lessonId } });
  const lesson = data?.lesson ?? null;
  const { onDialogOpen } = useDialogContext();

  const handleBack = () => {
    go(-1);
  };

  const handleDelete = () => {
    onDialogOpen({
      onSubmit: () => {
        lessonDelete({
          onCompleted: () => {
            push(`${AppRoutes.ManageContent}?tab=lessons`);
          }
        });
      },
      title: `Delete Lesson: ${lesson?.title}`,
      showCancel: true,
      message: `Lesson: ${lesson?.title} will be permanently deleted. Are you sure?`,
      confirmButton: 'Delete',
      confirmButtonColor: 'error'
    });
  };

  const handleEdit = () => {
    replace(AppRoutes.LessonEdit.replace(':lessonId', lessonId));
  };

  return {
    lesson,
    handleBack,
    handleDelete,
    handleEdit
  };
};
