import React from 'react';
import { Controller } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import {
  bold,
  code,
  codeBlock,
  divider,
  hr,
  image,
  italic,
  link,
  orderedListCommand,
  quote,
  strikethrough,
  title,
  unorderedListCommand
} from '@uiw/react-md-editor';
import { ContentErrorStyled, FooterStyled, RootStyled } from './TaskEditForm.styles';
import { useTaskEditFormController } from './TaskEditForm.controller';
import MarkdownEditor from '../../sharedComponents/markdown/MarkdownEditor';

interface TaskEditFormProps {
  onCloseForm?: () => void;
}
export const TaskEditForm: React.FC<TaskEditFormProps> = ({ onCloseForm }) => {
  const { control, formErrors, submitTaskHandle, cancelHandle } = useTaskEditFormController({ onCloseForm });

  return (
    <RootStyled>
      <Controller
        control={control}
        name="title"
        render={({ field: { onChange, value } }) => (
          <TextField
            label="Title"
            size="small"
            id="title"
            fullWidth={true}
            autoFocus={true}
            value={value}
            onChange={values => onChange(values)}
            error={!!formErrors.title}
            helperText={formErrors.title?.message}
          />
        )}
      />
      {formErrors.title && 'This is required.'}

      <Controller
        name="content"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <MarkdownEditor
              commands={[
                title,
                bold,
                italic,
                strikethrough,
                divider,
                link,
                image,
                divider,
                code,
                codeBlock,
                quote,
                hr,
                divider,
                orderedListCommand,
                unorderedListCommand
              ]}
              preview="edit"
              height={300}
              value={value || ''}
              onChange={(values: string) => onChange(values)}
            />
          );
        }}
      />
      {formErrors.content && <ContentErrorStyled>Content is required</ContentErrorStyled>}

      <FooterStyled>
        <Button onClick={cancelHandle} variant="outlined">
          Cancel
        </Button>
        <Button onClick={submitTaskHandle} variant="outlined">
          Save
        </Button>
      </FooterStyled>
    </RootStyled>
  );
};
