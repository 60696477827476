import { FormHelperText, Stack, styled } from '@mui/material';

export const RootStyled = styled(Stack)`
  gap: 1rem;
`;

export const ContentErrorStyled = styled(FormHelperText)`
  color: ${props => props.theme.palette.error.main};
  margin-top: 4px;
  margin-left: 14px;
`;
export const FooterStyled = styled(Stack)`
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
`;
