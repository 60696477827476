import React from 'react';
import { CircularProgress } from '@mui/material';
import { RootContainer } from './LoaderCentered.styles';

const LoaderCentered: React.FC = () => {
  return (
    <RootContainer>
      <CircularProgress />
    </RootContainer>
  );
};

export default LoaderCentered;
