import React from 'react';
import { Button, IconButton, Pagination, Tooltip, Typography } from '@mui/material';
import { Add, Done } from '@material-ui/icons';
import { ListEmptyState } from '../../emptyStates/listEmptySate/ListEmptyState';
import {
  EntityType,
  LessonsPaginated,
  MentorBlockPaginated,
  ScheduleSlotsPaginated,
  TasksPaginated
} from '../../../../graphql/generated';
import { RowStyled } from './EntityList.styles';
import LoaderCentered from '../../loaderCentered/LoaderCentered';

interface SelectableListTypes {
  listPaginated: LessonsPaginated | TasksPaginated | MentorBlockPaginated | null;
  scheduledListPaginated: ScheduleSlotsPaginated;
  entityType: EntityType;
  onAddToScheduleHandle: (itemId?: string) => void;
  onCreateNewItemHandle: () => void;
  page: number;
  onPageChange: (e: React.ChangeEvent<unknown>, page: number) => void;
  loading: boolean;
}
export const EntityList: React.FC<SelectableListTypes> = ({
  listPaginated,
  scheduledListPaginated,
  entityType,
  onAddToScheduleHandle,
  onCreateNewItemHandle,
  page,
  onPageChange,
  loading
}) => {
  const EMPTY_STATE = {
    message: '',
    subMessage: `It looks like you never create any of ${entityType} before. Let's create first one`
  };
  const TOOLTIP_ADDED = `This ${entityType} already added`;
  const isItemAddedToSchedule = (itemId?: string) => {
    return scheduledListPaginated?.list?.some(el => el?.entity?._id === itemId);
  };

  if (loading) return <LoaderCentered />;

  return (
    <>
      {listPaginated?.list.map(listItem => {
        const isSelected = isItemAddedToSchedule(listItem?._id);
        return (
          <RowStyled key={listItem?._id} onClick={() => onAddToScheduleHandle(listItem?._id)}>
            <Tooltip disableHoverListener={!isSelected} title={TOOLTIP_ADDED}>
              <div>
                <IconButton
                  size="small"
                  disabled={isSelected}
                  color={isSelected ? 'secondary' : 'default'}
                  onClick={() => onAddToScheduleHandle(listItem?._id)}
                >
                  {isSelected ? <Done /> : <Add />}
                </IconButton>
              </div>
            </Tooltip>
            <Typography noWrap>{listItem?.title}</Typography>
          </RowStyled>
        );
      })}
      {!listPaginated?.totalCount ? (
        <ListEmptyState subMessage={EMPTY_STATE.subMessage}>
          {onCreateNewItemHandle && <Button onClick={onCreateNewItemHandle}>Create A New {entityType}</Button>}
        </ListEmptyState>
      ) : (
        <Pagination
          count={listPaginated?.totalPages ?? 0}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={onPageChange}
        />
      )}
    </>
  );
};
