import { Button, Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledTopContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ButtonsContainer = styled('div')`
  display: flex;
  gap: 10px;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
  ${({ theme }) => theme.typography.subtitle1};

  :hover {
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const StarButton = styled(Button)`
  margin-left: auto;
`;

export const PageHeader = styled(Stack)`
  flex-direction: row;
`;

export const PageContent = styled(Stack)`
  padding: 1rem;
  gap: 1rem;
`;

export const FooterContainer = styled(Stack)`
  padding: 0 1rem;
`;
