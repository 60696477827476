import { Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const RootContainerStyled = styled(Stack)`
  width: 100%;
`;

export const BodyStyled = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(1),
  borderRadius: '4px'
}));
export const InfoContainerStyled = styled(Stack)`
  justify-content: space-between;
  flex-direction: row;
`;

export const InfoRightBoxStyled = styled(Stack)`
  flex-direction: row;
  align-items: center;
`;

export const LinkStyled = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightBold,
  ':hover': {
    color: theme.palette.primary.main
  }
}));

export const ButtonsStackStyled = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  flexDirection: 'row'
}));
