import { Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const RootStyled = styled(Stack)`
  align-items: flex-start;
  overflow: hidden;
  gap: 12px;
  width: 100%;
`;

export const RowStyled = styled(Stack)`
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  padding: 8px;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const InfoRow = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
  overflow: hidden;

  :hover {
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;
