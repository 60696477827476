import {
  EntityType,
  MentorBlockUpdateDocument,
  useMentorBlockAcceptRequestMutation,
  useMentorBlockDeleteMutation,
  useMentorBlockJoinMutation,
  useMentorBlockOwnerQuery,
  useMentorBlockRemoveMenteeMutation,
  useMentorBlockRemoveMenteeSelfMutation,
  useMentorBlockRemoveMentorSelfMutation,
  useMentorBlockStarMutation,
  useScheduleSlotsGetByParentIdQuery
} from '../../../graphql/generated';
import { useHistory } from 'react-router-dom';
import { checkPermission } from '../../../services/checkPermission';
import { useReactiveVar } from '@apollo/client';
import { globalVar } from '../../../cache';
import { useEntityStatus } from '../../../helpers/customHooks';
import { useParams } from 'react-router';
import { useDialogContext } from '../../../providers/dialogProvider/DialogContext';
import { AppRoutes } from '../../../helpers/constants';
import moment from 'moment';

export const useMentorBlockPageController = () => {
  const { profile } = useReactiveVar(globalVar);
  const history = useHistory();
  const { onDialogOpen } = useDialogContext();
  const { lessonId, scheduledTaskId, blockId } = useParams<{
    lessonId: string;
    scheduledTaskId: string;
    blockId: string;
  }>();
  const isHistoryBackExist = !!history.location.key;

  const { data, loading: ownerDataLoading } = useMentorBlockOwnerQuery({
    variables: { blockId },
    fetchPolicy: 'network-only'
  });
  const { data: scheduleLessonsData } = useScheduleSlotsGetByParentIdQuery({
    variables: { parentId: blockId, entityType: EntityType.Lesson }
  });

  const { data: scheduleTasksData } = useScheduleSlotsGetByParentIdQuery({
    variables: { parentId: blockId, entityType: EntityType.Task }
  });
  const [joinToMentorBlock, { loading: joinLoading }] = useMentorBlockJoinMutation({ variables: { blockId } });
  const [mentorBlockRemoveMentee, { loading: removeLoading }] = useMentorBlockRemoveMenteeMutation();
  const [mentorBlockAcceptRequest] = useMentorBlockAcceptRequestMutation();
  const [leaveMentorSelf] = useMentorBlockRemoveMentorSelfMutation({ variables: { blockId } });
  const [leaveMenteeSelf] = useMentorBlockRemoveMenteeSelfMutation({ variables: { blockId } });
  const [starMentorBlock, { loading: starMentorBlockLoading }] = useMentorBlockStarMutation({
    variables: { blockId }
  });
  const [mentorBlockDelete, { error: deleteError }] = useMentorBlockDeleteMutation({
    variables: { blockId }
  });

  const ownerId = data?.mentorBlock?.owner?._id ?? '';
  const title = data?.mentorBlock?.title ?? '';
  const description = data?.mentorBlock?.description ?? '';
  const techStack = data?.mentorBlock?.stack ?? [];
  const mentors = data?.mentorBlock?.mentors ?? [];
  const mentees = data?.mentorBlock?.mentees ?? [];
  const scheduleLessons = scheduleLessonsData?.scheduleSlotsGetByParentId?.list ?? [];
  const scheduleTasks = scheduleTasksData?.scheduleSlotsGetByParentId?.list ?? [];
  const menteesRequests = data?.mentorBlock?.requests ?? [];
  const isMentorBlockStared = data?.mentorBlock?.isStarred;
  const isGroup = data?.mentorBlock?.isGroup ?? false;
  const owner = data?.mentorBlock?.owner ?? { _id: '', firstName: '', lastName: '', image: '' };
  const startDate = data?.mentorBlock?.startDate ?? '';
  const endDate = data?.mentorBlock?.endDate ?? '';
  const { timeRangeStatus, isInvitable } = useEntityStatus({ startDate, endDate });
  const isModerator = checkPermission(MentorBlockUpdateDocument, ownerId);
  const isJoinPending = !!menteesRequests.find(mentee => mentee?._id === profile._id);
  const isJoinAllowed = profile._id !== ownerId && !mentors.find(mentor => mentor?._id === profile._id);
  const isMentee = !!mentees.find(mentee => mentee?._id === profile._id);
  const isMentor = !!mentors.find(mentor => mentor?._id === profile._id);
  const openedScheduledLesson = scheduleLessons.find(el => el?.entity._id === lessonId);
  const openedScheduleTask = scheduleTasks.find(el => el?._id === scheduledTaskId);
  const isEducationalContentVisible =
    (isMentee || isMentor || isModerator) && !openedScheduledLesson && !openedScheduleTask;
  const currentWeekEvents = [...scheduleLessons, ...scheduleTasks]
    .filter(event => {
      if (!event?.startDate) return false;

      const eventDate = moment(Number(event.startDate));
      const today = moment().startOf('day'); // Beginning of current day
      const sevenDaysLater = moment().add(7, 'days').endOf('day'); // The end of the day on the 7th day

      return eventDate.isSameOrAfter(today) && eventDate.isSameOrBefore(sevenDaysLater);
    })
    .sort((a, b) => Number(a?.startDate) - Number(b?.startDate));

  const nearestFiveEvents = [...scheduleLessons, ...scheduleTasks]
    .filter(event => !!event?.streamLink)
    .sort((a, b) => Number(a?.startDate) - Number(b?.startDate))
    .slice(0, 5);

  const onBackHandle = () => {
    history.goBack();
  };

  const onEditHandle = () => {
    history.push(`/mentorium/${blockId}/edit`);
  };

  const onJoinRejectMeHandle = () => {
    joinToMentorBlock();
  };

  const onLeaveMeHandle = () => {
    leaveMenteeSelf();
  };

  const onLeaveAsMentorHandle = () => {
    leaveMentorSelf();
  };

  const onRejectRemoveMultipleHandle = (usersIdList: Array<string>) => {
    mentorBlockRemoveMentee({
      variables: { blockId, usersIdList }
    });
  };

  const onAcceptRequestMultipleHandle = (usersIdList: Array<string>) => {
    mentorBlockAcceptRequest({
      variables: { blockId, usersIdList }
    });
  };

  const handleStarMentorBlock = async () => {
    await starMentorBlock();
  };

  const deleteButtonHandle = () => {
    onDialogOpen({
      onSubmit: async () => {
        await mentorBlockDelete({
          update(cache) {
            cache.evict({ id: cache.identify({ _id: blockId, __typename: 'MentorBlock' }) });
          },
          onCompleted: () => {
            if (!deleteError) history.replace(AppRoutes.MentorBlockList);
          }
        });
      },
      title: `Delete course: ${title}`,
      showCancel: true,
      message: `Do you want to delete Course: ${title}?`,
      confirmButton: 'Delete',
      confirmButtonColor: 'error'
    });
  };

  return {
    owner,
    title,
    description,
    mentors,
    mentees,
    menteesRequests,
    scheduleLessons,
    scheduleTasks,
    openedScheduledLesson,
    currentWeekEvents,
    nearestFiveEvents,
    techStack,
    startDate,
    endDate,
    isGroup,
    isHistoryBackExist,
    isModerator,
    isJoinAllowed,
    isJoinPending,
    isMentee,
    isMentor,
    isEducationalContentVisible,
    joinLoading: joinLoading || removeLoading,
    timeRangeStatus,
    isInvitable,
    onBackHandle,
    onEditHandle,
    onJoinRejectMeHandle,
    onLeaveMeHandle,
    onRejectRemoveMultipleHandle,
    onAcceptRequestMultipleHandle,
    onLeaveAsMentorHandle,
    openedScheduleTask,
    ownerDataLoading,
    isMentorBlockStared,
    starMentorBlockLoading,
    handleStarMentorBlock,
    deleteButtonHandle
  };
};
