import { Alert, Box, FormHelperText, Paper, Stack, styled } from '@mui/material';

export const RootStyled = styled(Box)`
  padding-bottom: 48px;
`;

export const PaperStyled = styled(Paper)`
  padding: 16px;
`;

export const AlertStyled = styled(Alert)`
  margin-bottom: 16px;
`;

export const FieldsStackStyled = styled(Stack)`
  row-gap: 16px;
  margin-bottom: 16px;
`;

export const FooterStackStyled = styled(Stack)`
  flex-direction: row;
  justify-content: end;
  margin-top: 16px;
  column-gap: 16px;
`;

export const LoaderStyled = styled(Stack)`
  justify-content: center;
`;

export const ContentErrorStyled = styled(FormHelperText)`
  color: ${props => props.theme.palette.error.main};
  margin-top: 4px;
  margin-left: 14px;
`;
