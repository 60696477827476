import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { ListEmptyStateRoot } from './ListEmptyState.styles';

interface ListEmptyStateProps {
  icon?: ReactNode;
  message?: string;
  subMessage?: string;
}
export const ListEmptyState: React.FC<ListEmptyStateProps> = ({
  message = 'There is no items',
  subMessage,
  icon,
  children,
  ...respProps
}) => {
  return (
    <ListEmptyStateRoot {...respProps}>
      {icon}
      {message && <Typography variant="inherit">{message}</Typography>}
      {subMessage && (
        <Typography align="center" variant="caption">
          {subMessage}
        </Typography>
      )}
      {children}
    </ListEmptyStateRoot>
  );
};
