import { fromPromise } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { AppRoutes, LocalStorageItems } from '../helpers/constants';
import refreshToken from '../services/refreshToken';

export default onError(({ graphQLErrors, operation, forward }: ErrorResponse) => {
  if (graphQLErrors) {
    const cleanupStorageAndRedirectToLogin = () => {
      localStorage.removeItem(LocalStorageItems.Token);
      localStorage.removeItem(LocalStorageItems.UserId);
      window.location.href = AppRoutes.Login;
    };

    for (let i = 0; i < graphQLErrors.length; i++) {
      const errorMessage = graphQLErrors[i].message.split(': ')[1];
      if ('UNAUTHORIZED' === errorMessage) {
        return fromPromise(
          refreshToken().catch(() => {
            // Handle token refresh errors e.g clear stored tokens, redirect to login
            cleanupStorageAndRedirectToLogin();
            return;
          })
        )
          .filter(value => Boolean(value))
          .flatMap(accessToken => {
            const oldHeaders = operation.getContext().headers;
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${accessToken}`
              }
            });

            // retry the request, returning the new observable
            return forward(operation);
          });
      }
      if ('UNAUTHORIZED-2' === errorMessage) {
        cleanupStorageAndRedirectToLogin();
      }
      if (errorMessage === 'Not found') {
        location.href = '/404';
      }
    }
  }
});
