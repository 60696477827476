import { useEffect, useState } from 'react';
import { useQueryParams } from '../../../helpers/customHooks';
import { useGlobalSearchQuery } from '../../../graphql/generated';
import { useHistory } from 'react-router-dom';
import { getPageName } from '../../../helpers/simplifiedData';

export const useSearchHeaderController = () => {
  const history = useHistory();
  const { search } = useQueryParams(history.location.search);
  const [searchValue, setSearchValue] = useState(search || '');
  const { data } = useGlobalSearchQuery({ variables: { value: searchValue }, fetchPolicy: 'cache-first' });
  const currentPage = getPageName(history.location.pathname);

  useEffect(() => {
    setSearchValue(search || '');
  }, [history.location.search]);

  const options = [
    {
      name: 'Users',
      count: data?.globalSearch.users?.totalCount ?? 0,
      isActive: currentPage === 'users',
      path: `/users?search=${searchValue}`
    },
    // {
    //   name: 'Companies',
    //   count: data?.globalSearch.companies?.totalCount ?? 0,
    //   isActive: currentPage === 'companies',
    //   path: `/companies?search=${searchValue}`
    // },
    {
      name: 'Publications',
      count: data?.globalSearch.publications?.totalCount ?? 0,
      isActive: currentPage === 'publications',
      path: `/publications?search=${searchValue}`
    },
    // {
    //   name: 'Problems',
    //   count: data?.globalSearch.problems?.totalCount ?? 0,
    //   isActive: currentPage === 'problems',
    //   path: `/problems?search=${searchValue}`
    // },
    {
      name: 'Mentor Blocks',
      count: data?.globalSearch.mentorBlocks?.totalCount ?? 0,
      isActive: currentPage === 'mentorium',
      path: `/mentorium?search=${searchValue}`
    }
  ];

  return {
    searchValue,
    options
  };
};
