import { Link } from 'react-router-dom';
import { CenteredDivStyled } from './account/Account.styles';

const NotFound404 = () => {
  return (
    <CenteredDivStyled>
      <h6>404 NOT FOUND</h6>
      <div>The page you are looking for was moved, removed, renamed or might not have existed.</div>
      <Link to="/">{'<< Go Home'}</Link>
    </CenteredDivStyled>
  );
};

export default NotFound404;
