import React from 'react';
import { IconButton, Menu, Tooltip } from '@mui/material';
import { MoreHorizSharp, SvgIconComponent } from '@material-ui/icons';
import { MenuItemStyled } from './ActionsMenu.styles';
import { useActionsMenuController } from './ActionsMenu.controller';

export type ActionsMenuOption = {
  onClick: () => void;
  name: string;
  icon: SvgIconComponent;
  isDisabled?: boolean;
  textForDisabled?: string;
};
export interface ActionsMenuProps {
  options: Array<ActionsMenuOption>;
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({ options = [] }) => {
  const { anchorActions, handleClick, handleClose } = useActionsMenuController();

  if (!options.length) return null;

  return (
    <>
      <IconButton onClick={handleClick} children={<MoreHorizSharp />} />
      <Menu
        anchorEl={anchorActions}
        open={!!anchorActions}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {options.map(option => {
          return (
            <Tooltip key={option.name} title={option.isDisabled && option.textForDisabled} placement="left">
              <div>
                <MenuItemStyled
                  onClick={() => {
                    if (!option.isDisabled) {
                      option.onClick();
                      handleClose();
                    }
                  }}
                  disabled={option.isDisabled}
                >
                  {React.createElement(option.icon)}
                  {option.name}
                </MenuItemStyled>
              </div>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
};

export default ActionsMenu;
