import { ArrowBack, Edit } from '@material-ui/icons';
import { Button, Chip, Rating, Typography } from '@mui/material';
import React from 'react';
import PermissionProvider from 'src/providers/PermissionProvider';
import { UserUpdateDocument } from '../../../graphql/generated';
import { FriendCtaButton } from '../friendCtaButton/FriendCtaButton';
import { MentorBlocksList } from './components/mentorBlocksList/MentorBlocksList';
import { PeopleList } from './components/peopleList/PeopleList';
import { ProblemsList } from './components/problemsList/ProblemsList';
import { PublicationsList } from './components/publicationsList/PublicationsList';
import { useUserPageController } from './UserPage.controller';
import {
  AvatarStyled,
  ContentStyled,
  HeaderRightBtnGroupStyled,
  HeaderStyled,
  ListsRootStyled,
  LoaderStyled,
  OptionContent,
  OptionStyled,
  RoleChipStyled,
  RootStyled,
  TabsStyled
} from './UserPage.styles';
const UserPage: React.FC = () => {
  const {
    loading,
    isHistoryBackExist,
    userId,
    image,
    roles,
    fullName,
    jobTitle,
    languages,
    about,
    isOwner,
    email,
    level,
    onEditHandle,
    onBackHandle,
    option,
    OPTION_CONTENT,
    setOption
  } = useUserPageController();

  const component = () => {
    if (option?.linkDirectory === 'user') return <PeopleList />;
    if (option?.linkDirectory === 'publication') return <PublicationsList />;
    if (option?.linkDirectory === 'mentorium') return <MentorBlocksList />;
    if (option?.linkDirectory === 'problem') return <ProblemsList />;
  };

  if (loading) return <LoaderStyled />;

  return (
    <>
      <HeaderStyled>
        {isHistoryBackExist && (
          <Button onClick={onBackHandle}>
            <ArrowBack />
          </Button>
        )}
        <HeaderRightBtnGroupStyled>
          <PermissionProvider permission={UserUpdateDocument} ownerId={userId}>
            <Button onClick={onEditHandle} startIcon={<Edit />} variant="outlined">
              Edit
            </Button>
          </PermissionProvider>
          <FriendCtaButton variant="outlined" inviteeId={userId} />
        </HeaderRightBtnGroupStyled>
      </HeaderStyled>

      <RootStyled>
        <AvatarStyled variant="square" src={image} />
        <RoleChipStyled color="secondary" label={roles} />

        <ContentStyled>
          <Typography component="div" variant="h4">
            {fullName}
          </Typography>
          <Typography variant="h6" component="div">
            {jobTitle}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            <div>
              <b>About: </b>
              {about}
            </div>
            <br />
            <b>Languages: </b>
            {languages.map((lang: React.ReactNode, i: number) => (
              <Chip size="small" key={i} label={lang} />
            ))}
            <div hidden={!isOwner}>
              <b>Email: </b>
              {email}
            </div>
            <div>
              <b>Level: </b>
              <Rating className="" name="size-small" defaultValue={level} size="small" readOnly max={level} />
            </div>
          </Typography>
        </ContentStyled>
      </RootStyled>

      {isOwner && (
        <ListsRootStyled>
          <TabsStyled minWidth="fit-content">
            {OPTION_CONTENT.map(btn => (
              <OptionStyled key={btn.title} onClick={() => setOption(btn)} isActive={option.title === btn.title}>
                {btn.title}
              </OptionStyled>
            ))}
          </TabsStyled>
          <OptionContent>{component()}</OptionContent>
        </ListsRootStyled>
      )}
    </>
  );
};

export default UserPage;
