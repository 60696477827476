import { ChatBubble, ChatBubbleOutline, ThumbUp, ThumbUpAltOutlined } from '@material-ui/icons';
import { CircularProgress } from '@mui/material';
import React from 'react';
import CommentList from '../../comment/commentList/CommentList';
import { useProfile } from '../../../helpers/customHooks';
import { ButtonStyled, RootStyled } from './ActionsFooter.styles';

interface ActionsFooterProps {
  parentId: string | undefined | null;
  likes:
    | Array<{ _id: string; firstName?: string | null; lastName?: string | null; image?: string | null } | null>
    | undefined
    | null;
  onLike: () => void;
  loading?: boolean;
  commentsLoading?: boolean;
  commentsCount?: number | null;
}

const ActionsFooter: React.FC<ActionsFooterProps> = ({
  parentId,
  likes,
  onLike,
  loading,
  commentsCount,
  commentsLoading
}) => {
  const profile = useProfile();
  const [showComments, setShowComments] = React.useState(false);
  const isLiked = likes?.find(user => user?._id == profile._id);
  const onCommentsShowHandle = () => {
    setShowComments(prevState => !prevState);
  };

  return (
    <RootStyled>
      <ButtonStyled id="like-btn" onClick={onLike}>
        {loading ? <CircularProgress size={24} /> : isLiked ? <ThumbUp /> : <ThumbUpAltOutlined />}
        <span>{likes?.length || null}</span>
      </ButtonStyled>

      <ButtonStyled id="comment-btn" onClick={onCommentsShowHandle}>
        {commentsLoading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            {showComments ? <ChatBubble /> : <ChatBubbleOutline />}
            <span>{commentsCount || null}</span>
          </>
        )}
      </ButtonStyled>

      <CommentList parentId={parentId} show={showComments} />
    </RootStyled>
  );
};

export default ActionsFooter;
