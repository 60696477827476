import React from 'react';

interface IProps {
  src: string;
  width?: string;
  height?: string;
}

const Image = ({ src = '', width = '100%', height = '100%' }: IProps) => {
  const [image, setImage] = React.useState(src);

  if (!image) return null;

  return (
    <img
      src={image}
      width={width}
      style={{ objectFit: 'cover' }}
      height={height}
      onError={() => setImage('https://i.stack.imgur.com/y9DpT.jpg')}
    />
  );
};

export default Image;
