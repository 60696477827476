import { FC } from 'react';
import { Notification } from '../../../graphql/generated';
import { Avatar, Typography } from '@mui/material';
import { ActionsMenuContainer, LinkStyled, NotificationListItemStyled } from './NotificationsListItem.styles';
import { useNotificationListItemController } from './NotificationListItem.controller';
import ActionsMenu from '../../sharedComponents/actionsMenu/ActionsMenu';

export interface NotificationListItemProps {
  notification: Notification | any;
}
export const NotificationListItem: FC<NotificationListItemProps> = ({ notification }) => {
  const {
    entityLink,
    initiatorAvatarLink,
    initiatorFullName,
    actionPhrase,
    entityNamePhrase,
    isNotificationRead,
    notificationMarkAsReadHandle,
    actionOptions
  } = useNotificationListItemController({
    notification
  });

  return (
    <LinkStyled to={entityLink} onClick={notificationMarkAsReadHandle}>
      <NotificationListItemStyled isRead={isNotificationRead}>
        <Avatar src={initiatorAvatarLink} />
        <Typography>
          <b>{initiatorFullName}</b>
          {actionPhrase}
          <b>{entityNamePhrase}</b>
        </Typography>
        <ActionsMenuContainer
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ActionsMenu options={actionOptions} />
        </ActionsMenuContainer>
      </NotificationListItemStyled>
    </LinkStyled>
  );
};
