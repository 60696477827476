import { FC } from 'react';
import { TypographyStyled } from 'src/app/mentorium/mentorBlockPage/MentorBlockPage.styles';
import { Maybe, Task } from '../../../graphql/generated';
import { LoaderStyled } from '../../user/userPage/UserPage.styles';
import MarkdownPreview from '../../sharedComponents/markdown/MarkdownPreview';

interface TaskViewProps {
  task: Maybe<Task>;
}
export const TaskView: FC<TaskViewProps> = ({ task }) => {
  if (!task) return <LoaderStyled />;

  return (
    <div>
      <TypographyStyled variant="h3">{task?.title}</TypographyStyled>
      {task?.content && <MarkdownPreview source={task?.content} />}
    </div>
  );
};
