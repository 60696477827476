import Header from '../header/Header';
import Menu from '../menu/Menu';
import { useMainLayoutController } from './MainLayout.controller';
import { ContentContainer, GeneralContainer, MainLayoutRoot } from './MainLayout.styles';

export const MainLayout = ({ children }: any) => {
  const { toggleMenu, isMenuOpen, handleLogout, currentPage, isLoggedIn, profile } = useMainLayoutController();

  if (!isLoggedIn || !profile.firstName) return null;

  return (
    <MainLayoutRoot>
      <Header toggleMenu={toggleMenu} />

      <GeneralContainer>
        <Menu
          onLogout={handleLogout}
          profile={profile}
          currentPage={currentPage}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
        />
        <ContentContainer>{children}</ContentContainer>
      </GeneralContainer>
    </MainLayoutRoot>
  );
};
