import React from 'react';
import { useQueryParams } from '../../../helpers/customHooks';
import { useHistory } from 'react-router-dom';
import { Paginate } from '../../../helpers/pagination';
import { useMentorBlocksSearchQuery } from '../../../graphql/generated';

export const useMentorBlockListController = () => {
  const limit = 10;
  const history = useHistory();
  const { p, ofs, search } = useQueryParams(history.location.search);
  const offset = ofs ? Number(ofs) : 0;
  const page = p ? Number(p) : 1;
  const { data, loading } = useMentorBlocksSearchQuery({
    variables: {
      value: search ?? '',
      offset,
      limit
    },
    fetchPolicy: 'cache-and-network'
  });
  const mentorBlocks = data?.mentorBlocksSearch?.list ?? [];
  const totalPages = data?.mentorBlocksSearch?.totalPages ?? 0;

  const onCreateHandle = () => {
    history.push('/mentorium/create');
  };

  const onPageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    Paginate.onChangePage({ page, limit, history, searchValue: search });
  };

  return {
    loading,
    mentorBlocks,
    totalPages,
    page,
    search,
    onPageChange,
    onCreateHandle
  };
};
