import {
  Publication,
  PublicationDeleteDocument,
  PublicationGetDocument,
  PublicationUpdateDocument,
  usePublicationCommentsCountGetQuery,
  usePublicationDeleteMutation,
  usePublicationGetQuery,
  usePublicationLikeMutation,
  useUserStarPublicationMutation,
  useUserStarredPublicationsGetQuery
} from '../../../graphql/generated';
import { checkPermission } from '../../../services/checkPermission';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useProfile } from '../../../helpers/customHooks';
import { useDialogContext } from '../../../providers/dialogProvider/DialogContext';

export const usePublicationPageController = () => {
  const history = useHistory();
  const historyBack = history.location.key;
  const { pubId }: { pubId: string } = useParams();
  const profile = useProfile();
  const { onDialogOpen } = useDialogContext();

  const [publicationLike, { loading: likeLoading }] = usePublicationLikeMutation({
    variables: { pubId },
    refetchQueries: [{ query: PublicationGetDocument, variables: { pubId } }],
    awaitRefetchQueries: true
  });

  const { loading: userLoading, data: currentUserData } = useUserStarredPublicationsGetQuery({
    skip: !profile._id,
    variables: { userId: profile._id }
  });

  const [starPublication, { loading: starPublicationLoading }] = useUserStarPublicationMutation({
    variables: { userId: profile._id, pubId }
  });

  const [publicationDelete, { loading: deleteLoading, error: deleteError }] = usePublicationDeleteMutation({
    variables: { pubId },
    update: cache => {
      cache.modify({
        fields: {
          publications(existingPublicationsRefs, { readField }) {
            return {
              ...existingPublicationsRefs,
              list: existingPublicationsRefs.list.filter((PublicationRef: Publication) => {
                return readField('_id', PublicationRef) !== pubId;
              })
            };
          }
        }
      });
    }
  });

  const { data: commentsData, loading: commentsLoading } = usePublicationCommentsCountGetQuery({
    fetchPolicy: 'network-only',
    variables: {
      pubId
    },
    skip: !pubId
  });

  const { loading: publicationGetLoading, data: { publication } = {} } = usePublicationGetQuery({
    variables: { pubId }
  });

  const commentsCount = commentsData?.publication?.commentsCount ?? 0;
  const canDelete = checkPermission(PublicationDeleteDocument, publication?.owner?._id);
  const canEdit = checkPermission(PublicationUpdateDocument, publication?.owner?._id);
  const userStarredPublications = currentUserData?.user?.starredPublications ?? [];
  const isPublicationStared = userStarredPublications.some(el => el?._id === publication?._id);

  const onLikeHandler = async () => {
    await publicationLike();
  };

  const handleEditClick = () => {
    history.push(`/publication/${pubId}/edit`);
  };

  const handleDeleteClick = () => {
    onDialogOpen({
      onSubmit: async () => {
        await publicationDelete();
        if (!deleteError) history.push(`/publications`);
      },
      title: `Delete Publication: ${publication?.title}`,
      showCancel: true,
      message: `Do you want to delete Publication: ${publication?.title}?`,
      confirmButton: 'Delete',
      confirmButtonColor: 'error'
    });
  };

  const handleStarPublication = async () => {
    await starPublication();
  };

  const onBackHandle = () => {
    history.goBack();
  };

  return {
    onLikeHandler,
    handleEditClick,
    handleDeleteClick,
    handleStarPublication,
    onBackHandle,
    loading: publicationGetLoading || deleteLoading || userLoading,
    starPublicationLoading,
    likeLoading,
    publication,
    isPublicationStared,
    canDelete,
    canEdit,
    historyBack,
    pubId,
    commentsCount,
    commentsLoading
  };
};
