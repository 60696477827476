import { DocumentNode, useReactiveVar } from '@apollo/client';
import { globalVar } from '../cache';
import gqlParser from '../helpers/gqlParser';

interface RolesCanTypes {
  user: string[];
  moderator: string[];
  admin: string[];
  [index: string]: string[];
}

const rolesCan: RolesCanTypes = {
  user: [
    'user',
    'userSearch',
    'userStarPublication',
    'userStarMentorBlock',
    'userFriendInvite',
    'userFriendReject',
    'publication',
    'publications',
    'publicationSearch',
    'publicationCreate',
    'publicationsByUserId',
    'publicationLike',
    'problem',
    'problems',
    'problemsGetByUserId',
    'problemCreate',
    'solution',
    'solutions',
    'solutionCreate',
    'solutionLike',
    'company',
    'companies',
    'companySearch',
    'companySearchNew',
    'companyCreate',
    'companyLike',
    'comment',
    'comments',
    'commentCreate',
    'commentLike',
    'mentorBlockCreate',
    'mentorBlock',
    'mentorBlocks',
    'mentorBlocksSearch',
    'mentorGetAllByMenteeId',
    'mentorBlockRemoveMenteeSelf',
    'mentorBlockRemoveMentorSelf',
    'mentorBlockJoin',
    'scheduleSlot',
    'scheduleSlotsGetByParentId',
    'scheduleSlotCreate',
    'scheduleSlotUpdate',
    'scheduleSlotDelete',
    'lessonCreate',
    'lesson',
    'lessonsGetByIdList',
    'lessonsGetByOwnerId',
    'taskCreate',
    'taskGet',
    'tasksGetByIdList',
    'tasksGetByOwnerId',
    'taskAnswerGet',
    'taskAnswersListGet',
    'taskAnswerCreate',
    'globalSearch'
  ],

  moderator: [
    'user',
    'userSearch',
    'userStarPublication',
    'userStarMentorBlock',
    'userFriendInvite',
    'userFriendReject',
    'publication',
    'publications',
    'publicationSearch',
    'publicationCreate',
    'publicationUpdate',
    'publicationLike',
    'publicationsByUserId',
    'problem',
    'problems',
    'problemsGetByUserId',
    'problemCreate',
    'problemUpdate',
    'solution',
    'solutions',
    'solutionCreate',
    'solutionUpdate',
    'solutionLike',
    'company',
    'companies',
    'companySearch',
    'companySearchNew',
    'companyCreate',
    'companyUpdate',
    'companyLike',
    'comment',
    'comments',
    'commentCreate',
    'commentUpdate',
    'commentLike',
    'mentorBlockCreate',
    'mentorBlockUpdate',
    'mentorBlock',
    'mentorBlocks',
    'mentorBlocksSearch',
    'mentorGetAllByMenteeId',
    'mentorBlockJoin',
    'mentorBlockAcceptRequest',
    'mentorBlockRemoveMentee',
    'mentorBlockRemoveMenteeSelf',
    'mentorBlockRemoveMentorSelf',
    'scheduleSlot',
    'scheduleSlotsGetByParentId',
    'scheduleSlotCreate',
    'scheduleSlotUpdate',
    'scheduleSlotDelete',
    'lessonCreate',
    'lessonUpdate',
    'lesson',
    'lessonsGetByIdList',
    'lessonsGetByOwnerId',
    'taskCreate',
    'taskUpdate',
    'taskGet',
    'tasksGetByIdList',
    'tasksGetByOwnerId',
    'taskAnswerGet',
    'taskAnswersListGet',
    'taskAnswerCreate',
    'taskAnswerUpdate',
    'taskAnswerStatusUpdate',
    'globalSearch'
  ],

  admin: [
    'user',
    'userSearch',
    'userStarPublication',
    'userStarMentorBlock',
    'userFriendInvite',
    'userFriendReject',
    'userUpdate',
    'userDelete',
    'publication',
    'publications',
    'publicationSearch',
    'publicationCreate',
    'publicationUpdate',
    'publicationDelete',
    'publicationLike',
    'publicationsByUserId',
    'problem',
    'problems',
    'problemsGetByUserId',
    'problemCreate',
    'problemUpdate',
    'problemDelete',
    'solution',
    'solutions',
    'solutionCreate',
    'solutionUpdate',
    'solutionDelete',
    'solutionLike',
    'company',
    'companies',
    'companySearch',
    'companySearchNew',
    'companyCreate',
    'companyUpdate',
    'companyLike',
    'companyDelete',
    'comment',
    'comments',
    'commentCreate',
    'commentUpdate',
    'commentDelete',
    'commentLike',
    'mentorBlockCreate',
    'mentorBlockDelete',
    'mentorBlockUpdate',
    'mentorBlock',
    'mentorBlocks',
    'mentorBlocksSearch',
    'mentorGetAllByMenteeId',
    'mentorBlockJoin',
    'mentorBlockAcceptRequest',
    'mentorBlockRemoveMentee',
    'mentorBlockRemoveMenteeSelf',
    'mentorBlockRemoveMentorSelf',
    'mentorBlockStar',
    'scheduleSlot',
    'scheduleSlotsGetByParentId',
    'scheduleSlotCreate',
    'scheduleSlotUpdate',
    'scheduleSlotDelete',
    'lessonCreate',
    'lessonDelete',
    'lessonUpdate',
    'lesson',
    'lessonsGetByIdList',
    'lessonsGetByOwnerId',
    'taskCreate',
    'taskDelete',
    'taskUpdate',
    'taskGet',
    'tasksGetByIdList',
    'tasksGetByOwnerId',
    'taskAnswerGet',
    'taskAnswersListGet',
    'taskAnswerCreate',
    'taskAnswerUpdate',
    'taskAnswerStatusUpdate',
    'taskAnswerDelete',
    'globalSearch'
  ]
};

export const checkPermission = (permission: DocumentNode, entityOwnerId: string | undefined) => {
  const { profile } = useReactiveVar(globalVar);
  const { queryName } = gqlParser(permission);
  let userHasPermission = profile._id === entityOwnerId;

  for (const role of profile.roles || []) {
    if (rolesCan[role || 0].includes(queryName)) {
      userHasPermission = true;
      break;
    }
  }

  return userHasPermission;
};
