import React, { useState } from 'react';
import { getSelectableListHook } from '../../../mentorium/mentorBlockForm/manageLessons/ManageLessons';

interface AddScheduledItemDialogControllerProps {
  getSelectableListHook: getSelectableListHook;
}

export const useAddScheduledItemDialogController = ({
  getSelectableListHook
}: AddScheduledItemDialogControllerProps) => {
  const [page, setPage] = useState(1);
  const { selectableListPaginated, refetch, loading } = getSelectableListHook({ page });
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState<string>();

  const tabChangeHandle = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const onCloseForm = () => {
    refetch();
    setTabIndex(0);
  };

  const onPageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const onSelectItemHandle = (itemId?: string) => {
    setSelectedItemId(itemId);
  };

  const backToSelectableListHandle = () => {
    setSelectedItemId('');
  };

  return {
    loading,
    selectableListPaginated,
    backToSelectableListHandle,
    tabIndex,
    setTabIndex,
    tabChangeHandle,
    onCloseForm,
    selectedItemId,
    onSelectItemHandle,
    page,
    onPageChange
  };
};
