import { Avatar, Button, Chip, CircularProgress, Paper, Stack, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const LoaderStyled = styled(CircularProgress)`
  display: table;
  margin: 0 auto;
`;

export const HeaderStyled = styled(Stack)`
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

export const HeaderRightBtnGroupStyled = styled(Stack)`
  flex-direction: row;
  gap: 4px;
`;

export const RootStyled = styled(Paper)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 1rem;
`;

export const AvatarStyled = styled(Avatar)`
  height: 300px;
  width: 300px;
  object-fit: cover;

  svg {
    width: 12rem;
    height: 12rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    width: calc(100vw - 4rem);
    height: calc(100vw - 4rem);
  }
`;

export const RoleChipStyled = styled(Chip)`
  position: absolute;
  margin: 0.5rem;
`;

export const ContentStyled = styled(Stack)`
  flex-basis: 0;
  flex-grow: 1;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const ListsRootStyled = styled(Paper)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 1rem;
  margin: 1rem 0;
  gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-wrap: wrap;
  }
`;

export const TabsStyled = styled(Stack)`
  gap: 4px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const OptionContent = styled(Stack)`
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
`;

export const OptionStyled = styled(Button)<{ isActive: boolean }>`
  justify-content: end;
  background-color: ${props => props.isActive && props.theme.palette.primary.main};
  color: ${props => props.isActive && 'white'};

  :hover {
    background-color: ${props => props.isActive && props.theme.palette.primary.dark};
  }
`;

export const EmptyListMessageStyled = styled(Typography)`
  margin: auto auto;
`;

export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};

  :hover {
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;
