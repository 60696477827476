import React from 'react';
import checkAuth from '../services/checkAuth';

const AuthProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  React.useEffect(() => {
    (async () => {
      await checkAuth();
    })();
  }, []);

  return children;
};

export default AuthProvider;
