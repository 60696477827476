import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import { client } from './appoloClient';

ReactDOM.render(
  //   <Antibot>
  <ApolloProvider client={client}>
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </ApolloProvider>,
  //   </Antibot>,
  document.getElementById('root')
);
