import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { REGEX, Validate } from '../../../../helpers/validation';
import {
  Maybe,
  useMentorBlockCreateMutation,
  useMentorBlockUpdateMutation,
  User,
  useUserSearchLazyQuery
} from '../../../../graphql/generated';
import React, { useEffect } from 'react';
import { debounce } from 'lodash';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { MainProps } from './MainInfo';
import { Moment } from 'moment';
import { LANGUAGES, TECH_STACK } from '../../../../helpers/constants';
import { useSnackbar } from 'notistack';

type Mentors = Array<Pick<User, 'firstName' | 'image' | 'jobTitle' | 'lastName' | '_id'> | null>;

interface MainMentorForm {
  description: string;
  mentors: Mentors;
  stack: Array<string | null>;
  title: string;
  isGroup: boolean;
  startDate?: Moment | null;
  endDate?: Moment | null;
}

export const useMainInfoController = ({ mentorBlockData }: MainProps) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { blockId }: { blockId: string } = useParams();
  const currentDate = moment();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting },
    control
  } = useForm<MainMentorForm>({
    resolver: yupResolver(
      yup.object({
        title: yup.string().matches(REGEX.singleInput, Validate.singleInput.error).required('Title is required').trim(),
        description: yup.string().required('Description is required').trim(),
        stack: yup.array(),
        isGroup: yup.boolean(),
        startDate: yup.date().required('Start Date is required').typeError('Please enter a valid Start Date'),
        endDate: yup.date().required('End Date is required').typeError('Please enter a valid End Date')
      })
    ),
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      description: '',
      stack: [],
      isGroup: false,
      mentors: []
    }
  });

  const watchStartDate = watch('startDate');

  const [userSearch, { loading: userLoading, data }] = useUserSearchLazyQuery();
  const [mentorBlockUpdate, { loading: updateLoading, error: updateError }] = useMentorBlockUpdateMutation();
  const [mentorBlockCreate, { loading: createLoading, error: createError }] = useMentorBlockCreateMutation();

  useEffect(() => {
    if (blockId && mentorBlockData) {
      const startDate = mentorBlockData.startDate;
      const endDate = mentorBlockData.endDate;
      reset({
        title: mentorBlockData.title ?? '',
        description: mentorBlockData.description ?? '',
        stack: mentorBlockData.stack ?? [],
        isGroup: mentorBlockData.isGroup ?? false,
        mentors: mentorBlockData.mentors ?? [],
        startDate: moment(startDate ? Number(startDate) : null),
        endDate: moment(endDate ? Number(endDate) : null)
      });
    }
  }, [blockId, mentorBlockData]);

  const getIdList = (fullDataList: Mentors) => fullDataList.map(el => el?._id || '');

  const clearDate = () => {
    setValue('startDate', null);
    setValue('endDate', null);
  };

  const onSubmitFormHandler = handleSubmit(async formData => {
    const modifiedFormData = {
      ...formData,
      startDate: formData.startDate ? formData.startDate.valueOf().toString() : '',
      endDate: formData.endDate ? formData.endDate.valueOf().toString() : '',
      mentors: getIdList(formData.mentors)
    };
    if (blockId) {
      const { errors } = await mentorBlockUpdate({
        variables: { blockId, values: modifiedFormData }
      });
      if (!errors) {
        history.push(`/mentorium/${blockId}`);
        enqueueSnackbar(`Updated course: ${formData.title}`, { variant: 'success' });
      } else {
        enqueueSnackbar(errors[0].message, { variant: 'error' });
      }
    } else {
      const { data, errors } = await mentorBlockCreate({
        variables: { data: modifiedFormData }
      });
      if (!errors) {
        history.replace(`/mentorium/${data?.mentorBlockCreate?._id}`);
        enqueueSnackbar(`Created course: ${formData.title}`, { variant: 'success' });
      } else {
        enqueueSnackbar(errors[0].message, { variant: 'error' });
      }
    }
  });

  if (isSubmitting) {
    reset(getValues());
  }

  const getFullName = (option: Maybe<Omit<User, 'roles'>>) => `${option?.firstName} ${option?.lastName}`;

  const usersSearchWithDebounce = debounce((value: string) => {
    userSearch({ variables: { value, offset: 0, limit: 10 }, fetchPolicy: 'network-only' });
  }, 400);

  const emptyFieldValue = {
    target: {
      value: ''
    }
  } as React.ChangeEvent<HTMLInputElement>;

  //Filer out owner and already selected users
  const watchMentors = watch('mentors');
  const filteredUsers = data?.userSearch?.list.filter(
    el => !watchMentors.some(user => el?._id === user?._id) && el?._id !== mentorBlockData.ownerId
  );

  const cancelButtonHandle = () => {
    history.replace(`/mentorium/${blockId || ''}`);
  };

  const stackOptions = [...LANGUAGES, ...TECH_STACK].sort();

  return {
    onSubmitFormHandler,
    register,
    control,
    formErrors: errors,
    loading: createLoading || updateLoading,
    userLoading,
    error: createError || updateError,
    users: filteredUsers,
    usersSearchWithDebounce,
    getFullName,
    currentDate,
    watchStartDate,
    stackOptions,
    emptyFieldValue,
    watchMentors,
    clearDate,
    cancelButtonHandle
  };
};
