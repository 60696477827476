import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { REGEX, Validate } from '../../../../helpers/validation';

interface TaskAnswerFormControllerProps {
  onSubmit: (content: string) => void;
}
export const useTaskAnswerFormController = ({ onSubmit }: TaskAnswerFormControllerProps) => {
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    resolver: yupResolver(
      yup.object({
        content: yup.string().matches(REGEX.content, Validate.content.error).required('Content is required').trim()
      })
    ),
    mode: 'onSubmit'
  });

  const SUBMIT_BUTTON_TEXT = 'Submit Answer';
  const CLOSE_BUTTON_TEXT = 'Close';

  const submitAnswerHandle = handleSubmit(({ content }) => {
    onSubmit(content);
  });

  return {
    errors,
    control,
    SUBMIT_BUTTON_TEXT,
    CLOSE_BUTTON_TEXT,
    submitAnswerHandle
  };
};
