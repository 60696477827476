import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useDisclosure } from '../../../../../helpers/useDisclosure';
import {
  Maybe,
  Status,
  TaskAnswerShortFragment,
  TaskAnswersListGetDocument,
  useTaskAnswerDeleteMutation,
  useTaskAnswerGetContentLazyQuery,
  useTaskAnswerStatusUpdateMutation,
  useTaskAnswerUpdateMutation
} from '../../../../../graphql/generated';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { REGEX, Validate } from '../../../../../helpers/validation';
import { AutorenewOutlined, BlockOutlined, CheckCircleOutlineSharp } from '@material-ui/icons';

interface TaskAnswersListItemControllerProps {
  taskAnswer: Maybe<TaskAnswerShortFragment>;
}

export const useTaskAnswersListItemController = ({ taskAnswer }: TaskAnswersListItemControllerProps) => {
  const { palette } = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditable, setIsEditable] = useState(false);
  const [answerGetContent, { data: answerData }] = useTaskAnswerGetContentLazyQuery();
  const [answerDelete, { loading: loadingDelete }] = useTaskAnswerDeleteMutation();
  const [answerUpdate, { loading: loadingUpdate }] = useTaskAnswerUpdateMutation();
  const [answerUpdateStatus, { loading: loadingStatusUpdate }] = useTaskAnswerStatusUpdateMutation();

  const {
    handleSubmit,
    formState: { isDirty },
    control,
    reset
  } = useForm({
    resolver: yupResolver(
      yup.object({
        content: yup.string().matches(REGEX.content, Validate.content.error).required('Content is required').trim()
      })
    ),
    mode: 'onSubmit'
  });

  const answerId = taskAnswer?._id ?? '';
  const author = `${taskAnswer?.owner?.firstName} ${taskAnswer?.owner?.lastName}`;
  const lastUpdatedDate = taskAnswer?.updatedAt ?? '';
  const status = taskAnswer?.status ?? '';
  const content = answerData?.taskAnswerGet?.content ?? '';
  const statusType = {
    accept: Status.done,
    reject: Status.returned
  };

  useEffect(() => {
    if (content) {
      reset({
        content
      });
    }
  }, [answerData]);
  const answerContentPreviewHandle = () => {
    answerGetContent({ variables: { answerId } });
  };

  const answerDeleteHandle = async () => {
    await answerDelete({
      variables: { answerId },
      refetchQueries: [TaskAnswersListGetDocument],
      awaitRefetchQueries: true
    });
    onClose();
  };

  const answerStatusUpdateHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
    const buttonId = e.currentTarget.id;

    const status = statusType[buttonId as keyof typeof statusType];
    answerUpdateStatus({ variables: { answerId, status } });
  };

  const answerSaveHandle = handleSubmit(({ content }) => {
    answerUpdate({ variables: { answerId, content } }).then(() => onEditHandle());
  });

  const onEditHandle = () => {
    setIsEditable(prevState => !prevState);
  };

  const StatusIcon =
    status === 'done' ? CheckCircleOutlineSharp : status === 'pending' ? AutorenewOutlined : BlockOutlined;
  const statusColor =
    status === 'done' ? palette.success.dark : status === 'pending' ? palette.primary.dark : palette.error.dark;

  const TEXT = {
    authorLabel: 'Author:',
    buttonReject: 'Reject',
    buttonAccept: 'Accept',
    buttonEdit: 'Edit',
    buttonDelete: 'Delete',
    buttonCancel: 'Cancel',
    buttonSave: 'Save',
    deleteAnswerDialogTitle: 'Delete answer',
    deleteAnswerDialogMessage: 'This will delete the Answer forever. Are you sure you want to continue?'
  };

  return {
    answerContentPreviewHandle,
    answerStatusUpdateHandle,
    answerDeleteHandle,
    answerSaveHandle,
    onEditHandle,
    loadingStatusUpdate,
    loadingUpdate,
    loadingDelete,
    isEditable,
    isDirty,
    isOpen,
    onOpen,
    onClose,
    author,
    lastUpdatedDate,
    statusColor,
    StatusIcon,
    status,
    content,
    control,
    TEXT
  };
};

export default useTaskAnswersListItemController;
