import { Stack, styled } from '@mui/material';

export const TimeframeRootStyled = styled(Stack)`
  gap: 1rem;
`;

export const RowStyled = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const ButtonsRowStyled = styled(RowStyled)`
  justify-content: flex-end;
`;
