import { useHistory } from 'react-router-dom';
import { useProfile, useQueryParams } from '../../../helpers/customHooks';
import React from 'react';
import { useUserSearchQuery } from '../../../graphql/generated';
import { Paginate } from '../../../helpers/pagination';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export const useUsersListController = () => {
  const { _id: profileId } = useProfile();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const limit = 20;
  const history = useHistory();
  const { p, ofs, search } = useQueryParams(history.location.search);
  const offset = ofs ? Number(ofs) : 0;
  const page = p ? Number(p) : 1;
  const { loading, data: { userSearch } = {} } = useUserSearchQuery({
    variables: {
      value: search ?? '',
      offset: offset,
      limit
    }
  });

  const onPageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    Paginate.onChangePage({ page, limit, history, searchValue: search });
  };

  return {
    loading,
    usersList: userSearch?.list,
    page,
    totalPages: userSearch?.totalPages,
    onPageChange,
    search,
    profileId,
    isMobile
  };
};
