import React from 'react';
import {
  AccordionStyled,
  ButtonsStackStyled,
  ContentRootStyled,
  DateAndStatusBoxStyled,
  MarkdownStyled,
  RootStyled
} from './TaskAnswersListItem.styles';
import { ExpandLess } from '@material-ui/icons';
import { AccordionDetails, AccordionSummary, Button, CircularProgress, Typography } from '@mui/material';
import moment from 'moment/moment';
import {
  bold,
  code,
  codeBlock,
  divider,
  hr,
  image,
  italic,
  link,
  orderedListCommand,
  quote,
  strikethrough,
  title,
  unorderedListCommand
} from '@uiw/react-md-editor';
import { Maybe, TaskAnswerShortFragment } from '../../../../../graphql/generated';
import DeleteDialog from '../../../../sharedComponents/deleteDialog/DeleteDialog';
import { Controller } from 'react-hook-form';
import useTaskAnswersListItemController from './TaskAnswersListItem.controller';
import CommentList from '../../../../comment/commentList/CommentList';
import MarkdownEditor from '../../../../sharedComponents/markdown/MarkdownEditor';

interface TaskAnswersListItemProps {
  taskAnswer: Maybe<TaskAnswerShortFragment>;
  isMentor: boolean;
}
export const TaskAnswersListItem: React.FC<TaskAnswersListItemProps> = ({ taskAnswer, isMentor }) => {
  const {
    answerContentPreviewHandle,
    answerStatusUpdateHandle,
    answerDeleteHandle,
    answerSaveHandle,
    onEditHandle,
    loadingStatusUpdate,
    loadingUpdate,
    loadingDelete,
    isEditable,
    isDirty,
    isOpen,
    onOpen,
    onClose,
    author,
    lastUpdatedDate,
    statusColor,
    StatusIcon,
    status,
    content,
    control,
    TEXT
  } = useTaskAnswersListItemController({ taskAnswer });

  return (
    <AccordionStyled onClick={answerContentPreviewHandle}>
      <AccordionSummary expandIcon={<ExpandLess />}>
        <RootStyled>
          <span>
            <b>{TEXT.authorLabel}</b> {author}
          </span>
          <DateAndStatusBoxStyled>
            <i>{moment(Number(lastUpdatedDate)).format('MM/DD/YY')}</i>
            <Typography color={statusColor}>
              <StatusIcon fontSize="small" />
              {status}
            </Typography>
          </DateAndStatusBoxStyled>
        </RootStyled>
      </AccordionSummary>
      <AccordionDetails>
        <ContentRootStyled>
          {!isEditable ? (
            <MarkdownStyled source={content} />
          ) : (
            <Controller
              name="content"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <MarkdownEditor
                    commands={[
                      title,
                      bold,
                      italic,
                      strikethrough,
                      divider,
                      link,
                      image,
                      divider,
                      code,
                      codeBlock,
                      quote,
                      hr,
                      divider,
                      orderedListCommand,
                      unorderedListCommand
                    ]}
                    preview="edit"
                    height={300}
                    value={value}
                    onChange={values => onChange(values)}
                  />
                );
              }}
            />
          )}

          <ButtonsStackStyled>
            {isMentor && (
              <>
                <Button
                  id={'reject'}
                  color="error"
                  onClick={answerStatusUpdateHandle}
                  disabled={loadingStatusUpdate}
                  startIcon={loadingStatusUpdate ? <CircularProgress size={15} /> : null}
                >
                  {TEXT.buttonReject}
                </Button>
                <Button
                  id={'accept'}
                  color="secondary"
                  onClick={answerStatusUpdateHandle}
                  disabled={loadingStatusUpdate}
                  startIcon={loadingStatusUpdate ? <CircularProgress size={15} /> : null}
                >
                  {TEXT.buttonAccept}
                </Button>
              </>
            )}
            {!isEditable && !isMentor && (
              <>
                <Button onClick={onEditHandle}>{TEXT.buttonEdit}</Button>
                <Button color="error" onClick={onOpen}>
                  {TEXT.buttonDelete}
                </Button>
              </>
            )}
            {isEditable && !isMentor && (
              <>
                <Button onClick={onEditHandle}>{TEXT.buttonCancel}</Button>
                <Button
                  disabled={loadingUpdate}
                  startIcon={loadingUpdate ? <CircularProgress size={15} /> : null}
                  color="secondary"
                  variant="contained"
                  onClick={isDirty ? answerSaveHandle : onEditHandle}
                >
                  {TEXT.buttonSave}
                </Button>
              </>
            )}
          </ButtonsStackStyled>
          <CommentList parentId={taskAnswer?._id} show />
        </ContentRootStyled>
      </AccordionDetails>
      <DeleteDialog
        title={TEXT.deleteAnswerDialogTitle}
        message={TEXT.deleteAnswerDialogMessage}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={answerDeleteHandle}
        isSubmitLoading={loadingDelete}
      />
    </AccordionStyled>
  );
};
