import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

export const ToggleContainer = styled(Link)`
  color: white;
  :hover {
    color: white;
  }
`;

export const TextContainer = styled('div')`
  padding: 0 1rem;
`;
