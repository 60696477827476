import { DialogActions, DialogContent, styled } from '@mui/material';

export const DialogContentStyled = styled(DialogContent)`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const DialogActionsStyled = styled(DialogActions)`
  margin: 1rem;
`;
