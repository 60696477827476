import { useMentorBlockOwnerLazyQuery } from '../../../graphql/generated';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useProfile } from '../../../helpers/customHooks';

export const useMentorBlockFormController = () => {
  const profile = useProfile();
  const { blockId }: { blockId: string } = useParams();
  const [tabIndex, setTabIndex] = useState(0);

  const [getMentorBlockOwner, { data, loading }] = useMentorBlockOwnerLazyQuery({
    variables: { blockId }
  });

  useEffect(() => {
    if (blockId) {
      getMentorBlockOwner();
    }
  }, [blockId]);

  const mentorBlockData = {
    title: data?.mentorBlock?.title ?? '',
    description: data?.mentorBlock?.description ?? '',
    stack: data?.mentorBlock?.stack ?? [],
    isGroup: data?.mentorBlock?.isGroup ?? false,
    mentors: data?.mentorBlock?.mentors ?? [],
    ownerId: (blockId ? data?.mentorBlock?.owner?._id : profile._id) || '',
    startDate: data?.mentorBlock?.startDate ?? '',
    endDate: data?.mentorBlock?.endDate ?? ''
  };

  const tabChangeHandle = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return {
    loading,
    mentorBlockData,
    tabChangeHandle,
    tabIndex,
    blockId
  };
};
