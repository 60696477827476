import React from 'react';
import { Button } from '@mui/material';
import { TaskAnswersListView } from '../../../../task/taskAnswersListView/TaskAnswersListView';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { Maybe, Task } from '../../../../../graphql/generated';
import { TaskView } from '../../../../task/taskView/TaskView';

interface MentorTaskPageProps {
  task: Maybe<Task>;
  isMentor: boolean;
}
const MentorTaskPage: React.FC<MentorTaskPageProps> = ({ isMentor, task }) => {
  const { push } = useHistory();
  const { blockId } = useParams<{ blockId: string }>();
  const handleBack = () => {
    push(`/mentorium/${blockId}`);
  };

  return (
    <div>
      <Button onClick={handleBack}>Back to main page</Button>
      <TaskView task={task} />
      <TaskAnswersListView isMentor={isMentor} />
    </div>
  );
};

export default MentorTaskPage;
