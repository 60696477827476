import { styled } from '@mui/material';

export const UserLoginErrorStyled = styled('div')`
  margin-top: 1rem;
`;

export const ForgotPasswordRowStyled = styled('div')`
  margin-top: 1rem;
  text-align: center;
`;
