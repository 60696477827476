import { RouteComponentProps } from 'react-router-dom';

interface IParams {
  page: number;
  limit: number;
  history: RouteComponentProps['history'];
  setOffset?: (offset: number) => void;
  setPage?: (page: number) => void;
  searchValue?: string;
}

//Use setOffset and setPage params only if pagination of list can't be controller without own ofset/page state!!!
export const Paginate = {
  onChangePage: ({ page, limit, history, setOffset, setPage, searchValue = '' }: IParams) => {
    const curOffset = page <= 1 ? 0 : (page - 1) * limit;
    if (setOffset) {
      setOffset(curOffset);
    }
    if (setPage) {
      setPage(page);
    }

    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set('p', String(page));
    searchParams.set('ofs', String(curOffset));
    if (searchValue) {
      searchParams.set('search', String(searchValue));
    }
    history.push(`${history.location.pathname}?${searchParams.toString()}`);
  }
};
