import React from 'react';
import { Link, List } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { CommentItem } from '../commentItem/CommentItem';
import CommentEditForm from '../commentEditForm/CommentEditForm';
import { useCommentListController } from './CommentList.controller';
import { RootContainerStyled } from './CommentList.styles';

interface CommentListProps {
  parentId: string | undefined | null;
  show: boolean;
}

const CommentList: React.FC<CommentListProps> = ({ parentId, show }) => {
  if (!show || !parentId) return null;
  const { comments, loadMoreComments } = useCommentListController({ parentId });

  return (
    <RootContainerStyled>
      <CommentEditForm parentId={parentId} isReply={false} />
      <List>
        {comments?.list.map(
          comment => comment && <CommentItem key={comment._id} comment={comment} parentId={parentId} />
        )}
      </List>
      <CircularProgress hidden={!!comments} />
      <Link component="button" onClick={loadMoreComments}>
        {comments?.hasNext && 'Load more...'}
      </Link>
    </RootContainerStyled>
  );
};

export default CommentList;
