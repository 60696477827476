import React from 'react';
import { Avatar } from '@mui/material';
import { RootStyled, LinkStyled, Name } from './UserTile.styles';

interface UserCardProps {
  name: string;
  userId: string;
  imageUrl: string;
  sizePxs?: number;
  fontSizePxs?: number;
}

export const UserTile: React.FC<UserCardProps> = ({ name, userId, imageUrl, sizePxs = 80, fontSizePxs = 10 }) => {
  const avatarSize = sizePxs - 24 - fontSizePxs;
  return (
    <RootStyled sizePxs={sizePxs}>
      <Avatar src={imageUrl} alt={name} sx={{ width: avatarSize, height: avatarSize }} />
      <Name fontSize={fontSizePxs} noWrap={true} variant="subtitle2">
        <LinkStyled to={`/user/${userId}`}>{name}</LinkStyled>
      </Name>
    </RootStyled>
  );
};
