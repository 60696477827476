import { Stack, styled } from '@mui/material';

export const MainLayoutRoot = styled('div')``;

export const GeneralContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.background.default};
  flex-direction: row;
  top: 72px;
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
`;

export const ContentContainer = styled(Stack)`
  width: 100%;
  overflow: auto;
  padding: 1rem;
  position: relative;
`;
