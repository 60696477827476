import React from 'react';
import {
  bold,
  code,
  codeBlock,
  divider,
  hr,
  image,
  italic,
  link,
  orderedListCommand,
  quote,
  strikethrough,
  title,
  unorderedListCommand
} from '@uiw/react-md-editor';
import { Button } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ContentErrorStyled } from '../../taskEditForm/TaskEditForm.styles';
import { useTaskAnswerFormController } from './TaskAnswerForm.controller';
import { ButtonsGroupStyled, RootStyled } from './TaskAnswerForm.styles';
import MarkdownEditor from '../../../sharedComponents/markdown/MarkdownEditor';

interface TaskAnswerFormProps {
  onSubmit: (content: string) => void;
  onClose: () => void;
}

export const TaskAnswerForm: React.FC<TaskAnswerFormProps> = ({ onSubmit, onClose }) => {
  const { errors, control, SUBMIT_BUTTON_TEXT, CLOSE_BUTTON_TEXT, submitAnswerHandle } = useTaskAnswerFormController({
    onSubmit
  });

  return (
    <RootStyled>
      <Controller
        name="content"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <MarkdownEditor
              commands={[
                title,
                bold,
                italic,
                strikethrough,
                divider,
                link,
                image,
                divider,
                code,
                codeBlock,
                quote,
                hr,
                divider,
                orderedListCommand,
                unorderedListCommand
              ]}
              preview="edit"
              height={300}
              value={value || ''}
              onChange={values => onChange(values)}
            />
          );
        }}
      />
      {errors.content && <ContentErrorStyled>{errors.content.message}</ContentErrorStyled>}
      <ButtonsGroupStyled>
        <Button onClick={onClose}>{CLOSE_BUTTON_TEXT}</Button>
        <Button variant="contained" onClick={submitAnswerHandle}>
          {SUBMIT_BUTTON_TEXT}
        </Button>
      </ButtonsGroupStyled>
    </RootStyled>
  );
};
