import { Alert, Button, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useUserResendActivationLinkMutation } from '../../graphql/generated';
import { Validate } from '../../helpers/validation';
import { PaperStyled, TextFieldStyled } from './Account.styles';
import RedirectToLogin from './RedirectToLogin';

const ResendActivationLink = () => {
  const [formState, setFormState] = useState('');
  const [emailValidationError, setEmailValidationError] = useState('');
  const [resendActivationLink, { data, loading }] = useUserResendActivationLinkMutation();

  const onFinish = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (Validate.email.check(formState)) {
      await resendActivationLink({
        variables: {
          email: formState
        }
      });
    } else {
      setEmailValidationError(Validate.email.error);
    }
  };

  return (
    <PaperStyled>
      <h3>Resend activation link</h3>
      <form onSubmit={onFinish}>
        <TextFieldStyled
          id="email"
          required={true}
          size="small"
          label="Email"
          fullWidth={true}
          autoFocus={true}
          value={formState}
          onChange={e => {
            setEmailValidationError('');
            setFormState(e.target.value);
          }}
          onBlur={e => {
            if (!Validate.email.check(e.target.value)) setEmailValidationError(Validate.email.error);
          }}
          helperText={emailValidationError}
          error={!!emailValidationError}
        />

        <Button
          type="submit"
          variant="contained"
          startIcon={loading && <CircularProgress size={18} />}
          disabled={!!emailValidationError || loading}
        >
          Send
        </Button>
      </form>

      {data && <Alert severity="success">New Activation link was send</Alert>}

      <RedirectToLogin />
    </PaperStyled>
  );
};

export default ResendActivationLink;
