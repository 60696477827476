import React from 'react';
import { LessonView } from '../lessonView/LessonView';
import { useLessonPageController } from './LessonPage.controller';
import { ArrowBack, Delete, Edit } from '@material-ui/icons';
import { Button } from '@mui/material';
import { ActionButtons, ButtonsContainer, ContentContainer, RootStyled } from './LessonPage.styles';

export const LessonPage = () => {
  const { lesson, handleBack, handleDelete, handleEdit } = useLessonPageController();

  return (
    <RootStyled>
      <ButtonsContainer>
        <Button onClick={handleBack}>
          <ArrowBack />
        </Button>
        <ActionButtons>
          <Button startIcon={<Edit />} variant="outlined" onClick={handleEdit}>
            Edit
          </Button>
          <Button startIcon={<Delete />} variant="outlined" onClick={handleDelete}>
            Delete
          </Button>
        </ActionButtons>
      </ButtonsContainer>
      <ContentContainer>
        <LessonView lesson={lesson} />
      </ContentContainer>
    </RootStyled>
  );
};
