import { Stack, styled } from '@mui/material';

export const RootStyled = styled(Stack)`
  gap: 1rem;
`;
export const ButtonsGroupStyled = styled(Stack)`
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
`;
