import { DependencyList, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { globalVar } from '../cache';
import moment from 'moment';

//useQueryParams Parsing search params from path
//Example: the url contains next search params /problems?p=0&ofs=0&column=Problem%20name&value=test
// it will return
// {
//   p: '0',
//   ofs: '0',
//   column: 'Problem name',
//   value: 'test'
// }
export const useQueryParams = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const params: { [key: string]: string } = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

// useDidMountEffect will trigger Effect only when dependency will update,
// but never when component mounted first time\
// Example:
// useDidMountEffect(() => {
//   Do something only when deps be updated! And not in first mount component.
// },[deps])
export const useDidMountEffect = (func: () => void, deps: DependencyList) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps || []);
};

export const useProfile = () => {
  const { profile } = useReactiveVar(globalVar);
  return profile;
};

export const useConvertDateToString = (date: object) => {
  if (Object.keys(date).length === 0) {
    return '';
  } else {
    return date.valueOf().toString();
  }
};

export interface EntityStatus {
  startDate: string;
  endDate: string;
}

export const useEntityStatus = (args: EntityStatus) => {
  const { startDate: startDateInString, endDate: endDateInString } = args;

  const dateNow = Date.now();
  const startDate = startDateInString ? moment(+startDateInString) : null;
  const endDate = endDateInString ? moment(+endDateInString) : null;
  const isValidStartDate = startDate?.isValid();
  const startsInMonth = startDate?.diff(dateNow, 'month') ?? 0;
  const startsInDays = startDate?.diff(dateNow, 'days') ?? 0;
  const startsInHours = startDate?.diff(dateNow, 'hours') ?? 0;
  const startsInMinutes = startDate?.diff(dateNow, 'minutes') ?? 0;
  const isStartDatePast = moment().isAfter(startDate);
  const isBetween = moment().isBetween(startDate, endDate);
  const isEndDateNotPast = moment().isBefore(endDate);
  const isFinished = moment().isAfter(endDate);

  const getStatus = () => {
    if (isValidStartDate) {
      if (startsInMonth > 0) return `starting in ${startsInMonth} month`;
      if (startsInDays > 0) return `starting in ${startsInDays} days`;
      if (startsInHours > 0) return `starting in ${startsInHours} hours`;
      if (startsInMinutes > 0) return `starting in ${startsInMinutes} minutes`;
      if (startsInMinutes === 0) return `starting in a few seconds`;
      if (isBetween) return `ongoing`;
      if (isFinished) return 'finished';
    }
    return 'none';
  };

  return {
    timeRangeStatus: getStatus(),
    isInvitable: !isStartDatePast,
    startsInDays,
    startsInHours,
    startsInMinutes,
    isEndDateNotPast,
    isBetween
  };
};
