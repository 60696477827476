import { Paper, Stack, Pagination } from '@mui/material';
import { styled } from '@mui/system';

export const RootStyled = styled(Stack)`
  gap: 1rem;
`;

export const ListStyled = styled(Paper)`
  overflow: hidden;
`;

export const PaginationStyled = styled(Pagination)`
  align-self: center;
`;
