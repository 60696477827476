import { CircularProgress, Paper, Stack, styled } from '@mui/material';

export const RootStyled = styled(Paper)`
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const FormStyled = styled('form')`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const LoaderStyled = styled(CircularProgress)`
  display: table;
  margin: 0 auto;
`;

export const FooterStyled = styled(Stack)`
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 3rem;
`;
