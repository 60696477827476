import { styled } from '@mui/material';

export const RootContainer = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
