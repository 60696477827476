import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Pagination, Typography } from '@mui/material';
import { ExpandLess } from '@material-ui/icons';
import { useTasksListController } from './TasksList.controller';
import { DetailsContainer, RootStyled, StyledLink } from './TasksList.styles';

const TasksList = () => {
  const { tasks, loading, totalPages, page, onPageChange } = useTasksListController();

  if (loading) return <CircularProgress />;

  return (
    <RootStyled>
      <div>
        {tasks.map(task => (
          <Accordion key={task?._id}>
            <AccordionSummary expandIcon={<ExpandLess />}>
              <Typography>{task?.title}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <DetailsContainer>
                <Typography variant="caption">
                  {task?.content}
                  <br />
                </Typography>
              </DetailsContainer>
              <StyledLink to={`/manageContent/task/${task?._id}`}>View more</StyledLink>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <Pagination count={totalPages} variant="outlined" shape="rounded" page={page} onChange={onPageChange} />
    </RootStyled>
  );
};

export default TasksList;
