import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';

interface DeleteDialogProps {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: () => void;
  submitButtonText?: string;
  message?: string;
  isSubmitLoading?: boolean;
  title?: string;
}
const DeleteDialog: React.FC<DeleteDialogProps> = ({
  onClose,
  isOpen,
  onSubmit,
  isSubmitLoading,
  submitButtonText = 'Delete',
  title = 'Delete Confirmation',
  message
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button disabled={isSubmitLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={onSubmit}
          autoFocus
          disabled={isSubmitLoading}
          startIcon={isSubmitLoading ? <CircularProgress size={15} /> : null}
        >
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
