import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  CommentsGetDocument,
  useCommentCreateMutation,
  useCommentUpdateMutation,
  usePublicationCommentsCountGetLazyQuery
} from '../../../graphql/generated';
import { REGEX, Validate } from '../../../helpers/validation';
import { useEffect } from 'react';
import { CommentEditFormProps } from './CommentEditForm';
import { useRouteMatch } from 'react-router-dom';

interface Input {
  value: string;
}

export const useCommentEditController = ({
  parentId,
  value = '',
  isEdit,
  onClose,
  commentId,
  parentComponentId
}: CommentEditFormProps) => {
  const [commentCreate, { loading: createLoading }] = useCommentCreateMutation();
  const [commentUpdate, { loading: updateLoading }] = useCommentUpdateMutation();
  const [getPublicationCommentsCount] = usePublicationCommentsCountGetLazyQuery();
  const isPublicationsPage = !!useRouteMatch(['/publications', '/publication/:pubId'])?.isExact;

  const validationSchema = yup
    .object({
      value: yup
        .string()
        .trim()
        .matches(REGEX.message, { excludeEmptyString: true, message: Validate.message.error })
        .required('Field is required')
    })
    .required();

  const form = useForm<Input>({
    defaultValues: {
      value: ''
    },
    resolver: yupResolver(validationSchema),
    mode: 'onTouched'
  });

  useEffect(() => {
    form.setValue('value', value + form.getValues('value'));
  }, [value]);

  const handleSubmit = form.handleSubmit(async ({ value }) => {
    if (parentId) {
      await commentCreate({
        variables: { parentId, content: value },
        refetchQueries: [CommentsGetDocument],
        awaitRefetchQueries: true,
        onCompleted: () => {
          if (isPublicationsPage) {
            getPublicationCommentsCount({
              //if there is topLevelParentId means comment created from Reply otherwise it is a new comment
              variables: { pubId: (parentComponentId && parentComponentId) || parentId },
              fetchPolicy: 'network-only'
            });
          }
        }
      });
    }
    if (commentId) {
      commentUpdate({
        variables: { commentId, content: value },
        refetchQueries: [CommentsGetDocument],
        awaitRefetchQueries: true
      });
    }
    form.setValue('value', '');
    onClose && onClose();
  });

  const handleClear = () => {
    form.resetField('value');
    form.setFocus('value');
  };

  return {
    ...form,
    loading: createLoading || updateLoading,
    handleSubmit,
    handleClear,
    submitButtonText: isEdit ? 'Save' : 'Send'
  };
};
