import React from 'react';
import { AppRoutes } from '../../../helpers/constants';
import { Badge, Menu as MenuPopup } from '@mui/material';
import { NotificationsOutlined } from '@material-ui/icons';
import { TextContainer, ToggleContainer } from './NotificationsToggle.styles';
import { useNotificationToggleController } from './NotificationToggle.controller';

export const NotificationToggle = () => {
  const { anchorEl, popupOpenHandle, popupCloseHandle, notificationsCount, text } = useNotificationToggleController();

  return (
    <div>
      <ToggleContainer
        id="notification-toggle"
        onMouseLeave={popupCloseHandle}
        onMouseEnter={popupOpenHandle}
        to={AppRoutes.Notifications}
      >
        <Badge badgeContent={notificationsCount} color="error" variant="standard">
          <NotificationsOutlined />
        </Badge>
      </ToggleContainer>
      {anchorEl && (
        <MenuPopup
          id="notification-toggle-popup"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          sx={{
            pointerEvents: 'none',
            marginTop: 2
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <TextContainer>{text}</TextContainer>
        </MenuPopup>
      )}
    </div>
  );
};
