import { Validate } from './validation';

export default (query: any) => {
  const queryName = query.definitions[0].selectionSet.selections[0].name.value;

  const args: { argName: string; argValue: string } = {
    argName: '',
    argValue: ''
  };
  const unparsedArgs = query.definitions[0].selectionSet.selections[0].arguments;

  for (const { name, value } of unparsedArgs) {
    if (!value.fields) {
      args.argName = name.value;
      args.argValue = value.value;
    }
  }

  return {
    queryName,
    // arguments: isValidId && args,
    entityId: Validate.mongoId.check(args.argValue) ? args.argValue : null
  };
};
