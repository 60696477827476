import { AppBar, IconButton, styled, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';

export const AppBarStyled = styled(AppBar)`
  position: fixed;
  flex-grow: 1;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  box-shadow: unset;
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.primary.main};
`;

export const ToolbarStyled = styled(Toolbar)`
  gap: 1rem;
  padding: 16px 24px;
`;

export const LogoStyled = styled(Link)`
  img {
    width: 40px;
    height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    display: none;
  }
`;
export const IconButtonStyled = styled(IconButton)`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: none;
  }
`;
