export interface UserInitialsProps {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email?: string | null | undefined;
}

export const userFullName = ({ firstName, lastName, email }: UserInitialsProps) => {
  return firstName && lastName ? `${firstName} ${lastName}` : email?.split('@')[0];
};

export const userInitials = ({ firstName, lastName, email }: UserInitialsProps) => {
  let initials = '?';
  if (!firstName && !lastName && email) initials = email[0];
  else if (firstName && !lastName) initials = firstName[0];
  else if (!firstName && lastName) initials = lastName[0];
  else if (firstName && lastName) initials = `${firstName[0]}${lastName[0]}`;
  return initials.toUpperCase();
};

export const getPageName = (pathname: string) => pathname.split('/')[1];
