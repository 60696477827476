import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useProfile, useQueryParams } from '../../../helpers/customHooks';
import { useHistory } from 'react-router-dom';
import { useNotificationsGetByOwnerIdQuery } from '../../../graphql/generated';
import { useMediaQuery } from '@mui/material';
import { Paginate } from '../../../helpers/pagination';

export const useNotificationsListController = () => {
  const dateNow = new Date();
  localStorage.setItem('notificationsDateSeen', dateNow.toISOString());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const profile = useProfile();
  const limit = isMobile ? 10 : 20;
  const history = useHistory();
  const { p, ofs, search } = useQueryParams(history.location.search);
  const offset = ofs ? Number(ofs) : 0;
  const page = p ? Number(p) : 1;
  const { data } = useNotificationsGetByOwnerIdQuery({
    variables: { ownerId: profile._id, offset: offset, limit },
    fetchPolicy: 'cache-and-network'
  });
  const list = data?.notificationsGetByOwnerId?.list ?? [];
  const totalPages = data?.notificationsGetByOwnerId?.totalPages ?? 0;
  const isListEmpty = !list.length;
  const emptyListMessage = { top: 'No new notifications yet!', sub: 'Check back soon for updates.' };

  const onPageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    Paginate.onChangePage({ page, limit, history, searchValue: search });
  };

  return {
    data,
    list,
    isListEmpty,
    isMobile,
    totalPages,
    page,
    onPageChange,
    emptyListMessage
  };
};
