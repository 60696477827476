import { Stack, styled } from '@mui/material';

export const RootStyled = styled(Stack)`
  width: auto;
`;

export const TopButtonsContainer = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
`;

export const CommentsContainer = styled(Stack)`
  margin-top: 2rem;
`;
