import React from 'react';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import { RootStyled } from './ProblemsList.styles';
import { EmptyListMessageStyled } from '../../UserPage.styles';
import { useProblemListController } from './ProblemList.controller';
import { ProblemsListItems } from './ProblemsListItems';

export const ProblemsList = () => {
  const { loading, option, OPTION_CONTENT, selectedTab, handleChangeTab } = useProblemListController();

  const component = () => {
    if (!option.list.length)
      return <EmptyListMessageStyled variant="body1">{option.emptyState}</EmptyListMessageStyled>;
    return <ProblemsListItems option={option} />;
  };

  return (
    <RootStyled>
      <Tabs value={selectedTab} onChange={handleChangeTab}>
        {OPTION_CONTENT.map((el, index) => (
          <Tab key={index} label={el.title} />
        ))}
      </Tabs>
      {loading ? <CircularProgress /> : component()}
    </RootStyled>
  );
};
