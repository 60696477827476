import { Alert, CircularProgress } from '@mui/material';
import { FC } from 'react';
import { AlertStyled, PaperStyled } from '../Account.styles';
import RedirectToLogin from '../RedirectToLogin';
import { useActivationController } from './Activation.controller';

const Activation: FC = () => {
  const { loading, error } = useActivationController();
  if (loading) return <CircularProgress />;

  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <PaperStyled>
      <AlertStyled severity="success">Activation Successful!</AlertStyled>
      <RedirectToLogin />
    </PaperStyled>
  );
};

export default Activation;
