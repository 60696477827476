import { useHistory } from 'react-router';
import React from 'react';
import { AppRoutes } from '../../helpers/constants';

export const useManageContentController = () => {
  const history = useHistory();
  const regex = new RegExp('manageContent/([^/]+)');
  const [, activeTab] = history.location.pathname.match(regex) || [];
  const TABS = ['lessons', 'tasks'];
  const isTabLessons = activeTab === 'lessons' || activeTab === undefined;
  const isTabTasks = activeTab === 'tasks';

  const tabChangeHandle = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === 'lessons') history.push(AppRoutes.ManageContentLessons);
    if (newValue === 'tasks') history.push(AppRoutes.ManageContentTasks);
  };

  const createLessonHandle = () => {
    history.push(isTabLessons ? AppRoutes.LessonCreate : AppRoutes.TaskCreate);
  };

  return {
    TABS,
    activeTab,
    tabChangeHandle,
    isTabLessons,
    isTabTasks,
    createLessonHandle
  };
};
