import { RootStyled, RowStyled, UserStackStyled } from './PeopleList.styles';
import { LinkStyled } from '../../UserPage.styles';
import { Avatar, Divider, Typography } from '@mui/material';
import { userInitials } from '../../../../../helpers/simplifiedData';
import { FriendCtaButton } from '../../../friendCtaButton/FriendCtaButton';
import React from 'react';
import { OptionTypes } from '../../UserPage.controller';
import { Maybe } from 'yup/es/types';
import { User } from '../../../../../graphql/generated';

interface OverridedOptionTypes extends Omit<OptionTypes, 'list'> {
  list: Maybe<User>[];
}
interface PeopleListProps {
  option: OverridedOptionTypes;
}

export const PeopleListItems: React.FC<PeopleListProps> = ({ option }) => {
  const { linkDirectory, list } = option;
  return (
    <RootStyled>
      {list?.map(user => {
        const fullName = `${user?.firstName} ${user?.lastName}`.trim();
        const link = `/${linkDirectory}/${user?._id}`;
        return (
          <>
            <RowStyled key={user?._id}>
              <UserStackStyled>
                <LinkStyled to={link}>
                  <Avatar src={user?.image || ''}>
                    {userInitials({
                      firstName: user?.firstName,
                      lastName: user?.lastName
                    })}
                  </Avatar>
                </LinkStyled>
                <LinkStyled to={link}>
                  <Typography variant="subtitle1" title={fullName}>
                    {fullName}
                  </Typography>
                  <Typography variant="body1" title={user?.jobTitle || 'Unemployed'}>
                    {user?.jobTitle || 'Unemployed'}
                  </Typography>
                </LinkStyled>
              </UserStackStyled>
              <FriendCtaButton variant="outlined" inviteeId={user?._id || ''} />
            </RowStyled>
            <Divider variant="fullWidth" />
          </>
        );
      })}
    </RootStyled>
  );
};
