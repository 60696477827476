import { useUserGetQuery } from '../../../../../graphql/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { OptionTypes } from '../../UserPage.controller';
import { useParams } from 'react-router-dom';

export const usePeopleListController = () => {
  const { userId }: { userId: string } = useParams();
  const { loading, data: userData } = useUserGetQuery({
    variables: { userId },
    fetchPolicy: 'network-only'
  });

  const pageData = useMemo(() => {
    return {
      friendInviteReceived: userData?.user?.friendInviteReceived ?? [],
      friendInviteSent: userData?.user?.friendInviteSent ?? [],
      friendList: userData?.user?.friendList ?? []
    };
  }, [userData]);

  const OPTION_CONTENT: OptionTypes[] = [
    {
      title: 'My Friends',
      list: pageData.friendList,
      linkDirectory: 'user',
      buttonName: '',
      onClick: () => null,
      emptyState: 'You have no friends'
    },
    {
      title: 'Invites Received',
      list: pageData.friendInviteReceived,
      linkDirectory: 'user',
      buttonName: '',
      onClick: () => null,
      emptyState: 'Nobody invited you to friend list yet'
    },
    {
      title: 'Invites Sent',
      list: pageData.friendInviteSent,
      linkDirectory: 'user',
      buttonName: '',
      onClick: () => null,
      emptyState: 'You did not invite anyone'
    }
  ];

  const [option, setOption] = useState<any>(OPTION_CONTENT[0]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setOption(OPTION_CONTENT.find(opt => opt.title === option.title));
  }, [userData]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setOption(OPTION_CONTENT[newValue]);
  };

  return {
    loading,
    option,
    OPTION_CONTENT,
    selectedTab,
    handleChangeTab
  };
};
