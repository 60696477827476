import React, { useEffect, useState } from 'react';
import { ListItem, ListItemText, ListItemAvatar, Checkbox, Avatar, Button, Typography, Stack } from '@mui/material';
import { User } from '../../../../../graphql/generated';
import { ListHeader, ListStyled } from './UserListSelectable.styles';

interface UserListSelectableProps {
  list: Array<any>;
  onRejectClick?: (usersIdList: Array<string>) => void;
  onAcceptClick?: (usersIdList: Array<string>) => void;
  rejectButtonName?: string;
  acceptButtonName?: string;
}
export const UserListSelectable: React.FC<UserListSelectableProps> = props => {
  const { list, onRejectClick, onAcceptClick, rejectButtonName, acceptButtonName } = props;
  const [checkableList, setCheckableList] = useState<Array<User & { checked: boolean }>>([]);
  const onlyCheckedIdsList = checkableList.filter(user => user.checked).map(item => item._id);
  const numberOfChecked = onlyCheckedIdsList.length;

  useEffect(() => {
    const checkableListInitial = list.map(user => ({ ...user, checked: false }));
    setCheckableList(checkableListInitial);
  }, [list]);

  const handleToggle = (labelId: string) => () => {
    const updatedList = checkableList.map(user => {
      if (user._id == labelId) {
        return { ...user, checked: !user.checked };
      } else {
        return user;
      }
    });
    setCheckableList(updatedList);
  };
  const handleCheckAll = () => {
    const updatedList = checkableList.map(user => ({
      ...user,
      checked: !(numberOfChecked === checkableList.length)
    }));
    setCheckableList(updatedList);
  };

  return list.length ? (
    <ListStyled
      subheader={
        <ListHeader>
          <div>
            {acceptButtonName && !!numberOfChecked && (
              <Button onClick={() => (onAcceptClick ? onAcceptClick(onlyCheckedIdsList) : null)} color="secondary">
                {acceptButtonName}
              </Button>
            )}
            {rejectButtonName && !!numberOfChecked && (
              <Button onClick={() => (onRejectClick ? onRejectClick(onlyCheckedIdsList) : null)} color="error">
                {rejectButtonName}
              </Button>
            )}
          </div>
          <Stack alignItems="flex-end">
            <Typography variant="body2">Check All</Typography>
            <Checkbox
              onChange={handleCheckAll}
              edge="end"
              indeterminate={numberOfChecked < checkableList.length && numberOfChecked !== 0}
              checked={numberOfChecked === checkableList.length && numberOfChecked !== 0}
            />
          </Stack>
        </ListHeader>
      }
      disablePadding
      dense
    >
      {checkableList.map(user => {
        const labelId = user._id;
        return (
          <ListItem
            key={labelId}
            secondaryAction={<Checkbox edge="end" onChange={handleToggle(labelId)} checked={user.checked} />}
          >
            <ListItemAvatar>
              <Avatar src={user.image || ''} />
            </ListItemAvatar>
            <ListItemText id={labelId} primary={`${user.firstName} ${user.lastName}`} />
          </ListItem>
        );
      })}
    </ListStyled>
  ) : (
    <ListStyled>Empty list</ListStyled>
  );
};
