import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert, Box, Button, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { FC } from 'react';
import { Validate } from '../../../helpers/validation';
import { CenteredDivStyled, PaperStyled, TextFieldStyled } from '../Account.styles';
import PasswordValidaitonRender from '../passwordValidation/PasswordValidationRender';
import RedirectToLogin from '../RedirectToLogin';
import { useSignUpFormController } from './SignUpPage.controller';
import { HaveAnAccountStyled, PaperRegistrationSuccessStyled } from './SignUpPage.styles';

export const SignUpPage: FC = () => {
  const {
    data,
    onFinish,
    formValidationError,
    setFormValidationError,
    setFormState,
    formState,
    showPassword,
    handleClickShowPassword,
    loading,
    error,
    listOfChecks,
    strength
  } = useSignUpFormController();

  if (data) {
    return (
      <>
        <PaperRegistrationSuccessStyled>
          <Box>
            <CenteredDivStyled>
              <div>Registration Successful!</div>
              <div>An activation link has been sent to your email.</div>
            </CenteredDivStyled>
            <RedirectToLogin />
          </Box>
        </PaperRegistrationSuccessStyled>
      </>
    );
  }

  return (
    <>
      <PaperStyled>
        <h3>Sign Up</h3>
        <form onSubmit={onFinish}>
          <TextFieldStyled
            id="email"
            required={true}
            size="small"
            label="Email"
            fullWidth={true}
            autoFocus={true}
            onChange={e => {
              setFormValidationError({ ...formValidationError, email: '' });
              setFormState({ ...formState, email: e.target.value });
            }}
            onBlur={e => {
              if (!Validate.email.check(e.target.value))
                setFormValidationError({ ...formValidationError, email: Validate.email.error });
            }}
            helperText={formValidationError.email}
            error={!!formValidationError.email}
          />
          <TextFieldStyled
            id="password"
            required={true}
            size="small"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth={true}
            onChange={e => {
              setFormValidationError({ ...formValidationError, password: '' });
              setFormState({ ...formState, password: e.target.value });
            }}
            onBlur={e => {
              if (!Validate.password.check(e.target.value))
                setFormValidationError({ ...formValidationError, password: Validate.password.error });
            }}
            helperText={formValidationError.password}
            error={!!formValidationError.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {formState.password && (
            <PasswordValidaitonRender listOfChecks={listOfChecks} percentageOfChecksPassed={strength} />
          )}

          <Button
            type="submit"
            variant="contained"
            startIcon={loading && <CircularProgress size={18} />}
            disabled={!!formValidationError.email || !!formValidationError.password || loading}
          >
            Sign Up
          </Button>
        </form>

        {error && <Alert severity="error">{error?.message.split(': ')[1]}</Alert>}

        <HaveAnAccountStyled>
          Already have an account? <RedirectToLogin inline prefix="" />
        </HaveAnAccountStyled>
      </PaperStyled>
    </>
  );
};

export default SignUpPage;
