import React from 'react';
import { PublicationCard } from './publicationCard/PublicationCard';
import { Button, CircularProgress, Pagination } from '@mui/material';
import './Publication.css';
import { Link } from 'react-router-dom';
import { PaginationStyled } from '../user/usersList/UsersList.styles';
import SearchHeader from '../globalSearch/searchHeader/SearchHeader';
import { usePublicationsController } from './Publications.controller';
import { NoResults } from '../globalSearch/noResults/NoResults';

const Publications: React.FC = () => {
  const {
    isLoading,
    publicationsList,
    userStarredPublications,
    search,
    hasNext,
    fetchMore,
    totalPages,
    page,
    onPageChange
  } = usePublicationsController();

  if (publicationsList === null) {
    return (
      <div className="justify-content-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {search && <SearchHeader />}
      {!publicationsList.length ? (
        <NoResults />
      ) : (
        <div className="pb-4 publication-list">
          <div className="mb-3 d-flex justify-content-end">
            <Link to="/publications/create" className="btn">
              <Button variant="outlined" size="small">
                Add Publication
              </Button>
            </Link>
          </div>

          {publicationsList.map(publication => (
            <PublicationCard
              key={publication?._id}
              publication={publication}
              starred={userStarredPublications.some(el => el?._id === publication?._id)}
            />
          ))}
          {!search &&
            (isLoading ? (
              <CircularProgress size={30} />
            ) : (
              <div
                hidden={!hasNext}
                className="btn-link"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  fetchMore({
                    variables: {
                      value: search,
                      offset: publicationsList.length
                    }
                  })
                }
              >
                Load more...
              </div>
            ))}

          {!!search && (
            <PaginationStyled>
              <Pagination
                count={totalPages || 0}
                variant="outlined"
                shape="rounded"
                page={page}
                onChange={onPageChange}
              />
            </PaginationStyled>
          )}
        </div>
      )}
    </>
  );
};

export default Publications;
