import { useProblemsGetByUserIdQuery } from '../../../../../graphql/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { OptionTypes } from '../../UserPage.controller';
import { useParams } from 'react-router-dom';

export const useProblemListController = () => {
  const { userId }: { userId: string } = useParams();
  const { loading, data } = useProblemsGetByUserIdQuery({ variables: { userId } });

  const pageData = useMemo(() => {
    return {
      problems: data?.problemsGetByUserId?.list ?? []
    };
  }, [data]);

  const OPTION_CONTENT: OptionTypes[] = [
    {
      title: 'My Problems',
      list: pageData.problems,
      linkDirectory: 'problem',
      buttonName: '',
      onClick: () => null,
      emptyState: 'You have no problems'
    }
  ];

  const [option, setOption] = useState<any>(OPTION_CONTENT[0]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setOption(OPTION_CONTENT.find(opt => opt.title === option.title));
  }, [data]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setOption(OPTION_CONTENT[newValue]);
  };

  return {
    loading,
    option,
    OPTION_CONTENT,
    selectedTab,
    handleChangeTab
  };
};
