import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  usePublicationSearchLazyQuery,
  usePublicationsGetLazyQuery,
  useUserStarredPublicationsGetQuery
} from '../../graphql/generated';
import { useProfile, useQueryParams } from '../../helpers/customHooks';
import { Paginate } from '../../helpers/pagination';

export const usePublicationsController = () => {
  const history = useHistory();
  const limit = 10;
  const { p, ofs, search } = useQueryParams(history.location.search);
  const offset = ofs ? Number(ofs) : 0;
  const page = p ? Number(p) : 1;
  const profileId = useProfile()._id;

  const { data: currentUserData } = useUserStarredPublicationsGetQuery({
    variables: { userId: profileId },
    fetchPolicy: 'cache-and-network',
    skip: !profileId
  });

  const [publicationsGet, { loading: isLoadingGet, data: dataGet, fetchMore }] = usePublicationsGetLazyQuery({
    variables: {
      offset,
      limit
    },
    notifyOnNetworkStatusChange: true
  });
  const [publicationsSearch, { loading: isLoadingSearch, data: dataSearch }] = usePublicationSearchLazyQuery();
  const userStarredPublications = currentUserData?.user?.starredPublications ?? [];
  const isLoading = isLoadingGet || isLoadingSearch;

  const { hasNext, totalPages, publicationsList } = useMemo(() => {
    let hasNext = dataGet?.publications?.hasNext ?? false;
    let totalPages = dataGet?.publications?.totalPages ?? 0;
    let publicationsList = dataGet?.publications?.list ?? null;

    if (search) {
      hasNext = dataSearch?.publicationSearch?.hasNext ?? false;
      totalPages = dataSearch?.publicationSearch?.totalPages ?? 0;
      publicationsList = dataSearch?.publicationSearch?.list ?? [];
    }
    return {
      hasNext,
      totalPages,
      publicationsList
    };
  }, [dataGet, dataSearch, search]);

  useEffect(() => {
    if (search) {
      publicationsSearch({
        variables: {
          value: search,
          offset,
          limit
        }
      });
    } else {
      publicationsGet();
    }
  }, [search, page]);

  const onPageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    Paginate.onChangePage({ page, limit, history, searchValue: search });
  };

  return {
    isLoading,
    publicationsList,
    userStarredPublications,
    search,
    hasNext,
    fetchMore,
    totalPages,
    page,
    onPageChange
  };
};
