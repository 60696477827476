import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Pagination, Typography } from '@mui/material';
import { ExpandLess } from '@material-ui/icons';
import { useLessonsListController } from './LessonsList.controller';
import { DetailsContainer, RootStyled, StyledLink } from './LessonsList.styles';

const LessonsList: FC = () => {
  const { page, lessons, loading, totalPages, onPageChange } = useLessonsListController();

  if (loading) return <CircularProgress />;

  return (
    <RootStyled>
      <div>
        {lessons.map(lesson => (
          <Accordion key={lesson?._id}>
            <AccordionSummary expandIcon={<ExpandLess />}>
              <Typography>{lesson?.title}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <DetailsContainer>
                <Typography variant="caption">
                  {lesson?.content}
                  <br />
                </Typography>
              </DetailsContainer>
              <StyledLink to={`/manageContent/lesson/${lesson?._id}`}>View more</StyledLink>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <Pagination count={totalPages} variant="outlined" shape="rounded" page={page} onChange={onPageChange} />
    </RootStyled>
  );
};

export default LessonsList;
