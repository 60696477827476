import React from 'react';
import { Button, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Controller } from 'react-hook-form';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { useEditScheduledItemFormController } from './EditScheduledItemForm.controller';
import { EntityType, ScheduleSlot } from '../../../../graphql/generated';
import { ButtonsRowStyled, RowStyled, TimeframeRootStyled } from './EditScheduledItemForm.styles';

interface EditScheduledItemProps {
  scheduledItem?: ScheduleSlot;
  itemId: string;
  onCancel: () => void;
  itemEntityTypeName: EntityType;
}
export const EditScheduledItemForm: React.FC<EditScheduledItemProps> = ({
  scheduledItem,
  onCancel,
  itemId,
  itemEntityTypeName
}) => {
  const { control, addHandle, watchStartDate } = useEditScheduledItemFormController({
    scheduledItem,
    onCancel,
    itemId,
    itemEntityTypeName
  });
  return (
    <TimeframeRootStyled>
      <Typography variant="subtitle2">Set timeframe:</Typography>
      <RowStyled>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Controller
            name="startDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Stack>
                <DateTimePicker
                  {...field}
                  disablePast
                  label="Start Time"
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: !!error
                    }
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock
                  }}
                />
                <FormHelperText sx={{ height: 0 }} error>
                  {error?.message}
                </FormHelperText>
              </Stack>
            )}
          />

          <Controller
            name="endDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Stack>
                <DateTimePicker
                  {...field}
                  disablePast
                  label="End Time"
                  minDateTime={watchStartDate}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: !!error
                    }
                  }}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock
                  }}
                />
                <FormHelperText sx={{ height: 0 }} error>
                  {error?.message}
                </FormHelperText>
              </Stack>
            )}
          />
        </LocalizationProvider>
      </RowStyled>

      <Typography variant="subtitle2">Do you want to add video call link? (eg. Zoom, Google Meet)</Typography>
      <Controller
        control={control}
        name="streamLink"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="Stream Link"
            size="small"
            id="streamLink"
            value={value}
            onChange={values => onChange(values)}
            error={!!error?.message}
            helperText={error?.message}
          />
        )}
      />

      <ButtonsRowStyled>
        <Button size="large" onClick={addHandle} children={scheduledItem ? 'Save' : 'Add'} />
        <Button onClick={onCancel} children="Cancel" />
      </ButtonsRowStyled>
    </TimeframeRootStyled>
  );
};
