import React from 'react';
import { useProfile } from '../../../helpers/customHooks';
import { useNotificationsHasUnreadByOwnerIdQuery } from '../../../graphql/generated';

export const useNotificationToggleController = () => {
  const notificationsDateSeen = localStorage.getItem('notificationsDateSeen');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const profile = useProfile();
  const { data } = useNotificationsHasUnreadByOwnerIdQuery({
    variables: { ownerId: profile._id, dateSeen: notificationsDateSeen },
    pollInterval: 300000 //5 min
  });
  const notificationsCount = data?.notificationsHasUnreadByOwnerId ?? 0;
  const pluralism = notificationsCount > 1 ? 's' : '';

  const popupOpenHandle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const popupCloseHandle = () => {
    setAnchorEl(null);
  };

  const text = !notificationsCount
    ? 'There is nothing new'
    : `You have ${notificationsCount} new notification${pluralism}`;

  return {
    anchorEl,
    popupOpenHandle,
    popupCloseHandle,
    notificationsCount,
    text
  };
};
