export const LANGUAGES = [
  'JavaScript',
  'TypeScript',
  'Python',
  'Java',
  'C',
  'C++',
  'C#',
  'Go',
  'Ruby',
  'Scala',
  'Kotlin',
  'Swift',
  'PHP',
  'Rust',
  '.NET',
  'Dart',
  'Objective-C',
  'Perl'
];

export const TECH_STACK = [
  'React',
  'Angular',
  'Vue.js',
  'HTML',
  'CSS',
  'Sass',
  'Webpack',
  'Node.js',
  'Django',
  'Ruby on Rails',
  'Spring Boot',
  '.NET Core',
  'Express.js',
  'Flask',
  'Laravel',
  'React Native',
  'Flutter',
  'Swift (iOS)',
  'Kotlin (Android)',
  'Xamarin',
  'MySQL',
  'PostgreSQL',
  'MongoDB',
  'SQLite',
  'Redis',
  'Firebase',
  'Oracle',
  'Amazon Web Services (AWS)',
  'Microsoft Azure',
  'Google Cloud Platform (GCP)',
  'IBM Cloud',
  'Heroku',
  'Git',
  'GitHub',
  'GitLab',
  'Bitbucket',
  'Docker',
  'Kubernetes',
  'AWS Lambda',
  'Azure Functions',
  'Google Cloud Functions',
  'TensorFlow',
  'PyTorch',
  'scikit-learn',
  'Keras',
  'Jenkins',
  'CircleCI',
  'Travis CI',
  'Ansible',
  'Visual Studio Code',
  'IntelliJ IDEA',
  'PyCharm',
  'Eclipse',
  'Figma',
  'Adobe XD',
  'Sketch',
  'Jest',
  'Mocha',
  'Selenium',
  'Cypress'
];

export enum LocalStorageItems {
  Token = 'token',
  UserId = 'userId',
  ActivationLinkId = 'activationLinkId',
  ThemeColorMode = 'themeColorMode'
}

export enum AppRoutes {
  AccountActivation = '/activate/:activationLinkId',
  Companies = '/companies',
  CompanyPage = '/company/:companyId',
  Dashboard = '/dashboard',
  EmailUpdateConfirmation = '/emailUpdateConfirmation/:activationLinkId',
  Login = '/login',
  MentorBlockCreate = '/mentorium/create',
  MentorBlockEdit = '/mentorium/:blockId/edit',
  MentorBlockLesson = '/mentorium/:blockId/lesson/:lessonId',
  MentorBlockList = '/mentorium',
  MentorBlockPage = '/mentorium/:blockId',
  MentorBlockScheduledTask = '/mentorium/:blockId/scheduledTask/:scheduledTaskId',
  PageNotFound = '/404',
  PasswordReset = '/passwordReset',
  PasswordResetConfirm = '/passwordReset/:activationLinkId',
  ProblemFormCreate = '/problems/create',
  ProblemFormEdit = '/problem/:problemId/edit',
  ProblemPage = '/problem/:problemId',
  Problems = '/problems',
  Profile = '/profile/:userId',
  PublicationCreate = '/publications/create',
  PublicationEdit = '/publication/:pubId/edit',
  PublicationPage = '/publication/:pubId',
  Publications = '/publications',
  ResendActivationLink = '/resendActivationLink',
  Signup = '/signup',
  UserEdit = '/user/:userId/edit',
  UserPage = '/user/:userId',
  Users = '/users',
  TaskCreate = '/manageContent/task/create',
  TaskEdit = '/manageContent/task/:taskId/edit',
  Task = '/manageContent/task/:taskId',
  LessonEdit = '/manageContent/lesson/:lessonId/edit',
  Lesson = '/manageContent/lesson/:lessonId',
  LessonCreate = '/manageContent/lesson/create',
  ManageContentLessons = '/manageContent/lessons',
  ManageContentTasks = '/manageContent/tasks',
  ManageContent = '/manageContent',
  Notifications = '/notifications'
}

export enum ExternalLinks {
  MailToInfo = 'mailto:info@socoders.com'
}
