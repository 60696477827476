import { Stack, styled } from '@mui/material';

export const RootStyled = styled(Stack)`
  flex-grow: 1;
  width: 100%;
`;

export const RowStyled = styled(Stack)`
  flex-direction: row;
  padding: 0.5rem 0;
  justify-content: space-between;
  align-items: center;
`;

export const UserStackStyled = styled(Stack)`
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;
