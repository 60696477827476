import React, { FC } from 'react';
import { MarkdownPreviewStyled } from './Markdown.styles';
import { MarkdownPreviewProps } from '@uiw/react-markdown-preview';
import { useThemeColorModeContext } from '../../../providers/ThemeColorModeProvider';

const MarkdownPreview: FC<MarkdownPreviewProps> = props => {
  const { mode } = useThemeColorModeContext();

  return (
    <div data-color-mode={mode}>
      <MarkdownPreviewStyled {...props} />
    </div>
  );
};

export default MarkdownPreview;
