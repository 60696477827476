import { useSnackbar } from 'notistack';
import { client } from '../appoloClient';
import { UserEmailUpdateDocument } from '../graphql/generated';
import { LocalStorageItems } from '../helpers/constants';

export default function () {
  const activationLinkId = localStorage.getItem(LocalStorageItems.ActivationLinkId);
  const profileId = localStorage.getItem(LocalStorageItems.UserId);
  const { enqueueSnackbar } = useSnackbar();

  if (activationLinkId) {
    client
      .mutate({
        mutation: UserEmailUpdateDocument,
        variables: {
          userId: profileId,
          values: {
            activationLinkId
          }
        }
      })
      .then(({ data }) => {
        localStorage.removeItem(LocalStorageItems.ActivationLinkId);

        if (data.userEmailUpdate.includes('Fail')) {
          enqueueSnackbar(data.userEmailUpdate, { variant: 'error' });
        } else {
          enqueueSnackbar(data.userEmailUpdate, { variant: 'success' });
        }
      });

    return true;
  }

  return false;
}
