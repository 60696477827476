import React, { useState } from 'react';

export const useActionsMenuController = () => {
  const [anchorActions, setAnchorActions] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorActions(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorActions(null);
  };

  return {
    anchorActions,
    handleClick,
    handleClose
  };
};
