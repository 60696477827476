import { FC } from 'react';
import { MDEditorStyled } from './Markdown.styles';
import { MDEditorProps } from '@uiw/react-md-editor/src/Editor';
import { useThemeColorModeContext } from '../../../providers/ThemeColorModeProvider';

const MarkdownEditor: FC<MDEditorProps> = props => {
  const { mode } = useThemeColorModeContext();
  return <MDEditorStyled {...props} data-color-mode={mode} />;
};

export default MarkdownEditor;
