import React from 'react';
import {
  CommentDeleteDocument,
  CommentsGetDocument,
  CommentUpdateDocument,
  useCommentDeleteMutation,
  useCommentLikeMutation,
  usePublicationCommentsCountGetLazyQuery
} from '../../../graphql/generated';
import { CommentComponentProps } from './CommentItem';
import { checkPermission } from '../../../services/checkPermission';
import { useRouteMatch } from 'react-router-dom';
import { ActionsMenuOption } from '../../sharedComponents/actionsMenu/ActionsMenu';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';

export const useCommentController = ({ comment, parentId }: CommentComponentProps) => {
  const [replyToValue, setReplyToValue] = React.useState('');
  const [isReply, setIsReply] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const isTopLevelComment = comment.parent?._id === parentId;
  const isPublicationsPage = !!useRouteMatch(['/publications', '/publication/:pubId'])?.isExact;

  const [commentLike, { loading }] = useCommentLikeMutation({
    variables: { commentId: comment?._id },
    refetchQueries: [CommentsGetDocument],
    awaitRefetchQueries: true
  });
  const [getPublicationCommentsCount] = usePublicationCommentsCountGetLazyQuery();

  const [commentDelete] = useCommentDeleteMutation({
    variables: { commentId: comment._id },
    refetchQueries: [CommentsGetDocument],
    onCompleted: () => {
      if (isPublicationsPage) {
        getPublicationCommentsCount({
          variables: { pubId: parentId },
          fetchPolicy: 'network-only'
        });
      }
    }
  });

  const onReplyHandle = () => {
    setIsReply(!isReply);
    const fullName = comment.owner ? `${comment.owner.firstName} ${comment.owner.lastName}`.trim() : 'Deleted User';
    setReplyToValue(`@${fullName}: `);
  };

  const onReplyCloseHandle = () => {
    setIsReply(false);
  };

  const onLikeHandle = async () => {
    await commentLike();
  };

  const onEditHandle = () => {
    setIsEdit(!isEdit);
  };

  const onEditCloseHandle = () => {
    setIsEdit(false);
  };

  const canEdit = checkPermission(CommentUpdateDocument, comment?.owner?._id);
  const canDelete = checkPermission(CommentDeleteDocument, comment?.owner?._id);
  const noPermissionText = 'No Permission';

  const onDeleteHandle = async () => {
    await commentDelete();
  };

  const actionOptions: ActionsMenuOption[] = [
    {
      onClick: onDeleteHandle,
      name: 'Delete',
      icon: DeleteOutlined,
      isDisabled: !canDelete,
      textForDisabled: noPermissionText
    },
    {
      onClick: onEditHandle,
      name: 'Edit',
      icon: EditOutlined,
      isDisabled: !canEdit,
      textForDisabled: noPermissionText
    }
  ];

  return {
    isEdit,
    onEditHandle,
    onEditCloseHandle,
    onDeleteHandle,
    onLikeHandle,
    onReplyHandle,
    onReplyCloseHandle,
    replyToValue,
    isReply,
    loading,
    isTopLevelComment,
    actionOptions
  };
};
