import React from 'react';
import { Button, CircularProgress, Pagination } from '@mui/material';
import MentorBlockCard from '../mentorBlockCard/MentorBlockCard';
import { useMentorBlockListController } from './MentorBlockList.controller';
import { PaginationStyled } from '../../company/companiesList/CompaniesList.styles';
import { CreateButtonStyled } from './MentorBlockList.styles';
import SearchHeader from '../../globalSearch/searchHeader/SearchHeader';

function MentorBlockList() {
  const { loading, mentorBlocks, totalPages, page, search, onPageChange, onCreateHandle } =
    useMentorBlockListController();

  if (loading) return <CircularProgress />;

  return (
    <>
      {search && <SearchHeader />}
      <CreateButtonStyled>
        <Button variant="outlined" size="small" onClick={onCreateHandle}>
          To be a mentor
        </Button>
      </CreateButtonStyled>

      {mentorBlocks.map(mentorBlock => (
        <MentorBlockCard key={mentorBlock?._id} mentorBlock={mentorBlock} starred={mentorBlock?.isStarred} />
      ))}

      {!!totalPages && (
        <PaginationStyled>
          <Pagination count={totalPages} variant="outlined" shape="rounded" page={page} onChange={onPageChange} />
        </PaginationStyled>
      )}
    </>
  );
}

export default MentorBlockList;
