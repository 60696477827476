import { Paper, Stack, Typography, styled, Button } from '@mui/material';

export const RootStyled = styled(Stack)`
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-wrap: wrap;
  }
`;
export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const SideBarStyled = styled(PaperStyled)`
  width: 280px;
`;

export const ContentStyled = styled(Stack)`
  gap: 1rem;
  width: 100%;
  overflow: hidden;
`;
export const MentorsStyled = styled(Stack)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;

export const StackStyled = styled(Stack)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
`;

export const TypographyStyled = styled(Typography)`
  word-break: break-word;
`;

export const DescriptionStyled = styled('div')`
  word-break: break-word;
`;

export const TitleBlockStyled = styled('div')`
  display: flex;
  align-items: start;
`;

export const StarButton = styled(Button)`
  margin-left: auto;
`;
