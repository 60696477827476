import { createContext, useContext, FC, useState, useEffect } from 'react';
import { LocalStorageItems } from '../helpers/constants';

export type ThemeColorModeContextValue = {
  mode: 'light' | 'dark';
  colorModeToggle: () => void;
};

export const ThemeColorModeContext = createContext<ThemeColorModeContextValue | undefined>(undefined);

export const ThemeColorModeProvider: FC = ({ children }) => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');

  useEffect(() => {
    const themeColorMode = localStorage.getItem(LocalStorageItems.ThemeColorMode);

    if (themeColorMode === 'light' || themeColorMode === 'dark') {
      setMode(themeColorMode);
    }
  }, []);

  const colorModeToggle = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem(LocalStorageItems.ThemeColorMode, newMode);
  };

  return <ThemeColorModeContext.Provider value={{ mode, colorModeToggle }}>{children}</ThemeColorModeContext.Provider>;
};

export const useThemeColorModeContext = () => {
  const context = useContext(ThemeColorModeContext);
  if (context === undefined) {
    throw new Error('useThemeColorModeContext must be used within a ThemeColorModeProvider');
  }
  return context;
};
