import { Stack, styled } from '@mui/material';

export const RootStyled = styled(Stack)`
  gap: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    gap: 1rem;
  }
`;

export const PaginationStyled = styled(Stack)`
  flex-direction: row;
  padding: 1rem 0;
  justify-content: center;
  width: 100%;
`;
