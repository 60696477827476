import React from 'react';
import { Button, CircularProgress, InputAdornment } from '@mui/material';
import { Close, Send, Save } from '@material-ui/icons';
import { useCommentEditController } from './CommentEditForm.controller';
import { FormControlStyled, StackStyled, TextFieldStyled } from './CommentEditForm.styles';

export interface CommentEditFormProps {
  parentId?: string | undefined | null;
  commentId?: string;
  parentComponentId?: string;
  value?: string;
  isEdit?: boolean;
  isReply?: boolean;
  onClose?: () => void;
}

const CommentEditForm: React.FC<CommentEditFormProps> = ({
  parentId,
  value,
  isEdit,
  isReply,
  onClose,
  commentId,
  parentComponentId
}) => {
  const {
    register,
    handleSubmit,
    handleClear,
    loading,
    submitButtonText,
    formState: { errors, isSubmitted }
  } = useCommentEditController({ parentId, value, isEdit, isReply, onClose, commentId, parentComponentId });

  return (
    <FormControlStyled>
      <TextFieldStyled
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Close type="button" onClick={handleClear} />
            </InputAdornment>
          )
        }}
        autoFocus={true}
        id="comment-input"
        placeholder="Add comment"
        label={<></>}
        multiline
        maxRows={4}
        fullWidth
        helperText={isSubmitted && errors.value?.message}
        error={isSubmitted && !!errors.value}
        onKeyDown={async e => {
          if (e.keyCode === 13 && e.ctrlKey) await handleSubmit();
        }}
        {...register('value')}
      />
      <StackStyled>
        <Button
          id="send-btn"
          size="medium"
          disabled={!!errors.value || loading}
          endIcon={loading ? <CircularProgress size={15} /> : isEdit ? <Save /> : <Send />}
          onClick={handleSubmit}
        >
          {submitButtonText}
        </Button>
        <Button hidden={!isReply && !isEdit} onClick={onClose}>
          Cancel
        </Button>
      </StackStyled>
    </FormControlStyled>
  );
};

export default CommentEditForm;
