import { Paper, Stack, styled } from '@mui/material';

export const RootStyled = styled(Stack)`
  gap: 1rem;
`;

export const ButtonsContainer = styled(Stack)`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const ActionButtons = styled(Stack)`
  flex-direction: row;
  gap: 1rem;
`;

export const ContentContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
`;
