import { Button, Paper, Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
  overflow: hidden;

  :hover {
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const RowStyled = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: auto;
  min-width: auto;
`;

export const RootStyled = styled('div')`
  width: 100%;
`;
