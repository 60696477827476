import React from 'react';
import { useSearchHeaderController } from './SearchHeader.controller';
import { ContainerStyled, LinkStyled, SearchHeaderStyled } from './SearchHeader.styles';

const SearchHeader: React.FC = () => {
  const { searchValue, options } = useSearchHeaderController();

  if (!searchValue) return null;

  return (
    <>
      <SearchHeaderStyled>Search result for: {`"${searchValue}"`}</SearchHeaderStyled>
      <ContainerStyled>
        {options.map(({ name, count, isActive, path }, i) => (
          <LinkStyled key={i} isActive={isActive} to={path}>
            {name}: {count}
          </LinkStyled>
        ))}
      </ContainerStyled>
      <hr />
    </>
  );
};

export default SearchHeader;
