import React from 'react';
import { checkPermission } from '../services/checkPermission';
import { DocumentNode } from '@apollo/client';

interface IProps {
  permission: DocumentNode;
  ownerId?: string | undefined;
  children: JSX.Element;
}

const PermissionProvider: React.FC<IProps> = ({ children, permission, ownerId }) => {
  const userHasPermission = checkPermission(permission, ownerId);

  return userHasPermission ? children : null;
};

export default PermissionProvider;
