import React from 'react';
import { LessonEditForm } from '../../lesson/lessonEditForm/LessonEditForm';
import { TaskEditForm } from '../../task/taskEditForm/TaskEditForm';
import { useContentEditPageController } from './ContentEditPage.controller';
import { RootStyled } from './ContentEditPage.styles';

export const ContentEditPage: React.FC = () => {
  const { isLessonEdit, isTaskEdit, closeFormHandle } = useContentEditPageController();

  return (
    <RootStyled style={{ padding: '1rem' }}>
      {isLessonEdit && <LessonEditForm onCloseForm={closeFormHandle} />}
      {isTaskEdit && <TaskEditForm onCloseForm={closeFormHandle} />}
    </RootStyled>
  );
};
