import React from 'react';
import { Alert, Autocomplete, Button, CircularProgress, TextField } from '@mui/material';
import { Save } from '@material-ui/icons';
import { LANGUAGES } from '../../../helpers/constants';
import { UserUpdateDocument } from '../../../graphql/generated';
import PermissionProvider from 'src/providers/PermissionProvider';
import { useUserEditFormController } from './UserEditForm.controller';
import EmailChangeDialog from './emailChangeDialog/EmailChangeDialog';
import { FooterStyled, FormStyled, LoaderStyled, RootStyled } from './UserEditForm.styles';
import { Controller } from 'react-hook-form';
import { UnsavedChangesProvider } from 'src/providers/unsavedChangesProvider/UnsavedChangesProvider';

const UserEditForm: React.FC = () => {
  const {
    apiError,
    formErrors,
    userGetLoading,
    userUpdateLoading,
    ownerId,
    isOwner,
    isDirty,
    onSubmitFormHandle,
    onCloseFormHandle,
    register,
    dialogData,
    control
  } = useUserEditFormController();

  if (userGetLoading) {
    return <LoaderStyled />;
  }

  return (
    <PermissionProvider permission={UserUpdateDocument} ownerId={ownerId}>
      <RootStyled>
        <Alert severity="error" hidden={!apiError}>
          {apiError}
        </Alert>

        <FormStyled
          onSubmit={e => {
            e.preventDefault();
            onSubmitFormHandle();
          }}
        >
          <TextField
            id="first-name"
            required
            size="small"
            label="First Name"
            fullWidth={true}
            autoFocus={true}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName?.message}
            {...register('firstName')}
          />

          <TextField
            id="last-name"
            required
            size="small"
            label="Last Name"
            fullWidth={true}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName?.message}
            {...register('lastName')}
          />
          <TextField
            id="job-title"
            required
            size="small"
            label="Job Title"
            fullWidth={true}
            error={!!formErrors.jobTitle}
            helperText={formErrors.jobTitle?.message}
            {...register('jobTitle')}
          />
          <TextField
            id="image"
            size="small"
            label="Link to an external profile image file"
            placeholder="Place a link to an external image file"
            fullWidth={true}
            error={!!formErrors.image}
            helperText={formErrors.image?.message}
            {...register('image')}
          />
          <TextField
            id="about"
            size="small"
            multiline
            label="About"
            fullWidth={true}
            error={!!formErrors.about}
            helperText={formErrors.about?.message}
            {...register('about')}
          />

          <Controller
            name="languages"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="languages"
                multiple
                size="small"
                value={value || []}
                options={LANGUAGES.sort()}
                getOptionLabel={option => option}
                onChange={(event, values) => onChange(values)}
                renderInput={params => <TextField {...params} label="Programming language" />}
              />
            )}
          />

          <TextField
            id="email-input"
            size="small"
            label="Email"
            fullWidth={true}
            hidden={!isOwner}
            error={!!formErrors.email}
            helperText={formErrors.email?.message}
            {...register('email')}
          />

          <FooterStyled>
            <Button variant="outlined" onClick={onCloseFormHandle}>
              Cancel
            </Button>
            <Button
              disabled={userUpdateLoading}
              startIcon={userUpdateLoading ? <CircularProgress size={18} /> : <Save />}
              variant="outlined"
              type="submit"
            >
              Save
            </Button>
          </FooterStyled>
        </FormStyled>

        <UnsavedChangesProvider hasUnsaved={isDirty} />

        <EmailChangeDialog {...{ register, ...dialogData }} />
      </RootStyled>
    </PermissionProvider>
  );
};

export default UserEditForm;
