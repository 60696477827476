import { Drawer, ListItem, styled, Stack, Divider, MenuItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const MENU_WIDTH = 280;

export const DesktopRootStyled = styled(Stack)`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: flex;
    background-color: ${({ theme }) => theme.palette.background.paper};
    width: ${MENU_WIDTH}px;
    padding: 1rem;
    gap: 1rem;
    height: 100%;
    flex-shrink: 0;
  }
`;

export const MobileRootStyled = styled(Drawer)`
  & .MuiDrawer-paper {
    top: 72px;
    width: ${MENU_WIDTH}px;
    padding: 1rem;
    gap: 1rem;
    height: auto;
    bottom: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: none;
  }
`;

export const ListItemStyled = styled(ListItem)<{ isActive?: boolean }>`
  background-color: ${({ theme, isActive }) => isActive && theme.palette.primary.lighter};
  border-radius: 12px;
  color: ${({ theme }) => theme.palette.primary.main};

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.lighter};
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.primary.main};

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.lighter};
  }
`;
export const LinkStyled = styled(RouterLink)`
  :hover {
    text-decoration: unset;
  }
`;

export const DividerStyled = styled(Divider)`
  margin-top: auto;
`;
