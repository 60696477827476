import { useProfile, useQueryParams } from '../../../helpers/customHooks';
import { useTasksGetByOwnerIdQuery } from '../../../graphql/generated';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { Paginate } from '../../../helpers/pagination';

export const useTasksListController = () => {
  const { _id: profileId } = useProfile();
  const limit = 10;
  const history = useHistory();
  const { p, ofs } = useQueryParams(history.location.search);
  const offset = ofs ? Number(ofs) : 0;
  const page = p ? Number(p) : 1;

  const { data, loading } = useTasksGetByOwnerIdQuery({
    variables: {
      ownerId: profileId,
      offset,
      limit
    },
    fetchPolicy: 'cache-and-network'
  });
  const tasks = data?.tasksGetByOwnerId?.list ?? [];
  const totalPages = data?.tasksGetByOwnerId?.totalPages ?? 0;

  const onPageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    Paginate.onChangePage({ page, limit, history });
  };

  return {
    tasks,
    loading,
    totalPages,
    page,
    onPageChange
  };
};
