import React from 'react';
import moment from 'moment';
import { ArrowBack, Delete, Edit } from '@material-ui/icons';
import { Button, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ActionsFooter from '../../sharedComponents/actionsFooter/ActionsFooter';
import Image from '../../sharedComponents/image/Image';
import {
  ButtonsContainer,
  FooterContainer,
  PageContent,
  PageHeader,
  StarButton,
  StyledLink,
  StyledTopContainer
} from './PublicationPage.styles';
import { usePublicationPageController } from './PublicationPage.controller';
import { StyledStar, StyledStarBorder } from '../publicationCard/PublicationCard.styles';
import MarkdownPreview from '../../sharedComponents/markdown/MarkdownPreview';
import LoaderCentered from '../../sharedComponents/loaderCentered/LoaderCentered';

function PublicationPage() {
  const {
    onLikeHandler,
    handleEditClick,
    handleDeleteClick,
    handleStarPublication,
    onBackHandle,
    loading,
    starPublicationLoading,
    likeLoading,
    publication,
    isPublicationStared,
    canDelete,
    canEdit,
    historyBack,
    pubId,
    commentsCount,
    commentsLoading
  } = usePublicationPageController();

  if (loading) return <LoaderCentered />;

  return (
    <div>
      <StyledTopContainer>
        <div>
          {historyBack && (
            <Button onClick={onBackHandle}>
              <ArrowBack />
            </Button>
          )}
        </div>
        <div>
          <ButtonsContainer>
            {canEdit && (
              <Button startIcon={<Edit />} variant="outlined" onClick={handleEditClick}>
                Edit
              </Button>
            )}
            {canDelete && (
              <Button startIcon={<Delete />} variant="outlined" onClick={handleDeleteClick}>
                Delete
              </Button>
            )}
          </ButtonsContainer>
        </div>
      </StyledTopContainer>
      <Paper>
        <>
          <PageContent>
            <PageHeader>
              <Avatar src={publication?.owner?.image || ''} className="mr-2" />
              <Stack>
                {publication?.owner?._id ? (
                  <StyledLink to={`/user/${publication?.owner?._id}`}>
                    {publication?.owner?.firstName} {publication?.owner?.lastName}
                  </StyledLink>
                ) : (
                  <Typography variant="subtitle1">Deleted User</Typography>
                )}
                <small>{moment(Number(publication?.createdAt)).format('MMMM DD YYYY')}</small>
              </Stack>
              <StarButton onClick={handleStarPublication}>
                {starPublicationLoading ? (
                  <CircularProgress size={24} />
                ) : isPublicationStared ? (
                  <StyledStar />
                ) : (
                  <StyledStarBorder />
                )}
              </StarButton>
            </PageHeader>
            <Typography variant="h3">{publication?.title}</Typography>
            <div>{publication?.description}</div>
            <MarkdownPreview source={publication?.content || ''} />
          </PageContent>
          <Image src={publication?.image || ''} />
        </>
        <FooterContainer>
          <ActionsFooter
            parentId={pubId}
            likes={publication?.likes}
            onLike={onLikeHandler}
            loading={likeLoading}
            commentsLoading={commentsLoading}
            commentsCount={commentsCount}
          />
        </FooterContainer>
      </Paper>
    </div>
  );
}

export default PublicationPage;
