import React from 'react';
import { CircularProgress, Tab, Tabs, Tooltip } from '@mui/material';
import { PaperStyled, RootStyled } from './MentorBlockEdit.styles';
import { useMentorBlockFormController } from './MentorBlockEdit.controller';
import { MainInfo } from './mainInfoForm/MainInfo';
import { ManageLessons } from './manageLessons/ManageLessons';
import { ManageTasks } from './manageTasks/ManageTasks';

const MentorBlockEdit: React.FC = () => {
  const { mentorBlockData, loading, tabChangeHandle, tabIndex, blockId } = useMentorBlockFormController();

  const MANAGE_TABS = ['Manage Lessons', 'Manage Tasks'];
  const DISABLED_TABS_TOOLTIP = 'Unavailable until create a Mentor Block';

  return (
    <RootStyled>
      <PaperStyled>
        <Tabs value={tabIndex} onChange={tabChangeHandle}>
          <Tab label="About course" />
          {MANAGE_TABS.map(tab => (
            <Tooltip key={tab} title={!blockId && DISABLED_TABS_TOOLTIP}>
              <Tab disabled={!blockId} style={{ pointerEvents: 'auto' }} label={tab} />
            </Tooltip>
          ))}
        </Tabs>
        {loading && (
          <div className="justify-content-center">
            <CircularProgress />
          </div>
        )}
        {tabIndex === 0 && <MainInfo mentorBlockData={mentorBlockData} />}
        {tabIndex === 1 && <ManageLessons ownerId={mentorBlockData.ownerId} />}
        {tabIndex === 2 && <ManageTasks ownerId={mentorBlockData.ownerId} />}
      </PaperStyled>
    </RootStyled>
  );
};

export default MentorBlockEdit;
