import React from 'react';
import { Lesson, Maybe, ScheduleSlot } from '../../../../../graphql/generated';
import { Button, Step, StepButton, StepContent, Stepper, Typography } from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

interface LessonsRoadmapProps {
  scheduleSlots: Array<Maybe<ScheduleSlot>>;
}
export const LessonsRoadmap = ({ scheduleSlots }: LessonsRoadmapProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const dateNow = Date.now();
  const history = useHistory();
  const { blockId } = useParams<{ blockId: string }>();

  const isStarted = (startDate: Maybe<string> | undefined) => {
    return moment(Number(startDate)).diff(dateNow, 'milliseconds') <= 0;
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const onLessonClickHandle = (lessonId: string) => {
    history.push(`/mentorium/${blockId}/lesson/${lessonId}`);
  };

  return (
    <Stepper nonLinear activeStep={activeStep} orientation="vertical">
      {scheduleSlots.map((scheduleSlot, index) => {
        const lesson = scheduleSlot?.entity as Lesson;
        const isLesson = scheduleSlot?.entityType === 'Lesson';
        return (
          isLesson && (
            <Step
              key={index}
              disabled={!isStarted(scheduleSlot?.startDate)}
              active={isStarted(scheduleSlot?.startDate)}
            >
              <StepButton
                optional={moment(Number(scheduleSlot?.startDate)).format('MM/DD/YY - LT')}
                sx={{ margin: 0 }}
                color="inherit"
                onClick={handleStep(index)}
                onDoubleClick={() => onLessonClickHandle(lesson._id)}
              >
                {lesson.title}
              </StepButton>
              <StepContent hidden={activeStep !== index}>
                <Typography maxHeight={100} overflow="hidden">
                  {lesson.content}
                </Typography>
                <Button onClick={() => onLessonClickHandle(lesson._id)}>Open Lesson</Button>
              </StepContent>
            </Step>
          )
        );
      })}
    </Stepper>
  );
};
