import { Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const RootStyled = styled(Stack)`
  gap: 2rem;
`;

export const DetailsContainer = styled('div')`
  max-height: 6rem;
  overflow: hidden;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
  ${({ theme }) => theme.typography.subtitle1};

  :hover {
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;
