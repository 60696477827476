import React from 'react';
import { useTaskPageController } from './TaskPage.controller';
import { Button } from '@mui/material';
import { TaskView } from '../taskView/TaskView';
import { ArrowBack, Delete, Edit } from '@material-ui/icons';
import { RootStyled, ButtonsContainer, ActionButtons, ContentContainer } from './TaskPage.styles';

export const TaskPage = () => {
  const { task, handleBack, handleEdit, handleDelete } = useTaskPageController();

  return (
    <RootStyled>
      <ButtonsContainer>
        <Button onClick={handleBack}>
          <ArrowBack />
        </Button>
        <ActionButtons>
          <Button startIcon={<Edit />} variant="outlined" onClick={handleEdit}>
            Edit
          </Button>
          <Button startIcon={<Delete />} variant="outlined" onClick={handleDelete}>
            Delete
          </Button>
        </ActionButtons>
      </ButtonsContainer>
      <ContentContainer>
        <TaskView task={task} />
      </ContentContainer>
    </RootStyled>
  );
};
