import { client } from '../appoloClient';
import { globalVar } from '../cache';
import { UserCheckAuthDocument } from '../graphql/generated';
import { LocalStorageItems } from '../helpers/constants';

function checkAuth() {
  return client.mutate({ mutation: UserCheckAuthDocument }).then(response => {
    const user = response.data.userCheckAuth.user;
    const accessToken = localStorage.getItem(LocalStorageItems.Token);

    globalVar({
      isLoggedIn: !!accessToken && !!user._id,
      profile: {
        _id: user._id,
        roles: user.roles,
        level: user.level,
        firstName: user.firstName,
        lastName: user.lastName,
        image: user.image
      }
    });
  });
}
export default checkAuth;
