import React from 'react';
import { Avatar, Button, CircularProgress, ListItem, ListItemAvatar, Typography } from '@mui/material';
import moment from 'moment';
import { Comment } from '../../../graphql/generated';
import ActionsMenu from '../../sharedComponents/actionsMenu/ActionsMenu';
import CommentEditForm from '../commentEditForm/CommentEditForm';
import {
  BodyStyled,
  ButtonsStackStyled,
  InfoContainerStyled,
  InfoRightBoxStyled,
  LinkStyled,
  RootContainerStyled
} from './CommentItem.styles';
import { useCommentController } from './CommentItem.controller';
import MarkdownPreview from '../../sharedComponents/markdown/MarkdownPreview';

export interface CommentComponentProps {
  comment: Comment;
  parentId: string;
}

export const CommentItem: React.FC<CommentComponentProps> = ({ comment, parentId }) => {
  const {
    isEdit,
    onEditCloseHandle,
    onLikeHandle,
    onReplyHandle,
    onReplyCloseHandle,
    replyToValue,
    isReply,
    loading,
    isTopLevelComment,
    actionOptions
  } = useCommentController({ comment, parentId });

  const Message = ({ content }: { content: string }) => {
    const match = /@(.*?[^=@]):/.exec(content);
    const name = match && match[0];
    const text = match ? content.replace(`${match[0]}`, '') : content;
    return (
      <div>
        <b>{name}</b>
        <span style={{ overflowWrap: 'break-word' }}>
          <MarkdownPreview source={text || ''} linkTarget="_blank" />
        </span>
      </div>
    );
  };

  return (
    <ListItem style={{ paddingLeft: !isTopLevelComment ? 50 : 'auto' }} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar src={comment.owner?.image || ''} />
      </ListItemAvatar>
      <RootContainerStyled>
        <BodyStyled>
          <InfoContainerStyled>
            {comment.owner?._id ? (
              <LinkStyled to={`/user/${comment.owner?._id}`}>
                {comment.owner?.firstName} {comment.owner?.lastName}
              </LinkStyled>
            ) : (
              <Typography variant="subtitle1">Deleted User</Typography>
            )}
            <InfoRightBoxStyled>
              <small>{moment(Number(comment.createdAt)).format('MMM DD YY')}</small>
              <ActionsMenu options={actionOptions} />
            </InfoRightBoxStyled>
          </InfoContainerStyled>
          {isEdit ? (
            <CommentEditForm
              commentId={comment._id}
              value={comment.content || ''}
              isEdit={isEdit}
              onClose={onEditCloseHandle}
            />
          ) : (
            <Message content={comment.content || ''} />
          )}
        </BodyStyled>
        <ButtonsStackStyled>
          <Button
            size="small"
            disabled={loading}
            endIcon={loading ? <CircularProgress size={15} /> : comment?.likes?.length ? comment.likes.length : null}
            onClick={onLikeHandle}
            children="Like"
          />
          <Button size="small" onClick={onReplyHandle} children="Reply" />
        </ButtonsStackStyled>
        {isReply && (
          <CommentEditForm
            parentId={isTopLevelComment ? comment._id : comment.parent?._id}
            parentComponentId={parentId}
            value={replyToValue}
            onClose={onReplyCloseHandle}
          />
        )}
      </RootContainerStyled>
    </ListItem>
  );
};
