import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert, Box, Button, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../helpers/constants';
import { CenteredDivStyled, PaperStyled, TextFieldStyled } from '../Account.styles';
import { useLoginFormController } from './LoginPage.controller';
import { ForgotPasswordRowStyled, UserLoginErrorStyled } from './LoginPage.styles';

export const LoginPage: FC = () => {
  const {
    formErrors,
    handleClickShowPassword,
    loading,
    onLoginFormSubmitHandle,
    register,
    showAPIError,
    showPassword,
    userLoginError
  } = useLoginFormController();

  const errorMessage = () => {
    let errorMessageText: string | undefined = 'An error occurred.';

    if (userLoginError) {
      const gqlError = userLoginError.graphQLErrors[0];
      if (gqlError.path?.join('.') === 'login') {
        errorMessageText = gqlError.message;
        if (errorMessageText?.includes('activate')) {
          return (
            <div>
              <div>{errorMessageText}</div>
              <Link to={AppRoutes.ResendActivationLink}>Or resend new activation link</Link>
            </div>
          );
        } else return errorMessageText;
      }
    }
    return errorMessageText;
  };

  return (
    <>
      <PaperStyled>
        <h3>Login</h3>

        <Box component="form" onSubmit={onLoginFormSubmitHandle} noValidate>
          <TextFieldStyled
            id="email"
            helperText={formErrors.email?.message}
            error={!!formErrors.email}
            size="small"
            label="Email"
            fullWidth
            {...register('email')}
          />

          <TextFieldStyled
            id="password"
            helperText={formErrors.password?.message}
            error={!!formErrors.password}
            size="small"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth={true}
            {...register('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Button
            type="submit"
            variant="contained"
            startIcon={loading && <CircularProgress size={18} />}
            disabled={loading}
          >
            Login
          </Button>
        </Box>

        {!loading && showAPIError ? (
          <UserLoginErrorStyled>
            {userLoginError ? <Alert severity="error">{errorMessage()}</Alert> : null}
          </UserLoginErrorStyled>
        ) : null}

        <ForgotPasswordRowStyled>
          Forgot password? <Link to={AppRoutes.PasswordReset}>Restore</Link>
        </ForgotPasswordRowStyled>
        <CenteredDivStyled>
          {"Don't have an account?"} <Link to={'/signup'}>Sign Up</Link>
        </CenteredDivStyled>
      </PaperStyled>
    </>
  );
};
