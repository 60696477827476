import React from 'react';
import { Avatar, Typography } from '@mui/material';
import { ButtonStyled, LinkStyled, RootStyled, RowStyled } from './ListItems.styles';
import { MentorBlock } from '../../../../../graphql/generated';
import { Maybe } from 'yup/es/types';
import { OptionTypes } from '../../UserPage.controller';

interface OverridedOptionTypes extends Omit<OptionTypes, 'list'> {
  list: Maybe<MentorBlock>[];
}
interface ListItemsProps {
  option: OverridedOptionTypes;
}

export const ListItems: React.FC<ListItemsProps> = ({ option }) => {
  const { linkDirectory, list, buttonName, onClick } = option;

  return (
    <RootStyled>
      {list &&
        list.map(el => (
          <RowStyled key={el?._id}>
            <Avatar src={el?.owner?.image ?? ''} variant="square" />
            <LinkStyled to={`/${linkDirectory}/${el?._id}`}>
              <Typography noWrap={true} variant="subtitle1" title={el?.title ?? 'empty'}>
                {el?.title}
              </Typography>

              <Typography noWrap={true} variant="body1" title={el?.description ?? 'empty'}>
                {el?.description}
              </Typography>
            </LinkStyled>
            {buttonName && (
              <ButtonStyled
                variant="outlined"
                onClick={onClick ? () => el && onClick(el._id) : undefined}
                title={buttonName}
              >
                Unstar
              </ButtonStyled>
            )}
          </RowStyled>
        ))}
    </RootStyled>
  );
};
