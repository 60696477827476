import { Maybe, MentorBlockFragment, useMentorBlockStarMutation } from '../../../graphql/generated';
import { useEntityStatus } from '../../../helpers/customHooks';

export const useMentorBlockCardController = (mentorBlock: Maybe<MentorBlockFragment>) => {
  const owner = mentorBlock?.owner ?? { _id: '', firstName: '', lastName: '', image: '' };
  const mentorsList = mentorBlock?.mentors?.filter(mentor => mentor?._id !== owner._id) ?? [];
  const startDate = mentorBlock?.startDate ?? '';
  const endDate = mentorBlock?.endDate ?? '';
  const { timeRangeStatus } = useEntityStatus({ startDate, endDate });
  const blockId = mentorBlock?._id ?? '';
  const [starMentorBlock, { loading: starMentorBlockLoading }] = useMentorBlockStarMutation({
    variables: { blockId }
  });

  const updateUserMentorBlockStarred = async () => {
    starMentorBlock();
  };

  return {
    owner,
    mentorsList,
    timeRangeStatus,
    updateUserMentorBlockStarred,
    starMentorBlockLoading
  };
};
