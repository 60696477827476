import { toString } from 'lodash';
import { FC } from 'react';
import { IValidationCheck } from '../../../helpers/validation';
import { LiStyled, StyledLinearProgress } from './PasswordValidationRender.styles';

export interface IPasswordValidaitonRenderProps {
  listOfChecks: IValidationCheck[];
  percentageOfChecksPassed: number;
}

export const PasswordValidaitonRender: FC<IPasswordValidaitonRenderProps> = ({
  listOfChecks,
  percentageOfChecksPassed = 0
}) => {
  const allChecksPassed = percentageOfChecksPassed === 100;
  return allChecksPassed ? (
    <></>
  ) : (
    <>
      <StyledLinearProgress
        variant="determinate"
        value={percentageOfChecksPassed}
        percentage_passed={percentageOfChecksPassed}
      />
      Please ensure it meets the following criteria:
      <ul>
        {listOfChecks.length !== 0 &&
          listOfChecks.map((condition, index) => (
            <LiStyled is_check_passed={toString(condition.isCheckPassed)} key={index}>
              {condition.text}
            </LiStyled>
          ))}
      </ul>
    </>
  );
};

export default PasswordValidaitonRender;
