import { Stack, styled, TableCell, TableContainer, TableHead, Typography } from '@mui/material';

export const TableContainerStyled = styled(TableContainer)`
  border: 1px solid ${({ theme }) => theme.palette.grey['300']};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: auto;
`;

export const TableHeadStyled = styled(TableHead)``;

export const TableHeaderCellStyled = styled(TableCell)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-right: solid 1px ${({ theme }) => theme.palette.grey['300']};
  border-bottom: solid 1px ${({ theme }) => theme.palette.grey['300']};
  &:last-child {
    border-right: none;
  }
`;

export const TableBodyCellStyled = styled(TableCell)`
  height: 70px;
  width: calc(100% / 7);
  border-right: solid 1px ${({ theme }) => theme.palette.grey['300']};
  &:last-child {
    border-right: none;
  }
  padding: 0;
`;
export const EventStyled = styled('div')<{ eventType: string }>`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme, eventType }) => {
    if (eventType === 'Task') {
      return theme.palette.secondary.main;
    }
    if (eventType === 'Lesson') {
      return theme.palette.primary.main;
    }
  }};
  color: white;
  padding: 0.25rem 0.5rem;
  margin: 0.5rem;
  cursor: pointer;
`;

export const EventPreviewContentStyled = styled(Stack)`
  align-items: center;
  gap: 1rem;
  min-width: 200px;
`;

export const EventTitleStyled = styled('div')`
  line-height: 1.2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
