import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import { Close } from '@material-ui/icons';

export interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
  showCancel?: boolean;
  showClose?: boolean;
  onSubmit?: () => void;
  message?: string;
  title?: string;
  confirmButton?: string;
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  isOpen,
  showCancel = false,
  showClose = false,
  onSubmit,
  message,
  title,
  confirmButton = 'OK',
  children
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {showClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      )}
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {showCancel && <Button onClick={onClose}>Cancel</Button>}
        {onSubmit && (
          <Button onClick={onSubmit} autoFocus>
            {confirmButton}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
