import React from 'react';
import { RootStyled } from './TaskAnswersList.styles';
import { TaskAnswersListItem } from './taskAnswersListItem/TaskAnswersListItem';
import { Maybe, TaskAnswerShortFragment } from '../../../../graphql/generated';

interface TaskAnswersListProps {
  list: Array<Maybe<TaskAnswerShortFragment>>;
  isMentor: boolean;
}

export const TaskAnswersList: React.FC<TaskAnswersListProps> = ({ list, isMentor }) => {
  return (
    <RootStyled>
      {list?.map(taskAnswer => {
        return <TaskAnswersListItem key={taskAnswer?._id} taskAnswer={taskAnswer} isMentor={isMentor} />;
      })}
    </RootStyled>
  );
};
