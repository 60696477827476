import { FC } from 'react';
import { Link } from 'react-router-dom';
import { CenteredDivStyled } from './Account.styles';

interface IRedirectToLogin {
  prefix?: string;
  inline?: boolean;
}

const RedirectToLogin: FC<IRedirectToLogin> = ({ prefix = 'Back to ', inline = false }) => {
  const linkElement = <Link to="/login">{prefix}Login</Link>;

  if (inline) {
    return linkElement;
  }

  return <CenteredDivStyled>{linkElement}</CenteredDivStyled>;
};

export default RedirectToLogin;
