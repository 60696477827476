import { useHistory, useParams } from 'react-router';
import { useTaskDeleteMutation, useTaskGetByIdQuery } from '../../../graphql/generated';
import { AppRoutes } from '../../../helpers/constants';
import { useDialogContext } from '../../../providers/dialogProvider/DialogContext';

export const useTaskPageController = () => {
  const { push, go, replace } = useHistory();
  const { taskId } = useParams<{ taskId: string }>();
  const { data } = useTaskGetByIdQuery({ variables: { taskId } });
  const [taskDelete] = useTaskDeleteMutation({ variables: { taskId } });
  const task = data?.taskGet ?? null;
  const { onDialogOpen } = useDialogContext();

  const handleBack = () => {
    go(-1);
  };

  const handleEdit = () => {
    replace(AppRoutes.TaskEdit.replace(':taskId', taskId));
  };

  const handleDelete = () => {
    onDialogOpen({
      onSubmit: () => {
        taskDelete({
          onCompleted: () => {
            push(`${AppRoutes.ManageContent}?tab=tasks`);
          }
        });
      },
      title: `Delete Task: ${task?.title}`,
      showCancel: true,
      message: `Task: ${task?.title} will be permanently deleted. Are you sure?`,
      confirmButton: 'Delete',
      confirmButtonColor: 'error'
    });
  };

  return {
    task,
    handleBack,
    handleEdit,
    handleDelete
  };
};
