import { Stack, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const RootStyled = styled(Stack)<{ sizePxs: number }>`
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;
  border-radius: 4px;
  gap: 4px;
  padding: 4px;
  width: ${props => `${props.sizePxs}px`};
  height: ${props => `${props.sizePxs}px`};
  overflow: hidden;
`;

export const Name = styled(Typography)`
  width: 100%;
  text-align: center;
`;

export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};

  :hover {
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;
