import React from 'react';
import { Typography } from '@mui/material';
import { LessonFragment, Maybe } from '../../../graphql/generated';
import MarkdownPreview from '../../sharedComponents/markdown/MarkdownPreview';

interface LessonViewProps {
  lesson: Maybe<LessonFragment>;
}
export const LessonView: React.FC<LessonViewProps> = ({ lesson }) => {
  return (
    <>
      <Typography variant="h3">{lesson?.title}</Typography>
      {lesson?.content && <MarkdownPreview source={lesson?.content} />}
    </>
  );
};
