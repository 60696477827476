import { useCommentsGetQuery } from '../../../graphql/generated';

interface CommentListControllerProps {
  parentId: string;
}

export const useCommentListController = ({ parentId }: CommentListControllerProps) => {
  const { data, fetchMore } = useCommentsGetQuery({
    fetchPolicy: 'cache-first',
    variables: {
      parentId,
      offset: 0,
      limit: 5
    }
  });

  const loadMoreComments = () => {
    return fetchMore({
      variables: {
        offset: data?.comments.list.length
      },

      updateQuery(previousResult, { fetchMoreResult }) {
        const comments = fetchMoreResult?.comments;
        const newList = comments?.list || [];
        return {
          comments: {
            ...comments,
            list: [...previousResult.comments.list, ...newList]
          }
        };
      }
    });
  };

  return {
    loadMoreComments,
    comments: data?.comments
  };
};
