import React from 'react';
import {
  useUserFriendInviteMutation,
  useUserFriendListGetQuery,
  useUserFriendRejectMutation
} from '../../../graphql/generated';
import { useProfile } from '../../../helpers/customHooks';
import { Button, ButtonProps } from '@mui/material';

export interface FriendCtaButtonProps extends ButtonProps {
  inviteeId: string;
}

export const FriendCtaButton: React.FC<FriendCtaButtonProps> = props => {
  const { inviteeId, ...restProps } = props;
  const { _id: profileId } = useProfile();
  const { data } = useUserFriendListGetQuery({ variables: { userId: profileId }, skip: !profileId });
  const [friendInviteHandle] = useUserFriendInviteMutation();
  const [friendRejectHandle] = useUserFriendRejectMutation();
  const friendInviteReceived = data?.user?.friendInviteReceived ?? [];
  const friendInviteSent = data?.user?.friendInviteSent ?? [];
  const friendList = data?.user?.friendList ?? [];
  const isInviteeInReceived = friendInviteReceived.find(el => el?._id === inviteeId);
  const isInviteeInSent = friendInviteSent.find(el => el?._id === inviteeId);
  const isInviteeInFriendList = friendList.find(el => el?._id === inviteeId);

  if (inviteeId === profileId) return null;

  const ctaButton = {
    name: isInviteeInFriendList ? 'Remove' : isInviteeInSent ? 'Reject' : isInviteeInReceived ? 'Accept' : 'Invite',
    onClick: () => {
      if (isInviteeInSent || isInviteeInFriendList) {
        friendRejectHandle({ variables: { friendId: inviteeId } });
      } else {
        friendInviteHandle({ variables: { friendId: inviteeId } });
      }
    }
  };

  return (
    <Button onClick={ctaButton.onClick} {...restProps}>
      {ctaButton.name}
    </Button>
  );
};
