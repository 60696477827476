import { Avatar, Paper, Stack, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const RootStyled = styled(Paper)`
  overflow: hidden;
  width: 12rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    width: calc(50vw - 2rem);
  }
`;

export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
  overflow: hidden;

  :hover {
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;
export const Header = styled('div')`
  padding: 0.25rem 0.5rem;
`;

export const Footer = styled(Stack)`
  padding: 0.5rem;
  gap: 0.5rem;
`;

export const AvatarStyled = styled(Avatar)`
  width: 12rem;
  height: 12rem;

  svg {
    width: 8rem;
    height: 8rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    width: calc(50vw - 2rem);
    height: calc(50vw - 2rem);
  }
`;
export const Name = styled(Typography)`
  width: 100%;
  text-align: center;
`;
