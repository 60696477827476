import { useDisclosure } from '../../../helpers/useDisclosure';
import { EntityType, ScheduleSlotsPaginated, useScheduleSlotsGetByParentIdLazyQuery } from '../../../graphql/generated';
import { useEffect } from 'react';

interface ScheduledListControllerProps {
  parentId: string;
  itemEntityTypeName: EntityType;
}
export const useScheduledListController = ({ itemEntityTypeName, parentId }: ScheduledListControllerProps) => {
  const { isOpen: isAddDialogOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const [getScheduledSlots, { data }] = useScheduleSlotsGetByParentIdLazyQuery();
  const scheduledSlotsPaginated = data?.scheduleSlotsGetByParentId as ScheduleSlotsPaginated;

  const EMPTY_STATE_TEXT = {
    message: `No ${itemEntityTypeName} are scheduled`,
    subMessage: `You do not have any ${itemEntityTypeName} scheduled.
                You can choose from the list of previously created ${itemEntityTypeName}, or create a new one and immediately add it to the schedule.
                Want to add one?`,
    buttonName: `Schedule ${itemEntityTypeName}`
  };

  useEffect(() => {
    if (parentId) {
      //Limit is timely manually handled
      getScheduledSlots({ variables: { parentId, entityType: itemEntityTypeName, limit: 1000 } });
    }
  }, [parentId]);

  return {
    isAddDialogOpen,
    onAddOpen,
    onAddClose,
    scheduledSlotsPaginated,
    EMPTY_STATE_TEXT
  };
};
