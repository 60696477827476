import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import moment from 'moment';
import { Cast } from '@material-ui/icons';
import { Maybe, ScheduleSlot } from '../../../../graphql/generated';
import { EventCard, EventCardBody, EventCardHeader, EventCardTitleStyled, EventsContainer } from './EventsStack.styles';

interface EventsStackProps {
  events: Array<Maybe<ScheduleSlot>>;
}
export const EventsStack: FC<EventsStackProps> = ({ events }) => {
  return (
    <EventsContainer>
      {events?.map(event => {
        const eventTime = moment(Number(event?.startDate)).format('LT');
        const day = moment(Number(event?.startDate)).format('dddd, MMM DD');
        return (
          event?.streamLink && (
            <EventCard key={event._id}>
              <EventCardHeader variant="subtitle2" align="center">
                {day}
              </EventCardHeader>
              <EventCardBody>
                <Typography variant="subtitle2" align="center">
                  {eventTime}
                </Typography>
                <EventCardTitleStyled title={event.entity.title || ''} variant="subtitle2" align="center">
                  {event.entity.title}
                </EventCardTitleStyled>
                <Button
                  className="mt-auto"
                  size="small"
                  variant="contained"
                  href={event.streamLink}
                  target="_blank"
                  startIcon={<Cast />}
                >
                  {event.streamProvider}
                </Button>
              </EventCardBody>
            </EventCard>
          )
        );
      })}
    </EventsContainer>
  );
};
