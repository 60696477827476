import React from 'react';
import {
  Autocomplete,
  BlockStackStyled,
  BlockStyled,
  EmptyState,
  InputBaseStyled,
  LinkedButtonStyled,
  RowStackStyled,
  RowStyled,
  SearchIconWrapper,
  SearchRoot,
  SeeAllLink
} from './GlobalSearchInput.styles';
import { Search, ViewListOutlined, Close, CastForEducation } from '@material-ui/icons';
import { useGlobalSearchInputController } from './GlobalSearchInput.controller';
import { Avatar, Backdrop, CircularProgress, IconButton, Typography } from '@mui/material';
import { userInitials } from '../../../helpers/simplifiedData';

const GlobalSearchInput: React.FC = () => {
  const {
    onChange,
    searchValue,
    showAutocomplete,
    onAutocompleteHide,
    onAutocompleteLinkClick,
    onInputClear,
    onFocusHandle,
    onSubmit,
    users,
    // companies,
    publications,
    // problems,
    usersCount,
    // companiesCount,
    publicationsCount,
    // problemsCount,
    searchResultCount,
    mentorBlocks,
    mentorBlocksCount,
    loading
  } = useGlobalSearchInputController();

  return (
    <>
      <Backdrop open={showAutocomplete} onClick={onAutocompleteHide} />
      <SearchRoot>
        <SearchIconWrapper>
          <Search />
        </SearchIconWrapper>
        <form onSubmit={onSubmit}>
          <InputBaseStyled
            onChange={onChange}
            onFocus={onFocusHandle}
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            value={searchValue}
            endAdornment={
              searchValue && (
                <IconButton onClick={onInputClear}>
                  <Close />
                </IconButton>
              )
            }
          />
        </form>

        {showAutocomplete && (
          <Autocomplete>
            {loading && <CircularProgress style={{ alignSelf: 'center' }} />}
            {!searchResultCount ? (
              <EmptyState>No results found</EmptyState>
            ) : (
              <BlockStackStyled typography="caption">
                {usersCount ? (
                  <BlockStyled>
                    <SeeAllLink onClick={() => onAutocompleteLinkClick(`/users?search=${searchValue}`)}>
                      Users: {usersCount}
                    </SeeAllLink>
                    <RowStackStyled>
                      {users.map((user, i) => (
                        <RowStyled key={i}>
                          <Avatar src={user?.image || ''} sx={{ width: 24, height: 24 }}>
                            {userInitials({
                              firstName: user?.firstName,
                              lastName: user?.lastName
                            })}
                          </Avatar>
                          <LinkedButtonStyled onClick={() => onAutocompleteLinkClick(`/user/${user?._id}`)}>
                            <Typography typography="caption">
                              {user?.firstName} {user?.lastName}
                            </Typography>
                          </LinkedButtonStyled>
                        </RowStyled>
                      ))}
                    </RowStackStyled>
                  </BlockStyled>
                ) : null}

                {/*{companiesCount ? (*/}
                {/*  <BlockStyled>*/}
                {/*    <SeeAllLink onClick={() => onAutocompleteLinkClick(`/companies?search=${searchValue}`)}>*/}
                {/*      Companies: {companiesCount}*/}
                {/*    </SeeAllLink>*/}
                {/*    <RowStackStyled>*/}
                {/*      {companies.map((company, i) => (*/}
                {/*        <RowStyled key={i}>*/}
                {/*          <Avatar*/}
                {/*            src={`https://${company?.image}` || ''}*/}
                {/*            sx={{ width: 24, height: 24 }}*/}
                {/*            variant="square"*/}
                {/*          >*/}
                {/*            <DomainOutlined />*/}
                {/*          </Avatar>*/}
                {/*          <LinkedButtonStyled onClick={() => onAutocompleteLinkClick(`/company/${company?._id}`)}>*/}
                {/*            <Typography typography="caption" noWrap={true}>*/}
                {/*              {company?.title}*/}
                {/*            </Typography>*/}
                {/*          </LinkedButtonStyled>*/}
                {/*        </RowStyled>*/}
                {/*      ))}*/}
                {/*    </RowStackStyled>*/}
                {/*  </BlockStyled>*/}
                {/*) : null}*/}

                {publicationsCount ? (
                  <BlockStyled>
                    <SeeAllLink onClick={() => onAutocompleteLinkClick(`/publications?search=${searchValue}`)}>
                      Publications: {publicationsCount}
                    </SeeAllLink>
                    <RowStackStyled>
                      {publications.map((publication, i) => (
                        <RowStyled key={i}>
                          <Avatar src={publication?.image || ''} sx={{ width: 24, height: 24 }} variant="square">
                            <ViewListOutlined />
                          </Avatar>
                          <LinkedButtonStyled
                            onClick={() => onAutocompleteLinkClick(`/publication/${publication?._id}`)}
                          >
                            <Typography typography="caption" noWrap={true}>
                              {publication?.title}
                            </Typography>
                          </LinkedButtonStyled>
                        </RowStyled>
                      ))}
                    </RowStackStyled>
                  </BlockStyled>
                ) : null}

                {/*{problemsCount ? (*/}
                {/*  <BlockStyled>*/}
                {/*    <SeeAllLink onClick={() => onAutocompleteLinkClick(`/problems?search=${searchValue}`)}>*/}
                {/*      Problems: {problemsCount}*/}
                {/*    </SeeAllLink>*/}
                {/*    <RowStackStyled>*/}
                {/*      {problems.map((problem, i) => (*/}
                {/*        <RowStyled key={i}>*/}
                {/*          <Avatar sx={{ width: 24, height: 24 }} variant="square">*/}
                {/*            <Code />*/}
                {/*          </Avatar>*/}
                {/*          <LinkedButtonStyled onClick={() => onAutocompleteLinkClick(`/problem/${problem?._id}`)}>*/}
                {/*            <Typography typography="caption" noWrap={true}>*/}
                {/*              {problem?.title}*/}
                {/*            </Typography>*/}
                {/*          </LinkedButtonStyled>*/}
                {/*        </RowStyled>*/}
                {/*      ))}*/}
                {/*    </RowStackStyled>*/}
                {/*  </BlockStyled>*/}
                {/*) : null}*/}

                {mentorBlocksCount ? (
                  <BlockStyled>
                    <SeeAllLink onClick={() => onAutocompleteLinkClick(`/mentorium?search=${searchValue}`)}>
                      Mentor Blocks: {mentorBlocksCount}
                    </SeeAllLink>
                    <RowStackStyled>
                      {mentorBlocks.map((mentorBlock, i) => (
                        <RowStyled key={i}>
                          <Avatar sx={{ width: 24, height: 24 }} variant="square">
                            <CastForEducation />
                          </Avatar>
                          <LinkedButtonStyled onClick={() => onAutocompleteLinkClick(`/mentorium/${mentorBlock?._id}`)}>
                            <Typography typography="caption" noWrap={true}>
                              {mentorBlock?.title}
                            </Typography>
                          </LinkedButtonStyled>
                        </RowStyled>
                      ))}
                    </RowStackStyled>
                  </BlockStyled>
                ) : null}
              </BlockStackStyled>
            )}
          </Autocomplete>
        )}
      </SearchRoot>
    </>
  );
};

export default GlobalSearchInput;
