import { Alert, Paper, styled, TextField } from '@mui/material';

export const PaperStyled = styled(Paper)`
  width: 370px;
  margin: auto;
  padding: 1.5rem;

  @media (max-width: 414px) {
    width: 90vw;
  }
`;

export const TextFieldStyled = styled(TextField)`
  margin-bottom: 1rem;
`;

export const PaperRegistrationSuccessStyled = styled(Paper)`
  margin: 0 auto;
  background-color: ${props => props.theme.palette.success.light};
  padding: 20px;
  width: fit-content;
`;

export const CenteredDivStyled = styled('div')`
  text-align: center;
`;

export const AlertStyled = styled(Alert)`
  margin-bottom: 1rem;
`;
