import React from 'react';
import { EmptyListMessageStyled } from '../../UserPage.styles';
import { usePublicationListController } from './PublicationsList.controller';
import { ListItems } from '../listItems/ListItems';
import { CircularProgress, Tab, Tabs } from '@mui/material';

export const PublicationsList = () => {
  const { loading, option, OPTION_CONTENT, selectedTab, handleChangeTab } = usePublicationListController();

  const component = () => {
    if (!option.list.length)
      return <EmptyListMessageStyled variant="body1">{option.emptyState}</EmptyListMessageStyled>;
    return <ListItems option={option} />;
  };

  return (
    <>
      <Tabs value={selectedTab} onChange={handleChangeTab}>
        {OPTION_CONTENT.map((el, index) => (
          <Tab key={index} label={el.title} />
        ))}
      </Tabs>

      {loading ? <CircularProgress /> : component()}
    </>
  );
};
