import { useReactiveVar } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { globalVar } from '../../../cache';
import { LocalStorageItems } from '../../../helpers/constants';

export const useEmailUpdateConfirmationController = () => {
  const { isLoggedIn } = useReactiveVar(globalVar);
  const history = useHistory();
  const { activationLinkId }: { activationLinkId: string } = useParams();

  localStorage.setItem(LocalStorageItems.ActivationLinkId, activationLinkId);

  return {
    history,
    isLoggedIn
  };
};
