import React, { Fragment } from 'react';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import moment from 'moment';
import { Delete, Edit, FormatListBulletedOutlined } from '@material-ui/icons';
import { ListEmptyState } from '../emptyStates/listEmptySate/ListEmptyState';
import {
  EntityType,
  Maybe,
  ScheduleSlot,
  ScheduleSlotsGetByParentIdDocument,
  useScheduleSlotDeleteMutation
} from '../../../graphql/generated';
import { EditScheduledItemForm } from './editScheduledItemForm/EditScheduledItemForm';
import { FooterStyled, RootStyled } from './ScheduledList.styles';
import { AddScheduledItemDialog } from './addScheduledItemDialog/AddScheduledItemDialog';
import { useScheduledListController } from './ScheduledList.controller';
import { getSelectableListHook } from '../../mentorium/mentorBlockForm/manageLessons/ManageLessons';
import { useDialogContext } from '../../../providers/dialogProvider/DialogContext';

interface ScheduledListProps {
  parentId: string;
  itemEntityTypeName: EntityType;
  getSelectableListHook: getSelectableListHook;
}

export const ScheduledList: React.FC<ScheduledListProps> = ({
  parentId,
  itemEntityTypeName,
  getSelectableListHook
}) => {
  const { isAddDialogOpen, onAddOpen, onAddClose, scheduledSlotsPaginated, EMPTY_STATE_TEXT } =
    useScheduledListController({ itemEntityTypeName, parentId });

  return (
    <RootStyled>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Scheduled {itemEntityTypeName}</TableCell>
              <TableCell align="right">Start Date</TableCell>
              <TableCell align="right">End Date</TableCell>
              <TableCell align="right">Stream</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scheduledSlotsPaginated?.list?.map(row => {
              return <ScheduledListTableRow key={row?._id} row={row} itemEntityTypeName={itemEntityTypeName} />;
            })}
          </TableBody>
          {!scheduledSlotsPaginated?.totalCount && (
            <TableRow>
              <TableCell colSpan={4}>
                <ListEmptyState
                  icon={<FormatListBulletedOutlined fontSize="large" />}
                  message={EMPTY_STATE_TEXT.message}
                  subMessage={EMPTY_STATE_TEXT.subMessage}
                >
                  <Button onClick={onAddOpen} variant="text">
                    {EMPTY_STATE_TEXT.buttonName}
                  </Button>
                </ListEmptyState>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>
      <FooterStyled>
        <Button onClick={onAddOpen} variant="outlined">
          Add
        </Button>
      </FooterStyled>

      <AddScheduledItemDialog
        isOpen={isAddDialogOpen}
        onClose={onAddClose}
        scheduledSlotsPaginated={scheduledSlotsPaginated}
        getSelectableListHook={getSelectableListHook}
        itemEntityTypeName={itemEntityTypeName}
      />
    </RootStyled>
  );
};

interface CustomTableRowProps {
  row: Maybe<ScheduleSlot>;
  itemEntityTypeName: EntityType;
}

const ScheduledListTableRow: React.FC<CustomTableRowProps> = ({ row, itemEntityTypeName }) => {
  const [scheduledSlotDelete] = useScheduleSlotDeleteMutation();
  const { onDialogOpen, onDialogClose } = useDialogContext();

  const EMPTY_DATE = 'No date';

  const editItemTimeframeHandle = () => {
    onDialogOpen({
      title: `Edit Scheduled ${itemEntityTypeName}: ${row?.entity?.title}?`,
      confirmButtonColor: 'primary',
      children: EditScheduledItemForm,
      childrenProps: {
        itemId: row?.entity._id,
        scheduledItem: row,
        onCancel: onDialogClose,
        itemEntityTypeName
      }
    });
  };
  const deleteItemHandle = () => {
    onDialogOpen({
      onSubmit: async () => {
        scheduledSlotDelete({ variables: { slotId: row?._id }, refetchQueries: [ScheduleSlotsGetByParentIdDocument] });
      },
      title: `Drop Scheduled ${itemEntityTypeName}: ${row?.entity?.title}?`,
      showCancel: true,
      message: `Do you want to drop Scheduled ${itemEntityTypeName}: ${row?.entity?.title}?`,
      confirmButton: 'Drop',
      confirmButtonColor: 'error'
    });
  };

  return (
    <Fragment key={row?._id}>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {row?.entity?.title}
        </TableCell>
        <TableCell align="right">
          {row?.startDate ? moment(Number(row?.startDate)).format('MM/DD/YY - LT') : EMPTY_DATE}
        </TableCell>
        <TableCell align="right">
          {row?.endDate ? moment(Number(row?.endDate)).format('MM/DD/YY - LT') : EMPTY_DATE}
        </TableCell>
        <TableCell align="right">
          {row?.streamProvider ? (
            <Button variant="outlined" size="small" href={row?.streamLink ?? ''} target="_blank">
              {row?.streamProvider}
            </Button>
          ) : (
            'No link'
          )}
        </TableCell>
        <TableCell align="right">
          <Tooltip title="Edit Timeframe">
            <IconButton size="small" onClick={editItemTimeframeHandle}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Drop">
            <IconButton size="small" onClick={deleteItemHandle}>
              <Delete />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
