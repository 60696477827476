import moment from 'moment';
import {
  PublicationFragment,
  PublicationGetDocument,
  usePublicationLikeMutation,
  useUserStarPublicationMutation
} from '../../../graphql/generated';
import { useProfile } from '../../../helpers/customHooks';

export const usePublicationCardController = (publication: PublicationFragment) => {
  const createdAt = publication.createdAt ?? '';
  const pubId = publication._id ?? '';
  const ownerFirstName = publication.owner?.firstName ?? 'Deleted User';
  const ownerLastName = publication.owner?.lastName ?? '';
  const ownerFullName = `${ownerFirstName} ${ownerLastName}`;
  const profileId = useProfile()._id;

  const [publicationLike, { loading: publicationLikeLoading }] = usePublicationLikeMutation({
    variables: { pubId },
    refetchQueries: [{ query: PublicationGetDocument, variables: { pubId } }],
    awaitRefetchQueries: true
  });

  const [userStarPublication, { loading: starPublicationLoading }] = useUserStarPublicationMutation({
    variables: { userId: profileId ? profileId : '', pubId }
  });

  const onLikeHandler = async () => {
    await publicationLike();
  };

  const updateUserPublicationStarred = async () => {
    await userStarPublication();
  };

  const formattedDate = moment(Number(createdAt)).format('MMMM DD YYYY');

  return {
    onLikeHandler,
    updateUserPublicationStarred,
    publicationLikeLoading,
    starPublicationLoading,
    createdAt,
    ownerFullName,
    pubId,
    formattedDate
  };
};
