import React from 'react';
import { Avatar, ListItemIcon, Menu as MenuPopup } from '@mui/material';
import { CastForEducation, ExpandMore, LibraryBooks, PeopleAltOutlined } from '@material-ui/icons';
import {
  LinkStyled,
  ListItemStyled,
  DesktopRootStyled,
  MobileRootStyled,
  DividerStyled,
  MenuItemStyled
} from './Menu.styles';
import { User } from '../../graphql/generated';
import { AppRoutes, ExternalLinks } from '../../helpers/constants';

interface MenuProps {
  onLogout: () => void;
  profile: User;
  currentPage: string;
  isMenuOpen: boolean;
  toggleMenu: () => void;
}

const menuItems = [
  //Dashboard page in progress...
  // {
  //   id: 'dashboard',
  //   name: 'Dashboard',
  //   link: '/dashboard',   !!use {AppRoutes.Dashboard}
  //   icon: <Dashboard />
  // },
  {
    id: 'publications',
    name: 'Publications',
    link: AppRoutes.Publications,
    icon: <LibraryBooks />
  },
  {
    id: 'users',
    name: 'Users',
    link: AppRoutes.Users,
    icon: <PeopleAltOutlined />
  },
  // {
  //   id: 'companies',
  //   name: 'Companies',
  //   link: AppRoutes.Companies,
  //   icon: <Public />
  // },
  // {
  //   id: 'problems',
  //   name: 'Problems',
  //   link: AppRoutes.Problems,
  //   icon: <Code />
  // },
  {
    id: 'mentorium',
    name: 'Mentorium',
    link: AppRoutes.MentorBlockList,
    icon: <CastForEducation />
  }
];
const Menu: React.FC<MenuProps> = ({ onLogout, profile, currentPage, isMenuOpen, toggleMenu }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleProfilePopupOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfilePopupClose = () => {
    setAnchorEl(null);
  };

  const handleProfilePopupOtionClick = () => {
    handleProfilePopupClose();
    toggleMenu();
  };

  const handleHelp = () => {
    window.location.href = ExternalLinks.MailToInfo;
  };

  const LINKS = (
    <>
      {menuItems.map(item => (
        <LinkStyled to={item.link} key={item.id} onClick={toggleMenu}>
          <ListItemStyled id={item.id} isActive={currentPage === item.id}>
            <ListItemIcon children={item.icon} />
            {item.name}
          </ListItemStyled>
        </LinkStyled>
      ))}

      <DividerStyled />

      <ListItemStyled id="account" onClick={handleProfilePopupOpen}>
        <ListItemIcon children={<Avatar src={profile.image || ''} />} />
        {profile.firstName + ' ' + profile.lastName}
        <ExpandMore />
      </ListItemStyled>
      <MenuPopup
        id="profile"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleProfilePopupClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <LinkStyled to={`/profile/${profile._id}`}>
          <MenuItemStyled onClick={handleProfilePopupOtionClick}>Profile</MenuItemStyled>
        </LinkStyled>
        <LinkStyled to={AppRoutes.ManageContentLessons}>
          <MenuItemStyled onClick={handleProfilePopupOtionClick}>Content</MenuItemStyled>
        </LinkStyled>
        <hr />
        <MenuItemStyled onClick={handleHelp}>Help / Feedback</MenuItemStyled>
        <MenuItemStyled id="logout" onClick={onLogout}>
          Logout
        </MenuItemStyled>
      </MenuPopup>
    </>
  );
  return (
    <>
      <DesktopRootStyled>{LINKS}</DesktopRootStyled>
      <MobileRootStyled onClose={toggleMenu} open={isMenuOpen} variant="temporary">
        {LINKS}
      </MobileRootStyled>
    </>
  );
};

export default Menu;
