import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  Maybe,
  MentorBlock,
  Problem,
  Publication,
  User,
  UserUpdateDocument,
  useUserGetQuery
} from '../../../graphql/generated';
import { userInitials } from '../../../helpers/simplifiedData';
import { useEffect, useMemo, useState } from 'react';
import { checkPermission } from '../../../services/checkPermission';

export interface OptionTypes {
  title: string;
  list: Maybe<Publication | MentorBlock | Omit<User, 'roles'> | Problem>[];
  linkDirectory: string;
  buttonName?: string;
  onClick?: (_id: string) => void;
  emptyState: string;
}

export const useUserPageController = () => {
  const history = useHistory();
  const isHistoryBackExist = !!history.location.key;
  const { userId }: { userId: string } = useParams();
  const isOwner = checkPermission(UserUpdateDocument, userId);
  const { loading, data: userData } = useUserGetQuery({
    variables: { userId },
    fetchPolicy: 'network-only'
  });

  const pageData = useMemo(() => {
    return {
      languages: userData?.user?.languages ?? [],
      firstName: userData?.user?.firstName ?? '',
      lastName: userData?.user?.lastName ?? '',
      image: userData?.user?.image ?? '',
      jobTitle: userData?.user?.jobTitle ?? '',
      about: userData?.user?.about ?? '',
      email: userData?.user?.email ?? '',
      level: userData?.user?.level ?? 0,
      roles: userData?.user?.roles ?? []
    };
  }, [userData]);

  const OPTION_CONTENT = [
    {
      title: 'Publications',
      linkDirectory: 'publication'
    },
    {
      title: 'Mentorium',
      linkDirectory: 'mentorium'
    },
    {
      title: 'Friends',
      linkDirectory: 'user'
    }
    // {
    //   title: 'Problems',
    //   linkDirectory: 'problem'
    // }
  ];

  const [option, setOption] = useState<any>(OPTION_CONTENT[0]);

  useEffect(() => {
    setOption(OPTION_CONTENT.find(opt => opt.title === option.title));
  }, [userData]);

  const onEditHandle = () => {
    history.push(`/user/${userId}/edit`);
  };

  const onBackHandle = () => {
    history.goBack();
  };

  const initials = userInitials({ firstName: pageData.firstName, lastName: pageData.lastName, email: pageData.email });
  const fullName = `${pageData.firstName} ${pageData.lastName}`.trim();

  return {
    loading,
    isHistoryBackExist,
    userId,
    initials,
    fullName,
    isOwner,
    onEditHandle,
    onBackHandle,
    option,
    setOption,
    OPTION_CONTENT,
    ...pageData
  };
};
