import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert, Button, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { useUserPasswordUpdateMutation } from '../../graphql/generated';
import { Validate } from '../../helpers/validation';
import { PaperStyled, TextFieldStyled } from './Account.styles';
import RedirectToLogin from './RedirectToLogin';

type RestorePasswordConfirmProps = RouteComponentProps<{
  activationLinkId: string;
}>;

const RestorePasswordConfirm: FC<RestorePasswordConfirmProps> = ({ match: { params } }) => {
  const [password, setPassword] = useState('');
  const [passwordValidationError, setPasswordValidationError] = useState('');
  const [confirm, { loading, data, error }] = useUserPasswordUpdateMutation({
    fetchPolicy: 'network-only'
  });
  const [showPassword, setShowPassword] = useState(false);
  const onFinish = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (Validate.password.check(password)) {
      await confirm({
        variables: {
          password: password,
          resetId: params.activationLinkId
        }
      });
    } else {
      setPasswordValidationError(Validate.password.error);
    }
  };

  const ErrorAlert = ({
    userPasswordReset,
    error
  }: {
    userPasswordReset?: string | null;
    error: string | undefined;
  }) => {
    if (!userPasswordReset) return null;
    if (error)
      return (
        <Alert className="mt-3 mb-3" severity="error">
          {error}
        </Alert>
      );

    return (
      <Alert className="mt-3 mb-3" severity="success">
        {userPasswordReset}
      </Alert>
    );
  };

  return (
    <PaperStyled>
      <h3>Restore password</h3>

      <form onSubmit={onFinish}>
        <TextFieldStyled
          id="password"
          required={true}
          size="small"
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth={true}
          autoFocus={true}
          value={password}
          onChange={({ target }) => {
            setPasswordValidationError('');
            setPassword(target.value);
          }}
          onBlur={({ target }) => {
            if (!Validate.password.check(target.value)) setPasswordValidationError(Validate.password.error);
          }}
          helperText={passwordValidationError}
          error={!!passwordValidationError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                  }}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <Button
          type="submit"
          variant="contained"
          startIcon={loading && <CircularProgress size={18} />}
          disabled={!!passwordValidationError || loading}
        >
          Send
        </Button>
      </form>

      <ErrorAlert userPasswordReset={data?.userPasswordReset} error={error?.message} />

      <RedirectToLogin />
    </PaperStyled>
  );
};

export default withRouter(RestorePasswordConfirm);
