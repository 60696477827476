import { debounce } from 'lodash';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useGlobalSearchLazyQuery } from '../../../graphql/generated';
import { useHistory } from 'react-router-dom';
import { useDidMountEffect, useQueryParams } from '../../../helpers/customHooks';

export const useGlobalSearchInputController = () => {
  const history = useHistory();
  const { search } = useQueryParams(history.location.search);
  const [searchQuery, { data, loading }] = useGlobalSearchLazyQuery();
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [searchValue, setSearchValue] = useState(search || '');
  const searchResultCount = data?.globalSearch.totalCount ?? 0;
  const users = data?.globalSearch.users?.list ?? [];
  const usersCount = data?.globalSearch.users?.totalCount ?? 0;
  const companies = data?.globalSearch.companies?.list ?? [];
  const companiesCount = data?.globalSearch.companies?.totalCount ?? 0;
  const publications = data?.globalSearch.publications?.list ?? [];
  const publicationsCount = data?.globalSearch.publications?.totalCount ?? 0;
  const problems = data?.globalSearch.problems?.list ?? [];
  const problemsCount = data?.globalSearch.problems?.totalCount ?? 0;
  const mentorBlocks = data?.globalSearch.mentorBlocks?.list ?? [];
  const mentorBlocksCount = data?.globalSearch.mentorBlocks?.totalCount ?? 0;

  const searchDebounced = useCallback(
    debounce(value => {
      if (value.length) {
        searchQuery({ variables: { value: value } });
      }
    }, 400),
    []
  );

  useEffect(() => {
    setSearchValue(search || '');
  }, [history.location.search]);

  useEffect(() => {
    if (searchValue) {
      searchDebounced(searchValue);
    }
  }, [searchValue]);

  useDidMountEffect(() => {
    onAutocompleteHide();
  }, [history.location.pathname]);

  const onChange = (e: { target: { value: string } }) => {
    setSearchValue(e.target.value);
    setShowAutocomplete(!!e.target.value.length);
  };
  const onFocusHandle = (e: { target: { value: string } }) => {
    setShowAutocomplete(!!e.target.value.length);
  };
  const onAutocompleteHide = () => setShowAutocomplete(false);
  const onInputClear = () => {
    setSearchValue('');
    history.push({
      pathname: history.location.pathname,
      search: `?search=`
    });
    onAutocompleteHide();
  };
  const onAutocompleteLinkClick = (path: string) => {
    onAutocompleteHide();
    history.push(path);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const input = form.elements[0] as HTMLInputElement;
    input.blur();
    onAutocompleteHide();
    history.push({
      pathname: history.location.pathname,
      search: `?search=${searchValue}`
    });
  };

  return {
    onChange,
    searchValue,
    showAutocomplete,
    onAutocompleteHide,
    onAutocompleteLinkClick,
    onInputClear,
    onFocusHandle,
    onSubmit,
    users,
    companies,
    publications,
    problems,
    mentorBlocks,
    usersCount,
    companiesCount,
    publicationsCount,
    problemsCount,
    mentorBlocksCount,
    searchResultCount,
    loading
  };
};
