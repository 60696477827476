import React, { useEffect, useMemo, useState } from 'react';
import {
  useMentorBlockGetAllByMenteeIdQuery,
  useMentorBlockGetAllByOwnerIdQuery,
  useUserStarMentorBlockMutation,
  useUserStarredMentorBlocksGetQuery
} from '../../../../../graphql/generated';
import { useParams } from 'react-router-dom';

export const useMentorBlocksListController = () => {
  const { userId }: { userId: string } = useParams();
  const { loading: mentorsLoading, data: profileMentorsData } = useMentorBlockGetAllByMenteeIdQuery({
    variables: { menteeId: userId }
  });
  const { loading: starredMentorsLoading, data: starredMentorsData } = useUserStarredMentorBlocksGetQuery({
    variables: { userId }
  });

  const { loading: ownedMentorsCoursesLoading, data: ownedMentorsData } = useMentorBlockGetAllByOwnerIdQuery({
    variables: { ownerId: userId }
  });

  const [starMentorBlockHandle] = useUserStarMentorBlockMutation();

  const mentorsData = useMemo(() => {
    return {
      profileMentors: profileMentorsData?.mentorGetAllByMenteeId?.list ?? [],
      starredMentors: starredMentorsData?.user?.starredMentorBlocks ?? [],
      ownedMentors: ownedMentorsData?.mentorGetAllByOwnerId?.list ?? []
    };
  }, [profileMentorsData, starredMentorsData, ownedMentorsData]);

  const onUnstarMentorBlock = (blockId: string) => {
    starMentorBlockHandle({ variables: { userId, blockId } });
  };

  const OPTION_CONTENT = [
    {
      title: 'I am learning',
      list: mentorsData.profileMentors,
      linkDirectory: 'mentorium',
      buttonName: '',
      onClick: () => null,
      emptyState: 'You are not learning any courses'
    },
    {
      title: 'Starred Courses',
      list: mentorsData.starredMentors,
      linkDirectory: 'mentorium',
      buttonName: 'Unstar',
      onClick: onUnstarMentorBlock,
      emptyState: 'You have no starred courses'
    },
    {
      title: 'Owned Courses',
      list: mentorsData.ownedMentors,
      linkDirectory: 'mentorium',
      buttonName: '',
      onClick: () => null,
      emptyState: 'You have no owned courses'
    }
  ];

  const [option, setOption] = useState<any>(OPTION_CONTENT[0]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setOption(OPTION_CONTENT.find(opt => opt.title === option.title));
  }, [mentorsData]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setOption(OPTION_CONTENT[newValue]);
  };

  return {
    loading: starredMentorsLoading || mentorsLoading || ownedMentorsCoursesLoading,
    option,
    OPTION_CONTENT,
    selectedTab,
    handleChangeTab
  };
};
