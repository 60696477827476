import { Stack, styled, Typography } from '@mui/material';
import { Star, StarBorder } from '@material-ui/icons';
import { Link } from 'react-router-dom';

export const StyledCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;
export const StyledCardHeaderLeft = styled('div')`
  display: flex;
`;

export const StyledUserProfileLinkWrapper = styled(Stack)`
  margin-left: 2rem;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
  ${({ theme }) => theme.typography.subtitle1};

  :hover {
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const StyledFooterWrapper = styled('div')`
  padding: 0 1rem;
`;

export const StyledHeaderWrapper = styled('div')`
  padding: 1rem;
`;

export const StyledStar = styled(Star)(({ theme }) => ({
  color: theme.palette.warning.dark
}));

export const StyledStarBorder = styled(StarBorder)(({ theme }) => ({
  color: theme.palette.warning.dark
}));

export const StyledTypography = styled(Typography)`
  overflow-wrap: break-word;
`;
