import { AppRoutes } from '../../../helpers/constants';
import checkRedirectPath from '../../../services/checkRedirectPath';
import { useEmailUpdateConfirmationController } from './EmailUpdateConfirmation.controller';

const EmailUpdateConfirmation = () => {
  const { isLoggedIn, history } = useEmailUpdateConfirmationController();
  if (isLoggedIn) checkRedirectPath();
  else history.push(AppRoutes.Login);

  return null;
};

export default EmailUpdateConfirmation;
