import { useState } from 'react';

/**
 * @example
 *
 * const MyComponent = () => {
 *  const { isOpen, onOpen, onClose } = useDisclosure();
 *  <button onClick={onOpen}>Trigger</button>
 *
 *  return (
 *  <Dialog isOpen={isOpen}>
 *    <Dialog.CloseButton onClose={onClose}/>
 *    <Dialog.Body>Content to display</Dialog.body>
 *  </Dialog>
 *  )
 * }
 *
 */
export const useDisclosure = () => {
  const [isOpenState, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const onToggle = () => {
    isOpenState ? onClose() : onOpen();
  };

  return {
    isOpen: isOpenState,
    onClose,
    onOpen,
    onToggle
  } as const;
};
