import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import shape from './shape';
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import breakpoints from './breakpoints';
import { ThemeColorModeProvider, useThemeColorModeContext } from '../providers/ThemeColorModeProvider';

ThemeConfig.propTypes = {
  children: PropTypes.node
};

ThemeConfigProvider.propTypes = {
  children: PropTypes.node
};

function ThemeConfig({ children }) {
  //Use OS color mode (for future purposes)
  // const prefersOSDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { mode } = useThemeColorModeContext();
  const isDarkMode = mode === 'dark';

  const themeOptions = useMemo(
    () => ({
      palette: isDarkMode ? palette.dark : palette.light,
      shape,
      typography,
      shadows: isDarkMode ? shadows.dark : shadows.light,
      customShadows: isDarkMode ? customShadows.dark : customShadows.light,
      breakpoints
    }),
    [isDarkMode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function ThemeConfigProvider({ children }) {
  return (
    <ThemeColorModeProvider>
      <ThemeConfig>{children}</ThemeConfig>
    </ThemeColorModeProvider>
  );
}

export default ThemeConfigProvider;
