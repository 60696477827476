import { Avatar, AvatarGroup, Paper, Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import MarkdownPreview from '../../sharedComponents/markdown/MarkdownPreview';
import { Star, StarBorder } from '@material-ui/icons';

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-wrap: wrap;
  }
`;

export const AvatarBlockStyled = styled(Stack)`
  margin: 1rem;
  gap: 2px;
`;

export const AvatarOwnerStyled = styled(Avatar)`
  width: 126px;
  height: 126px;
  font-size: 2rem;
`;

export const AvatarGroupStyled = styled(AvatarGroup)`
  gap: 2px;
  justify-content: flex-end;

  & > .MuiAvatar-root {
    width: 30px;
    height: 30px;
    font-size: 12px;
    margin: 0;
    border: unset;
  }
`;

export const TitleBlockStyled = styled('div')`
  margin: 1em;
  flex-basis: 70%;
  overflow: hidden;
`;

export const DescriptionMarkdownStyled = styled(MarkdownPreview)`
  max-height: 200px;
  overflow: hidden;
  margin: 1rem 0;
`;

export const TypeGroupStyled = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 1rem;
  align-items: center;
  align-self: flex-start;
`;

export const RatingStyled = styled('div')`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

export const StackStyled = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};

  :hover {
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const StyledStar = styled(Star)(({ theme }) => ({
  color: theme.palette.warning.dark
}));

export const StyledStarBorder = styled(StarBorder)(({ theme }) => ({
  color: theme.palette.warning.dark
}));
