import React from 'react';
import { Typography } from '@mui/material';
import { OptionTypes } from '../../UserPage.controller';
import { Maybe, Problem } from '../../../../../graphql/generated';
import { InfoRow, LinkStyled, RootStyled, RowStyled } from './ProblemsList.styles';

interface OverridedOptionTypes extends Omit<OptionTypes, 'list'> {
  list: Maybe<Problem>[];
}

interface UserProblemsProps {
  option: OverridedOptionTypes;
}

export const ProblemsListItems: React.FC<UserProblemsProps> = ({ option }) => {
  const { list, linkDirectory } = option;

  return (
    <RootStyled>
      {list.map(el => (
        <RowStyled key={el?._id}>
          <LinkStyled to={`/${linkDirectory}/${el?._id}`}>
            <Typography noWrap={true} variant="subtitle1" title={el?.title || 'empty'}>
              {el?.title}
            </Typography>
          </LinkStyled>

          <InfoRow>
            <Typography variant="body1" title="Position">
              <b>Position: </b>
              {el?.jobTitle}
            </Typography>

            <Typography gap={4} variant="body1" title="Company">
              <b>Company: </b>
              <LinkStyled to={`/company/${el?.company?._id}`}>{el?.company?.title}</LinkStyled>
            </Typography>
          </InfoRow>
        </RowStyled>
      ))}
    </RootStyled>
  );
};
