import { Stack, styled, Typography } from '@mui/material';

export const EventsContainer = styled(Stack)`
  flex-direction: row;
  gap: 1rem;
  overflow: auto;
`;

export const EventCard = styled(Stack)`
  border: 1px solid ${({ theme }) => theme.palette.grey['300']};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: hidden;
  min-width: 150px;
  height: 150px;
`;

export const EventCardHeader = styled(Typography)`
  color: white;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey['300']};
  padding: 0.25rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const EventCardBody = styled(Stack)`
  padding: 0.5rem;
  flex-grow: 1;
  overflow: hidden;
`;

export const EventCardTitleStyled = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
