import { Box, Paper, Stack, styled } from '@mui/material';

export const RootStyled = styled(Box)`
  padding-bottom: 48px;
`;

export const PaperStyled = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FieldsStackStyled = styled(Stack)`
  row-gap: 16px;
  margin-bottom: 16px;
`;

export const FooterStackStyled = styled(Stack)`
  flex-direction: row;
  justify-content: end;
  margin-top: 16px;
  column-gap: 16px;
`;

export const CreateButtonStyled = styled('div')`
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
`;

export const RowStyled = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;
