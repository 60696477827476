import { Paper, styled } from '@mui/material';

export const PaperRegistrationSuccessStyled = styled(Paper)`
  margin: 0 auto;
  background-color: ${props => props.theme.palette.success.light};
  padding: 20px;
  width: fit-content;
`;

export const HaveAnAccountStyled = styled('div')`
  text-align: center;
  margin-top: 1rem;
`;
