import React, { useState } from 'react';
import { DialogContext } from './DialogContext';
import { useDisclosure } from '../../helpers/useDisclosure';
import { Close } from '@material-ui/icons';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { CloseButtonStyled, DialogTitleStyled } from './Dialog.styles';
import Dialog from '@mui/material/Dialog';

export type ModalConfig = {
  onSubmit?: VoidFunction;
  title?: string;
  showClose?: boolean;
  showCancel?: boolean;
  confirmButton?: string;
  confirmButtonColor?: 'primary' | 'secondary' | 'error';
  message?: string;
  children?: React.ElementType;
  childrenProps?: React.ComponentProps<React.ElementType>;
};

export const DialogProvider: React.FC = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Default state
  const defaultModalConfig: ModalConfig = {
    showClose: true,
    showCancel: false,
    confirmButton: 'OK',
    confirmButtonColor: 'primary'
  };

  // State to hold modal configuration
  const [modalConfig, setModalConfig] = useState<ModalConfig>(defaultModalConfig);

  const {
    title,
    confirmButtonColor,
    confirmButton,
    message,
    onSubmit,
    showClose,
    showCancel,
    children: dialogChildren,
    childrenProps
  } = modalConfig;

  // Function to update modal configuration
  const onDialogOpen = (config: ModalConfig) => {
    setModalConfig(prev => ({ ...prev, ...config }));
    onOpen(); // Automatically open the modal when its configuration is set
  };

  const onDialogClose = () => {
    setModalConfig(defaultModalConfig); //Back to default every time when dialog is closed to avoid keep previous config
    onClose();
  };

  const submitHandle = () => {
    if (onSubmit) {
      onSubmit();
    }

    onDialogClose();
  };

  //Executing creation of an element from dialogChildren
  const DialogChildComponent =
    dialogChildren && React.createElement(dialogChildren as React.ElementType, childrenProps);

  return (
    <DialogContext.Provider value={{ isDialogOpen: isOpen, onDialogClose: onDialogClose, onDialogOpen }}>
      {children}
      <Dialog open={isOpen} onClose={onDialogClose}>
        {showClose && (
          <CloseButtonStyled id="dialog-close" onClick={onDialogClose}>
            <Close />
          </CloseButtonStyled>
        )}
        <DialogTitleStyled id="dialog-title" showClose={showClose}>
          {title}
        </DialogTitleStyled>
        <DialogContent>
          <DialogContentText id="dialog-description">{message}</DialogContentText>
          {DialogChildComponent}
        </DialogContent>
        <DialogActions>
          {showCancel && (
            <Button id="dialog-cancel" onClick={onDialogClose}>
              Cancel
            </Button>
          )}
          {onSubmit && (
            <Button id="dialog-submit" color={confirmButtonColor} variant="contained" onClick={submitHandle} autoFocus>
              {confirmButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
};
