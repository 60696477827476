import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useUserEmailUpdateMutation, useUserGetQuery, useUserUpdateMutation } from '../../../graphql/generated';
import { LocalStorageItems } from '../../../helpers/constants';
import { REGEX, Validate } from '../../../helpers/validation';

type OnDialogSubmitTypes = Pick<FieldValues, 'email' | 'password'>;

export const useUserEditFormController = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userId }: { userId: string } = useParams();
  const history = useHistory();
  const [userUpdate, { loading: userUpdateLoading, error: userUpdateError }] = useUserUpdateMutation();
  const [userEmailUpdate, { loading: emailUpdateLoading }] = useUserEmailUpdateMutation();
  const { data, loading: userGetLoading, error: userGetError } = useUserGetQuery({ variables: { userId } });
  const ownerId = get(data, 'user._id', '');
  const user = get(data, 'user', null);
  const isOwner = localStorage.getItem(LocalStorageItems.UserId) === user?._id;

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .matches(REGEX.userSingleName, Validate.userSingleName.error)
      .required('First name is required')
      .trim(),
    lastName: yup
      .string()
      .matches(REGEX.userSingleName, Validate.userSingleName.error)
      .required('Last name is required')
      .trim(),
    jobTitle: yup
      .string()
      .matches(REGEX.singleInput, Validate.singleInput.error)
      .required('Job title is required')
      .trim(),
    image: yup.string().url().trim(),
    about: yup.string().matches(REGEX.message, Validate.message.error).trim(),
    languages: yup.array()
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
    control
  } = useForm<FieldValues>({
    resolver: yupResolver(
      isOwner
        ? validationSchema.concat(
            yup.object({
              email: yup.string().matches(REGEX.email, Validate.email.error).required('Email is required').trim()
            })
          )
        : validationSchema
    ),
    mode: 'onSubmit'
  });

  useEffect(() => {
    reset({
      firstName: user?.firstName,
      lastName: user?.lastName,
      jobTitle: user?.jobTitle,
      image: user?.image,
      about: user?.about,
      languages: user?.languages,
      email: user?.email,
      password: ''
    });
  }, [data]);

  const onCloseFormHandle = () => {
    history.push(`/user/${ownerId}`);
  };

  const onSubmitFormHandle = handleSubmit(async (data: FieldValues) => {
    const { email, password, ...restValues } = data;
    if (email !== user?.email) onDialogOpenHandle();
    else {
      reset(data);
      const { errors } = await userUpdate({ variables: { userId: ownerId, values: restValues } });
      if (!errors) {
        history.goBack();
        enqueueSnackbar('User updated', { variant: 'success' });
      }
    }
  });

  //EMAIL UPDATE DIALOG DATA
  const [showDialog, setShowDialog] = useState(false);

  const onDialogOpenHandle = () => {
    setShowDialog(true);
  };

  const onDialogCloseHandle = () => {
    setShowDialog(false);
    setValue('password', '');
  };

  const onEmailUpdateHandle = async (values: OnDialogSubmitTypes) => {
    const { errors, data } = await userEmailUpdate({ variables: { userId: ownerId, values } });
    if (errors) {
      enqueueSnackbar(errors[0].message, { variant: 'error' });
      return false;
    } else {
      enqueueSnackbar(data?.userEmailUpdate, { variant: 'success' });
      return true;
    }
  };

  const onDialogSubmitHandle = handleSubmit(async data => {
    const { email, password } = data;
    const result = await onEmailUpdateHandle({ email, password });
    if (result) {
      onDialogCloseHandle();
      setValue('email', user?.email);
    }
  });

  return {
    apiError: userUpdateError?.message || userGetError?.message,
    formErrors: errors,
    userGetLoading,
    userUpdateLoading,
    ownerId,
    isOwner,
    isDirty,
    onSubmitFormHandle,
    onCloseFormHandle,
    register,
    control,
    dialogData: {
      showDialog,
      onDialogCloseHandle,
      onDialogSubmitHandle,
      emailUpdateLoading,
      currentEmail: user?.email,
      updatedEmail: getValues('email')
    }
  };
};
