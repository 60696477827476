import React from 'react';
import { Typography } from '@mui/material';

import { TaskAnswerForm } from './TaskAnswerForm/TaskAnswerForm';
import { TaskAnswersList } from './taskAnswersList/TaskAnswersList';
import { CreateButtonStyled, PaginationStyled, RootStyled } from './TaskAnswersListView.styles';

import { ListEmptyState } from '../../sharedComponents/emptyStates/listEmptySate/ListEmptyState';
import { LoaderStyled } from '../../user/userPage/UserPage.styles';
import { useTaskAnswersListViewController } from './TaskAnswersListView.controller';

interface TaskAnswerViewProps {
  isMentor: boolean;
}

export const TaskAnswersListView: React.FC<TaskAnswerViewProps> = ({ isMentor }) => {
  const {
    submitAnswerHandle,
    onPageChangeHandle,
    startAnswerHandle,
    isPaginationDisplayed,
    isFormShow,
    isAnswered,
    taskAnswersList,
    totalCount,
    totalPages,
    loading,
    page,
    TEXT
  } = useTaskAnswersListViewController();

  return (
    <RootStyled>
      <Typography variant="h3">{TEXT.answersListLabel}</Typography>
      {loading ? (
        <LoaderStyled />
      ) : (
        <React.Fragment>
          {!totalCount && <ListEmptyState message={TEXT.answersListEmptyStateMessage} />}
          <TaskAnswersList list={taskAnswersList} isMentor={isMentor} />
          {isPaginationDisplayed && (
            <PaginationStyled
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={onPageChangeHandle}
            />
          )}
          {!isFormShow && !isMentor && !isAnswered && (
            <CreateButtonStyled variant="contained" onClick={startAnswerHandle}>
              {TEXT.buttonCreateAnAnswer}
            </CreateButtonStyled>
          )}
          {isFormShow && <TaskAnswerForm onSubmit={submitAnswerHandle} onClose={startAnswerHandle} />}
        </React.Fragment>
      )}
    </RootStyled>
  );
};
