import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { LocalStorageItems } from '../helpers/constants';

export default new ApolloLink((operation: Operation, forward: NextLink) => {
  const token = localStorage.getItem(LocalStorageItems.Token);

  if (token) {
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    });
  }

  return forward(operation);
});
